import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import { useLocale } from '@repo/i18n';
import { getPaymentPlanInfo } from '@repo/widget-utils/traversals/paymentPlan';
import { MembershipIntentMultiResponse, MembershipPaymentPlan } from '@repo/types';
import CreditsPayment from './CreditsPayment';
import MembershipCardPayment from './MembershipCardPayment';
import MoneyPaymentPanel from './MoneyPaymentPanel';
import { useAtom } from 'ximple';
import { paymentIntentAtom } from 'src/state/paymentIntentAtom.atom';
import {
    findValueCardValidToDisplayDateText,
    getValidUntilDisplayText,
} from '@repo/widget-utils/display-helper';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { getValueCardDateRange } from '@repo/widget-utils/DateHelpers';
import { sumBy } from 'lodash-es';
import { useCartContext } from 'src/widgets/CartContext';

function getAllPaymentPlans(intents: MembershipIntentMultiResponse | null) {
    let plans: MembershipPaymentPlan[] = [];
    if (intents?.activityReservations) {
        plans = intents.activityReservations.flatMap((ar) =>
            Object.values(ar.paymentPlanByTourId).flatMap((p) => p.tickets),
        );
    }
    if (intents?.timeslotReservations) {
        plans = plans.concat(intents.timeslotReservations.flatMap((tr) => tr.paymentPlan.tickets));
    }
    return plans;
}

export default function PaymentOptionsPanel() {
    const { t } = useLocale();

    const { valueCardsForUser } = useMemberContext();
    const { paymentPlanResponse, isLoadingPaymentPlan: isLoadingIntents } = useCartContext();

    const [
        { valueCardIdsToIgnore, valueCardProductIdsToIgnore, valueCardProductTypeIdsToIgnore },
        updateIgnoredValueCards,
    ] = useAtom(paymentIntentAtom);

    const [isCreditsAndCurrencyNeeded, setIsCreditsAndCurrencyNeeded] = useState(false);

    const paymentPlanInfo = useMemo(
        () => getPaymentPlanInfo(getAllPaymentPlans(paymentPlanResponse), valueCardsForUser),
        [paymentPlanResponse, valueCardsForUser],
    );

    paymentPlanInfo.creditsGroup.valueCardIds;
    const valueCards = valueCardsForUser.filter((vc) =>
        paymentPlanInfo.creditsGroup.valueCardIds.includes(vc.id),
    );
    const creditsLeft = sumBy(valueCards, (vc) => (vc.balance?.creditsAvailable ?? 0) / 100);

    useEffect(() => {
        const paymentPlans = getAllPaymentPlans(paymentPlanResponse);
        const creditCost = paymentPlans
            .flatMap((x) => x.valueCardUsages)
            .reduce((pVal, cVal) => pVal + cVal.creditCost, 0);
        const currencyCost = paymentPlans
            .filter((x) => x.currencyCost !== null)
            .reduce((pVal, cVal) => pVal + (cVal.currencyCost?.price ?? 0), 0);

        setIsCreditsAndCurrencyNeeded(
            creditCost === 0 ? false : creditCost > 0 && currencyCost > 0,
        );
    }, [paymentPlanResponse]);

    function updatePeriodCardIdsToIgnoreAndIntent(periodCard: {
        valueCardId: number;
        valueCardProductId: number;
        valueCardProductTypeId: number;
        name: string;
        checked: boolean;
    }) {
        const newValueCardIdsToIgnore = getNewIdsToIgnore(
            periodCard.checked,
            valueCardIdsToIgnore,
            [periodCard.valueCardId],
        );
        updateIgnoredValueCards({
            valueCardIdsToIgnore: newValueCardIdsToIgnore,
            valueCardProductIdsToIgnore,
            valueCardProductTypeIdsToIgnore,
        });
    }

    function updateCreditsIdsToIgnoreAndIntent() {
        const newValueCardProductTypeIdsToIgnore = getNewIdsToIgnore(
            paymentPlanInfo.creditsGroup.checked,
            valueCardProductTypeIdsToIgnore,
            paymentPlanInfo.creditsGroup.valueCardProductTypeIds,
        );
        updateIgnoredValueCards({
            valueCardProductTypeIdsToIgnore: newValueCardProductTypeIdsToIgnore,
            valueCardIdsToIgnore,
            valueCardProductIdsToIgnore,
        });
    }

    const isCreditsPaymentDisabled =
        isLoadingIntents ||
        (paymentPlanInfo.currencyCost <= 0 && !paymentPlanInfo.creditsGroup.checked);

    const isCurrencyPaymentDisabled =
        isLoadingIntents ||
        (paymentPlanInfo.currencyCost > 0 && paymentPlanInfo.creditsGroup.checked) ||
        (paymentPlanInfo.currencyCost <= 0 && !paymentPlanInfo.creditsGroup.checked);

    const creditsVariant = getVariant(isCreditsAndCurrencyNeeded);

    return (
        <Layout
            flexDirection="column"
            sx={{ position: 'relative', opacity: isLoadingIntents ? 0.5 : 1 }}
        >
            <Layout flexDirection="column" spacing="small">
                <Layout flexDirection="column" spacing="wide">
                    <Layout flexDirection="column" spacing="small">
                        {paymentPlanInfo.periodCard.map((periodCard) => {
                            const validToDisplayDateText = findValueCardValidToDisplayDateText(
                                periodCard.membershipUserValueCard,
                            );
                            const validUntilText = getValidUntilDisplayText(
                                validToDisplayDateText,
                                t,
                            );

                            return (
                                <MembershipCardPayment
                                    key={`single-${periodCard.valueCardId}`}
                                    text={periodCard.name}
                                    subtext={validUntilText}
                                    valueCardDescription={periodCard.description}
                                    variant={getVariant(false, 'checkbox')}
                                    isChecked={periodCard.checked}
                                    onClick={() => updatePeriodCardIdsToIgnoreAndIntent(periodCard)}
                                    disabled={isLoadingIntents}
                                    membership={getValueCardDateRange(
                                        periodCard.validFrom,
                                        validToDisplayDateText,
                                    )}
                                />
                            );
                        })}
                    </Layout>
                    <Layout flexDirection="column" spacing="small">
                        {paymentPlanInfo.periodCard && paymentPlanInfo.periodCard.length > 0 && (
                            <PanelTitle>{t.choosePaymentOtherMethods}</PanelTitle>
                        )}
                        {paymentPlanInfo.creditsGroup.valueCardIds.length > 0 && (
                            <CreditsPayment
                                key={`single-${paymentPlanInfo.creditsGroup.valueCardIds.join(
                                    ',',
                                )}`}
                                creditsIds={paymentPlanInfo.creditsGroup.valueCardIds}
                                text={t.credits}
                                subtext={creditsLeft + ' ' + t.creditsLeft}
                                variant={creditsVariant}
                                disabled={isCreditsPaymentDisabled}
                                isChecked={paymentPlanInfo.creditsGroup.checked}
                                onClick={() => {
                                    if (
                                        !isCreditsPaymentDisabled &&
                                        (!paymentPlanInfo.creditsGroup.checked ||
                                            creditsVariant === 'checkbox')
                                    ) {
                                        updateCreditsIdsToIgnoreAndIntent();
                                    }
                                }}
                            />
                        )}
                    </Layout>
                </Layout>
                <MoneyPaymentPanel
                    variant={creditsVariant}
                    disabled={isCurrencyPaymentDisabled}
                    isChecked={paymentPlanInfo.currencyCost > 0}
                    onClick={() => {
                        if (!isCurrencyPaymentDisabled && paymentPlanInfo.currencyCost <= 0)
                            updateCreditsIdsToIgnoreAndIntent();
                    }}
                />
            </Layout>
            {isLoadingIntents && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '40%',
                        right: '0',
                        left: '0',
                        margin: 'auto',
                    }}
                    color="primary"
                />
            )}
        </Layout>
    );
}

function getNewIdsToIgnore(checked: boolean, currentIdsToIgnore: number[], relevantIds: number[]) {
    const newIdsToIgnore = checked
        ? currentIdsToIgnore.concat(relevantIds)
        : currentIdsToIgnore.filter((id) => !relevantIds.includes(id));
    return newIdsToIgnore;
}

function getVariant(
    isCreditsAndCurrencyNeeded = false,
    forceToValue?: 'radio' | 'checkbox',
): 'radio' | 'checkbox' {
    if (forceToValue) return forceToValue;
    if (isCreditsAndCurrencyNeeded) return 'checkbox';

    return 'radio';
}
