import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useLocale } from '@repo/i18n';

type ButtonDisplayProps = { isMakingReservation: boolean; buttonText?: string };

export function ButtonDisplay({ isMakingReservation, buttonText }: ButtonDisplayProps) {
    const { t } = useLocale();
    const buttonTextToDisplay = buttonText ?? t.go_and_pay;
    if (isMakingReservation) {
        return (
            <Box>
                <CircularProgress
                    color="primary"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                {t.next} - {buttonTextToDisplay}
            </Box>
        );
    } else {
        return (
            <Box>
                {t.next} - {buttonTextToDisplay}
            </Box>
        );
    }
}
