import { useTheme } from '@mui/material';

import ProductCard2 from 'src/components/domain/product-card-2/ActivityProductCard';
import { Product } from '@repo/types';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';

type IProps = {
    products: Product[];
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
};

export function SearchResult(props: IProps) {
    const {
        products: productCatalogs,
        numElements,
        accentTextColors,
        primaryTextColors,
        accentColors,
        primaryColors,
        backgroundColors,
        textColors,
    } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <ProductCardListContainer
            scroll={false}
            numElements={numElements}
            sx={{
                position: 'relative',
                padding: theme.spacing(10, 2),
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(3, 2),
                },
            }}
        >
            {productCatalogs.map((p, i) => {
                const { length } = backgroundColors;
                const cur = i % length;
                const alternatingCustomizations: ThemeType = {
                    ...customizations,
                    productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
                    productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
                    productCardPrimaryColor:
                        primaryColors[cur] ?? customizations.productCardPrimaryColor,
                    productCardPrimaryColorContrast:
                        primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
                    productCardAccentColor:
                        accentColors[cur] ?? customizations.productCardAccentColor,
                    productCardAccentColorContrast:
                        accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
                };
                return (
                    <CustomizationsContext.Provider value={alternatingCustomizations} key={p.id}>
                        <ProductCardListItem
                            numElements={numElements}
                            scroll={false}
                            spacingX={4}
                            spacingBottom={4}
                            numProductCatalogs={productCatalogs.length}
                        >
                            <ProductCard2 product={p} url={p.url} />
                        </ProductCardListItem>
                    </CustomizationsContext.Provider>
                );
            })}
        </ProductCardListContainer>
    );
}
