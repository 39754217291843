import { Box, Slide, useTheme } from '@mui/material';

import { zIndex } from 'src/utils/widget/zIndex';

type OverlayProps = {
    children: any;
};

export default function Overlay({ children }: OverlayProps) {
    const theme = useTheme();
    return (
        <Slide in={true} direction="left">
            <Box
                sx={{
                    position: 'fixed',
                    zIndex: zIndex.base,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#fff',
                    color: theme.typography.body1.color,
                    overflow: 'auto',
                }}
            >
                {children}
            </Box>
        </Slide>
    );
}
