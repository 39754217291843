import { Box } from '@mui/material';

type Spacing = 'dense' | 'normal' | 'wide';
type GridLayoutProps = {
    rows: number;
    cols: number;
    justify?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'baseline'
        | 'space-between'
        | 'space-evenly'
        | 'stretch'
        | 'normal';
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' | 'normal';
    spacing?: Spacing;
    role?: string;
    children: React.ReactNode[] | React.ReactNode;
};

export default function GridLayout(props: GridLayoutProps) {
    const {
        rows,
        cols,
        justify = 'flex-start',
        alignItems = 'stretch',
        spacing = 'normal',
        role,
        children,
    } = props;

    const space = spacingAmount(spacing);

    return (
        <Box
            role={role}
            sx={(theme) => ({
                width: 'fit-content',
                display: 'grid',
                gridTemplateColumns: `repeat(${cols}, 1fr)`,
                gridTemplateRows: `repeat(${rows}, 1fr)`,
                gap: theme.spacing(space),
                justifyContent: justify,
                alignItems: alignItems,
            })}
        >
            {children}
        </Box>
    );
}

function spacingAmount(spacing: Spacing) {
    switch (spacing) {
        case 'dense':
            return 0.2;
        case 'normal':
            return 1;
        case 'wide':
            return 2;
    }
}
