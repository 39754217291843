import { CheckoutInfo } from '@repo/types';
import { loadDataFromLocalStorage, LocalStorageKey, updateWidgetsMeta } from '../localstorage';

export function storeCheckoutInfoState(checkoutInfo: CheckoutInfo | null) {
    localStorage.setItem(LocalStorageKey.BILBERRY_CHECKOUT_INFO_NEW, JSON.stringify(checkoutInfo));
    updateWidgetsMeta();
}

export function loadCheckoutInfoState() {
    return loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_CHECKOUT_INFO_NEW,
    ) as CheckoutInfo | null;
}
