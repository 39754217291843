import { Package, ProductInstance, TicketOptionWithQuantity } from '@repo/types';

export function hasPackageProductsCapacity(
    pkg: Package | null,
    availabilitiesDay: ProductInstance[],
    quantities: TicketOptionWithQuantity[],
) {
    if (!pkg) return true;
    return pkg.ticketOptions.every(({ id, products }) => {
        const validForTicketOption = availabilitiesDay.filter((p) => {
            return products.some((productId) => productId === p.id);
        });

        return validForTicketOption.every(
            (p) => p.capacity > (quantities.find((q) => q.id === id)?.quantity ?? 0),
        );
    });
}
