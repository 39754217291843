import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';

export type PaymentRedirectContext = {
    paymentId: string | null;
};

export function tryFindPaymentRedirectContext(): PaymentRedirectContext | null {
    const paymentId = findUrlQueryParam('paymentId');
    const status = findUrlQueryParam('status');
    const isRedirectFromSuccessfulPayment = paymentId && status === '100';

    return isRedirectFromSuccessfulPayment
        ? {
              paymentId: paymentId!,
          }
        : null;
}
