import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import { ArrowButton } from 'src/components/common/buttons/arrow/ArrowButton';
import { useLocale } from '@repo/i18n';
import { Image } from '@repo/types';
import { zIndex } from 'src/utils/widget/zIndex';
import ProductImage from '../../product-image/ProductImage';

type ProductGalleryOverlayProps = {
    imageList: Image[];
    clickedImageIndex: number;
    setOpen: any;
    open: boolean;
    popoverRef: any;
};

export const ProductGalleryOverlay = memo((props: ProductGalleryOverlayProps) => {
    const { imageList, clickedImageIndex, setOpen, popoverRef, open } = props;
    const [imageIndex, setImageIndex] = useState(clickedImageIndex);
    const { t } = useLocale();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isPortrait = useMediaQuery('(orientation:portrait)');
    const isSmallDeviceInPortraitMode = isSmallDevice && isPortrait;

    const onOverlayLeft = () => {
        setImageIndex((prev) => {
            if (prev === 0) return imageList.length - 1;
            return prev - 1;
        });
    };
    const onOverlayRight = () => {
        setImageIndex((prev) => {
            if (prev === imageList.length - 1) return 0;
            return prev + 1;
        });
    };

    return (
        <Grid
            zIndex={zIndex.aboveBookingWidget}
            left={0}
            right={0}
            top={0}
            bottom={0}
            position="fixed"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={[
                isSmallDevice && { backgroundColor: theme.palette.common.black },
                {
                    backgroundColor: `rgba(0,0,0,0.8)`,
                },
            ]}
            onClick={() => setOpen(!open)}
            ref={popoverRef}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Escape') {
                    setOpen(!open);
                }
                if (e.key === 'ArrowRight') {
                    imageList && onOverlayRight();
                }
                if (e.key === 'ArrowLeft') {
                    imageList && onOverlayLeft();
                }
            }}
        >
            <Grid
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                alignSelf="stretch"
                position="relative"
                minHeight="48px"
            >
                <Typography
                    position="absolute"
                    left="50%"
                    sx={{
                        color: 'white',
                        transform: 'translateX(-50%)',
                    }}
                >
                    {imageIndex + 1} {t.of} {imageList.length}
                </Typography>
                <IconButton
                    sx={{
                        color: 'white',
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0)',
                        },
                    }}
                    aria-label={t.close}
                >
                    <CloseIcon />
                </IconButton>
            </Grid>

            <Grid
                flex="1"
                width="100%"
                display="flex"
                position="relative"
                maxWidth="1920px"
                overflow="hidden"
                mb={theme.spacing(2)}
            >
                <Grid
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onOverlayLeft();
                    }}
                    height="100%"
                    p={theme.spacing(2)}
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            padding: theme.spacing(4),
                        },
                        '& > button': {
                            position: 'relative',
                            right: 'unset',
                            left: 'unset',
                        },
                    }}
                    display={isSmallDeviceInPortraitMode ? 'none' : 'block'}
                >
                    <ArrowButton direction="left" />
                </Grid>
                <Grid
                    flex={1}
                    m="auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: 'black',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    }}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                    <ProductImage
                        url={imageList?.[imageIndex]?.src ?? null}
                        size="l"
                        disableShadows={true}
                        format={isSmallDeviceInPortraitMode ? undefined : '16_9'}
                    />
                </Grid>

                <Grid
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onOverlayRight();
                    }}
                    height="100%"
                    p={theme.spacing(2)}
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            padding: theme.spacing(4),
                        },
                        '& > button': {
                            position: 'relative',
                            right: 'unset',
                            left: 'unset',
                        },
                    }}
                    display={isSmallDeviceInPortraitMode ? 'none' : 'block'}
                >
                    <ArrowButton direction="right" />
                </Grid>
            </Grid>
            <Grid
                p={theme.spacing(2)}
                sx={{ backgroundColor: theme.palette.common.black }}
                display={isSmallDeviceInPortraitMode ? 'flex' : 'none'}
                justifyContent="space-evenly"
                width="100%"
                pt={2}
            >
                <ArrowButton
                    direction="left"
                    positionAbsolutely={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        onOverlayLeft();
                    }}
                />
                <ArrowButton
                    direction="right"
                    positionAbsolutely={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        onOverlayRight();
                    }}
                />
            </Grid>
        </Grid>
    );
});
