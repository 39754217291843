import { Theme } from '@mui/material';

export const timeslotsContainerStyles = (theme: Theme) => {
    return {
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarGutter: 'stable',
        width: 'fit-content',
        margin: theme.spacing(3, 'auto'),
    };
};
