import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import {
    CartItem,
    MembershipSite,
    MembershipUser,
    Package,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
} from '@repo/types';
import { cartAtom } from './cartAtom';
import { TZDate } from '@repo/tzdate';

interface ICartEvent {
    type: 'ADD' | 'REMOVE' | 'REMOVE_BY_ID' | 'CLEAR' | 'INITIALIZE' | 'ADD_OR_REPLACE_CART_ITEMS';
    currentSite?: MembershipSite | null;
    loggedInUser?: MembershipUser | null;
}

export interface IAddEvent<T> extends ICartEvent {
    type: 'ADD';
    currentSite: MembershipSite | null;
    loggedInUser: MembershipUser | null;
    data: T;
}

export interface IRemoveEvent<T> extends ICartEvent {
    type: 'REMOVE';
    currentSite: MembershipSite | null;
    loggedInUser: MembershipUser | null;
    data: T;
}

export interface IRemoveByIdEvent extends ICartEvent {
    type: 'REMOVE_BY_ID';
    currentSite: MembershipSite | null;
    loggedInUser: MembershipUser | null;
    data: string;
}

export interface IClearEvent extends ICartEvent {
    type: 'CLEAR';
}

export interface IInitializeEvent<T> extends ICartEvent {
    type: 'INITIALIZE';
    data: T;
}

export interface IAddOrReplaceCartItemsEvent<T> extends ICartEvent {
    type: 'ADD_OR_REPLACE_CART_ITEMS';
    data: T;
}

export type CartEvents =
    | IAddEvent<CartItem>
    | IRemoveEvent<number>
    | IRemoveByIdEvent
    | IClearEvent
    | IInitializeEvent<CartItem[]>
    | IAddOrReplaceCartItemsEvent<CartItem[]>;

export function reduceCartState(state: CartItem[], action: CartEvents): CartItem[] {
    switch (action.type) {
        case 'ADD': {
            const cartItemId = getCartItemId(action.data);
            // only allow one pkg in cart at a time (for now)
            const existingIdx = state.findIndex((x) => getCartItemId(x) === cartItemId);
            const newState = state.slice();

            if (existingIdx !== -1) newState[existingIdx] = action.data;
            else newState.push(action.data);
            return newState;
        }
        case 'ADD_OR_REPLACE_CART_ITEMS': {
            const newState = state.slice();
            action.data.forEach((item) => {
                const cartItemId = getCartItemId(item);
                const existingIdx = state.findIndex((x) => getCartItemId(x) === cartItemId);
                if (existingIdx !== -1) {
                    newState[existingIdx] = item;
                } else {
                    newState.push(item);
                }
            });

            return newState;
        }
        case 'REMOVE': {
            const newState = state.slice();
            newState.splice(action.data, 1);
            return newState;
        }
        case 'REMOVE_BY_ID': {
            const newState = state.slice();
            const index = newState.findIndex((item) => getCartItemId(item) === action.data);
            newState.splice(index, 1);
            return newState;
        }
        case 'CLEAR':
            return [];
        case 'INITIALIZE':
            return action.data;
        default:
            return state;
    }
}

export function createAddProductsToCartEvent(
    products: ProductInstance[],
    ticketOptions: TicketOptionWithQuantity[],
    disablePaymentPlans: boolean,
    requiresPaymentPlans: boolean,
    currentSite: MembershipSite | null,
    loggedInUser: MembershipUser | null,
    pkg?: { pkg: Package; date: TZDate },
    ticketType?: TicketType,
    idPrefix?: string,
): IAddEvent<CartItem> {
    return {
        type: 'ADD',
        currentSite,
        loggedInUser,
        data: {
            products,
            ticketOptions,
            pkg,
            ticketType,
            idPrefix,
            disablePaymentPlans,
            requiresPaymentPlans,
        },
    };
}

export function removeFromCartEvent(
    cartItem: CartItem,
    currentSite: MembershipSite | null,
    loggedInUser: MembershipUser | null,
): IRemoveEvent<number> {
    const index = cartAtom.subject.value.findIndex(
        (item) => getCartItemId(item) === getCartItemId(cartItem),
    );

    return {
        type: 'REMOVE',
        currentSite,
        loggedInUser,
        data: index,
    };
}

export function clearCartEvent(): IClearEvent {
    return {
        type: 'CLEAR',
    };
}
