import { errorLog } from '@repo/common-utils/Logger';

type ParsingArgument = string | number;

export function parseTranslationTemplate(str: string, ...args: ParsingArgument[]) {
    const indices: number[] = [];
    let index = 0;

    for (const ch of str) {
        if (ch === '<') {
            if (indices.length === 0 && index !== 0) indices.push(0);
            indices.push(index);
        } else if (ch === '>') {
            indices.push(index + 1);
        }
        index++;
    }
    indices.push(str.length);

    const tokens = indices.map((idx, i) => str.slice(indices.at(Math.max(i - 1, 0)) ?? 0, idx));
    let argumentIdx = 0;
    const res = [];
    for (const token of tokens) {
        const [node, newIdx] = parse(token, args, argumentIdx);
        argumentIdx = newIdx;

        res.push(node);
    }
    return res.join('');
}

function parse(token: string, args: ParsingArgument[], idx: number): [string, number] {
    if (token.startsWith('<COUNT>')) {
        const val = args[idx];

        return [String(val), idx + 1];
    } else if (token.startsWith('<COUNT:')) {
        const val = args[idx];
        const identifierLength = '<COUNT:'.length;

        const [singular, plural] = parseBinaryToken(token, identifierLength);
        return [val === 1 ? `${val} ${singular}` : `${val} ${plural}`, idx + 1];
    } else if (token.startsWith('<NAME>')) {
        const val = args[idx];

        return [String(val), idx + 1];
    } else if (token.startsWith('<PLURALIZE:')) {
        const val = args[idx];
        const identifierLength = '<PLURALIZE:'.length;

        const [singular, plural] = parseBinaryToken(token, identifierLength);
        return [val === 1 ? singular : plural, idx + 1];
    } else if (token.startsWith('<') && token.endsWith('>')) errorLog(`Unknown token: ${token}`);

    return [token, idx];
}

function parseBinaryToken(token: string, identifierLength: number) {
    const tokenParts = token.slice(identifierLength, token.length - 1);

    const partArray = tokenParts.split(':');
    if (tokenParts.length < 2)
        errorLog(
            "Couldn't parse binary token, ensure syntax is in the form of <IDENTIFIER:operand1:operand2>",
        );
    return [partArray[0], partArray[1]];
}
