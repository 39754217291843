import {
    ArrowForwardIos,
    KeyboardArrowDownRounded,
    KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { useCustomizations } from 'src/components/utils/theme/customizations';

interface IProps {
    expandArrowInside: boolean;
    visible: boolean;
}

export default function BookingToggleOpenButton(props: IProps) {
    const { expandArrowInside, visible } = props;
    const { t } = useLocale();
    const customizations = useCustomizations();
    return expandArrowInside ? (
        <Box
            position="absolute"
            mt={0}
            right="50%"
            left="50%"
            p={0}
            sx={[
                visible
                    ? { top: '-1.5em', transform: 'rotate(90deg)' }
                    : { top: '1.5em', transform: 'rotate(-90deg)' },
                {
                    backgroundColor: customizations.bookingWidgetColor,
                    color: customizations.bookingWidgetPrimaryColor,
                    '&:hover, &:active': {
                        backgroundColor: customizations.bookingWidgetColor,
                        color: customizations.bookingWidgetPrimaryColor,
                    },
                    '& .MuiSvgIcon-root': {
                        color: customizations.bookingWidgetPrimaryColor,
                        transform: 'scale(2)',
                    },
                },
            ]}
        >
            <IconButton
                color="primary"
                onClick={() =>
                    toggleBookingAtom.update({
                        visible: !visible,
                        interaction: 'mouse',
                    })
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                    e.preventDefault();
                    if (e.key === 'Enter') {
                        toggleBookingAtom.update({ visible: !visible, interaction: 'keyboard' });
                    }
                }}
                sx={{ transform: 'scale(0.6)' }}
                aria-label={visible ? t.aria_close_booking_widget : t.aria_open_booking_widget}
            >
                {visible ? <ArrowForwardIos /> : <ArrowForwardIos />}
            </IconButton>
        </Box>
    ) : (
        <IconButton
            sx={{
                backgroundColor: customizations.bookingWidgetColor,
                color: customizations.bookingWidgetHeaderColor,
                position: 'absolute',
                top: '-1em',
                marginTop: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover, &:active': {
                    backgroundColor: customizations.bookingWidgetColor,
                    color: customizations.bookingWidgetHeaderColor,
                },
                '& .MuiSvgIcon-root': {
                    color: customizations.bookingWidgetHeaderColor,
                },
            }}
            color="primary"
            onClick={() =>
                toggleBookingAtom.update({
                    visible: !visible,
                    interaction: 'mouse',
                })
            }
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    toggleBookingAtom.update({ visible: !visible, interaction: 'keyboard' });
                }
            }}
            aria-label={visible ? t.aria_close_booking_widget : t.aria_open_booking_widget}
        >
            {visible ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
        </IconButton>
    );
}
