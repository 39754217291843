import { VERSION } from '@repo/widget-env/__autogen/env';
import { atom } from 'ximple';
import { ProductInstance } from '@repo/types';
import { decycle, retrocycle } from '@repo/common-utils/cycle';
import { dayjsReplacer, dayjsReviver } from '@repo/common-utils/serialize';

export const upcomingSelectedTourAtom = atom<ProductInstance | null>({
    persistKey: 'upcomingSelectedTour',
    initialValue: null,
    appVersion: VERSION,
    transformOnSerialize: (obj) => decycle(obj, dayjsReplacer),
    transformOnDeserialize: (obj) => retrocycle(obj, dayjsReviver),
});
