import {
    MembershipPaymentPlan,
    MembershipUserValueCard,
    MembershipValueCardProduct,
} from '@repo/types';

export type PaymentPlanInfo = {
    periodCard: {
        valueCardId: number;
        valueCardProductId: number;
        valueCardProductTypeId: number;
        name: string;
        description: string;
        validFrom: string;
        validTo: string;
        validForDurationInDays: number;
        checked: boolean;
        membershipUserValueCard: MembershipUserValueCard;
    }[];
    creditsGroup: {
        valueCardIds: number[];
        valueCardProductIds: number[];
        valueCardProductTypeIds: number[];
        name: string;
        checked: boolean;
        creditsToUse: number;
    };
    currencyCost: number;
};

function isPeriodCardId(
    id: number,
    ownedValueCards: { id: number; valueCardProduct: MembershipValueCardProduct }[],
) {
    return (
        ownedValueCards.find((owned) => owned.id === id)?.valueCardProduct.configuration
            .creditLimit === null
    );
}

function calculatePeriodCardsPaymentPlan(
    paymentPlan: MembershipPaymentPlan[],
    ownedValueCards: MembershipUserValueCard[],
    relevantValueCardIds: number[],
    currentPaymentPlanInfo: PaymentPlanInfo,
) {
    const relevantPeriodCardIds = relevantValueCardIds.filter((id) =>
        isPeriodCardId(id, ownedValueCards),
    );
    return relevantPeriodCardIds.reduce((acc, id) => {
        const cardToUse = ownedValueCards.find((owned) => owned.id === id)!;
        const isPeriodCardAlreadyAdded = alreadyAdded(
            acc.map((card) => card.valueCardId),
            cardToUse.id,
        );

        if (!isPeriodCardAlreadyAdded) {
            acc.push(getPeriodCard(cardToUse, paymentPlan));
        }

        const periodCardInList = acc.find((card) => card.valueCardId === cardToUse.id);

        if (periodCardInList) {
            periodCardInList.checked = shouldBeChecked(paymentPlan, cardToUse);
        }

        return acc;
    }, currentPaymentPlanInfo.periodCard);
}

function calculateCreditsPaymentPlan(
    paymentPlan: MembershipPaymentPlan[],
    ownedValueCards: MembershipUserValueCard[],
    relevantValueCardIds: number[],
    currentPaymentPlanInfo: PaymentPlanInfo,
) {
    const relevantCreditsIds = relevantValueCardIds.filter(
        (id) => !isPeriodCardId(id, ownedValueCards),
    );

    const newPaymentPlanInfo = { ...currentPaymentPlanInfo };

    newPaymentPlanInfo.creditsGroup = relevantCreditsIds.reduce((acc, id) => {
        const cardToUse = ownedValueCards.find((owned) => owned.id === id);
        if (!cardToUse) return acc;
        const isAlreadyAdded = alreadyAdded(
            currentPaymentPlanInfo.creditsGroup.valueCardIds,
            cardToUse.id,
        );
        if (isAlreadyAdded) return acc;
        return addCredits(acc, cardToUse);
    }, newPaymentPlanInfo.creditsGroup);

    paymentPlan.forEach((plan) =>
        plan.valueCardUsages.forEach((usage) => {
            const isCreditsUsed = newPaymentPlanInfo.creditsGroup.valueCardIds.includes(
                usage.valueCardId,
            );
            if (isCreditsUsed) {
                newPaymentPlanInfo.creditsGroup.checked = true;
                newPaymentPlanInfo.creditsGroup.creditsToUse += usage.creditCost;
            }
        }),
    );

    return newPaymentPlanInfo.creditsGroup;
}

export function getPaymentPlanInfo(
    paymentPlan: MembershipPaymentPlan[],
    ownedValueCards: MembershipUserValueCard[],
    paymentPlanInfo?: PaymentPlanInfo,
): PaymentPlanInfo {
    const currentPaymentPlanInfo = getCurrentPaymentPlanInfo(paymentPlanInfo);
    const relevantValueCardIds = getRelevantValueCardIds(paymentPlan, ownedValueCards);
    currentPaymentPlanInfo.currencyCost = getCurrencyCost(paymentPlan);

    currentPaymentPlanInfo.periodCard = calculatePeriodCardsPaymentPlan(
        paymentPlan,
        ownedValueCards,
        relevantValueCardIds,
        currentPaymentPlanInfo,
    );

    currentPaymentPlanInfo.creditsGroup = calculateCreditsPaymentPlan(
        paymentPlan,
        ownedValueCards,
        relevantValueCardIds,
        currentPaymentPlanInfo,
    );

    return {
        periodCard: currentPaymentPlanInfo.periodCard,
        creditsGroup: currentPaymentPlanInfo.creditsGroup,
        currencyCost: currentPaymentPlanInfo.currencyCost,
    } as PaymentPlanInfo;
}

function alreadyAdded(lookupList: number[], lookupElement: number) {
    return lookupList.includes(lookupElement);
}

export function getCurrencyCost(paymentPlan: MembershipPaymentPlan[]): number {
    return paymentPlan
        .filter((x) => x.currencyCost !== null)
        .reduce((pVal, cVal) => {
            return pVal + (cVal.currencyCost?.price || 0);
        }, 0);
}

function getRelevantValueCardIds(
    paymentPlan: MembershipPaymentPlan[],
    ownedValueCards: { id: number; valueCardProduct: MembershipValueCardProduct }[],
): number[] {
    const ownedValueCardsGuard = ownedValueCards ? ownedValueCards : [];
    return [
        ...new Set(
            paymentPlan.flatMap((plan) => [
                ...plan.relevantValueCardIds,
                ...ownedValueCardsGuard
                    .filter(
                        (ownedCard) =>
                            plan.ticket.ignoredValueCardProductTypeIds.includes(
                                ownedCard.valueCardProduct.productTypeId,
                            ) ||
                            plan.ticket.ignoredValueCardProductIds.includes(
                                ownedCard.valueCardProduct.id,
                            ) ||
                            plan.ticket.ignoredValueCardIds.includes(ownedCard.id),
                    )
                    .map((ownedCard) => ownedCard.id),
            ]),
        ),
    ];
}

function getCurrentPaymentPlanInfo(
    paymentPlanInfo: PaymentPlanInfo | undefined,
    paymentPlan?: MembershipPaymentPlan[],
): PaymentPlanInfo {
    const creditUsages =
        paymentPlan?.flatMap((plan) => plan.valueCardUsages.filter((vcu) => vcu.creditCost > 0)) ??
        [];
    return {
        periodCard: paymentPlanInfo?.periodCard || [],
        creditsGroup: {
            valueCardIds:
                paymentPlanInfo?.creditsGroup.valueCardIds ??
                creditUsages?.map((usage) => usage.valueCardId) ??
                [],
            valueCardProductIds:
                paymentPlanInfo?.creditsGroup.valueCardProductIds ??
                creditUsages?.map((usage) => usage.valueCardProductId) ??
                [],
            valueCardProductTypeIds:
                paymentPlanInfo?.creditsGroup.valueCardProductTypeIds ??
                creditUsages?.map((usage) => usage.valueCardProductTypeId) ??
                [],
            name: 'Credits',
            checked: false,
            creditsToUse: 0,
        },
        currencyCost: paymentPlanInfo?.currencyCost || 0,
    };
}

function shouldBeChecked(
    paymentPlan: MembershipPaymentPlan[],
    cardToUse: { id: number; valueCardProduct: MembershipValueCardProduct },
) {
    return paymentPlan.some((plan) =>
        plan.valueCardUsages.some((usage) => usage.valueCardId === cardToUse.id),
    );
}

function getPeriodCard(
    cardToUse: MembershipUserValueCard,
    paymentPlan: MembershipPaymentPlan[],
): {
    valueCardId: number;
    valueCardProductId: number;
    valueCardProductTypeId: number;
    name: string;
    description: string;
    validFrom: string;
    validTo: string;
    validForDurationInDays: number;
    checked: boolean;
    membershipUserValueCard: MembershipUserValueCard;
} {
    return {
        valueCardId: cardToUse.id,
        valueCardProductId: cardToUse.valueCardProduct.id,
        valueCardProductTypeId: cardToUse.valueCardProduct.productTypeId,
        name: cardToUse.valueCardProduct.name,
        description: cardToUse.valueCardProduct.description,
        validFrom: cardToUse.validFrom,
        validTo: cardToUse.validTo,
        validForDurationInDays: cardToUse.valueCardProduct.configuration.validForDurationInDays,
        checked: shouldBeChecked(paymentPlan, cardToUse),
        membershipUserValueCard: cardToUse,
    };
}
function addCredits(
    creditsGroup: {
        valueCardIds: number[];
        valueCardProductIds: number[];
        valueCardProductTypeIds: number[];
        name: string;
        checked: boolean;
        creditsToUse: number;
    },
    cardToUse: { id: number; valueCardProduct: MembershipValueCardProduct },
): {
    valueCardIds: number[];
    valueCardProductIds: number[];
    valueCardProductTypeIds: number[];
    name: string;
    checked: boolean;
    creditsToUse: number;
} {
    const newCreditsGroup = { ...creditsGroup };

    newCreditsGroup.valueCardIds = [...new Set(newCreditsGroup.valueCardIds.concat(cardToUse.id))];
    newCreditsGroup.valueCardProductIds = [
        ...new Set(newCreditsGroup.valueCardProductIds.concat(cardToUse.valueCardProduct.id)),
    ];
    newCreditsGroup.valueCardProductTypeIds = [
        ...new Set(
            newCreditsGroup.valueCardProductTypeIds.concat(
                cardToUse.valueCardProduct.productTypeId,
            ),
        ),
    ];
    return newCreditsGroup;
}
