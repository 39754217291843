import {
    BilberryApiExceptionErrorDetails,
    BilberryApiValidationErroDetails,
    MembershipApiErrorDetails,
} from '@repo/types';

export class BilberryApiError extends Error {
    response: Response;

    constructor(msg: string, response: Response) {
        super(msg);

        this.response = response;

        // Set the prototype explicitly.
        // Recommendation: https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, BilberryApiError.prototype);
    }

    async errorMessages(): Promise<string[]> {
        const json = await this.response.json();

        // Validation error
        if (this.response.status === 422) {
            const details = json as BilberryApiValidationErroDetails;
            return Object.values(details.errors).flat();
        }

        // API Exception
        if (this.response.status === 406) {
            const details = json as BilberryApiExceptionErrorDetails;
            return [details.error.message];
        }

        // If not recognized, just stringify and return the whole json
        return [JSON.stringify(json)];
    }

    async errorDetails(): Promise<MembershipApiErrorDetails> {
        try {
            const json = await this.response.json();
            const details = json as MembershipApiErrorDetails;
            return details;
        } catch (e) {
            return { title: this.message, detail: '', status: this.response.status, traceId: '' };
        }
    }
}
