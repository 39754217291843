import { ReactComponent as CreditsIcon } from 'src/assets/icons/credits.svg';
import CheckboxPanel from 'src/components/common/timeslots-checkbox-panel/CheckboxPanel';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import Text from 'src/components/common/timeslots-typography/Text';
import Title from 'src/components/common/timeslots-typography/Title';
import { useLocale } from '@repo/i18n';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { sumBy } from 'lodash-es';

type CreditsPaymentProps = {
    text: string;
    creditsIds: number[];
    subtext?: string;
    variant?: 'radio' | 'checkbox';
    isChecked?: boolean;
    onClick: () => void;
    disabled?: boolean;
};

export default function CreditsPayment({
    text,
    creditsIds,
    subtext,
    variant = 'radio',
    isChecked = false,
    onClick,
    disabled = false,
}: CreditsPaymentProps) {
    const { t } = useLocale();
    const { valueCardsForUser } = useMemberContext();

    const valueCards = valueCardsForUser.filter((vc) => creditsIds.includes(vc.id));
    const creditsLeft = sumBy(valueCards, (vc) => (vc.balance?.creditsAvailable ?? 0) / 100);

    return variant === 'radio' ? (
        <RadioPanel
            isChecked={isChecked}
            Icon={CreditsIcon as any}
            text={text}
            subtext={subtext}
            onChange={onClick}
            disabled={disabled}
        >
            <Title text={creditsLeft.toString()}></Title>
            <Text>{t.creditsLeft.toUpperCase()}</Text>
        </RadioPanel>
    ) : (
        <CheckboxPanel
            isChecked={isChecked}
            subtext={subtext}
            Icon={CreditsIcon as any}
            text={text}
            onChange={onClick}
            disabled={disabled}
        >
            <Title text={creditsLeft.toString()}></Title>
            <Text>{t.creditsLeft.toUpperCase()}</Text>
        </CheckboxPanel>
    );
}
