import { UserTextCustomizations } from '@repo/types';
import { createRoot } from 'react-dom/client';
import { getIdAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import createWebComponentTagName from 'src/utils/widget/createWebComponentTagName';
import ProductOverview2WidgetRoot from 'src/widgets/activities/product/product-overview-2/ProductOverview2WidgetRoot';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryProductOverview2 extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        const aboutTheTripTextCustomizationKey = this.getAttribute(
            'about-the-trip-text-customization-key',
        ) as keyof UserTextCustomizations;
        const pricesTextCustomizationKey = this.getAttribute(
            'prices-text-customization-key',
        ) as keyof UserTextCustomizations;
        const departureTextCustomizationKey = this.getAttribute(
            'departure-text-customization-key',
        ) as keyof UserTextCustomizations;
        const durationTextCustomizationKey = this.getAttribute(
            'duration-text-customization-key',
        ) as keyof UserTextCustomizations;
        const difficultyTextCustomizationKey = this.getAttribute(
            'difficulty-text-customization-key',
        ) as keyof UserTextCustomizations;
        const ageTextCustomizationKey = this.getAttribute(
            'age-text-customization-key',
        ) as keyof UserTextCustomizations;
        const pricesFromTextCustomizationKey = this.getAttribute(
            'prices-from-text-customization-key',
        ) as keyof UserTextCustomizations;
        const additionalInfoTextCustomizationKey = this.getAttribute(
            'additional-info-text-customization-key',
        ) as keyof UserTextCustomizations;
        const startingTimeTextCustomizationKey = this.getAttribute(
            'starting-time-text-customization-key',
        ) as keyof UserTextCustomizations;

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductOverview2WidgetRoot
                    productCatalogId={productCatalogId}
                    aboutTheTripTextCustomizationKey={aboutTheTripTextCustomizationKey ?? undefined}
                    pricesTextCustomizationKey={pricesTextCustomizationKey ?? undefined}
                    departureTextCustomizationKey={departureTextCustomizationKey ?? undefined}
                    durationTextCustomizationKey={durationTextCustomizationKey ?? undefined}
                    difficultyTextCustomizationKey={difficultyTextCustomizationKey ?? undefined}
                    ageTextCustomizationKey={ageTextCustomizationKey ?? undefined}
                    pricesFromTextCustomizationKey={pricesFromTextCustomizationKey ?? undefined}
                    additionalInfoTextCustomizationKey={
                        additionalInfoTextCustomizationKey ?? undefined
                    }
                    startingTimeTextCustomizationKey={startingTimeTextCustomizationKey ?? undefined}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        createWebComponentTagName('bilberry-product-overview', 'v2'),
        BilberryProductOverview2,
    );
}
