import { Box, Link, SxProps, Typography } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { cartAtom } from 'src/state/cart/cartAtom';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { serializeCartToQueryParams } from '@repo/widget-utils/sharing/serializeCart';
import { useAtom } from 'ximple';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useCartContext } from 'src/widgets/CartContext';

type ShareCartButtonProps = {
    setShareUrl: (val: string) => void;
    shareDialogVisible: boolean;
    setShareDialogVisible: (val: boolean) => void;
    sx?: SxProps<any>;
    hasInvertedColors?: boolean;
};

export function ShareCartButton({
    setShareUrl,
    setShareDialogVisible,
    shareDialogVisible,
    sx,
    hasInvertedColors,
}: ShareCartButtonProps) {
    const { cartItems: cart } = useCartContext();
    const { t } = useLocale();
    const configuration = useConfigurations();
    const customizations = useCustomizations();

    async function onClick() {
        const cartParams = serializeCartToQueryParams(cart);
        const bref = configuration.referralCode;
        const url =
            window.location.origin + '?' + cartParams + (bref !== '' ? '&bref=' + bref : '');
        const shareData = {
            title: document.title,
            text: t.share,
            url,
        };

        setShareUrl(url);

        try {
            await navigator.share(shareData);
        } catch (_e) {
            const nav = window.navigator as any;
            // Since FF doesn't explicitly support canShare yet, no type is implemented and
            // we need cast to any to avoid a type error.
            if (!nav.canShare || !nav.share || !nav.canShare(shareData))
                setShareDialogVisible(!shareDialogVisible);
        }
    }

    const color = hasInvertedColors
        ? { color: customizations.bookingWidgetPrimaryColor }
        : { color: customizations.linkColor };

    return (
        <Box display="flex" flexDirection="column" sx={sx}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link
                    component="button"
                    onClick={onClick}
                    sx={{
                        '&:hover': {
                            color,
                        },
                    }}
                >
                    <Typography sx={{ color }}>{t.share}</Typography>
                </Link>
            </Box>
        </Box>
    );
}
