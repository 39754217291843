import { Button as MuiButton, Theme, useTheme } from '@mui/material';
import { ReactComponent as AccountIcon } from 'src/assets/icons/account.svg';
import { useLocale } from '@repo/i18n';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { Translations } from '@repo/types';
import { useAuthenticationContext } from 'src/widgets/timeslots/timeslots/AuthenticationContext';

type LoginButtonProps = {
    sayMySiteWhenLoggedIn?: boolean;
    loggedInOnClick?: () => void;
    beforeLoginClick?: () => void;
    leavePageOnLogout?: boolean;
    variant?: 'login' | 'signup';
    render?: (onClick: () => void) => JSX.Element;
    useLoggedOutStyling?: boolean;
};

export default function LoginButton({
    sayMySiteWhenLoggedIn = false,
    loggedInOnClick,
    beforeLoginClick,
    useLoggedOutStyling,
    leavePageOnLogout = false,
    variant = 'login',
    render,
}: LoginButtonProps) {
    const theme = useTheme();
    const { t } = useLocale();

    const { isAuthenticated, login, logout } = useAuthenticationContext();

    const customizations = useCustomizations();
    const handleAuthentication = async () => {
        if (beforeLoginClick) beforeLoginClick();

        if (!isAuthenticated) {
            await login();
        } else if (loggedInOnClick) {
            loggedInOnClick();
        } else {
            logout(leavePageOnLogout);
        }
    };

    const { button } = useStyling(isAuthenticated, theme, useLoggedOutStyling);
    const label = labelling(t, isAuthenticated, sayMySiteWhenLoggedIn, variant);

    if (render) {
        return render(handleAuthentication);
    }

    return (
        <MuiButton
            startIcon={
                !isAuthenticated || useLoggedOutStyling ? (
                    <AccountIcon style={{ fill: customizations.primaryColor }} />
                ) : undefined
            }
            onClick={handleAuthentication}
            sx={button}
        >
            {label}
        </MuiButton>
    );
}

function labelling(
    t: Translations,
    isAuthenticated: boolean,
    sayMySiteWhenLoggedIn: boolean,
    variant: 'login' | 'signup',
) {
    if (variant === 'signup') return t.signUp;
    return isAuthenticated ? (sayMySiteWhenLoggedIn ? t.mySite : t.logout) : t.login;
}

function useStyling(isAuthenticated: boolean, theme: Theme, useLoggedOutIcon?: boolean) {
    const customizations = useCustomizations();
    if (isAuthenticated && !useLoggedOutIcon) {
        return {
            button: {
                background: theme.palette.grey['100'],
                color: theme.typography.body1.color,
                border: '1px solid',
                borderColor: theme.palette.grey['200'],
                '&:hover': {
                    backgroundColor: theme.palette.grey['50'],
                },
            },
        };
    } else {
        return {
            button: {
                background: customizations.secondaryColor,
                color: customizations.secondaryColorContrast,
                border: '1px solid',
                borderColor: customizations.primaryColor,
                '&:hover': {
                    backgroundColor: customizations.lightestGrey,
                    color: customizations.primaryColor,
                },
            },
        };
    }
}
