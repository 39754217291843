/* eslint-disable max-len */

import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'Mostra politica di cancellazione',
    hide_cancellation_policy: 'Nascondi politica di cancellazione',
    departure: 'Partenza',
    price: 'Prezzo',
    difficulty: 'Difficoltà',
    duration: 'Durata',
    starting_time: 'Orario di inizio',
    about: 'Informazioni',
    book_now: 'Prenota ora',
    book: 'Prenota',
    buy_as_gift: 'Acquista come regalo',
    from: 'Da',
    your_booking_reference_is: 'Il tuo riferimento di prenotazione è',
    booking_details_in_email: "Riceverai presto un'email con ulteriori informazioni",
    see_more_activities: 'Vedi più attività',
    your_cart: 'Il tuo carrello',
    when_are_you_going: 'Scegli una data e un orario',
    how_many_are_going: 'Quanti parteciperanno?',
    contact_details: 'Dati di contatto',
    traveler: 'Viaggiatore',
    extras: 'Extra',
    please_select: 'Si prega di selezionare',
    no_items_in_cart: 'Nessun articolo nel carrello',
    payment: 'Pagamento',
    receipt: 'Ricevuta',
    do_you_have_any_comments_or_special_needs: 'Hai commenti o esigenze speciali?',
    i_have_read_and_accepted_the: 'Ho letto e accettato la',
    privacy_policy: 'Politica sulla privacy',
    terms_and_conditions: 'Termini e condizioni',
    first_name: 'Nome',
    last_name: 'Cognome',
    phone_number: 'Numero di telefono',
    dial_code: 'Prefisso internazionale',
    email: 'Email',
    please_register: 'Si prega di registrarsi',
    main_contact: 'Contatto principale',
    additional_information: 'Informazioni aggiuntive',
    remove_cart_item: 'Rimuovi elemento dal carrello',
    back_to_webshop: 'Continua lo shopping',
    excl: 'escl.',
    vat: 'IVA',
    total: 'Totale',
    next: 'Avanti',
    go_and_pay: 'Vai e paga',
    print: 'Stampa',
    close: 'Chiudi',
    checkout: 'Check-out',
    summary: 'Riepilogo',
    cancel: 'Annulla',
    save: 'Salva',
    select_time: 'Seleziona orario',
    selected_time: 'Orario selezionato',
    time: 'Orario',
    go_back: 'Torna indietro',
    thank_you_for_booking_with_us: 'Grazie per aver prenotato con noi!',
    please_fill_in_all_required_information:
        'Si prega di compilare tutte le informazioni richieste',
    error_occurred_when_creating_reservation:
        'Si è verificato un errore durante la creazione della prenotazione',
    error_occurred_when_creating_giftcard:
        'Si è verificato un errore durante la creazione della carta regalo',
    couldnt_apply_promocode_is_the_code_correct:
        'Impossibile applicare il codice promozionale. È corretto?',
    choose_a_country: 'Scegli un paese',
    select_date: 'Seleziona data',
    select_travelers: 'Seleziona viaggiatori',
    hourShort: 'h',
    dayShort: 'g',
    minuteShort: 'min',
    years: 'anni',
    age: 'Età',
    more_information: 'Ulteriori informazioni',
    same_as_main_contact: 'Uguale al contatto principale',
    easy: 'Facile',
    medium: 'Medio',
    hard: 'Difficile',
    please_select_date: 'Si prega di selezionare una data',
    please_select_date_and_time: 'Si prega di selezionare data e orario',
    name: 'Nome',
    comment: 'Commento',
    submit_request: 'Invia richiesta',
    any_special_requests_etc: 'Eventuali richieste speciali, allergie alimentari...',
    request_tour_comment: 'Descrivi la tua richiesta',
    thank_you_for_your_request: 'Grazie per la tua richiesta, ti risponderemo al più presto.',
    got_it: 'Ricevuto!',
    continue_shopping: 'Continua lo shopping',
    continue_travelers: 'Viaggiatori',
    please_provide_your_name: 'Si prega di fornire un nome',
    please_provide_your_email: 'Si prega di fornire un indirizzo email valido',
    please_provide_a_description: 'Si prega di fornire una descrizione',
    an_error_occurred_when_submitting_your_request:
        "Si è verificato un errore durante l'invio della tua richiesta. Riprova più tardi.",
    private_tour: 'Tour privato',
    read_more_and_book: 'Leggi di più e prenota',
    read_more: 'Leggi di più',
    prices_from: 'Prezzi da',
    about_the_trip: 'Informazioni sul viaggio',
    prices: 'Prezzi',
    whats_included: 'Cosa è incluso',
    any: 'Qualsiasi',
    duration_up_to_1_hour: 'Fino a 1 ora',
    duration_1_2_hours: '1 - 2 ore',
    duration_2_6_hours: '2 - 6 ore',
    duration_6_12_hours: '6 - 12 ore',
    duration_12_24_hours: '12 - 24 ore',
    duration_1_2_days: '1 - 2 giorni',
    duration_more_than_2_days: 'Più di 2 giorni',
    find_activities: 'Trova attività',
    ok: 'OK',
    clear: 'Cancella',
    filterActivities: 'Filtra attività',
    hideFilters: 'Nascondi filtri',
    shopMore: 'Acquista di più',
    noSearchResults:
        'Spiacenti, non abbiamo trovato attività disponibili che corrispondano alla tua ricerca.',
    update: 'Aggiorna',
    select_date_to_view_timeslots: 'Scegli una data per visualizzare gli orari disponibili',
    your_reservation_timed_out: 'La tua prenotazione è scaduta. Per favore, riprova.',
    aria_calendar_input_field_label: 'Input del calendario. Premi Invio per aprire.',
    aria_book_now_button: 'Apri una finestra per prenotare questa attività',
    aria_next_image: 'Immagine successiva',
    aria_previous_image: 'Immagine precedente',
    aria_open_booking_widget: 'Apri la finestra di prenotazione',
    aria_close_booking_widget: 'Chiudi la finestra di prenotazione',
    aria_open_request_tour_widget: 'Apri la finestra di richiesta di tour',
    aria_close_request_tour_widget: 'Chiudi la finestra di richiesta di tour',
    aria_open_find_activity: 'Apri ricerca attività',
    aria_close_find_activity: 'Chiudi ricerca attività',
    request_tour: 'Richiedi un tour',
    giftcard_message: 'Messaggio',
    giftcard_message_label: 'Messaggio personale al destinatario (opzionale)',
    recipient_details: 'Dettagli del destinatario',
    gift_card: 'Carta regalo',
    value: 'Valore',
    create: 'Crea',
    create_your_gift: 'Crea la tua carta regalo',
    your_giftcard_reference_is: 'Il tuo riferimento è',
    giftcard_voucher_and_receipt:
        'Abbiamo inviato un buono regalo pronto per la stampa insieme a una ricevuta a:',
    giftcard_voucher: 'Abbiamo inviato il buono regalo a:',
    giftcard_and_receipt: 'e una ricevuta a:',
    thank_you_for_your_purchase: 'Grazie per il tuo acquisto!',
    valid_until: 'Valido fino al',
    print_receipt: 'Stampa ricevuta',
    select_gift_type: 'Seleziona tipo di regalo',
    please_select_gift_type: 'Seleziona tipo di regalo',
    select_gift_print_description:
        'Ricevi una carta regalo PDF stampabile nella tua casella di posta',
    select_gift_email_description: 'Invia una carta regalo per email direttamente al destinatario',
    enter_giftcard_code: 'Inserisci un codice carta regalo',
    enter_promo_code: 'Inserisci un codice promozionale',
    apply: 'Applica',
    giftcard_code_error: 'Codice carta regalo non valido',
    remaining_value: 'Valore rimanente',
    giftcard_code_not_found: 'Impossibile trovare la carta regalo con il codice:',
    giftcard_zero_balance: 'la carta regalo è già stata spesa',
    giftcard_is_expired: 'la carta regalo è scaduta',
    purchased_giftcard: {
        self: 'carta regalo stampabile inviata a te',
        recipient: 'carta regalo stampabile inviata al destinatario',
    },
    contact_information: {
        short: 'Informazioni di contatto',
        long: 'Informazioni di contatto',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minuto',
    },
    hours: {
        singular: 'ora',
        plural: 'ore',
    },
    days: {
        singular: 'giorno',
        plural: 'giorni',
    },
    children: {
        singular: 'bambino',
        plural: 'bambini',
    },
    adult: {
        singular: 'adulto',
        plural: 'adulti',
    },
    card: {
        singular: 'carta',
        plural: 'carte',
    },
    number_of_travelers_exceeded: {
        template: 'Numero massimo di partecipanti in questa data è <COUNT:persona:persone>',
    } as any,
    booking_is_closed: {
        template: 'Prenotazione per <NAME> è ora chiusa',
    } as any,
    rooms: {
        singular: 'stanza',
        plural: 'stanze',
    },
    person: {
        singular: 'persona',
        plural: 'persone',
    },
    select_room: 'seleziona stanza',
    change_room: 'cambia stanza',
    less_than: 'meno di',
    year: {
        singular: 'anno',
        plural: 'anni',
    },
    select_age: 'seleziona età',
    done: 'fatto',
    number_of_rooms: 'numero di stanze',
    select_bed: 'seleziona letto',
    room_info: 'informazioni sulla stanza',
    price_one_night_one_guest: {
        template: 'prezzo per <COUNT:notte:notti>, <COUNT:ospite:ospiti>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:notte:notti>, <COUNT:ospite:ospiti>',
    } as any,
    price_nights: {
        template: 'prezzo per <COUNT:notte:notti>',
    } as any,
    night: {
        singular: 'notte',
        plural: 'notti',
    },
    select_room_for_total_price: 'seleziona stanze per ottenere il prezzo totale',
    find_rooms: 'trova stanze',
    check_in_check_out: 'Check-in e Check-out',
    guest_and_rooms: 'Ospiti e stanze',
    no_accommodations_found:
        'Ci dispiace, ma non abbiamo trovato stanze che corrispondono ai tuoi criteri di ricerca. Hai considerato di regolare i parametri di ricerca per trovare stanze disponibili?',
    facilities_included: 'Servizi inclusi',
    price_per_night: 'Prezzo per notte',
    season: 'Stagione',

    has_airconditioning: 'Aria condizionata',
    has_balcony: 'Balcone',
    has_bicycles: 'Biciclette',
    has_boat: 'Barca',
    has_coffee_maker: 'Macchina per il caffè',
    has_dishwasher: 'Lavastoviglie',
    has_dryer: 'Asciugatrice',
    has_fireplace: 'Camino',
    has_garage: 'Garage',
    has_garden_furniture: 'Mobili da giardino',
    has_grill: 'Griglia',
    has_kitchen: 'Cucina',
    has_minibar: 'Minibar',
    has_mooring_space: "Spazio per l'ormeggio",
    has_pool: 'Piscina',
    has_roomservice: 'Servizio in camera',
    has_sauna: 'Sauna',
    has_tv: 'TV',
    has_washing_machine: 'Lavatrice',
    has_wifi: 'WiFi',
    is_breakfast_included: 'Colazione',
    is_cancellation_included: 'Possibilità di cancellazione',
    is_pets_allowed: 'Animali ammessi',
    is_smoking_allowed: 'Permesso di fumare',
    is_towels_and_bedlines_included: 'Asciugamani e lenzuola',
    is_wheelchair_accessible: 'Accessibile in sedia a rotelle',
    wifi: 'WiFi',
    breakfast: 'Colazione',
    accessible: 'Accessibile',
    pets: 'Animali',
    kitchen: 'Cucina',
    smoking: 'Fumatori',
    unknown: 'Sconosciuto',
    accommodation_cancellation_policy: "Politica di cancellazione dell'alloggio",
    accommodations: 'Alloggi',
    activities: 'Attività',
    address: 'indirizzo',
    city: 'città',
    post_code: 'codice postale',
    to: 'a',
    date_range_start: 'inizio',
    date_range_end: 'fine',
    flag_of: 'bandiera di',
    monday: 'lunedì',
    tuesday: 'martedì',
    wednesday: 'mercoledì',
    thursday: 'giovedì',
    friday: 'venerdì',
    saturday: 'sabato',
    sunday: 'domenica',
    today: 'oggi',
    tomorrow: 'domani',
    january: 'gennaio',
    february: 'febbraio',
    march: 'marzo',
    april: 'aprile',
    may: 'maggio',
    june: 'giugno',
    july: 'luglio',
    august: 'agosto',
    september: 'settembre',
    october: 'ottobre',
    november: 'novembre',
    december: 'dicembre',
    few_left: 'pochi rimasti',
    tickets_left: {
        template: '<COUNT:biglietto rimasto:biglietti rimasti>',
    } as any,
    available: 'disponibile',
    sold_out: 'esaurito',
    upcoming_tours_title: 'Prossimi Tour',
    unavailable: 'non disponibile',
    notes_regarding_your_stay: 'Note riguardanti il tuo soggiorno',
    notes_for_accommodation_name_with_guests: {
        template: 'Note per <NAME> con <COUNT:guest:ospiti>',
    } as any,
    select_price_type: 'Seleziona tipo di prezzo',
    finding_available_rooms: 'Ricerca di stanze disponibili',
    total_price: 'Prezzo totale',
    of: 'di',
    select_dates: 'Seleziona date',
    please_select_price_type: 'Seleziona il tipo di prezzo',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `È richiesto un minimo di <COUNT> partecipanti per prenotare questo prodotto`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Una conferma di prenotazione sarà inviata al tuo indirizzo email non appena avremo elaborato il tuo ordine.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Una conferma sarà inviata al tuo indirizzo email non appena avremo elaborato il tuo ordine.',
    add: 'Aggiungi',
    amount: 'Quantità',
    areYouAMemberOrHaveValueCard: 'Sei un membro o hai una carta valore?',
    areYouSureYouWantToCancelYourBooking: 'Sei sicuro di voler cancellare questa prenotazione?',
    areYouSureYouWantToCancelYourMembership: 'Sei sicuro di voler cancellare la tua iscrizione?',
    automaticallyRenewed: 'Automaticamente rinnovato',
    at: 'Alle',
    backToWebsite: 'Torna al sito web',
    belayCertification: 'Certificazione Belay',
    belayCertificationConsent: 'Ho una certificazione Belay',
    buyMembership: 'Acquista una membership',
    buyNow: 'Compra ora',
    cancelled: 'Annullato',
    canBeCancelledFrom: 'Può essere annullato dal',
    choose_payment_method: 'Scegli il metodo di pagamento',
    bookNow: 'Prenota ora',
    bookTimeslot: 'Prenota biglietti',
    cancellation: 'Cancellazione',
    choose: 'Scegli',
    chooseACountry: 'Scegli un paese',
    chooseCreditsType: 'Scegli il tipo di crediti',
    chooseDateAndTime: 'Scegli data e ora',
    chooseNumberOfCredits: 'Scegli il numero di crediti',
    chooseStartDate: 'Scegli la data di inizio',
    chooseType: 'Scegli tipo',
    ticketCode: "Codice dell'ordine",
    completed: 'Completato',
    consents: 'Consenso',
    contact: 'Contatto',
    contactByEmail: 'Contatta via Email',
    contactBySms: 'Contatta via SMS',
    couldntApplyGiftcardIsTheCodeCorrect:
        'Non è possibile applicare la carta regalo. Il codice è corretto?',
    couldntApplyPromocodeIsTheCodeCorrect:
        'Impossibile applicare il codice promozionale. Il codice è corretto?',
    couldntGetCompanyInformation: "Impossibile ottenere le informazioni dell'azienda",
    couldntGetPaymentPlan:
        'Impossibile ottenere il piano di pagamento. Per favore, prova a ricaricare la pagina web.',
    couldntGetPaymentPlanUsingPreviousPlan:
        'Impossibile ottenere il piano di pagamento, utilizzando il piano precedente',
    couldntGetValueCards: 'Impossibile ottenere le carte valore',
    couldntGetProductPleaseTryAgain: 'Impossibile ottenere il prodotto, per favore riprova',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'Impossibile aprire il checkout di prenotazione, non sono stati specificati tutti i dati di prenotazione richiesti.',
    couldntOpenProfilePleaseSignInFirst: 'Impossibile aprire il profilo, per favore accedi prima.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'Impossibile aprire il checkout della carta valore, non sono stati specificati tutti i dati richiesti della carta valore.',
    country: 'Paese',
    createAccount: 'Crea un account',
    credit: {
        singular: 'Credito',
        plural: 'Crediti',
    },
    credits: 'Crediti',
    creditsLeft: 'Crediti rimasti',
    creditDiscount: 'Sconto credito',
    date: 'Data',
    delete: 'Elimina',
    doYouHaveAnAccount: 'Hai un account?',
    doYouHaveValueCards: 'Hai un account o delle carte valore?',
    doYouHaveBrattkort: 'Hai un Brattkort o una certificazione Belay?',
    doYouHaveClimbingCertification: 'Hai una certificazione da arrampicata?',
    edit: 'Modifica',
    editTimeslot: 'Modifica orario',
    failed: 'Fallito',
    firstName: 'Nome',
    giftcard: 'Carta regalo',
    goToMyPage: 'Vai alla mia pagina',
    ifYouWantToDeleteYourProfile: 'Se desideri eliminare il tuo profilo',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Puoi acquistare i biglietti dal negozio su questo sito web.',
    yourOrderReferenceIs: "Il tuo riferimento d'ordine è",
    iHaveReadAndAccepted: 'Ho letto e accettato il',
    lastName: 'Cognome',
    postalLocation: 'Posizione',
    location: 'Posizione',
    loggedInAs: 'Accesso come',
    login: 'Accedi',
    logout: 'Esci',
    membershipCard: 'Carta di membro',
    membershipNumber: 'Numero di membro',
    membershipSlashCredits: 'Membro / Crediti',
    monthlyCharge: 'Addebito mensile',
    myAddress: 'Il mio indirizzo',
    myPage: 'La mia pagina',
    myProfile: 'Il mio profilo',
    nextCreateAccount: 'Avanti: Crea un account',
    no: 'No',
    noneSelected: 'Nessuna selezione',
    noSelectedDate: 'Nessuna data selezionata',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Non tutti i campi sono stati compilati, ti preghiamo di fornire tutti i campi.',
    noUserInfoFound: 'Nessuna informazione utente trovata.',
    numberOfPersons: {
        singular: 'persona',
        plural: 'persone',
        template: '<COUNT:person:persone>',
    } as any,
    pay: 'Paga',
    payWithCreditCard: 'Paga con carta di credito',
    phoneNumber: 'Numero di telefono',
    pleaseChoose: 'Si prega di scegliere',
    pleaseContact: 'Si prega di contattare',
    pleaseFillOutUserInfoInProfile: 'Si prega di compilare le informazioni utente nel tuo profilo.',
    pleaseLogInToBook: 'Si prega di accedere per acquistare con questi',
    pleaseLogInToPurchase:
        'Si prega di accedere e noi compileremo le tue informazioni di contatto per te',
    pleaseProvideAllValueCardInformation:
        'Si prega di fornire tutte le informazioni sulla carta valore',
    pleaseProvideProperBookingInformation: 'Si prega di fornire informazioni valide',
    pleaseRegister: 'Si prega di registrarsi',
    postalCodeAndLocation: 'Codice postale / luogo',
    prefix: 'Prefisso',
    privacyPolicy: 'Informativa sulla privacy',
    problemLoadingData: 'Errore durante il caricamento dei dati',
    processing: 'Elaborazione',
    purchaseValueCard: 'Acquista carta valore',
    readMoreAbout: 'Leggi di più su',
    readMoreAndBook: 'Leggi di più e acquista',
    readMoreAndBuy: 'Leggi di più e acquista',
    refillCredits: 'Ricarica crediti',
    returnToWebsite: 'Torna al sito web',
    scanTicket: 'Scansiona il biglietto',
    signUp: 'Registrati',
    subtract: 'Sottrai',
    shoesAndHarnessAlwaysIncluded: 'Scarpe e imbracatura sempre incluse.',
    shoesAndHarnessNotIncluded: 'Scarpe e imbracatura non incluse.',
    somethingWentWrongUpdatingUserInfo:
        "Qualcosa è andato storto nell'aggiornamento delle informazioni utente.",
    termsOfUse: {
        template: "Condizioni d'uso per <NAME>",
    } as any,
    upcomingBookings: 'Prenotazioni future',
    updateAddress: 'Aggiorna indirizzo',
    terminate: 'Termina',
    thankYouForBookingWithUs: 'Grazie per aver prenotato con noi.',
    thePrivacyPolicy: 'Informativa sulla privacy',
    thankYouForShoppingWithUs: 'Grazie per aver fatto acquisti con noi.',
    thisGiftcardIsEmpty: 'Questa carta regalo è vuota.',
    tHours: 'ore',
    totalCredits: 'Crediti totali',
    totalNOK: 'Totale NOK',
    updateBooking: 'Aggiorna prenotazione',
    updateProfile: 'Aggiorna profilo',
    useGiftcard: 'Usa la carta regalo',
    useGiftcardOrPromocode: 'Usa carta regalo / codice promozionale',
    validationNotANumber: 'Deve essere un numero',
    validationMaxNumber: {
        template: 'Nessun numero superiore a <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Nessun numero inferiore a <NUM>',
    } as any,
    validForThePeriod: 'Valido per il periodo',
    validUntil: 'Valido fino al',
    validFrom: 'Valido da',
    valueCard: 'Carta valore',
    visitors: 'Visitatori',
    withoutVat: 'Senza IVA',
    yes: 'Sì',
    youDontHaveAnyUpcomingBookings: 'Non hai prenotazioni future.',
    youDontHaveAnyValueCards: 'Non hai carte valore',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'Non hai una tessera di membro valida, desideri acquistarne una?',
    youMustSpecifyAValueCard: 'Devi specificare una carta valore valida.',
    yourReservationTimedOut: 'La tua prenotazione è scaduta.',
    zipcode: 'Codice postale',
    payByMembershipCard: 'Paga con la tessera di membro',
    payWith: 'Paga con',
    deleteMyProfile: 'Cancella il mio profilo',
    areYouSureYouWantToDeleteYourProfile: 'Sei sicuro di voler cancellare il tuo profilo?',
    deleteYourProfileInformation:
        'Stai per eliminare e scollegare le informazioni del tuo profilo da qualsiasi prenotazione e prodotto acquistato. Questa azione non può essere annullata. Alcune informazioni potrebbero essere conservate per motivi legali.',
    choosePaymentOtherMethods: 'Scegli altri metodi di pagamento',
    dontUseMembershipCardForThisBooking:
        'Non utilizzare la tessera di membro per questa prenotazione',
    nameNotProvided: 'Il nome non è stato fornito',
    phoneNumberNotProvided: 'Il numero di telefono non è stato fornito',
    emailNotProvided: "L'indirizzo email non è stato fornito",
    addressNotProvided: "L'indirizzo non è stato fornito",
    promocode: 'Codice promozionale',
    show_usage_terms: "Mostra Termini d'uso",
    hide_usage_terms: "Nascondi Termini d'uso",
    about_the_product: 'riguardo al prodotto',
    successful: 'Riuscito',
    selectProduct: 'Seleziona Prodotto',
    withCreditCard: 'Con carta di credito',
    paymentMethod: 'Metodo di pagamento',
    noAvailableTimeslots: 'Nessun orario disponibile',
    orderReference: "Riferimento dell'ordine",
    iWishToRecieveNewsletters: 'Desidero ricevere newsletter',
    mySite: 'Il mio sito',
    pleaseConfirm: {
        template: 'Si prega di confermare "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Si prega di fornire il numero di partecipanti',
    pleaseSelectTheDateAndTimeOfYourBooking:
        "Si prega di selezionare la data e l'orario della tua prenotazione",
    pleaseSelectNumberOfCredits: 'Si prega di selezionare il numero di crediti',
    pleaseSelectTypeOfCreditcard: 'Si prega di selezionare il tipo di carta di credito',
    pleaseSelectAStartDate: 'Si prega di selezionare una data di inizio',
    pleaseSelectAMembershipType: 'Si prega di selezionare un tipo di membership',
    contactCustomerServiceToDeleteYourProfile:
        'Contattare il servizio clienti per cancellare il proprio profilo',
    inclInMembership: 'Incl. nella membership',
    maxAmount: 'Importo massimo',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    anErrorOccurredDuringPayment: 'Si è verificato un errore durante il pagamento',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Si è verificato un errore, si prega di riprovare ricaricando la pagina. Messaggio di errore',
    share: 'Condividi',
    copyLink: 'Copia link',
    howDoYouWantToShare: 'Come desideri condividere?',
    shoppingCart: 'Carrello',
    heresMyShoppingCart: 'Ecco il mio carrello',
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        "Non c'è abbastanza capacità per gli elementi in questo carrello",
    full: 'Pieno',
    no_available_capacity_for_this_tour: 'Nessuna capacità disponibile per questo tour',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Una o più attività non hanno abbastanza capacità',
    pick_a_date: 'Scegli una data',
    package: 'Pacchetto',
    packages: 'Pacchetti',
    customize_activities: 'Personalizza attività',
    check_in_at: 'Check-in alle',
    activity_starts_at: "L'attività inizia alle",
    other_times_are_available: 'Altri orari sono disponibili',
    press_to_select_other_options: 'Premi per selezionare altre opzioni',
    unavailableForSelectedTime: "Non disponibile all'orario selezionato",
    order_status_awaiting_payment: 'In attesa di pagamento',
    order_status_payment_failed: 'Pagamento fallito',
    awaiting_payment_info:
        'Elaborazione pagamento... di solito non richiede più di qualche minuto.',
    cancel_membership: 'Annulla la membership',
    update_subscription_payment_details: 'Aggiorna i dettagli di pagamento',
    payment_failed_info:
        'Sembra ci sia un problema con il tuo pagamento. Puoi provare a aggiornare i dettagli di pagamento.',
    retry_subscription_payment: 'Riprova il pagamento ora',
    company: 'Azienda',
    company_name: 'Nome azienda',
    org_number: 'Numero di registrazione',
    pay_with_invoice: 'Paga con fattura',
    continueWithRebooking: 'Continua con la riprenotazione',
    searchAndBookAccommodations: 'Cerca e prenota alloggi',
    selectNumberOfGuests: 'Seleziona il numero di ospiti',
    checkAvailability: 'Controlla la disponibilità',
    updateAvailability: 'Aggiorna la disponibilità',
    you_will_have_to_switch_rooms_during_your_stay:
        'Dovrete cambiare camera durante il vostro soggiorno. Contattateci per procedere con la prenotazione.',
    selected: 'Selezionato',
    remove: 'Rimuovi',
    promo_code_not_supported_for_gift_cards:
        'Il codice promozionale non è supportato per le carte regalo',
    select_products: 'Seleziona prodotti',
    change_selected_products: 'Cambia prodotti selezionati',
    cartCompatibilityWarning:
        'Ci sono prodotti nel tuo carrello che non sono compatibili con questo prodotto. Ti preghiamo di acquistarli separatamente o di svuotare il carrello per continuare.',
    membershipBookingDisabledWarning:
        'La prenotazione per i membri è disabilitata su questo sito web, ma questo prodotto la richiede. Si tratta molto probabilmente di una configurazione errata. Si prega di contattare il servizio clienti.',
} as any;

export default translations;
