import { Box, Skeleton, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { useCustomizations } from 'src/components/utils/theme/customizations';

export default function AccommodationImage(props: {
    url: string | null | undefined;
    disableShadows?: boolean;
    faded?: boolean;
}): JSX.Element {
    const { url, disableShadows, faded = false } = props;

    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Fragment>
            {url ? (
                <Box
                    boxShadow={
                        customizations.imageShadow && !disableShadows
                            ? { boxShadow: theme.shadows[2] }
                            : undefined
                    }
                    sx={[
                        {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            borderTopLeftRadius: customizations.borderRadius - 1,
                            borderTopRightRadius: customizations.borderRadius - 1,
                        },
                        faded && { opacity: 0.25, filter: 'grayscale(80%)' },
                    ]}
                    src={url}
                    component="img"
                    alt="image"
                />
            ) : (
                <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
        </Fragment>
    );
}
