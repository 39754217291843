import { Grid, Stack, SxProps } from '@mui/material';

import {
    ProductCardContainer,
    ProductCardHeader,
} from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfoContainer } from 'src/components/domain/product-card-2/ProductCardInfo';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';
import { TourProductCardBookingForm } from './TourProductCardForm';
import { ProductInstance } from '@repo/types';
import { ActivityProductCardInfoContent } from '../product-card-2/ActivityProductCard';
import { ProductCard2Landscape } from '../product-card-2/ProductCardLandscape';
import { BookingContextProvider } from 'src/widgets/BookingContext';

type PropsLandscape = {
    tours: ProductInstance[][];
    hideReadMore?: boolean;
    hideImageIfMissing?: boolean;
    orientation: 'landscape';
    cardBorder?: string;
    cardBorderRadius?: number;
    hideImage?: boolean;
    hideDescription?: boolean;
    hideTitle?: boolean;
};

type PropsPortrait = Omit<PropsLandscape, 'orientation'> & {
    sx?: SxProps;
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    maxWidthCard?: Parameters<typeof ProductCardContainer>[0]['maxWidth'];
    orientation?: 'portrait';
    hideImage?: boolean;
    hideDescription?: boolean;
    hideTitle?: boolean;
};

export default function TourProductCardList(props: PropsPortrait | PropsLandscape): JSX.Element {
    if (props.orientation === 'landscape') {
        return <TourProductCardListLandscape {...props} />;
    }
    return <TourProductCardListPortrait {...props} />;
}

export function TourProductCardListLandscape(props: PropsLandscape): JSX.Element {
    const {
        tours,
        hideReadMore,
        hideImageIfMissing,
        cardBorder,
        cardBorderRadius,
        hideImage,
        hideTitle,
        hideDescription,
    } = props;

    const productCardList = tours.map((products) => {
        const [firstProduct] = products;
        const shouldHideImage =
            hideImage || (hideImageIfMissing && !firstProduct.product?.coverImage.src);

        const hasMultipleProducts = products.length > 1;
        const initialSelectedProducts = !hasMultipleProducts ? products : undefined;

        return (
            <BookingContextProvider
                key={firstProduct.id}
                initialProducts={initialSelectedProducts}
                initialTicketOptions={[]}
            >
                <ProductCard2Landscape
                    product={firstProduct.product!}
                    body={
                        <TourProductCardBookingForm products={products} displayTimeBorder={false} />
                    }
                    hidePriceIcon
                    hideIcons={
                        !!firstProduct.product!.icons && firstProduct.product!.icons.length === 0
                    }
                    hideImage={shouldHideImage}
                    hideTitle={hideTitle}
                    hideDescription={hideDescription}
                    readMoreProductId={firstProduct.product?.id}
                    readMoreLinkVariant={hideReadMore ? 'none' : 'popup'}
                    border={cardBorder}
                    borderRadius={cardBorderRadius}
                />
            </BookingContextProvider>
        );
    });

    return (
        <Stack gap={3} width="100%">
            {productCardList}
        </Stack>
    );
}

export function TourProductCardListPortrait(props: PropsPortrait): JSX.Element {
    const {
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        tours,
        hideReadMore,
        hideImageIfMissing,
        hideImage,
        hideTitle,
        hideDescription,
        sx,
        maxWidthCard,
    } = props;
    const customizations = useCustomizations();

    const productCardList = tours.map((products, i) => {
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
            bookingWidgetColorContrast: textColors[cur] ?? customizations.productCardTextColor,
        };

        const [firstProduct] = products;
        const shouldHideImage =
            hideImage || (hideImageIfMissing && !firstProduct.product?.coverImage.src);

        const { showTime } = firstProduct;

        const hasMultipleProducts = products.length > 1;
        const initialSelectedProducts = !hasMultipleProducts ? products : undefined;

        return (
            <BookingContextProvider
                key={firstProduct.id}
                initialProducts={initialSelectedProducts}
                initialTicketOptions={[]}
            >
                <CustomizationsContext.Provider value={alternatingCustomizations}>
                    <ProductCardListItem
                        scroll={scroll}
                        numElements={numElements}
                        numProductCatalogs={tours.length ?? 0}
                    >
                        <ProductCardContainer maxWidth={maxWidthCard}>
                            {!shouldHideImage && (
                                <ProductCardHeader
                                    imageUrl={firstProduct.product?.coverImage.src}
                                />
                            )}
                            <ProductCardInfoContainer>
                                <ActivityProductCardInfoContent
                                    key={firstProduct.id}
                                    product={firstProduct.product}
                                    url={firstProduct.product?.url ?? ''}
                                    customPriceLabel={
                                        <Grid
                                            component="span"
                                            fontWeight={customizations.boldFontWeight}
                                            fontSize="1rem"
                                        >
                                            {firstProduct.start.format('Do MMM')}
                                        </Grid>
                                    }
                                    hidePriceIcon
                                    hideFromLabel={!showTime || shouldHideImage}
                                    hideTitle={hideTitle}
                                    hideDescription={hideDescription}
                                    readMoreProductId={firstProduct.product?.id}
                                    readMoreLinkVariant={hideReadMore ? 'none' : 'popup'}
                                />
                                <TourProductCardBookingForm products={products} />
                            </ProductCardInfoContainer>
                        </ProductCardContainer>
                    </ProductCardListItem>
                </CustomizationsContext.Provider>
            </BookingContextProvider>
        );
    });

    return (
        <ProductCardListContainer scroll={scroll} numElements={numElements} sx={sx}>
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}
