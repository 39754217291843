import { groupBy, mapValues } from 'lodash-es';
import {} from 'react';
import { localeAtom } from '@repo/i18n';
import { TicketOptionWithQuantity, Translations } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';

function getNumberOfTravelers(quantities: TicketOptionWithQuantity[]) {
    return quantities.reduce((sum, current) => sum + current.quantity * current.occupancy, 0);
}

function calculateMaxForInstanceCollection(
    quantity: number,
    totalTravelers: number,
    maxEntrantsProductCollection: number | null,
    maxEntrantsProductInstance: number | null,
) {
    const maxProductInstance = maxEntrantsProductInstance ?? Infinity;
    const maxProductCollection = maxEntrantsProductCollection
        ? maxEntrantsProductCollection -
          (totalTravelers !== quantity ? totalTravelers - quantity : 0)
        : Infinity;
    return Math.min(maxProductInstance, maxProductCollection);
}

function calculateMaxBaseCase(
    capacity: number,
    maxEntrantsProductInstance: number | null,
    quantity: number,
    totalTravelers: number,
) {
    const max = Math.min(maxEntrantsProductInstance ?? Infinity, capacity);
    const maxProductInstance = max - (totalTravelers !== quantity ? totalTravelers - quantity : 0);
    return Math.min(max, maxProductInstance);
}

function calculateMin(
    minEntrants: number | null,
    productInstances: TicketOptionWithQuantity['productInstances'],
) {
    if (minEntrants) return minEntrants;
    return productInstances.reduce((min, pi) => Math.max(min, pi.minEntrants ?? 0), 0);
}

export function mapPriceQuantitiesToMultipleNumberInputValueType(
    t: Translations,
    quantities: TicketOptionWithQuantity[],
    defaultQuantities: TicketOptionWithQuantity[],
    errors: QuantityError[],
) {
    const totalTravelers = getNumberOfTravelers(quantities);
    const multipleNumberInputValues = quantities.map((q, i) => {
        let maxEntrantsProductCollection = null;
        let maxEntrantsProductInstance = null;
        let isProductInstanceCollection = false;

        q.productInstances.forEach((pi) => {
            if (pi.productInstanceCollectionId !== null) {
                isProductInstanceCollection = true;
            }
            if (pi.productInstanceCollectionMaxEntrantsPerBooking) {
                maxEntrantsProductCollection = pi.productInstanceCollectionMaxEntrantsPerBooking;
            }
            if (pi.capacity !== Number.MAX_VALUE) {
                maxEntrantsProductInstance = pi.capacity;
            }
        });

        const quantity = q.quantity * q.occupancy;

        let max = Math.floor(
            calculateMaxBaseCase(q.capacity, maxEntrantsProductInstance, quantity, totalTravelers) /
                q.occupancy,
        );

        if (isProductInstanceCollection && maxEntrantsProductCollection) {
            max = calculateMaxForInstanceCollection(
                quantity,
                totalTravelers,
                maxEntrantsProductCollection,
                maxEntrantsProductInstance,
            );
        } else if (isProductInstanceCollection) {
            max = calculateMaxForInstanceCollection(
                quantity,
                quantity,
                maxEntrantsProductInstance,
                maxEntrantsProductInstance,
            );
        }

        const min = calculateMin(q.minEntrants, q.productInstances);

        return {
            name: q.name,
            value: q.quantity,
            subText: getSubText(t, q),
            id: q.ticketCategoryId as unknown as number,
            // disabled: q.shouldDisablePrice, // TODO: Find a way to handle this
            disabledText: t.some_tickets_are_unavailable_the_selected_day,
            min,
            max,
            error: errors.find((e) => e.id === q.ticketCategoryId),
        };
    });

    const defaultMultipleNumberInputValues = defaultQuantities.map((q, i) => {
        const min = calculateMin(q.minEntrants, q.productInstances);

        return {
            name: q.name,
            value: q.quantity,
            subText: getSubText(t, q),
            id: q.ticketCategoryId as unknown as number,
            // disabled: q.shouldDisablePrice, // TODO: Find a way to handle this
            disabledText: t.some_tickets_are_unavailable_the_selected_day,
            min: min === Infinity ? 0 : min,
            max: Math.floor(q.capacity / q.occupancy),
            error: errors.find((e) => e.id === q.ticketCategoryId),
        };
    });

    return { multipleNumberInputValues, defaultMultipleNumberInputValues };
}

export function getSubText(t: Translations, ticketOptions: TicketOptionWithQuantity | undefined) {
    if (!ticketOptions) return undefined;
    return ticketOptions.fromAge && ticketOptions.toAge
        ? `(${capitalize(t.age)} ${ticketOptions.fromAge}-${ticketOptions.toAge})`
        : undefined;
}

export function onChangeQuantity(
    id: number,
    quantity: number,
    setHasChangedQuantities: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setQuantities: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>,
) {
    setQuantities((prevState) => {
        const index = prevState?.findIndex((x) => x.ticketCategoryId === id.toString());

        if (prevState[index].quantity !== quantity) {
            const newQuantities = [
                ...prevState.slice(0, index),
                { ...prevState[index], quantity: quantity },
                ...prevState.slice(index + 1),
            ];

            if (newQuantities[index].quantity > newQuantities[index].capacity) {
                if (quantity > prevState[index].quantity) {
                    return prevState;
                }
            }
            return newQuantities;
        }
        return prevState;
    });
    setHasChangedQuantities?.(true);
}

export type QuantityError = {
    id: string;
    error: 'minEntrants' | 'capacity';
    value: number;
    type: 'individual' | 'total';
};

function validateMinEntrantsForProductInstanceCollection(
    ticketOptions: TicketOptionWithQuantity[],
    errors: QuantityError[],
) {
    const productInstanceCollectionMinEntrants =
        ticketOptions[0].productInstances.find(
            (pi) => typeof pi.productInstanceCollectionMinEntrants === 'number',
        )?.productInstanceCollectionMinEntrants ?? 0;
    const numberOfTravelers = ticketOptions.reduce(
        (acc, cur) => acc + cur.quantity * cur.occupancy,
        0,
    );

    if (numberOfTravelers < productInstanceCollectionMinEntrants) {
        errors.push({
            id: ticketOptions[0].productInstances[0].productInstanceCollectionId ?? '',
            error: 'minEntrants',
            value: productInstanceCollectionMinEntrants,
            type: 'total',
        });
    }
}

function validateIndividualProductRequirements(
    ticketOptions: TicketOptionWithQuantity[],
    numberOfTravelersPerProductInstance: Record<string, number>,
    requireExplicitlyAllowedZeroQuantity: boolean,
    errors: QuantityError[],
) {
    ticketOptions.forEach((to) => {
        const quantity = to.quantity * to.occupancy;
        if (quantity === 0 && !requireExplicitlyAllowedZeroQuantity) return;

        const hasMinEntrantsForTicketOption =
            to.minEntrants !== null && typeof to.minEntrants === 'number';

        const isCurrentQuantityLessThanMinEntrantsForTicketOption =
            hasMinEntrantsForTicketOption && quantity < to.minEntrants;

        if (isCurrentQuantityLessThanMinEntrantsForTicketOption) {
            errors.push({
                id: to.ticketCategoryId,
                error: 'minEntrants',
                type: 'individual',
                value: to.minEntrants,
            });
        } else {
            to.productInstances.forEach((pi) => {
                const requiredNumberOfTravellersForThisInstance = pi.minEntrants ?? 0;
                const currentTravellersForThisInstance = numberOfTravelersPerProductInstance[pi.id];
                const hasTravellersForThisProductInstance = currentTravellersForThisInstance > 0;

                const validateRequiredNumberOfTravellers =
                    hasTravellersForThisProductInstance || requireExplicitlyAllowedZeroQuantity;
                const hasLessTravellersThanRequired =
                    currentTravellersForThisInstance < requiredNumberOfTravellersForThisInstance;

                const hasError =
                    validateRequiredNumberOfTravellers && hasLessTravellersThanRequired;

                if (hasError) {
                    errors.push({
                        id: to.ticketCategoryId,
                        error: 'minEntrants',
                        type: 'individual',
                        value: pi.minEntrants ?? 0,
                    });
                }
            });
        }
    });
}

function checkCapacityConstraints(
    ticketOptions: TicketOptionWithQuantity[],
    numberOfTravelers: number,
    errors: QuantityError[],
) {
    ticketOptions.forEach((to) => {
        let travelers = numberOfTravelers;
        const isProductInstanceCollection = to.productInstances.some(
            (p) => p.productInstanceCollectionId !== null,
        );

        if (isProductInstanceCollection) {
            travelers = to.quantity * to.occupancy;
        }

        to.productInstances.forEach((pi) => {
            if (travelers > pi.capacity) {
                errors.push({
                    id: to.ticketCategoryId,
                    error: 'capacity',
                    type: isProductInstanceCollection ? 'individual' : 'total',
                    value: pi.capacity,
                });
            }

            if (
                typeof pi.productInstanceCollectionMaxEntrantsPerBooking === 'number' &&
                travelers > pi.productInstanceCollectionMaxEntrantsPerBooking
            ) {
                errors.push({
                    id: to.ticketCategoryId,
                    error: 'capacity',
                    type: 'total',
                    value: pi.productInstanceCollectionMaxEntrantsPerBooking,
                });
            }
        });

        if (to.capacity !== Number.MAX_VALUE && travelers > to.capacity) {
            errors.push({
                id: to.ticketCategoryId,
                error: 'capacity',
                type: isProductInstanceCollection ? 'individual' : 'total',
                value: to.capacity,
            });
        }
    });
}

export function verifyQuantities(priceQuantities: TicketOptionWithQuantity[]) {
    const errors: QuantityError[] = [];
    const groupedTicketOptions = groupBy(priceQuantities, (pq) =>
        pq.productInstances.map((pi) => pi.productInstanceCollectionId ?? pi.id).join(''),
    );

    Object.values(groupedTicketOptions).forEach((ticketOptions) => {
        const firstTicketOption = ticketOptions[0];
        const firstProductInstance =
            firstTicketOption.productInstances.length > 0
                ? firstTicketOption.productInstances[0]
                : null;
        const isJoinedTrip =
            firstProductInstance &&
            firstProductInstance.productInstanceCollectionMinEntrants !== null
                ? true
                : false;

        const numberOfTravelers = ticketOptions.reduce(
            (acc, cur) => acc + cur.quantity * cur.occupancy,
            0,
        );
        const numberOfTravelersPerProductInstance = mapValues(
            groupBy(ticketOptions, (to) => to.productInstances[0]?.id),
            (to) => to.reduce((acc, cur) => acc + cur.quantity * cur.occupancy, 0),
        );

        const isProjectCollectionMode = ticketOptions[0].productInstances.some(
            (x) => x.productInstanceCollectionId,
        );

        if (isProjectCollectionMode) {
            validateMinEntrantsForProductInstanceCollection(ticketOptions, errors);
        }

        const projectCollectionRequireAllValid = ticketOptions.some((t) =>
            t.productInstances.some((pi) => pi.productInstanceCollectionValidateAll),
        );

        const skipIndividualProductRequirementValidation =
            isProjectCollectionMode && isJoinedTrip && !projectCollectionRequireAllValid;
        const requireIndividialProductRequirementValidation =
            !isProjectCollectionMode || !skipIndividualProductRequirementValidation;
        if (requireIndividialProductRequirementValidation) {
            const requireExplicitlyAllowedZeroQuantity =
                isJoinedTrip && projectCollectionRequireAllValid;
            validateIndividualProductRequirements(
                ticketOptions,
                numberOfTravelersPerProductInstance,
                requireExplicitlyAllowedZeroQuantity,
                errors,
            );
        }

        checkCapacityConstraints(ticketOptions, numberOfTravelers, errors);
    });

    return errors;
}

export function verifyQuantitiesValid(
    priceQuantities: TicketOptionWithQuantity[],
    capacityWarningLabel?: string,
    hasChosenDate?: boolean,
    attemptedBooking?: boolean,
) {
    const errors: QuantityError[] = verifyQuantities(priceQuantities);

    function getWarningLabel() {
        if (!hasChosenDate) return undefined;
        const { t } = localeAtom.subject.value;
        if (errors.some((e) => e.error === 'minEntrants')) {
            return t.a_minimum_of_participants_is_required_to_book_this_product;
        } else if (errors.some((e) => e.error === 'capacity')) {
            return capacityWarningLabel ?? t.no_available_capacity_for_this_tour;
        }
    }

    if (!priceQuantities.some((q) => q.quantity > 0) && attemptedBooking && errors.length === 0) {
        errors.push({
            id: 'totalMinEntrants',
            error: 'minEntrants',
            value: 1,
            type: 'total',
        });
    }

    const quantityErrors = errors.filter((q) => q.type === 'individual');
    const totalErrors = errors.filter((q) => q.type === 'total');

    return {
        disableBookButton: (!hasChosenDate && attemptedBooking) || errors.length > 0,
        quantityErrors,
        totalErrors,
        getWarningLabel,
    };
}
