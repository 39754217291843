import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
    Box,
    capitalize,
    Collapse,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    TextField,
    useTheme,
} from '@mui/material';

import { useState } from 'react';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { ChildInfo, updateChild } from './guestInfo';

interface ChildrenDataProps {
    childId: number;
    child: ChildInfo;
    childrenList: ChildInfo[];
    onChildrenListChanged: (childrenInfo: ChildInfo[]) => void;
}

const childAgeList = ['less than 1 year', '1 year', '2 years', '3 years'];
const childBedList = ['in parents bed', 'in crib', 'in extra bed'];

export default function ChildrenData(props: ChildrenDataProps): JSX.Element {
    const { childId, child, childrenList, onChildrenListChanged } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const [ageExpanded, setAgeExpanded] = useState(false);
    const [bedExpanded, setBedExpanded] = useState(false);

    return (
        <Box mt={theme.spacing(2)} fontSize="smaller">
            <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="flex-end"
                flex={'1 1 0px'}
            >
                <FormControl
                    sx={{
                        width: '100%',
                        maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
                        padding: theme.spacing(0, 1),
                    }}
                >
                    <InputLabel
                        sx={{
                            ...TextFieldStyleUtils.positionInputLabelAboveField,
                            color: theme.palette.text.primary,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {capitalize(t.children.singular) + ' ' + childId.toString()}
                    </InputLabel>
                    <TextField
                        variant="filled"
                        sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            width: '100%',
                            borderRadius: `${theme.shape.borderRadius}px`,
                            marginBottom: 0,

                            '& input': {
                                width: '70px',
                                fontSize: theme.spacing(1.5),
                                padding: `${theme.spacing(1)}px`,
                            },
                            '& svg': {
                                fontSize: theme.spacing(3),
                            },
                        }}
                        margin="dense"
                        onClick={() => setAgeExpanded(!ageExpanded)}
                        placeholder={capitalize(t.select_age)}
                        value={child.age}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{ position: 'absolute', right: theme.spacing(0.5) }}
                                >
                                    {<KeyboardArrowDownRounded />}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Collapse
                        in={ageExpanded}
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            backgroundColor: 'white',
                            fontSize: 'smaller',
                            zIndex: 100,
                        }}
                    >
                        <Grid item container direction="column">
                            {childAgeList.map((entry, key) => (
                                <MenuItem
                                    value={key}
                                    key={key}
                                    onClick={() =>
                                        handleSelectAge(
                                            entry,
                                            ageExpanded,
                                            setAgeExpanded,
                                            childrenList,
                                            childId,
                                            onChildrenListChanged,
                                        )
                                    }
                                >
                                    {capitalize(entry)}
                                </MenuItem>
                            ))}
                        </Grid>
                    </Collapse>
                </FormControl>

                {child.age ? (
                    <FormControl
                        fullWidth
                        sx={{
                            maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
                            padding: theme.spacing(0, 1),
                        }}
                    >
                        <TextField
                            variant="filled"
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100%',
                                borderRadius: theme.shape.borderRadius,
                                marginBottom: 0,

                                '& input': {
                                    width: '70px',
                                    fontSize: theme.spacing(1.5),
                                    padding: `${theme.spacing(1)}px`,
                                },
                                '& svg': {
                                    fontSize: theme.spacing(3),
                                },
                            }}
                            margin="dense"
                            placeholder={capitalize(t.select_bed)}
                            onClick={() => setBedExpanded(!bedExpanded)}
                            value={child.bed}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{ position: 'absolute', right: theme.spacing(0.5) }}
                                    >
                                        {<KeyboardArrowDownRounded />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Collapse
                            in={bedExpanded}
                            sx={{
                                position: 'absolute',
                                top: '100%',
                                backgroundColor: 'white',
                                fontSize: 'smaller',
                                zIndex: 100,
                            }}
                        >
                            <Grid item container direction="column">
                                {childBedList.map((entry, key) => (
                                    <MenuItem
                                        value={key}
                                        key={key}
                                        onClick={() =>
                                            handleSelectBed(
                                                entry,
                                                setBedExpanded,
                                                bedExpanded,
                                                childrenList,
                                                childId,
                                                onChildrenListChanged,
                                            )
                                        }
                                    >
                                        {capitalize(entry)}
                                    </MenuItem>
                                ))}
                            </Grid>
                        </Collapse>
                    </FormControl>
                ) : (
                    <Box></Box>
                )}
            </Grid>
        </Box>
    );
}

function handleSelectAge(
    age: string,
    ageExpanded: boolean,
    setAgeExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    childrenList: ChildInfo[],
    childId: number,
    onChildrenListChanged: (childrenInfo: ChildInfo[]) => void,
) {
    setAgeExpanded(!ageExpanded);
    const newChildren = updateChild(childrenList, childId, { age });
    onChildrenListChanged(newChildren);
}

function handleSelectBed(
    bed: string,
    setBedExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    bedExpanded: boolean,
    childrenList: ChildInfo[],
    childId: number,
    onChildrenListChanged: (childrenInfo: ChildInfo[]) => void,
) {
    setBedExpanded(!bedExpanded);
    const newChildren = updateChild(childrenList, childId, { bed });
    onChildrenListChanged(newChildren);
}
