import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { getPrimaryColors } from 'src/components/utils/theme/getPrimaryColors';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { zIndex } from 'src/utils/widget/zIndex';

declare module '@mui/material/styles' {
    interface Palette {
        button: { main: string };
    }
    interface PaletteOptions {
        button?: { main?: string };
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        button: true;
    }
}

const defaultMuiTheme = createTheme();

export const MUITheme = (customizations: ThemeType) => {
    const theme = createTheme({
        spacing: customizations.baseUnit,
        shape: {
            borderRadius: parseInt(customizations.borderRadius.toString()),
        },
        palette: {
            primary: {
                main: customizations.primaryColor,
                contrastText: customizations.primaryColorContrast,
            },
            secondary: {
                main: customizations.secondaryColor,
                contrastText: customizations.secondaryColorContrast,
            },
            error: {
                main: customizations.errorColor,
            },
            warning: {
                main: customizations.warningColor,
            },
            text: {
                primary: customizations.primaryColor,
                secondary: customizations.secondaryColor,
            },
            grey: {
                '50': customizations.lightestGrey,
                '100': darken(customizations.lightestGrey, 0.05),
                '200': darken(customizations.lightestGrey, 0.1),
                '300': darken(customizations.lightestGrey, 0.15),
                '400': darken(customizations.lightestGrey, 0.2),
                '500': darken(customizations.lightestGrey, 0.3),
                '600': darken(customizations.lightestGrey, 0.4),
                '700': darken(customizations.lightestGrey, 0.5),
                '800': darken(customizations.lightestGrey, 0.6),
                '900': darken(customizations.lightestGrey, 0.7),
            },
            button: {
                main: customizations.primaryColor,
            },
        },
        typography: {
            fontFamily: customizations.fontFamily,
            fontSize: customizations.fontSize,
            body1: {
                fontSize: customizations.bodySize,
                fontFamily: customizations.bodyFont,
                fontWeight: customizations.bodyFontWeight,
                color: customizations.bodyColor,
            },
            body2: {
                fontSize: customizations.bodySize,
                fontFamily: customizations.bodyFont,
                fontWeight: customizations.bodyFontWeight,
                color: customizations.bodyColor,
            },
            h1: {
                fontSize: customizations.h1Size,
                fontFamily: customizations.h1Font,
                fontWeight: customizations.h1FontWeight,
                color: customizations.h1Color,
            },
            h2: {
                fontSize: customizations.h2Size,
                fontFamily: customizations.h2Font,
                fontWeight: customizations.h2FontWeight,
                color: customizations.h2Color,
            },
            h3: {
                fontSize: customizations.h3Size,
                fontFamily: customizations.h3Font,
                fontWeight: customizations.h3FontWeight,
                color: customizations.h3Color,
            },
            h4: {
                fontSize: customizations.h4Size,
                fontFamily: customizations.h4Font,
                fontWeight: customizations.h4FontWeight,
                color: customizations.h4Color,
            },
            h5: {
                fontSize: customizations.h5Size,
                fontFamily: customizations.h5Font,
                fontWeight: customizations.h5FontWeight,
                color: customizations.h5Color,
            },
            h6: {
                fontSize: customizations.h6Size,
                fontFamily: customizations.h6Font,
                fontWeight: customizations.h6FontWeight,
                color: customizations.h6Color,
            },
        },
        breakpoints: {
            values: customizations.breakpoints,
        },
        components: {
            MuiFormControl: {
                styleOverrides: {
                    marginDense: {
                        marginTop: 0,
                        marginBottom: '4px',
                    },
                },
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: `rgba(0, 0, 0, 0.8)`,
                    },
                    invisible: {
                        opacity: '0 !important',
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: customizations.primaryColor,
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: customizations.linkStyle === 'underline' ? 'always' : 'hover',
                },
                styleOverrides: {
                    root: {
                        color: customizations.linkColor,
                        '&:hover': {
                            color: customizations.primaryColor,
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColor}`,
                        },
                    },
                },
            },
            MuiScopedCssBaseline: {
                styleOverrides: {
                    root: {
                        '*': {
                            boxSizing: 'border-box',
                        },
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        zIndex: zIndex.alwaysVisible,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: customizations.buttonFontWeight ?? 600,
                        fontSize: customizations.buttonFontSize ?? customizations.fontSize,
                        fontFamily: customizations.buttonFont,
                        textTransform: 'none',

                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColor}, \
                                        0 0 0 3px ${customizations.primaryColorContrast}`,
                        },
                    },
                    contained: {
                        boxShadow: 'none',
                        '&:hover, &:active': {
                            boxShadow: 'none',
                        },
                        paddingLeft: `${customizations.containedButtonLeftRightPadding}px !important`,
                        paddingRight: `${customizations.containedButtonLeftRightPadding}px !important`,
                    },
                    containedPrimary: {
                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColorContrast}, \
                                        0 0 0 3px ${customizations.primaryColor}`,
                        },
                    },
                    outlined: {
                        borderWidth: `${customizations.outlinedButtonBorderWidth}px !important`,
                        paddingLeft: `${customizations.outlinedButtonLeftRightPadding}px !important`,
                        paddingRight: `${customizations.outlinedButtonLeftRightPadding}px !important`,
                    },
                    outlinedPrimary: {
                        borderColor: customizations.outlinedButtonBorderColor,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: '12px',
                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColor}, \
                                        0 0 0 3px ${customizations.primaryColorContrast}`,
                        },
                    },
                    colorPrimary: {
                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColorContrast}, \
                                        0 0 0 3px ${customizations.primaryColor}`,
                        },
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    action: {
                        '& .MuiIconButton-root': {
                            padding: '5px',
                        },
                    },
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    grouped: {
                        '&:not(:last-of-type)': {
                            borderTopRightRadius: customizations.borderRadius,
                            borderBottomRightRadius: customizations.borderRadius,
                            borderTopLeftRadius: customizations.borderRadius,
                            borderBottomLeftRadius: customizations.borderRadius,
                            borderWidth: customizations.outlinedButtonBorderWidth,
                            borderColor: customizations.secondaryColor,
                        },
                        '&:last-of-type': {
                            borderTopRightRadius: customizations.borderRadius,
                            borderBottomRightRadius: customizations.borderRadius,
                            borderTopLeftRadius: customizations.borderRadius,
                            borderBottomLeftRadius: customizations.borderRadius,
                            borderWidth: customizations.outlinedButtonBorderWidth,
                            borderColor: customizations.secondaryColor,
                        },
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        fontWeight: customizations.buttonFontWeight ?? 600,
                        fontSize: customizations.buttonFontSize ?? customizations.fontSize,
                        fontFamily: customizations.buttonFont,
                        borderColor: customizations.secondaryColor,
                        color: customizations.secondaryColor,
                        margin: customizations.baseUnit * 2,
                        padding: `${customizations.baseUnit * 1}px ${
                            customizations.baseUnit * 1.5
                        }px`,
                        transition: '0.15s ease-in-out',

                        '&:hover': {
                            backgroundColor: darken(customizations.lightestGrey, 0.05),
                            color: customizations.secondaryColor,
                        },

                        '&:focus': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColor}, \
                                        0 0 0 3px ${customizations.primaryColorContrast}`,
                        },

                        '&.Mui-selected': {
                            '&:hover': {
                                backgroundColor: darken(customizations.primaryColor, 0.2),
                                color: customizations.primaryColorContrast,
                            },
                            '&:focus': {
                                boxShadow: `0 0 0 2px ${customizations.primaryColorContrast}, \
                                            0 0 0 3px ${customizations.primaryColor}`,
                            },

                            '@media (hover: none)': {
                                '&:focus,&:active,&:hover': {
                                    borderColor: `${customizations.primaryColor} !important`,
                                    backgroundColor: customizations.primaryColor,
                                    color: customizations.primaryColorContrast,
                                },
                            },
                            borderColor: `${customizations.primaryColor} !important`,
                            backgroundColor: customizations.primaryColor,
                            color: customizations.primaryColorContrast,
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: `${defaultMuiTheme.palette.common.white} !important`,
                        color: customizations.inputFieldTextColor,
                        borderTopLeftRadius: customizations.borderRadius,
                        borderTopRightRadius: customizations.borderRadius,
                        borderBottomLeftRadius: customizations.borderRadius,
                        borderBottomRightRadius: customizations.borderRadius,
                        '&:hover,&:focus,&:active,&.Mui-focused': {
                            boxShadow: `0px 0px 2px 2px ${
                                getPrimaryColors(
                                    customizations.primaryColor,
                                    customizations.primaryColorContrast,
                                    '#FFFFFF',
                                    true,
                                ).backgroundColor
                            }`,
                        },
                    },
                    input: {
                        lineHeight: '1em !important',
                        height: '1.1876em',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: customizations.inputFieldLabelColor,
                        whiteSpace: 'normal',
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            boxShadow: `0 0 0 2px ${customizations.primaryColorContrast}, \
                                        0 0 0 3px ${customizations.primaryColor}`,
                        },
                    },
                    underline: {
                        '&::before,&:hover::before,&::after,&:hover::after': {
                            borderColor: 'transparent !important',
                            border: 0,
                        },
                    },
                    multiline: {
                        padding: `0 !important`,
                    },
                    input: {
                        padding: '10px !important',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& fieldset ': {
                            transition: 'border-color 0.255s',
                            borderColor: customizations.inputFieldBorderColor,
                        },
                        color: customizations.inputFieldTextColor,
                        '&:hover,&:focus,&:active,&.Mui-focused': {
                            boxShadow: 'none',
                            borderColor: getPrimaryColors(
                                customizations.primaryColor,
                                customizations.primaryColorContrast,
                                '#FFFFFF',
                                true,
                            ).backgroundColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: getPrimaryColors(
                                customizations.primaryColorContrast,
                                customizations.primaryColor,
                                '#FFFFFF',
                                true,
                            ).backgroundColor,
                        },
                    },
                    multiline: {
                        padding: `10.5px 14px`,
                    },
                    input: {
                        padding: '10px !important',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxSizing: 'border-box',
                        padding: customizations.baseUnit * 2,
                        borderRadius: customizations.borderRadius,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontSize: customizations.navigationTabFontSize ?? customizations.fontSize,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popper: {
                        zIndex: `${zIndex.alwaysVisible} !important` as any,
                    },
                    input: {
                        padding: '4px !important',
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: {
                        zIndex: `${zIndex.alwaysVisible} !important` as any,
                        '& MuiToolbar-root': {
                            backgroundColor: customizations.bookingWidgetColor,
                        },
                    },
                    paper: {
                        padding: 0,
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: `${customizations.secondaryColor} `,
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        height: 4,
                        color: customizations.bookingWidgetColor,
                    },
                    mark: {
                        height: 0,
                    },
                    track: {
                        height: 4,
                    },
                    rail: {
                        height: 4,
                    },
                    thumb: {
                        background: defaultMuiTheme.palette.common.white,
                        backgroundColor: defaultMuiTheme.palette.common.white,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: customizations.bookingWidgetColor,
                        width: 20,
                        height: 20,
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        borderRadius: customizations.borderRadius > 0 ? '50%' : 0,
                        fontFamily: customizations.bodyFont,
                        fontSize: customizations.bodySize,
                        color: defaultMuiTheme.palette.common.black,
                        '&.Mui-selected': {
                            backgroundColor: customizations.bookingWidgetColor,
                            color: customizations.bookingWidgetPrimaryColor,
                        },
                        '&:focus': {
                            backgroundColor: lighten(customizations.bookingWidgetColor, 0.7),
                        },
                    },
                },
            },
            MuiDateRangePickerDay: {
                styleOverrides: {
                    root: {
                        '&.MuiDateRangePickerDay-rangeIntervalDayHighlight:first-of-type': {
                            borderTopLeftRadius: customizations.borderRadius > 0 ? '50%' : 0,
                            borderBottomLeftRadius: customizations.borderRadius > 0 ? '50%' : 0,
                        },
                        '&.MuiDateRangePickerDay-rangeIntervalDayHighlight:last-of-type': {
                            borderTopRightRadius: customizations.borderRadius > 0 ? '50%' : 0,
                            borderBottomRightRadius: customizations.borderRadius > 0 ? '50%' : 0,
                        },
                    },
                },
            },
        },
    });

    return createTheme(theme);
};
