import { Box, Button, capitalize, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { useState } from 'react';
import AccommodationOverviewDescription from 'src/components/domain/accommodation-overview/subcomponents/AccommodationOverviewDescription';
import AccommodationOverviewFacilitiesList from 'src/components/domain/accommodation-overview/subcomponents/AccommodationOverviewFacilitiesList';
import AccommodationOverviewFacilityIcons from 'src/components/domain/accommodation-overview/subcomponents/AccommodationOverviewFacilityIcons';
import { useLocale, formatCurrency } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { Product } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';
import AccommodationProductDetails from './AccommodationProductDetails';

type AccommodationProductPageProps = {
    accommodation: Product;
    dateRange: DateRange<TZDate>;
    guests?: number;
};

export default function AccommodationProduct({
    accommodation,
    dateRange,
    guests = 1,
}: AccommodationProductPageProps) {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const [currency] = useAtom(currencyAtom);

    const firstPrice = accommodation.ticketOptions[0]?.ticketTypes[0];
    const checkInTime = accommodation.accommodationAttributes?.descriptions.checkInTime;
    const checkOutTime = accommodation.accommodationAttributes?.descriptions.checkOutTime;
    const days = dateRange[0] && dateRange[1] ? dateRange[1].diff(dateRange[0], 'days') : 1;
    const priceText = capitalize(t.price_one_night_one_guest.parsed(days, guests));

    return (
        <Grid container direction="column" position="relative" width="100%">
            <Box pb={3}>
                <AccommodationOverviewFacilityIcons accommodation={accommodation} />
            </Box>

            <Grid container direction="row" justifyContent="space-between">
                <Grid item md={6} xs={12}>
                    <AccommodationOverviewDescription accommodation={accommodation} />
                    <AccommodationOverviewFacilitiesList accommodation={accommodation} />
                </Grid>
                <Grid item md={4}>
                    <Grid
                        container
                        direction="column"
                        alignItems="flex-end"
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: {
                                marginTop: theme.spacing(4),
                            },
                        })}
                    >
                        <Grid
                            container
                            display="flex"
                            direction="column"
                            alignItems="flex-start"
                            gap={3}
                        >
                            {firstPrice && (
                                <AccommodationProductDetails
                                    header={priceText}
                                    description={
                                        t.from +
                                        ' ' +
                                        formatCurrency(locale, currency, firstPrice.price)
                                    }
                                    sx={{
                                        marginBottom: theme.spacing(4),
                                        minWidth: '160px',
                                    }}
                                />
                            )}
                            {(checkInTime || checkOutTime) && (
                                <AccommodationProductDetails
                                    header={t.check_in_check_out}
                                    description={[
                                        `${t.checkIn} ${t.from.toLowerCase()}: ${checkInTime}`,
                                        `${t.checkOut}: ${checkOutTime}`,
                                    ]}
                                    sx={{
                                        marginBottom: theme.spacing(4),
                                        minWidth: '160px',
                                    }}
                                />
                            )}
                            {t.accommodation_cancellation_policy && (
                                <Grid item>
                                    <Button
                                        variant="text"
                                        sx={{ color: customizations.linkColor, padding: 0 }}
                                        onClick={() =>
                                            setShowCancellationPolicy(!showCancellationPolicy)
                                        }
                                    >
                                        {capitalize(
                                            showCancellationPolicy
                                                ? t.hide_cancellation_policy
                                                : t.show_cancellation_policy,
                                        )}
                                    </Button>
                                    <Collapse in={showCancellationPolicy}>
                                        <Typography>
                                            {t.accommodation_cancellation_policy}
                                        </Typography>
                                    </Collapse>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
