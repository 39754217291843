import { Grid, Typography, useTheme } from '@mui/material';
import { useLocale, getLocaleNumberFormatTwoDecimals } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { PriceSummary } from '@repo/widget-utils/price-helper';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';

export default function SummaryVat(props: {
    priceSummary: PriceSummary;
    invertedColorsClass?: Record<string, unknown>;
}): JSX.Element | null {
    const { t, locale } = useLocale();
    const [configuration] = useAtom(configurationAtom);
    const theme = useTheme();

    const vatAmountInfoKeysSorted = Object.keys(props.priceSummary.vatAmountInfo).sort(
        (keyA, keyB) => {
            return -(parseFloat(keyA) - parseFloat(keyB));
        },
    );

    if (configuration.hideVat) return null;

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            borderTop={`1px solid ${theme.palette.grey[400]}`}
            borderBottom={`1px solid ${theme.palette.grey[400]}`}
            py={theme.spacing(1)}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="3"
                flexShrink="1"
            >
                <Typography align="right" color="textSecondary" sx={props.invertedColorsClass}>
                    {capitalize(t.excl)}&nbsp;{t.vat}
                </Typography>

                {vatAmountInfoKeysSorted.map((key) => {
                    return props.priceSummary.vatAmountInfo[key] > 0 ? (
                        <Typography
                            key={key}
                            align="right"
                            color="textSecondary"
                            sx={props.invertedColorsClass}
                        >
                            {t.vat}&nbsp;{`${key}%`}
                        </Typography>
                    ) : null;
                })}
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="1"
                flexShrink="1"
            >
                <Typography align="right" color="textSecondary" sx={props.invertedColorsClass}>
                    {getLocaleNumberFormatTwoDecimals(locale, props.priceSummary.totalPriceExVat)}
                </Typography>

                {vatAmountInfoKeysSorted.map((key) => {
                    return props.priceSummary.vatAmountInfo[key] > 0 ? (
                        <Typography
                            key={key}
                            align="right"
                            color="textSecondary"
                            sx={props.invertedColorsClass}
                        >
                            {getLocaleNumberFormatTwoDecimals(
                                locale,
                                props.priceSummary.vatAmountInfo[key],
                            )}
                        </Typography>
                    ) : null;
                })}
            </Grid>
        </Grid>
    );
}
