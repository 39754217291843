import { createRoot } from 'react-dom/client';
import { getStringAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductDetailsWidgetRoot from './ProductDetailsWidgetRoot';

class BilberryProductDetails extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getStringAttribute(this, 'product-catalog-id', false) ?? '';

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductDetailsWidgetRoot productCatalogId={productCatalogId} />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-details', BilberryProductDetails);
}
