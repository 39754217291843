import { Box, Button, Grid, useTheme } from '@mui/material';

import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from 'src/widgets/activities/checkout/cart/views/ButtonDisplay';
import Summary from '../summary/Summary';
import PaymentOptionsPanel from '../timeslots-payment-options-panel/PaymentOptionsPanel';
import { useCartContext } from 'src/widgets/CartContext';

type IntentViewProps = {
    largeScreen: boolean;
    isMakingReservation: boolean;
    handleGoPayClicked: () => void;
};

export default function IntentView(props: IntentViewProps) {
    const { handleGoPayClicked, isMakingReservation, largeScreen } = props;
    const theme = useTheme();
    const customizations = useCustomizations();

    const { isLoadingPaymentPlan: isLoadingIntents } = useCartContext();

    return (
        <>
            <Grid
                container
                justifyContent={largeScreen ? 'space-between' : 'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                {!largeScreen && (
                    <Box
                        maxWidth="440px"
                        width="100%"
                        sx={{
                            [theme.breakpoints.down('md')]: {
                                marginBottom: theme.spacing(3),
                            },
                        }}
                    >
                        <Button
                            sx={{ float: 'right', marginTop: theme.spacing(2) }}
                            color="primary"
                            variant={customizations.primaryButtonStyle}
                            disabled={isMakingReservation}
                            onClick={handleGoPayClicked}
                        >
                            <ButtonDisplay
                                isMakingReservation={isMakingReservation || !!isLoadingIntents}
                            />
                        </Button>
                    </Box>
                )}

                <Box pt={2}>
                    <PaymentOptionsPanel />
                </Box>

                <Summary
                    showShare
                    hideGoPay={!largeScreen}
                    isCheckoutSummary={true}
                    isMakingReservation={isMakingReservation}
                    goPayClicked={handleGoPayClicked}
                />
            </Grid>
        </>
    );
}
