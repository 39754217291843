import { Skeleton } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { useProduct } from '@repo/widget-utils/api/api';
import { getIdAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductGallery2, {
    ProductGalleryAlign,
} from '../product/product-gallery-2/gallery/ProductGallery2';

const initialDateRange: DateRange<TZDate> = [TZDate.now(), TZDate.now().add(1, 'days')];

export function AccommodationGalleryWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    id: number;
    numVisibleImages: any;
    align: any;
}): JSX.Element {
    const { data: accommodation, isLoading } = useProduct(
        props.id.toString(),
        'accommodation',
        initialDateRange,
        1,
    );

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            {isLoading || !accommodation?.accommodationAttributes ? (
                <Skeleton width="100%" height="auto" sx={{ aspectRatio: '16/9' }} />
            ) : (
                <ProductGallery2
                    imageList={accommodation?.images ?? null}
                    numVisibleImages={props.numVisibleImages}
                    align={props.align}
                />
            )}
        </WidgetWrapper>
    );
}

function getAlignType(align: string) {
    switch (align.toLowerCase()) {
        case 'left':
            return ProductGalleryAlign.LEFT;
        case 'center':
            return ProductGalleryAlign.CENTER;
        case 'right':
            return ProductGalleryAlign.RIGHT;
        default:
            return ProductGalleryAlign.SCALE;
    }
}
class BilberryAccommodationGallery extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const accommodationCatalogId = getIdAttribute(this, false);

        const numVisibleImages = getMediaQuerySizesFromAttributes(this, 1, 2, 3, 3);
        const alignText = this.getAttribute('align');
        const align = alignText ? getAlignType(alignText) : ProductGalleryAlign.SCALE;

        createRoot(this._mountPoint).render(
            <AccommodationGalleryWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                id={accommodationCatalogId}
                numVisibleImages={numVisibleImages}
                align={align}
            ></AccommodationGalleryWidget>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-accommodation-gallery', BilberryAccommodationGallery);
}
