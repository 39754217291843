import {
    CartItem,
    ProductInstance,
    TicketOption,
    TicketOptionWithQuantity,
    TicketType,
    AppliedGiftCard,
    BilberryGiftcardStatus,
    BilberryPromoCodeStatus,
    AppliedPromoCode,
} from '@repo/types';
import { sumBy } from 'lodash-es';
import { getLocaleNumberFormatNoDecimals, localeAtom } from '@repo/i18n';
import {
    isPromoCodeApplicableForProductPurchase,
    promocodeAsDiscountSource,
} from './discounts/promocodes';

export type PriceSummary = {
    creditsUsed?: number;
    numTicketsIncludedInMembership?: number;
    totalPrice: number;
    totalPriceExDiscounts: number;
    totalPriceExVat: number;
    totalVat: number;
    vatAmountInfo: { [vat: string]: number };
    promoCodeDiscounts: {
        amount: number;
        percentage: number;
        numDiscountedQuantities: number;
        code: string;
    }[];
};

export function getPriceSummaryFromCartItems(
    cartItems: CartItem[],
    promoCodeStatus?: BilberryPromoCodeStatus | null,
): PriceSummary {
    const promoCodeUsageContext = {
        promoCodeUsagesPerOrder: new Map<string, number>(),
        promoCodeUsages: new Map<string, number>(),
    };

    return cartItems.reduce(
        (acc, item) => {
            const ticketOptions = item.ticketOptions.filter((to) => to.quantity > 0);

            const priceSummaryForTicketOptions = getPriceSummaryFromTicketOptions(
                ticketOptions,
                item.ticketType,
                promoCodeStatus,
                promoCodeUsageContext,
            );

            acc.totalPrice += priceSummaryForTicketOptions.totalPrice;
            acc.totalPriceExDiscounts += priceSummaryForTicketOptions.totalPriceExDiscounts;
            acc.totalPriceExVat += priceSummaryForTicketOptions.totalPriceExVat;
            acc.totalVat += priceSummaryForTicketOptions.totalVat;
            acc.vatAmountInfo = Object.entries(priceSummaryForTicketOptions.vatAmountInfo).reduce(
                (acc, [rate, vat]) => ({
                    ...acc,
                    [rate]: (acc[rate] ?? 0) + vat,
                }),
                acc.vatAmountInfo,
            );
            acc.creditsUsed! += priceSummaryForTicketOptions.creditsUsed ?? 0;
            acc.numTicketsIncludedInMembership! +=
                priceSummaryForTicketOptions.numTicketsIncludedInMembership ?? 0;
            acc.promoCodeDiscounts = acc.promoCodeDiscounts.concat(
                priceSummaryForTicketOptions.promoCodeDiscounts,
            );

            return acc;
        },
        {
            totalPrice: 0,
            totalPriceExDiscounts: 0,
            totalPriceExVat: 0,
            totalVat: 0,
            vatAmountInfo: {},
            numTicketsIncludedInMembership: 0,
            creditsUsed: 0,
            promoCodeDiscounts: [],
        } as PriceSummary,
    );
}

export function getGiftcardSpent(giftcard: BilberryGiftcardStatus, totalPrice: number): number {
    let priceReduction;
    if (totalPrice < giftcard.balance) {
        priceReduction = totalPrice;
    } else {
        priceReduction = giftcard.balance;
    }

    return priceReduction;
}

export function getInitialQuantityData(
    prices: TicketOption[],
    minEntrants: number | undefined | null,
): TicketOptionWithQuantity[] {
    if (prices.length === 1) {
        return prices.map((price) => ({
            ...price,
            quantity: minEntrants ?? 1,
        }));
    }

    const initialAdults = minEntrants ? Math.ceil(minEntrants / 2) : 1;
    const initialChildren = minEntrants ? Math.floor(minEntrants / 2) : 0;

    return prices.map((price, i) => ({
        ...price,
        quantity: i === 0 ? initialAdults : i === 1 ? initialChildren : 0,
    }));
}

export function updateQuantityData(
    selectedProducts: readonly ProductInstance[] | undefined,
    defaultPrices: readonly TicketOption[],
    existingQuantities: readonly TicketOptionWithQuantity[],
): TicketOptionWithQuantity[] {
    const selectedProductPrices = selectedProducts?.[0]?.ticketOptions ?? [];
    const allPricesPreferSelected = defaultPrices.map(
        (price) =>
            selectedProductPrices?.find(
                (selectedPrice) => selectedPrice.ticketCategoryId === price.ticketCategoryId,
            ) ?? {
                ...price,
                // shouldDisablePrice: !!selectedProductPrices // TODO: Find a good way to disable prices
            },
    );

    return allPricesPreferSelected
        .map((price) => {
            const existingQuantity = existingQuantities.find(
                (quantity) => quantity.ticketCategoryId === price.ticketCategoryId,
            );

            return {
                ...price,
                quantity: existingQuantity?.quantity ?? 0,
            };
        })
        .filter((price) =>
            selectedProductPrices && selectedProductPrices.length > 0
                ? selectedProductPrices.some(
                      (spp) => spp.ticketCategoryId === price.ticketCategoryId,
                  )
                : true,
        );
}

/**
 * Get the price summary from ticket options.
 * If the {ticketType} arg is present, use price from that without multiplying by quantity.
 */
export function getPriceSummaryFromTicketOptions(
    ticketOptions: TicketOptionWithQuantity[],
    ticketType?: TicketType,
    promoCodeStatus?: BilberryPromoCodeStatus | null,
    promoCodeUsageContext?: {
        promoCodeUsagesPerOrder: Map<string, number>;
        promoCodeUsages: Map<string, number>;
    },
): PriceSummary {
    if (ticketType) {
        return {
            totalPrice: ticketType.price,
            totalPriceExDiscounts: ticketType.price,
            totalPriceExVat: ticketType.price - ticketType.vatAmount,
            totalVat: ticketType.vatAmount,
            vatAmountInfo: ticketType.vatBreakdown.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.rate]: (acc[cur.rate] ?? 0) + cur.vatAmount,
                }),
                {} as PriceSummary['vatAmountInfo'],
            ),
            promoCodeDiscounts: [],
        };
    }

    const { locale } = localeAtom.subject.value;
    return ticketOptions.reduce(
        (acc, ticketOption) => {
            if (ticketOption.membership && ticketOption.membership.length > 0) {
                acc.totalPrice +=
                    sumBy(ticketOption.membership ?? [], (m) => m.currencyCost?.price ?? 0) / 100;
                acc.totalPriceExVat +=
                    sumBy(ticketOption.membership ?? [], (m) => m.currencyCost?.netPrice ?? 0) /
                    100;
                acc.totalVat +=
                    sumBy(ticketOption.membership ?? [], (m) => m.currencyCost?.vatAmount ?? 0) /
                    100;
                acc.vatAmountInfo =
                    (
                        ticketOption.membership?.flatMap((m) => m.currencyCost?.rates ?? []) ?? []
                    ).reduce((vbAcc, vbCur) => {
                        vbAcc[getLocaleNumberFormatNoDecimals(locale, vbCur.taxRate / 100)] =
                            (vbAcc[getLocaleNumberFormatNoDecimals(locale, vbCur.taxRate / 100)] ??
                                0) +
                            vbCur.taxAmount / 100;
                        return vbAcc;
                    }, acc.vatAmountInfo) ?? acc.vatAmountInfo;
                const creditCost =
                    sumBy(
                        ticketOption.membership?.flatMap((m) => m.valueCardUsages ?? []) ?? [],
                        'creditCost',
                    ) / 100;

                const promoData = ticketOption.membership.reduce(
                    (acc: NonNullable<PriceSummary['promoCodeDiscounts']>[0] | null, m) => {
                        if (m.promoData) {
                            const newValue = {
                                amount: (m.promoData?.amount ?? 0) / 100,
                                percentage:
                                    m.promoData?.promoType === 'percent'
                                        ? m.promoData.promoValue
                                        : 0,
                                code: m.promoData?.name,
                                numDiscountedQuantities: 1,
                            };
                            if (!acc) return newValue;

                            acc.amount += newValue.amount;
                            acc.percentage += newValue.percentage;
                            acc.numDiscountedQuantities += newValue.numDiscountedQuantities;
                            return acc;
                        }
                        return acc;
                    },
                    null,
                );
                acc.promoCodeDiscounts = acc.promoCodeDiscounts.concat(promoData ?? []);
                acc.totalPriceExDiscounts =
                    acc.totalPrice + acc.promoCodeDiscounts.reduce((acc, d) => acc + d.amount, 0);

                acc.creditsUsed! += creditCost;

                acc.numTicketsIncludedInMembership! += ticketOption.membership.reduce(
                    (acc, cur) => {
                        let val = acc;

                        if (cur.currencyCost?.price === 0 && !cur.promoData) {
                            val += cur.valueCardUsages.reduce(
                                (acc, cur) => acc + (cur.creditCost === 0 ? 1 : 0),
                                0,
                            );
                        }

                        return val;
                    },
                    0,
                );

                return acc;
            }

            const vatBreakdown = getVatBreakdownFromTicketOption(
                ticketOption,
                ticketType,
                promoCodeStatus,
                promoCodeUsageContext,
            );
            const { price, discount } = getPriceFromTicketOptionNonMembership(
                ticketOption,
                ticketType,
                promoCodeStatus,
                promoCodeUsageContext,
            );

            acc.totalPrice += price - (discount?.amount ?? 0);
            acc.totalPriceExDiscounts += price;
            acc.totalPriceExVat += price - vatBreakdown.vatAmount;
            acc.totalVat += vatBreakdown.vatAmount;
            acc.vatAmountInfo = Object.entries(vatBreakdown.vatBreakdown).reduce(
                (vbAcc, [rate, value]) => {
                    return {
                        ...vbAcc,
                        [rate]: (vbAcc[rate] ?? 0) + value,
                    };
                },
                acc.vatAmountInfo,
            );
            acc.promoCodeDiscounts = acc.promoCodeDiscounts.concat(discount ? [discount] : []);

            return acc;
        },
        {
            totalPrice: 0,
            totalPriceExDiscounts: 0,
            totalPriceExVat: 0,
            totalVat: 0,
            vatAmountInfo: {},
            creditsUsed: 0,
            numTicketsIncludedInMembership: 0,
            promoCodeDiscounts: [],
        } as PriceSummary,
    );
}

export function getPriceFromTicketOption(
    ticketOption: TicketOptionWithQuantity,
    ticketType?: TicketType,
    promoCodeStatus?: BilberryPromoCodeStatus | null,
    promoCodeUsageContext?: {
        promoCodeUsagesPerOrder: Map<string, number>;
        promoCodeUsages: Map<string, number>;
    },
) {
    return getPriceSummaryFromTicketOptions(
        [ticketOption],
        ticketType,
        promoCodeStatus,
        promoCodeUsageContext,
    );
}

export function getPriceFromTicketOptionNonMembership(
    ticketOption: TicketOptionWithQuantity,
    ticketType?: TicketType,
    promoCodeStatus?: BilberryPromoCodeStatus | null,
    promoCodeUsageContext?: {
        promoCodeUsagesPerOrder: Map<string, number>;
        promoCodeUsages: Map<string, number>;
    },
) {
    const productType = ticketOption.productInstances[0]?.product?.type ?? '';
    const isAccommodation = productType === 'accommodation';

    const price = ticketOption.price * ticketOption.quantity;

    if (isAccommodation && ticketType) {
        return { price: ticketType.price, discount: null };
    }

    let discount: {
        amount: number;
        percentage: number;
        numDiscountedQuantities: number;
        code: string;
    } | null = null;

    if (promoCodeStatus && !ticketOption.membership) {
        discount = getPromoCodeDiscount(
            promoCodeStatus,
            promoCodeUsageContext,
            ticketOption.productInstances[0]?.product?.id.toString() ?? '',
            ticketOption.quantity,
            ticketOption.price,
        );
    }
    return { price, discount };
}

export function getPromoCodeDiscount(
    promoCodeStatus: BilberryPromoCodeStatus,
    promoCodeUsageContext:
        | {
              promoCodeUsagesPerOrder: Map<string, number>;
              promoCodeUsages: Map<string, number>;
          }
        | undefined,
    productId: string,
    promoCodeUsages: number,
    priceOneUsage: number,
) {
    let discount: {
        amount: number;
        percentage: number;
        numDiscountedQuantities: number;
        code: string;
    } | null = null;

    const isPercent = promoCodeStatus.coupon_type === 'percent';
    const productMaxQuantityEntry = promoCodeStatus.product_max_quantity.find(
        (x) => x.product_id.toString() === productId,
    );
    if (productMaxQuantityEntry || promoCodeStatus.product_max_quantity.length === 0) {
        const usages = promoCodeUsageContext?.promoCodeUsages.get(productId ?? '');

        const entryRemainingQuantity =
            productMaxQuantityEntry?.remaining_quantity ?? Number.MAX_SAFE_INTEGER;

        const entryMaxQuantity =
            productMaxQuantityEntry?.max_quantity && productMaxQuantityEntry.max_quantity > 0
                ? productMaxQuantityEntry.max_quantity
                : Number.MAX_SAFE_INTEGER;

        const remainingQuantity =
            Math.min(entryRemainingQuantity, entryMaxQuantity) - (usages ?? 0);

        if (remainingQuantity > 0) {
            const quantitiesToDiscount = Math.min(promoCodeUsages, remainingQuantity);
            discount = {
                amount: isPercent
                    ? (promoCodeStatus.coupon_value / 100) * (priceOneUsage * quantitiesToDiscount)
                    : promoCodeStatus.coupon_value,
                percentage: isPercent ? promoCodeStatus.coupon_value : 0,
                code: promoCodeStatus.coupon_code,
                numDiscountedQuantities: quantitiesToDiscount,
            };
            promoCodeUsageContext?.promoCodeUsages.set(
                productId ?? '',
                (usages ?? 0) + promoCodeUsages,
            );
        }
    }
    return discount;
}

export function getVatBreakdownFromTicketOption(
    ticketOption: TicketOptionWithQuantity,
    ticketType?: TicketType,
    promoCodeStatus?: BilberryPromoCodeStatus | null,
    promoCodeUsageContext?: {
        promoCodeUsagesPerOrder: Map<string, number>;
        promoCodeUsages: Map<string, number>;
    },
) {
    const productType = ticketOption.productInstances[0]?.product?.type ?? '';
    const isAccommodation = productType === 'accommodation';

    if (isAccommodation && ticketType) {
        return {
            vatBreakdown: ticketType.vatBreakdown.reduce(
                (acc, cur) => {
                    return {
                        ...acc,
                        [cur.rate]: (acc[cur.rate] ?? 0) + cur.vatAmount,
                    };
                },
                {} as Record<string, number>,
            ),
            vatAmount: ticketType.vatAmount,
            discountedVatAmount: 0,
            discountedVatBreakdown: {},
        };
    }

    // TODO: Add promo code discounts here to make VAT correct
    return {
        vatAmount: ticketOption.vatAmount * ticketOption.quantity,
        vatBreakdown: ticketOption.vatBreakdown.reduce(
            (vbAcc, vbCur) => {
                return {
                    ...vbAcc,
                    [vbCur.rate]:
                        (vbAcc[vbCur.rate] ?? 0) + vbCur.vatAmount * ticketOption.quantity,
                };
            },
            {} as Record<string, number>,
        ),
    };
}

export function calculateAppliedGiftcards(giftcards: BilberryGiftcardStatus[], totalPrice: number) {
    if (!giftcards) return [];
    const appliedGiftcards: AppliedGiftCard[] = [];

    for (const giftcard of giftcards) {
        const priceReducedByAppliedGiftcards = sumBy(appliedGiftcards, (x) => x.priceReduction);
        const currentTotalPrice = totalPrice - priceReducedByAppliedGiftcards;

        const priceReduction = getGiftcardSpent(giftcard, currentTotalPrice);
        const appliedGiftcard: AppliedGiftCard = {
            giftcardStatus: giftcard,
            priceReduction,
        };

        appliedGiftcards.push(appliedGiftcard);
    }

    return appliedGiftcards;
}

export function getAppliedPromoCodeIfApplicableForValueCardPurchase(
    promoCode: BilberryPromoCodeStatus | null,
    productId: number,
): AppliedPromoCode | null {
    if (!promoCode) return null;

    const isApplicableForCurrentProductPurchase = isPromoCodeApplicableForProductPurchase(
        promoCode,
        productId,
        true,
    );
    if (!isApplicableForCurrentProductPurchase) return null;

    return {
        promoCode,
        discount: promocodeAsDiscountSource(promoCode),
    };
}
