import BasketIconButton, {
    BasketIconButtonVariant,
} from 'src/widgets/activities/basket-icon/BasketIconButton';
import { WarningMessage } from './BookingCardWarningMessage';
import { useLocale } from '@repo/i18n';

export function CartCompatibilityWarning(props: { color?: string }) {
    const { t } = useLocale();
    return (
        <>
            <WarningMessage color={props.color} label={t.cartCompatibilityWarning} />
            <BasketIconButton
                size="s"
                xsSize="s"
                smallOnMobile={false}
                variant={BasketIconButtonVariant.DEFAULT}
            />
        </>
    );
}
