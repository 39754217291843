import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { ProductInstance } from '@repo/types';

export function groupToursByProduct(tours: ProductInstance[]) {
    let defaultId = 0;
    const toursByProduct = groupBy(tours, (t) => t.product?.id ?? `no-product-id-${defaultId++}`);
    const toursGrouped = mapValues(toursByProduct, (tours) => {
        const groupedByDate = Object.values(
            groupBy(tours, (tour) => tour.start.format('YYYY-MM-DD')),
        );
        return groupedByDate.map((group) => group.sort((a, b) => a.start.diff(b.start)));
    });

    const sorted = Object.values(toursGrouped)
        .flat()
        .sort((a, b) => a[0].start.diff(b[0].start));

    return sorted;
}
