import { Box, useTheme } from '@mui/material';
import { RefObject } from 'react';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';

type PanelProps = {
    title?: string;
    children: React.ReactNode[] | React.ReactNode;
    variant?: 'standard' | 'shaded';
    sx?: any;
    alignment?: 'center';
    boxRef?: RefObject<HTMLDivElement>;
};

export default function Panel({
    title,
    children,
    variant = 'standard',
    sx = {},
    alignment,
    boxRef,
}: PanelProps) {
    const theme = useTheme();
    return (
        <Box
            ref={boxRef}
            sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                border: '1px solid',
                borderColor: theme.palette.grey['500'],
                backgroundColor: variant === 'shaded' ? theme.palette.grey['50'] : undefined,
                borderRadius: theme.spacing(0.5),
                display: 'flex',
                flexDirection: 'column',
                alignItems: alignment === 'center' && 'center',
                ...sx,
            }}
        >
            {title && <PanelTitle>{title}</PanelTitle>}
            {children}
        </Box>
    );
}
