import { Box, Button, darken, Popover, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { createRef, useContext, useState } from 'react';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from '@repo/i18n';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { zIndex } from 'src/utils/widget/zIndex';
import StaticDateRangePicker from '../StaticDateRangePicker';

type IProps = {
    dateRangeVariant?: 'days' | 'nights' | undefined;
    anchorEl: HTMLInputElement | HTMLDivElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | HTMLInputElement | null>>;
    minDate: TZDate | undefined;
    onSelectDateRange: (dateRange: DateRange<TZDate>) => void;
    dateRange: DateRange<TZDate>;
};

export default function DateRangePickerPopover(props: IProps) {
    const { anchorEl, setAnchorEl, minDate, onSelectDateRange, dateRange, dateRangeVariant } =
        props;
    const mountPoint = useContext(MountPointContext);
    const popoverRef = createRef<HTMLDivElement>();
    const theme = useTheme();
    const customizations = useCustomizations();
    const { t } = useLocale();
    const trap = useFocusTrap(popoverRef, !!anchorEl, '#bilberry-date-range-picker-input');
    const [displayDateRange, setDisplayDateRange] = useState(dateRange);
    const onClose = () => {
        if (trap) trap.deactivate();
        setAnchorEl(null);
    };

    const disableOkButton = dateRangeVariant === 'nights' && displayDateRange?.includes(null);

    const onOkClicked = () => {
        onSelectDateRange(displayDateRange);
        onClose();
    };
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
                zIndex: `${zIndex.alwaysVisible} !important` as any,

                '& > .MuiPaper-root': {
                    maxWidth: 325,
                },
            }}
            container={mountPoint.popover}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={onClose}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            <StaticDateRangePicker
                dateRange={displayDateRange}
                onChange={setDisplayDateRange}
                maxDate={TZDate.now().add(5, 'years')}
                minDate={minDate}
            />
            <Box
                p={theme.spacing(0, 0, 2, 0)}
                display="flex"
                justifyContent="center"
                sx={{
                    '& button:hover': {
                        backgroundColor: (_props) =>
                            customizations.primaryButtonStyle === 'contained'
                                ? darken(theme.palette.primary.main, 0.2)
                                : 'rgba(0, 0, 0, 30%)',
                    },
                }}
            >
                <Button
                    variant={customizations.primaryButtonStyle}
                    color="primary"
                    onClick={onOkClicked}
                    disabled={disableOkButton}
                    role={capitalize(t.select_date)}
                >
                    {t.ok}
                </Button>
            </Box>
        </Popover>
    );
}
