import { Box, Stack, SvgIcon, SxProps, Typography, useTheme, Theme } from '@mui/material';

import { useLayoutEffect, useRef } from 'react';
import { useCustomizations } from 'src/components/utils/theme/customizations';

export default function ProductCardIcon(props: {
    label?: string;
    Icon: typeof SvgIcon | string;
    sx: SxProps;
    justify?: 'center' | 'flex-start';
}) {
    const { label, Icon, sx, justify = 'center' } = props;
    const labelRef = useRef<HTMLParagraphElement>(null);
    const theme = useTheme();
    const customizations = useCustomizations();
    const isSvgUrl = typeof Icon === 'string' && Icon.endsWith('.svg');

    useLayoutEffect(() => {
        if (labelRef.current) {
            const textWidth = getTextWidth(theme, label);
            const labelWidth = Math.min(
                Math.max(labelRef.current.getBoundingClientRect().width, textWidth),
                90,
            );
            labelRef.current.style.width = `${labelWidth}px`;
            const siblingElement = labelRef.current.previousElementSibling as HTMLElement | null;
            const parentElement = labelRef.current.parentElement as HTMLElement | null;
            if (siblingElement && parentElement) {
                const parentWidth = Math.max(
                    siblingElement.getBoundingClientRect().width,
                    labelWidth,
                );
                parentElement.style.width = `${parentWidth}px`;
                labelRef.current.style.whiteSpace = 'normal';
            }
        }
    }, [label, theme]);

    return (
        <Stack direction="row" justifyContent={justify}>
            <Stack alignItems="center" justifyContent="center" sx={sx} gap={1}>
                {typeof Icon !== 'string' ? (
                    <Icon />
                ) : isSvgUrl ? (
                    <Stack justifyContent="center" alignItems="center">
                        <Box
                            aria-hidden
                            height="1.5em"
                            width="1.5em"
                            sx={{
                                maskImage: `url(${Icon})`,
                                maskSize: 'contain',
                                maskRepeat: 'no-repeat',
                                maskPosition: 'center',
                                background:
                                    customizations.productCardIconColor ??
                                    theme.palette.primary.main,
                            }}
                        />
                    </Stack>
                ) : (
                    <Box component="img" src={Icon} aria-hidden height="1.5em" width="auto" />
                )}
                {label && (
                    <Typography
                        ref={labelRef}
                        sx={{ wordBreak: 'nowrap', whiteSpace: 'nowrap', textAlign: 'center' }}
                    >
                        {label}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}
function getTextWidth(theme: Theme, label: string | undefined) {
    const span = document.createElement('span');
    span.style.whiteSpace = 'nowrap';
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.style.top = '-9999px';
    span.style.left = '-9999px';
    span.style.fontSize = '16px';
    span.style.fontFamily = (theme.typography.fontFamily as string) ?? 'sans-serif';
    span.textContent = label ?? '';
    document.body.appendChild(span);
    const textWidth = span.getBoundingClientRect().width;
    document.body.removeChild(span);
    return textWidth;
}
