import { Grid, Link, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { UserTextCustomizations } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';

export function ProductCardContainer(props: {
    disableMaxWidth?: boolean;
    maxWidth?:
        | number
        | {
              xs?: number | string;
              sm?: number | string;
              md?: number | string;
              lg?: number | string;
              xl?: number | string;
          };
    children: React.ReactNode[] | React.ReactNode;
}) {
    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            borderRadius={`${customizations.borderRadius}px`}
            overflow="hidden"
            position="relative"
            boxShadow={customizations.imageShadow ? theme.shadows[3] : undefined}
            height="100%"
            maxWidth={!props.disableMaxWidth ? props.maxWidth ?? '436px' : undefined}
            minWidth={260}
            wrap="nowrap"
        >
            {props.children}
        </Grid>
    );
}

export function ProductCardHeader(props: {
    url?: string;
    imageUrl?: string;
    isPrivateTour?: boolean;
    category?: string | null;
    campaign?: string | null;
}) {
    const { url, imageUrl, category, campaign, isPrivateTour = false } = props;

    const { t } = useLocale();
    const theme = useTheme();

    return (
        <>
            <Link href={url} display="block" position="relative">
                <ProductImage
                    url={imageUrl}
                    size="s"
                    disableShadows={true}
                    format="16_9"
                    stretchToFit={true}
                />
                {isPrivateTour && (
                    <Grid
                        padding="0 64px"
                        justifyContent="center"
                        position="absolute"
                        top={0}
                        right={0}
                        sx={{
                            transform: 'rotateZ(45deg) translateX(64px)',
                            background: theme.palette.grey[800],
                            '& > .MuiTypography-root': {
                                color: theme.palette.common.white,
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <Typography>{t.private_tour}</Typography>
                    </Grid>
                )}
            </Link>
            {category && <CardLabel variant="left">{category}</CardLabel>}
            {campaign && <CardLabel variant="right">{campaign}</CardLabel>}
        </>
    );
}

function CardLabel({
    children,
    variant,
}: {
    children: React.ReactNode[] | React.ReactNode;
    variant: 'left' | 'right';
}) {
    const theme = useTheme();
    const customizations = useCustomizations();
    return (
        <Grid
            position="absolute"
            top={theme.spacing(2)}
            left={variant === 'left' ? 0 : undefined}
            right={variant === 'right' ? 0 : undefined}
            padding={theme.spacing(1, 2, 1, 2)}
            sx={{
                backgroundColor: customizations.productCardPrimaryColor,
                '& > .MuiTypography-root': {
                    color: customizations.productCardPrimaryColorContrast,
                    fontWeight: customizations.boldFontWeight,
                },
            }}
        >
            <Typography
                fontSize={theme.typography.fontSize * 0.85}
                lineHeight={1}
                textTransform="uppercase"
            >
                {children}
            </Typography>
        </Grid>
    );
}

export type ProductCard2Props = {
    category?: string | null;
    campaign?: string | null;
    image?: { url?: string; alt?: string };
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    disableMaxWidth?: boolean;
    body?: JSX.Element;
};

export function ProductCard2(props: ProductCard2Props): JSX.Element {
    const { category, campaign, image, url, disableMaxWidth = false, body } = props;

    const isPrivateTour = false; // TODO: not yet implemented in Bilberry

    return (
        <ProductCardContainer disableMaxWidth={disableMaxWidth}>
            <ProductCardHeader
                isPrivateTour={isPrivateTour}
                url={url}
                category={category}
                campaign={campaign}
                imageUrl={image?.url}
            />
            {body}
        </ProductCardContainer>
    );
}
