import { Box, Button, Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import { route } from 'src/components/common/router/Router';
import { useLocale, getLocaleNumberFormatNoDecimals } from '@repo/i18n';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { MembershipSite, MembershipUser, ProductInstance, TicketType } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';
import { GuestInfo } from '../MultipleRoomSearch/guestInfo';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

type IProps = {
    selectedAccommodations: ProductInstance[][]; // Array of selected rooms => selected days for each room (which will always be identical dates)
    selectedTicketTypes: TicketType[];
    guestInfoList: GuestInfo[];
    clearRoomSelections: () => void;
};

export default function HotelTotalPrice(props: IProps): JSX.Element {
    const { selectedAccommodations, selectedTicketTypes, guestInfoList, clearRoomSelections } =
        props;
    const { t, locale } = useLocale();
    const theme = useTheme();
    const isRoomSelected = selectedAccommodations.length > 0;
    const [currency] = useAtom(currencyAtom);
    const { currentSite, loggedInUser } = useMemberContext();

    const isMediumPlusScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="50%"
            border="1px solid rgb(206,206,206)"
            borderRadius={`${theme.shape.borderRadius}px`}
            padding={theme.spacing(3, 2)}
            gap={theme.spacing(1)}
            sx={{
                backgroundColor: theme.palette.grey[50],
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            }}
        >
            <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="flex-end"
                width="fit-content"
                color="black"
            >
                <Typography variant="h3">
                    <Box component="span" fontWeight="bold" fontSize="32px">
                        {t.total_price}&ensp;
                    </Box>
                </Typography>
                {!isRoomSelected && isMediumPlusScreen && (
                    <Typography variant="body1" fontWeight="normal" fontSize="18px">
                        <Box component="span">{capitalize(t.select_room_for_total_price)}</Box>
                    </Typography>
                )}
            </Grid>
            <Grid
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                }}
            >
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    }}
                >
                    {isRoomSelected && (
                        <Grid container item alignItems="baseline" pr={2} width="auto">
                            <Typography variant="h3">
                                <Box component="span" fontSize="34px">
                                    {getLocaleNumberFormatNoDecimals(
                                        locale,
                                        getTotalPrice(selectedTicketTypes),
                                    )}
                                </Box>
                            </Typography>
                            <Typography variant="body1" fontSize="18px" fontWeight="normal">
                                <Box component="span" fontWeight="bold">
                                    &nbsp;{currency.currency}
                                </Box>
                            </Typography>
                        </Grid>
                    )}
                    {!isRoomSelected && !isMediumPlusScreen && (
                        <Typography variant="body1" fontSize="18px" fontWeight="normal">
                            <Box component="span">{capitalize(t.select_room_for_total_price)}</Box>
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={(theme) => ({
                            padding: theme.spacing(1.5, 4),
                        })}
                        disabled={!isRoomSelected}
                        onClick={() =>
                            handleBookNow(
                                selectedAccommodations,
                                selectedTicketTypes,
                                clearRoomSelections,
                                guestInfoList,
                                currentSite,
                                loggedInUser,
                            )
                        }
                    >
                        {t.book_now.toUpperCase()}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

function getTotalPrice(selectedTicketTypes: TicketType[]): number {
    const prices = selectedTicketTypes.map((ticketType) => ticketType?.price ?? 0);

    const totalPrice = prices.reduce((previousValue, currentSum) => previousValue + currentSum, 0);

    return totalPrice;
}

async function handleBookNow(
    selectedAccommodations: ProductInstance[][],
    selectedTicketTypes: TicketType[],
    clearRoomSelections: () => void,
    guestInfoList: GuestInfo[],
    currentSite: MembershipSite | null,
    loggedInUser: MembershipUser | null,
) {
    const configurations = configurationAtom.subject.value;
    const shouldShowBasketOnBook = !configurations.skipBasketOnBook;

    clearRoomSelections();
    await Promise.all(
        selectedAccommodations.map((selectedAccommodation, i) => {
            const ticketOptions = selectedAccommodation[0].ticketOptions.map((to) => ({
                ...to,
                quantity:
                    to.id === '0'
                        ? guestInfoList[i]?.adults ?? 1
                        : guestInfoList[i]?.children.length ?? 0,
                productInstances: selectedAccommodation,
            }));

            return cartAtom.update(
                createAddProductsToCartEvent(
                    selectedAccommodation,
                    ticketOptions,
                    true, // Accomodation is not supported by membership api, so this item will disable payment plans
                    false, // Obviously, it then doesn't require payment plans
                    currentSite,
                    loggedInUser,
                    undefined,
                    selectedTicketTypes[i],
                ),
            );
        }),
    );
    if (shouldShowBasketOnBook) {
        showBasketAtom.update({
            visible: true,
        });
    } else route('/checkout');
}
