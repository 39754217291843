import { createRoot } from 'react-dom/client';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { getStringAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import { getColorAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import ProductFeatured from 'src/widgets/activities/product/product-featured/ProductFeaturedWidgetRoot';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

export function ProductFeaturedWidget(props: {
    productCatalogId: string;
    productCatalogUrl?: string;
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    backgroundColor: string | null;
    textColor: string | null;
    primaryColor: string | null;
    primaryTextColor: string | null;
    accentColor: string | null;
    accentTextColor: string | null;
}): JSX.Element {
    const customizationOverrides: Partial<{
        [key in keyof ThemeType]: ThemeType[keyof ThemeType];
    }> = {};

    if (props.backgroundColor) customizationOverrides.productCardColor = props.backgroundColor;
    if (props.textColor) customizationOverrides.productCardTextColor = props.textColor;
    if (props.primaryColor) customizationOverrides.productCardPrimaryColor = props.primaryColor;
    if (props.primaryTextColor)
        customizationOverrides.productCardPrimaryColorContrast = props.primaryTextColor;
    if (props.accentColor) customizationOverrides.productCardAccentColor = props.accentColor;
    if (props.accentTextColor)
        customizationOverrides.productCardAccentColorContrast = props.accentTextColor;

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
            customizationOverrides={customizationOverrides}
        >
            <ProductFeatured
                productCatalogId={props.productCatalogId}
                productCatalogUrl={props.productCatalogUrl}
            />
        </WidgetWrapper>
    );
}

class BilberryProductFeatured extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getStringAttribute(this, 'product-catalog-id', false) ?? '';
        const productCatalogUrl = this.getAttribute('product-catalog-url') ?? undefined;
        const colors = getColorAttributes(this);

        createRoot(this._mountPoint).render(
            <ProductFeaturedWidget
                productCatalogId={productCatalogId}
                productCatalogUrl={productCatalogUrl}
                backgroundColor={colors.backgroundColor}
                textColor={colors.textColor}
                primaryColor={colors.primaryColor}
                primaryTextColor={colors.primaryTextColor}
                accentColor={colors.accentColor}
                accentTextColor={colors.accentTextColor}
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-featured', BilberryProductFeatured);
}
