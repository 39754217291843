import {
    FormControl,
    FormHelperText,
    InputLabel,
    Paper,
    SxProps,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';

const CHAR_LIMIT = 300;
const NUMBER_OF_LINES_LIMIT = 11;
const NUMBER_OF_ROWS_DISPLAYED = 13;
const LEGAL_EDITING_KEYS = [
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'End',
    'Home',
    'PageDown',
    'PageUp',
    'Backspace',
    'Clear',
    'Copy',
    'Cut',
    'Delete',
    'EraseEof',
];

interface IProps {
    largeScreen: boolean;
    message: string;
    onMessageChanged: (message: string) => void;
}

export default function GiftcardMessage(props: IProps): JSX.Element {
    const { message, onMessageChanged } = props;
    const theme = useTheme();
    const { t } = useLocale();
    const [numberOfLines, setNumberOfLines] = useState(1);

    const restrictNumberOfLines = (e: React.KeyboardEvent) => {
        const legalKeys = LEGAL_EDITING_KEYS.includes(e.key);

        if (!legalKeys && numberOfLines >= NUMBER_OF_LINES_LIMIT) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.currentTarget.value;
        const currentNumberOfLines = (text.match(/\n/g) || []).length + 1;
        setNumberOfLines(currentNumberOfLines);
        onMessageChanged(text);
    };

    return (
        <Paper
            sx={
                {
                    '& > *': {
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                    },
                    color: theme.palette.text.primary,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    marginRight: theme.spacing(2),
                    minWidth: props.largeScreen ? '400px' : '300px',
                    width: '100%',
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '440px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: theme.spacing(2),
                    backgroundColor: 'white',
                    marginTop: theme.spacing(2),
                    border: `1px solid ${theme.palette.grey[300]}`,
                } as SxProps
            }
            variant="outlined"
        >
            <Typography color="secondary" variant="h6" mt={0}>
                {t.giftcard_message.toUpperCase()}
            </Typography>

            <Fragment>
                <FormControl>
                    <InputLabel
                        sx={{
                            ...TextFieldStyleUtils.positionInputLabelAboveField,
                            color: theme.palette.secondary.main,
                        }}
                    >
                        {capitalize(t.giftcard_message_label)}
                    </InputLabel>
                    <TextField
                        multiline
                        value={message}
                        minRows={7}
                        maxRows={NUMBER_OF_ROWS_DISPLAYED}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        margin="dense"
                        onChange={handleMessageChange}
                        onKeyDown={restrictNumberOfLines}
                        onKeyUp={restrictNumberOfLines}
                        inputProps={{
                            maxLength: CHAR_LIMIT,
                        }}
                    />
                    <FormHelperText
                        sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            margin: theme.spacing(1),
                            color: theme.palette.text.disabled,
                        }}
                    >
                        {`${message.length} / ${CHAR_LIMIT}`}
                    </FormHelperText>
                </FormControl>
            </Fragment>
        </Paper>
    );
}
