import { Box, Button, CircularProgress } from '@mui/material';
import { createRoot, Root } from 'react-dom/client';
import { localeAtom } from '@repo/i18n';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryStripePayButton extends BilberryCustomElement {
    private _handleSubmit: ((e: any) => void) | undefined = undefined;
    private root: Root | null = null;

    static get observedAttributes() {
        return ['isloading', 'handleSubmit'];
    }

    constructor() {
        super();
    }

    get isloading() {
        return this.getAttribute('isloading') === 'true';
    }

    get handleSubmit() {
        return this._handleSubmit;
    }

    set handleSubmit(value: ((e: any) => void) | undefined) {
        this._handleSubmit = value;
        this.renderComponent();
    }

    attributeChangedCallback() {
        this.renderComponent();
    }

    connectedCallback() {
        super.connectedCallback();
        this.root = createRoot(this._mountPoint);
        this.renderComponent();
    }

    renderComponent() {
        const { t } = localeAtom.subject.value;

        // finally we need to wrap our application in a StylesProvider
        this.root?.render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <Box sx={{ position: 'relative' }}>
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        variant="contained"
                        className="bilberry-stripe-payment"
                        disabled={this.isloading}
                        sx={{
                            mt: 2,
                        }}
                    >
                        {t.buyNow}
                    </Button>
                    {this.isloading && (
                        <CircularProgress sx={{ position: 'absolute', top: 10, left: 25 }} />
                    )}
                </Box>
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-stripe-pay-button', BilberryStripePayButton);
}
