import { updateCurrencyAtomFromCode } from '@repo/widget-utils/currencyAtom';
import { BilberrySettings } from '@repo/types';
import { errorLog } from '@repo/common-utils/Logger';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import dayjs from 'dayjs';
import { TZDate } from '@repo/tzdate';
import { getBilberrySettings } from '@repo/widget-utils/api/product.api';

/**
 * Runs initialization code dependent on BilberrySettings.
 */
export async function initFromSettings() {
    try {
        const settings = await getBilberrySettings();

        if (settings) {
            initCurrencyFromBilberrySettings(settings);
            initTimezoneFromBilberrySettings(settings);
            dayjs.tz.setDefault(settings.timezone);
        }
        configurationAtom.update({
            ...configurationAtom.subject.value,
            __settingsLoaded: true,
        });
    } catch (e: any) {
        errorLog(e);
    }
}

function initCurrencyFromBilberrySettings(settings: BilberrySettings) {
    const code = settings.currency ?? 'NOK';
    updateCurrencyAtomFromCode(code);
}

function initTimezoneFromBilberrySettings(settings: BilberrySettings) {
    const timezone = settings.timezone ?? 'Europe/Oslo';
    TZDate.timezone = timezone;
    configurationAtom.update({
        ...configurationAtom.subject.value,
        timezone,
    });
}
