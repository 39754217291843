import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'vis betingelser',
    hide_cancellation_policy: 'skjul betingelser',
    departure: 'Avgang',
    price: 'Pris',
    difficulty: 'Vanskelighetsgrad',
    duration: 'Varighet',
    starting_time: 'Starttidspunkt',
    about: 'Om',
    book_now: 'Bestill nå',
    book: 'Bestill',
    buy_as_gift: 'Kjøp som gave',
    from: 'Fra',
    your_booking_reference_is: 'Din referanse er',
    booking_details_in_email: 'Du vil snart motta en epost med ytterligere informasjon',
    see_more_activities: 'Se flere aktiviteter',
    your_cart: 'Din handlekurv',
    when_are_you_going: 'Velg dato og tid',
    how_many_are_going: 'Hvor mange i følget?',
    contact_details: 'Kontaktinfo',
    traveler: 'Deltaker',
    extras: 'Tillegg',
    please_select: 'Vennligst velg',
    no_items_in_cart: 'Du har ingen produkter i handlekurven',
    payment: 'Betaling',
    receipt: 'Kvittering',
    do_you_have_any_comments_or_special_needs: 'Har du noen kommentarer eller spesielle behov?',
    i_have_read_and_accepted_the: 'Jeg har lest og forstått',
    privacy_policy: 'personvernerklæringen',
    terms_and_conditions: 'Vilkår og Betingelser',
    first_name: 'Fornavn',
    last_name: 'Etternavn',
    phone_number: 'Telefon',
    dial_code: 'Landkode',
    email: 'E-post',
    please_register: 'Vennligst registrer',
    main_contact: 'Kontaktperson',
    additional_information: 'Praktisk informasjon',
    remove_cart_item: 'Remove cart item',
    back_to_webshop: 'Fortsett å handle',
    excl: 'eks.',
    vat: 'MVA',
    total: 'Totalt',
    next: 'Neste',
    go_and_pay: 'Til betaling',
    print: 'Skriv ut',
    close: 'Lukk',
    checkout: 'gå til kassen',
    summary: 'Oppsummering',
    cancel: 'Avbryt',
    save: 'Lagre',
    select_time: 'Velg tidspunkt',
    selected_time: 'Valgt tidspunkt',
    time: 'Tidspunkt',
    go_back: 'Tilbake',
    thank_you_for_booking_with_us: 'Takk for at du bestilte hos oss!',
    please_fill_in_all_required_information: 'vennligst legg inn all nødvendig informasjon',
    error_occurred_when_creating_reservation:
        'beklager, det oppstod en feil ved oppretting av reservasjonen',
    error_occurred_when_creating_giftcard:
        'beklager, det oppstod en feil ved oppretting av gavekort',
    couldnt_apply_promocode_is_the_code_correct: 'Kunne ikke bruke rabattkoden. Er koden riktig?',
    choose_a_country: 'Velg land',
    select_date: 'Velg dato',
    select_travelers: 'Velg deltakere',
    hourShort: 't',
    dayShort: 'd',
    minuteShort: 'm',
    years: 'år',
    age: 'Alder',
    more_information: 'mer informasjon',
    same_as_main_contact: 'samme som kontaktperson',
    easy: 'Lett',
    medium: 'Middels',
    hard: 'Krevende',
    please_select_date: 'Vennligst velg dato',
    please_select_date_and_time: 'vennligst velg dato og tidspunkt',
    name: 'Navn',
    comment: 'Kommentar',
    submit_request: 'Send forespørsel',
    any_special_requests_etc: 'Noen spesielle forespørsler, allergier...?',
    request_tour_comment: 'Beskriv din forespørsel',
    thank_you_for_your_request: 'Takk for din henvendelse, vi kommer raskt tilbake til deg.',
    got_it: 'Det er greit!',
    continue_shopping: 'Fortsett å handle',
    continue_travelers: 'Reisende',
    please_provide_your_name: 'Vennligst oppgi navnet ditt',
    please_provide_your_email: 'Vennligst oppgi en gyldig epostadresse',
    please_provide_a_description: 'Vennligst legg til en beskrivelse',
    an_error_occurred_when_submitting_your_request:
        'En feil oppstod under innsendingen av skjemaet. Vennligst prøv igjen senere.',
    private_tour: 'Privat tur',
    read_more_and_book: 'Les mer og book',
    read_more: 'Les mer',
    prices_from: 'Priser fra',
    about_the_trip: 'Om turen',
    prices: 'Priser',
    whats_included: 'Inkludert i turen',
    any: 'Alle',
    duration_up_to_1_hour: 'Opptil 1 time',
    duration_1_2_hours: '1 - 2 timer',
    duration_2_6_hours: '2 - 6 timer',
    duration_6_12_hours: '6 - 12 timer',
    duration_12_24_hours: '12 - 24 timer',
    duration_1_2_days: '1 - 2 dager',
    duration_more_than_2_days: 'Mer enn 2 dager',
    find_activities: 'Finn aktiviteter',
    ok: 'OK',
    clear: 'Tøm',
    filterActivities: 'Filtrer aktiviteter',
    hideFilters: 'Skjul filtre',
    shopMore: 'Handle mer',
    noSearchResults: 'Beklager, vi fant ingen ledige aktiviteter i ditt søk.',
    update: 'Oppdater',
    select_date_to_view_timeslots: 'Velg en dato for å se ledige tidspunkt',
    your_reservation_timed_out: 'Din bestilling fikk et tidsavbrudd. Vennligst prøv igjen',
    aria_calendar_input_field_label: 'Kalenderfelt. Trykk Enter for å åpne.',
    aria_book_now_button: 'Åpne et vindu for bestilling av denne aktiviteten',
    aria_next_image: 'Neste bilde',
    aria_previous_image: 'Forrige bilde',
    aria_open_booking_widget: 'Åpne bestillingsvindu',
    aria_close_booking_widget: 'Lukk bestillingsvindu',
    aria_open_request_tour_widget: 'Åpne forespørselvindu',
    aria_close_request_tour_widget: 'Lukk forespørselvindu',
    aria_open_find_activity: 'Åpne finn aktiviteter',
    aria_close_find_activity: 'Lukk finn aktiviteter',
    request_tour: 'send forespørsel',
    giftcard_message: 'melding',
    giftcard_message_label: 'personlig melding til mottaker (valgfritt)',
    recipient_details: 'mottakers kontaktinformasjon',
    gift_card: 'gavekort',
    value: 'verdi',
    create: 'opprett',
    create_your_gift: 'opprett gavekort',
    your_giftcard_reference_is: 'referansen din er',
    giftcard_voucher_and_receipt:
        'Vi har sendt deg et gavekort som er klart for utskrift, sammen med en kvittering til',
    giftcard_voucher: 'vi har sendt gavekortvoucheren til:',
    giftcard_and_receipt: 'og en kvittering til:',
    thank_you_for_your_purchase: 'Takk for handelen!',
    valid_until: 'gyldig til',
    print_receipt: 'skriv ut',
    select_gift_type: 'velg type gavekort',
    please_select_gift_type: 'vennligst velg type gavekort',
    select_gift_print_description: 'motta en PDF i din innboks som kan skrives ut',
    select_gift_email_description: 'send gavekortet på mail direkte til mottakeren',
    enter_giftcard_code: 'Bruk gavekort',
    enter_promo_code: 'Bruk rabattkode',
    apply: 'bruk',
    giftcard_code_error: 'gavekortkoden er ugyldig',
    remaining_value: 'resterende beløp',
    giftcard_code_not_found: 'fant ikke gavekort med koden:',
    giftcard_zero_balance: 'gavekortet er allerede oppbrukt',
    giftcard_is_expired: 'gavekortet er utløpt',
    purchased_giftcard: {
        self: 'gavekort du kan skrive ut',
        recipient: 'gavekort sendt til mottaker',
    },
    contact_information: {
        short: 'kontaktinfo',
        long: 'kontaktinformasjon',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minutt',
    } as any,
    hours: {
        singular: 'time',
        plural: 'timer',
    } as any,
    days: {
        singular: 'dag',
        plural: 'dager',
    } as any,
    children: {
        singular: 'barn',
        plural: 'barn',
    } as any,
    adult: {
        singular: 'voksen',
        plural: 'voksne',
    } as any,
    card: {
        singular: 'kort',
        plural: 'kort',
    } as any,
    too_few_travelers: 'For få deltakere',
    too_few_travelers_quantity: {
        template: 'Minimum <COUNT:billett:billetter> påkrevd valgt dag',
    },
    number_of_travelers_exceeded: {
        template: 'Maks <COUNT:billett:billetter> tilgjengelig valgt dag',
    } as any,
    booking_is_closed: {
        template: 'Booking for <NAME> er nå stengt',
    } as any,
    rooms: {
        singular: 'rom',
        plural: 'rom',
    } as any,
    person: {
        singular: 'person',
        plural: 'personer',
    } as any,
    select_room: 'velg rom',
    change_room: 'endre rom',
    less_than: 'mindre enn',
    year: {
        singular: 'år',
        plural: 'år',
    } as any,
    select_age: 'velg alder',
    done: 'ferdig',
    number_of_rooms: 'antall rom',
    select_bed: 'velg seng',
    room_info: 'rominformasjon',
    price_one_night_one_guest: {
        template: 'pris for <COUNT:natt:netter>, <COUNT:gjest:gjester>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:natt:netter>, <COUNT:gjest:gjester>',
    } as any,
    price_nights: {
        template: 'pris for <COUNT:natt:netter>',
    } as any,
    night: {
        singular: 'natt',
        plural: 'netter',
    } as any,
    select_room_for_total_price: 'velg rom for å se totalpris',
    find_rooms: 'finn rom',
    check_in_check_out: 'Innsjekk og Utsjekk',
    guest_and_rooms: 'Gjester og rom',
    no_accommodations_found:
        'Vi beklager, men vi kunne ikke finne noen rom som passer søkekriteriene dine. Har du vurdert å justere søket ditt for å finne ledige rom?',
    facilities_included: 'Inkluderte fasiliteter',
    price_per_night: 'Pris per natt',
    season: 'Sesong',

    has_airconditioning: 'Aircondition',
    has_balcony: 'Balkong',
    has_bicycles: 'Sykler inkludert',
    has_boat: 'Båt inkludert',
    has_coffee_maker: 'Kaffemaskin',
    has_dishwasher: 'Oppvaskmaskin',
    has_dryer: 'Tørketrommel',
    has_fireplace: 'Peis',
    has_garage: 'Garasje',
    has_garden_furniture: 'Hagemøbler',
    has_grill: 'Grill',
    has_kitchen: 'Kjøkken',
    has_minibar: 'Minibar',
    has_mooring_space: 'Område for fortøyning',
    has_pool: 'Badebasseng',
    has_roomservice: 'Room Service',
    has_sauna: 'Badstu',
    has_tv: 'TV',
    has_washing_machine: 'Vaskemaskin',
    has_wifi: 'Trådløst internett',
    is_breakfast_included: 'Frokost',
    is_cancellation_included: 'Mulighet for kansellering',
    is_pets_allowed: 'Kjæledyr tillatt',
    is_smoking_allowed: 'Røyking tillatt',
    is_towels_and_bedlines_included: 'Håndklær og sengetøy',
    is_wheelchair_accessible: 'Tilgjengelig for rullestolbrukere',
    wifi: 'WiFi',
    breakfast: 'Frokost',
    accessible: 'Tilgjengelig',
    pets: 'Kjæledyr',
    kitchen: 'Kjøkken',
    smoking: 'Røyking',
    unknown: 'Ukjent',
    accommodation_cancellation_policy: '',
    accommodations: 'Overnatting',
    activities: 'Aktiviteter',
    city: 'By',
    post_code: 'Postnummer',
    to: 'til',
    date_range_start: 'start',
    date_range_end: 'slutt',
    flag_of: 'flagget til',
    monday: 'mandag',
    tuesday: 'tirsdag',
    wednesday: 'onsdag',
    thursday: 'torsdag',
    friday: 'fredag',
    saturday: 'lørdag',
    sunday: 'søndag',
    today: 'i dag',
    tomorrow: 'i morgen',
    january: 'januar',
    february: 'februar',
    march: 'mars',
    april: 'april',
    may: 'mai',
    june: 'juni',
    july: 'juli',
    august: 'august',
    september: 'september',
    october: 'oktober',
    november: 'november',
    december: 'desember',
    few_left: 'få igjen',
    tickets_left: {
        template: '<COUNT:billett:billetter> igjen',
    } as any,
    available: 'ledig',
    sold_out: 'utsolgt',
    upcoming_tours_title: 'Kommende turer',
    unavailable: 'Ikke tilgjengelig',
    notes_regarding_your_stay: 'Notater til ditt opphold',
    notes_for_accommodation_name_with_guests: {
        template: 'Notater til <NAME> med <COUNT:gjest:gjester>',
    } as any,
    select_price_type: 'Velg pristype',
    finding_available_rooms: 'Finner ledige rom',
    total_price: 'Totalpris',
    of: 'av',
    select_dates: 'Velg datoer',
    please_select_price_type: 'Vennligst velg pristype',
    a_minimum_of_participants_is_required_to_book_this_product:
        'Det kreves flere deltakere for å bestille dette produktet',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `Det kreves minst <COUNT> deltakere for å bestille dette produktet`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'En ordrebekreftelse vil bli sendt til din epostadresse så snart vi har behandlet ordren din.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'En ordrebekreftelse vil bli sendt til din epostadresse så snart vi har behandlet ordren din.',
    address: 'Adresse',
    add: 'Legg til',
    amount: 'Antall',
    areYouAMemberOrHaveValueCard: 'Er du medlem eller har klippekort?',
    areYouSureYouWantToCancelYourBooking: 'Er du sikker på at du vil kansellere bookingen?',
    areYouSureYouWantToCancelYourMembership: 'Er du sikker på at du vil avslutte ditt medlemsskap?',
    automaticallyRenewed: 'Automatisk fornyet',
    at: 'Hos',
    backToWebsite: 'Tilbake til nettsiden',
    belayCertification: 'Sikringskort',
    belayCertificationConsent: 'Jeg har brattkort og/eller topptaukort',
    buyMembership: 'Kjøp periodebillett',
    buyNow: 'Kjøp nå',
    canBeCancelledFrom: 'Kan bli kansellert fra',
    cancelled: 'Kansellert',
    cancellation: 'Avbestilling',
    choose_payment_method: 'Velg betalingsmetode',
    bookNow: 'Kjøp nå',
    bookTimeslot: 'Kjøp billetter',
    chooseACountry: 'Velg et land',
    choose: 'Velg',
    chooseDateAndTime: 'Velg dato og tid',
    chooseCreditsType: 'Velg klippekorttype',
    chooseNumberOfCredits: 'Velg antall klipp',
    chooseStartDate: 'Velg startdato',
    chooseType: 'Velg type',
    ticketCode: 'Ordrereferanse',
    completed: 'Fullført',
    consents: 'Samtykker',
    contact: 'Kontakt',
    contactByEmail: 'Kontakt på epost',
    contactBySms: 'Kontakt på SMS',
    couldntApplyGiftcardIsTheCodeCorrect: 'Kunne ikke hente gavekortet. Er koden riktig?',
    couldntApplyPromocodeIsTheCodeCorrect: 'Kunne ikke bruke rabattkoden. Er koden riktig?',
    couldntGetCompanyInformation: 'Kunne ikke hente informasjon om firmaet',
    couldntGetPaymentPlan:
        'Kunne ikke hente betalingsplan. Vennligst forsøk å laste nettsiden på nytt.',
    couldntGetPaymentPlanUsingPreviousPlan: 'Kunne ikke hente betalingsplan, bruker forrige plan',
    couldntGetValueCards: 'Kunne ikke hente verdikort',
    couldntGetValueCardProducts: 'Kunne ikke hente verdikortprodukter',
    couldntGetProductPleaseTryAgain: 'Kunne ikke hente produkt, vennligst prøv igjen',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'Kunne ikke åpne checkout for bookinger, ikke all booking-informasjon har blitt spesifisert.',
    couldntOpenProfilePleaseSignInFirst: 'Kunne ikke åpne checkout for bookinger, logg inn først.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'Kunne ikke åpne checkout for verdikort, ikke all verdikort-informasjon har blitt spesifisert.',
    country: 'Land',
    createAccount: 'Opprett konto',
    credit: {
        singular: 'Klipp',
        plural: 'Klipp',
    },
    credits: 'Klippekort',
    creditsLeft: 'Klipp igjen',
    creditDiscount: 'Klipperabatt',
    date: 'Dato',
    delete: 'Slett',
    doYouHaveAnAccount: 'Har du konto?',
    doYouHaveValueCards: 'Har du en konto, eller verdikort?',
    doYouHaveBrattkort: 'Har du brattkort og / eller topptaukort?',
    doYouHaveClimbingCertification: 'Har du en klatresertifisering?',
    edit: 'Endre',
    editTimeslot: 'Edit timeslot',
    failed: 'Feilet',
    firstName: 'Fornavn',
    giftcard: 'Gavekort',
    goToMyPage: 'Gå til min side',
    ifYouWantToDeleteYourProfile: 'Dersom du vil slette profilen din',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Du kan kjøpe billetter fra butikken på dette nettstedet.',
    yourOrderReferenceIs: 'Din ordrereferanse er',
    iHaveReadAndAccepted: 'Jeg har lest og godtatt',
    lastName: 'Etternavn',
    postalLocation: 'Poststed',
    location: 'Sted',
    loggedInAs: 'Innlogget som',
    login: 'Logg inn',
    logout: 'Logg ut',
    membershipCard: 'Medlemskap',
    membershipNumber: 'Medlemsnummer',
    membershipSlashCredits: 'Medlemskap / Klippekort',
    monthlyCharge: 'Månedstrekk',
    myAddress: 'Min adresse',
    myPage: 'Min side',
    myProfile: 'Min profil',
    nextCreateAccount: 'Neste: Opprett konto',
    no: 'Nei',
    noneSelected: 'Ingen valgt',
    noSelectedDate: 'Ingen dato valgt',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Ikke alle felter er utfylt, vær vennlig å fylle ut alle.',
    noUserInfoFound: 'Ingen brukerinformasjon funnet.',
    numberOfPersons: {
        singular: 'person',
        plural: 'personer',
        template: '<COUNT:person:personer>',
    } as any,
    pay: 'Betal',
    payWithCreditCard: 'Betal med kort',
    phoneNumber: 'Telefonnummer',
    pleaseChoose: 'Vennligst velg',
    pleaseContact: 'Vennligst kontakt',
    pleaseFillOutUserInfoInProfile: 'Fyll ut brukerinformasjon i din profil.',
    pleaseLogInToBook: 'Vennligst logg inn for å kjøpe med disse',
    pleaseLogInToPurchase: 'Vennligst logg inn så fyller vi inn dine data for deg',
    pleaseProvideAllValueCardInformation: 'Vennligst oppgi all informasjon om verdikortet',
    pleaseProvideProperBookingInformation: 'Vennligst oppgi korrekt kjøpsinformasjon',
    pleaseRegister: 'Vennligst registrer',
    postalCodeAndLocation: 'Postnummer / poststed',
    prefix: 'Landkode',
    privacyPolicy: 'Personvernerklæring',
    problemLoadingData: 'Feil ved innlasting av data',
    processing: 'Prosesseres',
    purchaseValueCard: 'Kjøp periodebillett eller klippekort',
    readMoreAbout: 'Les mer om vår',
    readMoreAndBook: 'Les mer og kjøp',
    readMoreAndBuy: 'Les mer og betal',
    refillCredits: 'Fyll på klippekort',
    returnToWebsite: 'Returner til nettsiden',
    scanTicket: 'Skann billett',
    signUp: 'Opprett konto',
    shoesAndHarnessAlwaysIncluded: 'Sele og sko er alltid inkludert.',
    shoesAndHarnessNotIncluded: 'Sele og sko ikke inkludert.',
    somethingWentWrongUpdatingUserInfo: 'Noe gikk galt ved oppdateringen av brukerinformasjon',
    subtract: 'Trekk fra',
    terminate: 'Avslutt',
    termsOfUse: {
        template: 'Vilkår for bruk av <NAME>',
    } as any,
    upcomingBookings: 'Kommende bookinger',
    updateAddress: 'Oppdater adresse',
    thankYouForBookingWithUs: 'Takk for at du booket hos oss.',
    thePrivacyPolicy: 'Personvernerklæringen',
    thankYouForShoppingWithUs: 'Takk for at du handlet hos oss',
    thisGiftcardIsEmpty: 'Dette gavekortet er tomt.',
    tHours: 't',
    totalCredits: 'Totalt antall klipp',
    totalNOK: 'Total NOK',
    updateBooking: 'Oppdater booking',
    updateProfile: 'Oppdater profil',
    useGiftcard: 'Løs inn gavekort',
    useGiftcardOrPromocode: 'Løs inn gavekort / rabattkode',
    validationNotANumber: 'Må være et tall',
    validationMaxNumber: {
        template: 'Ingen tall over <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Ingen tall under <NUM>',
    } as any,
    validForThePeriod: 'Gyldig i perioden',
    validUntil: 'Gyldig til',
    validFrom: 'Gyldig fra',
    valueCard: 'Verdikort',
    visitors: 'Besøkende',
    withoutVat: 'Uten MVA',
    yes: 'Ja',
    youDontHaveAnyUpcomingBookings: 'Du har ingen kommende bookinger',
    youDontHaveAnyValueCards: 'Du har ingen verdikort',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'Du har ikke en gyldig periodebillett, vil du kjøpe et?',
    youMustSpecifyAValueCard: 'Du må velge et verdikort.',
    yourReservationTimedOut: 'Reservasjonen din har utgått.',
    zipcode: 'Postnummer',
    payByMembershipCard: 'Betal med periodebillett',
    payWith: 'Betal med',
    deleteMyProfile: 'Slett min profil',
    areYouSureYouWantToDeleteYourProfile: 'Er du sikker på at du vil slette din profil?',
    deleteYourProfileInformation:
        'Du er i ferd med å slette og koble fra profilinformasjonen din fra eventuelle bestillinger og kjøpte produkter. Denne handlingen kan ikke angres. Noen opplysninger kan bli lagret av juridiske årsaker.',
    choosePaymentOtherMethods: 'Velg andre betalingsmåter',
    dontUseMembershipCardForThisBooking: 'Ikke bruk periodebillett for denne bookingen',
    nameNotProvided: 'Navn er ikke angitt',
    phoneNumberNotProvided: 'Telefonnummer er ikke angitt',
    emailNotProvided: 'E-post er ikke angitt',
    addressNotProvided: 'Adresse er ikke angitt',
    promocode: 'Rabattkode',
    show_usage_terms: 'vis betingelser',
    hide_usage_terms: 'skjul betingelser',
    about_the_product: 'om produktet',
    successful: 'Vellykket',
    selectProduct: 'Velg produkt',
    withCreditCard: 'Med kredittkort',
    paymentMethod: 'Betalingsmetode',
    noAvailableTimeslots: 'Ingen ledige timer',
    orderReference: 'Ordrereferanse',
    iWishToRecieveNewsletters: 'Jeg ønsker å motta nyhetsbrev',
    mySite: 'Min Side',
    pleaseConfirm: {
        template: 'Vennligst bekreft "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Vennligst oppgi antall deltakere',
    pleaseSelectTheDateAndTimeOfYourBooking: 'Vennligst velg dato og tid for ditt besøk',
    pleaseSelectNumberOfCredits: 'Vennligst velg antall klipp',
    pleaseSelectTypeOfCreditcard: 'Vennligst velg type klippekort',
    pleaseSelectAStartDate: 'Vennligst velg startdato',
    pleaseSelectAMembershipType: 'Vennligst velg type årskort',
    contactCustomerServiceToDeleteYourProfile: 'Kontakt kundeservice for å slette din profil',
    inclInMembership: 'Inkl. i medlemskap',
    maxAmount: 'Maks antall',
    anErrorOccurredDuringPayment: 'Noe gikk galt med betalingen',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Det har skjedd en feil, last siden på nytt for å prøve igjen. Feilmelding',
    share: 'Del',
    copyLink: 'Kopier lenke',
    howDoYouWantToShare: 'Hvordan vil du dele?',
    checkIn: 'Innsjekk',
    checkOut: 'Utsjekk',

    shoppingCart: 'Handlekurv',
    heresMyShoppingCart: 'Her er handlekurven min',
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'Det er ikke nok ledig kapasitet for produktene i denne handlekurven',

    full: 'Fullt',
    no_available_capacity_for_this_tour: 'Ingen ledig kapasitet på denne turen',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Det er ikke nok ledige plasser på en eller flere av aktivitetene og/eller overnattingene',

    pick_a_date: 'Velg en dato',
    package: 'Pakke',
    packages: 'Pakker',
    customize_activities: 'Skreddersy aktiviteter',
    check_in_at: 'Innsjekk kl.',
    activity_starts_at: 'Aktiviteten starter kl.',
    other_times_are_available: 'Andre tidspunkt er tilgjengelige',
    press_to_select_other_options: 'Trykk for å velge andre alternativer',
    unavailableForSelectedTime: 'Ikke ledig på valgt tidspunkt',
    order_status_awaiting_payment: 'Betaling pågår',
    order_status_payment_failed: 'Betaling feilet',
    awaiting_payment_info: 'Betaling pågår... dette tar vanligvis ikke mer enn noen minutter.',
    cancel_membership: 'Avslutt medlemskap',
    update_subscription_payment_details: 'Oppdater betalingsinformasjonen',
    payment_failed_info:
        'Det ser ut til å være et problem med betalingen. Du kan forsøke å oppdatere betalingsinformasjonen.',
    retry_subscription_payment: 'Forsøk betaling på nytt nå',

    entrance: 'Inngang',
    related_products: 'Relaterte produkter',
    some_tickets_are_unavailable_the_selected_day: 'Noen billetter er utilgjengelig på valgt dag',
    company: 'Bedrift',
    company_name: 'Firmanavn',
    org_number: 'Organisasjonsnummer',
    pay_with_invoice: 'Betal med faktura',
    continueWithRebooking: 'Fortsett med ombookingen',
    searchAndBookAccommodations: 'Søk og bestill overnatting',
    selectNumberOfGuests: 'Velg antall gjester',
    checkAvailability: 'Sjekk tilgjengelighet',
    updateAvailability: 'Oppdater tilgjengelighet',
    you_will_have_to_switch_rooms_during_your_stay:
        'Du må bytte rom under oppholdet. Kontakt betjeningen for å bestille.',
    selected: 'Valgt',
    remove: 'Fjern',
    promo_code_not_supported_for_gift_cards: 'Rabattkoden er ikke gyldig for gavekort',
    select_products: 'Velg produkter',
    change_selected_products: 'Endre valgte produkter',
    cartCompatibilityWarning:
        'Det finnes produkter i handlekurven din som ikke er kompatible med dette produktet. Vennligst kjøp dem separat, eller tøm handlekurven din for å fortsette.',
    membershipBookingDisabledWarning:
        'Membership Booking er deaktivert på akkurat denne nettsiden, men dette produktet krever det. Dette er mest sannsynlig en feilkonfigurasjon. Vennligst kontakt kundeservice.',
};

export default translations;
