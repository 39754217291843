import { Theme, useMediaQuery } from '@mui/material';
import { AppliedGiftCard, CompletedPaymentInfo, PaymentInfo } from '@repo/types';
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { loadPurchase } from 'src/state/payment/purchase.localstorage';
import {
    createInitialCompletedPaymentInfo,
    validateCheckoutInfoData,
} from '@repo/widget-utils/checkout-info-helper';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { CheckoutTabType, IdxOpts } from './views/getTabIndex';
import { useCartContext } from 'src/widgets/CartContext';
import {
    PaymentRedirectContext,
    tryFindPaymentRedirectContext,
} from '@repo/widget-utils/payment-redirect-helper';
import {
    calculateAppliedGiftcards,
    getPriceSummaryFromCartItems,
    PriceSummary,
} from '@repo/widget-utils/price-helper';

type CartCheckoutContextState = {
    activeTab: CheckoutTabType;
    setActiveTab: (tab: CheckoutTabType) => void;
    paymentInfo: PaymentInfo | null;
    setPaymentInfo: (paymentInfo: PaymentInfo | null) => void;
    completedPaymentInfo: CompletedPaymentInfo | null;
    setCompletedPaymentInfo: (completedPaymentInfo: CompletedPaymentInfo | null) => void;
    isMakingReservation: boolean;
    setIsMakingReservation: (isMakingReservation: boolean) => void;
    paymentRedirectContext: PaymentRedirectContext | null;
    priceSummary: PriceSummary;
    appliedGiftcards: AppliedGiftCard[];
    relatedProductIds: string[];
    idxOpts: IdxOpts;
    showAddressFields: boolean;
    largeScreen: boolean;
    onConfirmationPage: boolean;
    isCheckoutInfoDataValid: boolean;
};

const CartCheckoutContext = createContext<CartCheckoutContextState | undefined>(undefined);

export type CartCheckoutContextProps = PropsWithChildren;
export function CartCheckoutContextProvider(props: CartCheckoutContextProps) {
    const config = useConfigurations();

    const { cartItems, giftcards, appliedPromoCode, isUsingPaymentPlan, checkoutInfo } =
        useCartContext();

    const paymentRedirectContext = tryFindPaymentRedirectContext();
    const storedPurchase = loadPurchase();
    const initialCompletedPaymentInfo = createInitialCompletedPaymentInfo(
        storedPurchase,
        paymentRedirectContext,
    );

    const isPackageInCart = Object.values(cartItems).some((item) => item.pkg);

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [isMakingReservation, setIsMakingReservation] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
    const [completedPaymentInfo, setCompletedPaymentInfo] = useState<CompletedPaymentInfo | null>(
        initialCompletedPaymentInfo,
    );

    const itemsWithExtras = cartItems.filter((item) =>
        item.products.some(
            (p) =>
                p.extraProducts.length > 0 || p.timeslots.some((t) => t.extraProducts.length > 0),
        ),
    );
    const hasExtras = (config.enableExtras && itemsWithExtras.length > 0) || false;

    const relatedProductIds = Array.from(
        new Set(
            cartItems.flatMap((item) =>
                item.products.flatMap((p) => p.relatedProducts.map((r) => r.toString())),
            ),
        ),
    ).filter((id) => !cartItems.some((item) => item.products[0]?.product?.id === id));

    const idxOpts: IdxOpts = {
        hasExtras: hasExtras,
        hasPackage: isPackageInCart,
        display: true,
        isUsingPaymentPlan,
        hasRelatedProducts: !!config.enableRelatedProducts && relatedProductIds.length > 0,
    };

    const initialActiveTab =
        paymentRedirectContext !== null
            ? 'payment'
            : idxOpts.hasPackage
              ? 'package'
              : idxOpts.hasRelatedProducts
                ? 'relatedproducts'
                : idxOpts.hasExtras
                  ? 'extras'
                  : 'contactinfo';
    const [activeTab, setActiveTab] = useState<CheckoutTabType>(initialActiveTab);

    const showAddressFields = cartItems.some((item) =>
        item.products.some(
            (p) => p.product?.type === 'accommodation' || p.product?.type === 'timeslot',
        ),
    );

    const priceSummary = useMemo(
        () => getPriceSummaryFromCartItems(cartItems, appliedPromoCode),
        [cartItems, appliedPromoCode],
    );

    const appliedGiftcards = useMemo(
        () => calculateAppliedGiftcards(giftcards, priceSummary.totalPrice),
        [giftcards, priceSummary.totalPrice],
    );

    const onConfirmationPage = completedPaymentInfo !== null;

    const isCheckoutInfoDataValid = useMemo(() => {
        return checkoutInfo !== null
            ? validateCheckoutInfoData(checkoutInfo, false) // showAddressFields)
            : false;
    }, [checkoutInfo]);

    return (
        <>
            <CartCheckoutContext.Provider
                value={{
                    activeTab,
                    setActiveTab,
                    paymentRedirectContext,
                    priceSummary,
                    appliedGiftcards,
                    relatedProductIds,
                    paymentInfo,
                    setPaymentInfo,
                    completedPaymentInfo,
                    setCompletedPaymentInfo,
                    isMakingReservation,
                    setIsMakingReservation,
                    idxOpts,
                    showAddressFields,
                    largeScreen,
                    onConfirmationPage,
                    isCheckoutInfoDataValid,
                }}
            >
                {props.children}
            </CartCheckoutContext.Provider>
        </>
    );
}

export function useCartCheckoutContext(): CartCheckoutContextState {
    const context = useContext(CartCheckoutContext);
    if (context === undefined) {
        throw new Error('useCartCheckoutContext must be used within a CartCheckoutContextProvider');
    }
    return context;
}
