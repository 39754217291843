import { tzdate, TZDate } from '@repo/tzdate';
import { groupBy, sumBy } from 'lodash-es';
import { countryOptionsEn } from '@repo/common-utils/countries';
import { localeAtom } from '@repo/i18n';
import {
    BilberryPackage,
    BilberryPackageAvailability,
    BilberryPackageReservation,
    BilberryProduct,
    BilberryProductCatalog,
    BilberryProductCollection,
    BilberryProductMedia,
    BilberryProductPrice,
    BilberryReservation,
    BilberryReservationCompany,
    BilberryReservationPerson,
    BilberryReservationQuestionnaireAnswers,
    BilberryReservationTour,
    BilberrySmartEventPlan,
    BilberryAccommodation,
    BilberryAccommodationReservationRequest,
    BilberryTimeslot,
    BilberryTimeslotsProject,
    MembershipConsumer,
    MembershipMultiReservationTours,
    MembershipTicket,
    Location,
    Package,
    Product,
    TicketOption,
    CartItem,
    CheckoutInfo,
    TicketOptionWithQuantity,
    CheckoutQuestionnaire,
    PackageTicketOptionWithQuantity,
    ProductInstance,
    TicketType,
    Timeslot,
    BilberryReservationGuestList,
    Iso2Code,
} from '@repo/types';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { getPriceSummaryFromCartItems, PriceSummary } from './price-helper';
import { configurationAtom } from './widgetsConfiguration';
import { currencyAtom } from './currencyAtom';
import { errorLog } from '@repo/common-utils/Logger';

export function packageFromBilberryPackage(
    obj: BilberryPackage,
    products: Product[],
    packageAvailability: BilberryPackageAvailability[],
): Package {
    const coverImage = findMediaCoverImage(obj.media);
    const pkg: Package = {
        id: obj.id.toString(),
        type: 'package',
        title: obj.title,
        shortDescription: obj.short_description,
        longDescription: obj.full_description,
        startTimes: null,
        additionalInfo: null,
        url: obj.web_url,
        coverImage: { src: coverImage?.url ?? '', alt: `Image of ${obj.title}` },
        images: obj.media.gallery.map((image) => ({
            src: image.url,
            alt: `Image of ${obj.title}`,
        })),
        duration: obj.duration,
        ticketOptions: [],
        fromPrice: obj.from_price,
        icons: null,
        products:
            products.length === 0 || !obj.ticket_options
                ? []
                : obj.ticket_options.flatMap((opt) => {
                      const usedProducts: number[] = [];
                      return opt.products
                          .map((ticketProduct): Product => {
                              usedProducts.push(ticketProduct.package_product_id);

                              const product = products.find(
                                  (p) => p.id === ticketProduct.product_id.toString(),
                              );

                              if (!product) {
                                  throw new Error(
                                      `Product for package not found.
                         Package id: ${obj.id},
                         Product id: ${ticketProduct.product_id},
                         Ticket option id: ${opt.ticket_option_id}`,
                                  );
                              }

                              return {
                                  ...product,
                                  pkgId: obj.id.toString(),
                                  pkgTitle: obj.title,
                                  pkgProductId:
                                      ticketProduct?.package_product_id.toString() ?? null,
                                  pkgTicketTypeId: ticketProduct.price_category_id.toString(),
                                  pkgTicketOptionId: opt.ticket_option_id.toString(),
                                  pkgTicketOptionName: opt.name,
                                  pkgMinutesRelativeStart: ticketProduct.minutes_relative_start,
                                  pkgMinutesRelativeEnd: ticketProduct.minutes_relative_end,
                              };
                          })
                          .filter(Boolean) as Product[];
                  }),
    };

    (pkg as any).ticketOptions = obj.ticket_options
        ? ticketOptionsFromBilberryPackage(obj, packageAvailability, pkg)
        : [];
    return pkg;
}

export function productsFromProductCollection(collection: BilberryProductCollection): Product[] {
    return collection.products.map((obj) => {
        const coverImage = obj.media.find((x) => x.collection_name === 'image');
        const galleryImages = obj.media.filter((x) => x.collection_name === 'gallery');

        return {
            id: obj.id.toString(),
            type: typeFromProductCatalog(obj as any),
            title: obj.web_title ?? obj.name,
            shortDescription: obj.web_short_description,
            longDescription: obj.web_full_description ?? obj.description,
            additionalInfo: obj.web_additional_info ?? null,
            startTimes: null,
            url: obj.web_url,
            coverImage: { src: coverImage?.url, alt: `Image of ${obj.web_title}` },
            images: galleryImages.map((image) => ({
                src: image.url,
                alt: `Image of ${obj.web_title}`,
            })),
            duration: obj.duration,
            ticketOptions: obj.default_prices.map((price) => ticketOptionFromPrice(price, null)),
            fromPrice: obj.from_price,
            fromAge: obj.from_age,
            location: locationFromProductCatalogLocation(obj.location),
            difficulty: obj.difficulty,
            departureLocation: locationFromProductCatalogLocation(obj.location),
            capacity: obj.capacity,
            minEntrants: obj.min_entrants,
            terms: obj.web_disclaimer,
            currency: obj.currency,
            pkgId: null,
            pkgTitle: null,
            pkgProductId: null,
            pkgTicketTypeId: null,
            pkgTicketOptionId: null,
            pkgTicketOptionName: null,
            pkgMinutesRelativeStart: null,
            pkgMinutesRelativeEnd: null,
            accommodationAttributes: null,
            cutoffTime: null,
            icons: obj.icons ?? null,
        };
    });
}

export function productFromProductCatalog(obj: BilberryProductCatalog): Product {
    const coverImage = findMediaCoverImage(obj.media);
    return {
        id: obj.id.toString(),
        type: typeFromProductCatalog(obj),
        title: obj.web_title ?? obj.name,
        shortDescription: obj.web_short_description ?? '',
        longDescription: obj.web_full_description ?? obj.description,
        additionalInfo: obj.web_additional_info ?? null,
        startTimes: obj.web_times ?? null,
        url: obj.web_url,
        coverImage: { src: coverImage?.url, alt: `Image of ${obj.web_title}` },
        images:
            obj.media.gallery?.map((image) => ({
                src: image.url,
                alt: `Image of ${obj.web_title}`,
            })) ?? [],
        icons: obj.icons ?? null,
        duration: obj.duration,
        ticketOptions: obj.default_prices.map((price) => ticketOptionFromPrice(price, null)),
        fromPrice: obj.from_price,
        fromAge: obj.from_age,
        location: locationFromProductCatalogLocation(obj.location),
        difficulty: obj.difficulty,
        departureLocation: locationFromProductCatalogLocation(obj.location),
        capacity: obj.capacity,
        minEntrants: obj.min_entrants,
        terms: obj.web_disclaimer,
        currency: obj.currency,
        pkgId: null,
        pkgTitle: null,
        pkgProductId: null,
        pkgTicketTypeId: null,
        pkgTicketOptionId: null,
        pkgTicketOptionName: null,
        pkgMinutesRelativeStart: null,
        pkgMinutesRelativeEnd: null,
        accommodationAttributes: null,
        cutoffTime: null,
    };
}

export function productFromBilberryTimeslot(obj: BilberryTimeslot): Product {
    const coverImage = findMediaCoverImage(obj.media);
    return {
        id: obj.id.toString(),
        type: 'timeslot',
        title: obj.web_title ?? obj.internal_title,
        shortDescription: obj.web_short_description,
        longDescription: obj.web_full_description ?? obj.internal_description,
        additionalInfo: obj.web_additional_info ?? null,
        startTimes: obj.web_times ?? null,
        url: obj.web_url,
        coverImage: { src: coverImage?.url, alt: `Image of ${obj.web_title}` },
        images:
            obj.media.gallery?.map((image) => ({
                src: image.url,
                alt: `Image of ${obj.web_title}`,
            })) ?? [],
        icons: obj.icons ?? null,
        duration: obj.duration,
        ticketOptions: obj.default_ticket_options.map((opt) => ({
            id: opt.id.toString(),
            defaultId: opt.id.toString(),
            fromAge: opt.age_from,
            name: opt.name,
            occupancy: opt.occupancy || 1,
            guests: 1,
            capacity: Number.MAX_SAFE_INTEGER,
            minEntrants: null,
            price: opt.price,
            toAge: opt.age_to,
            ticketTypes: [],
            ticketCategoryId: opt.id + opt.ticket_category,
            productInstances: [],
            vatAmount: opt.vat_amount,
            vatBreakdown: opt.rates.map((r) => ({
                amount: r.price,
                rate: r.vat,
                vatAmount: r.vat_amount,
            })),
        })),
        fromPrice: obj.from_price,
        fromAge: obj.from_age,
        location: locationFromProductCatalogLocation(obj.location),
        difficulty: obj.difficulty,
        departureLocation: locationFromProductCatalogLocation(obj.location),
        capacity: obj.capacity ?? Number.MAX_SAFE_INTEGER,
        minEntrants: obj.group_size,
        terms: obj.web_disclaimer,
        currency: currencyAtom.subject.value.currency,
        pkgId: null,
        pkgTitle: null,
        pkgProductId: null,
        pkgTicketTypeId: null,
        pkgTicketOptionId: null,
        pkgTicketOptionName: null,
        pkgMinutesRelativeStart: null,
        pkgMinutesRelativeEnd: null,
        accommodationAttributes: null,
        cutoffTime: null,
    };
}

export function productFromBilberryAccommodation(obj: BilberryAccommodation): Product {
    const { t } = localeAtom.subject.value;

    const ticketTypes = obj.prices.map(
        (p): TicketType => ({
            id: p.id.toString(),
            name: p.name,
            price: p.value,
            vatAmount: 0,
            vatBreakdown: [],
        }),
    );

    const ticketOptions: TicketOption[] = [
        {
            id: '0',
            defaultId: '0',
            name: t.adult.plural,
            price: 0,
            vatAmount: 0,
            vatBreakdown: [],
            ticketCategoryId: '0',
            fromAge: null,
            occupancy: 1,
            guests: 1,
            minEntrants: null,
            capacity: configurationAtom.subject.value.personsMax ?? Number.MAX_VALUE,
            toAge: null,
            productInstances: [],
            ticketTypes,
        },
        {
            id: '1',
            defaultId: '1',
            name: t.children.plural,
            price: 0,
            vatAmount: 0,
            vatBreakdown: [],
            ticketCategoryId: '1',
            fromAge: null,
            occupancy: 1,
            guests: 1,
            minEntrants: null,
            capacity: configurationAtom.subject.value.personsMax ?? Number.MAX_VALUE,
            toAge: null,
            productInstances: [],
            ticketTypes,
        },
    ];

    return {
        id: obj.id.toString(),
        type: 'accommodation',
        title: obj.name,
        shortDescription:
            obj.attributes?.descriptions.shortDescriptionHtml?.value ??
            obj.attributes?.descriptions.shortDescription ??
            '',
        longDescription:
            obj.attributes?.descriptions.longDescriptionHtml?.value ??
            obj.attributes?.descriptions.longDescription ??
            '',
        additionalInfo: null,
        startTimes: null,
        url: '',
        coverImage: {
            src:
                obj.attributes?.images.largeImage ??
                obj.attributes?.images.roomImage ??
                obj.attributes?.images.smallImage ??
                '',
            alt: `Image of ${obj.name}`,
        },
        images:
            obj.attributes?.images.galleryImages?.map((image) => ({
                src: image.url,
                alt: `Image of ${obj.name}`,
            })) ?? [],
        icons: null,
        duration: 24 * 60,
        ticketOptions,
        fromPrice: ticketOptions.slice().sort((a, b) => (a.price < b.price ? -1 : 1))[0].price,
        fromAge: 0,
        location: {
            address: obj.attributes?.positions.positionAdress ?? '',
            city: obj.attributes?.positions.positionCity ?? '',
            coordinates:
                obj.attributes?.positions.positionLatitude &&
                obj.attributes?.positions.positionLongitude
                    ? [
                          obj.attributes.positions.positionLatitude,
                          obj.attributes.positions.positionLongitude,
                      ]
                    : null,
        },
        difficulty: null,
        departureLocation: null,
        capacity: 0,
        minEntrants:
            obj.prices
                .slice()
                .sort((a, b) => ((a.minGuests ?? 0) < (b.minGuests ?? 0) ? -1 : 1))
                .pop()?.minGuests ?? null,
        terms: '',
        currency: currencyAtom.subject.value.currency,
        pkgId: null,
        pkgTitle: null,
        pkgProductId: null,
        pkgTicketTypeId: null,
        pkgTicketOptionId: null,
        pkgTicketOptionName: null,
        pkgMinutesRelativeStart: null,
        pkgMinutesRelativeEnd: null,
        accommodationAttributes: obj.attributes ?? null,
        cutoffTime: null,
    };
}

export function productInstanceFromBilberryAccommodation(
    obj: BilberryAccommodation,
    date: TZDate,
    product: Product,
): ProductInstance {
    const productInstance: ProductInstance = {
        id: product.id + '-' + date.unix(),
        productInstanceCollectionId: null,
        productInstanceCollectionMinEntrants: null,
        productCollectionTitle: null,
        productCollectionShowTime: false,
        productCollectionShowTitle: false,
        productInstanceCollectionMaxEntrantsPerBooking: null,
        productInstanceCollectionValidateAll: false,
        collectedIds: [product.id + '-' + date.unix()],
        capacity: obj.availableUnits,
        personsPerUnit: obj.capacity,
        cancellationDeadline: 0,
        cutoffDate: null,
        cutoffTime: null,
        end: date.add(1, 'days'),
        guestListQuestionnaire: [],
        isClosedForBooking: false,
        orderQuestionnaire: [
            {
                id: 1,
                key: 'accommodation_notes',
                mandatory: false,
                type: 'textarea',
                title: localeAtom.subject.value.t.notes_regarding_your_stay,
            },
        ],
        product,
        requiresGuestListQuestionnaire: false,
        requiresOrderQuestionnaire: false,
        start: date,
        minEntrants: product.minEntrants,
        minProductsRequiredToBook: null,
        ticketOptions: product.ticketOptions.map((to) => ({
            ...to,
            quantity: 0,
            capacity: obj.capacity,
            ticketTypes: obj.prices.map((p) => ({
                id: p.id.toString(),
                name: p.name,
                price: p.value,
                vatAmount: 0,
                vatBreakdown: [],
            })),
        })),
        timeslots: [],
        relatedProducts: [],
        extraProducts: [],
        showExtrasAsMinimal: false,
        showExtrasAsList: false,
        showTime: true,
        isExtraProduct: false,
        icons: product.icons,
    };

    (productInstance as any).ticketOptions = product.ticketOptions.map((to) => ({
        ...to,
        productInstances: [productInstance],
    }));

    if (configurationAtom.subject.value.enableGodMode) {
        (productInstance as any).cutoffTime = -60 * 24 * 365;
    }

    return productInstance;
}

export function productInstanceFromBilberryProduct(
    obj: BilberryProduct,
    product: Product,
): ProductInstance {
    const productInstance: ProductInstance = {
        id: obj.id.toString(),
        productInstanceCollectionId: obj.project_collection_id?.toString() ?? null,
        collectedIds: [obj.id.toString()],
        productInstanceCollectionMaxEntrantsPerBooking:
            obj.project_collection_max_entrants_per_booking,
        productInstanceCollectionMinEntrants: obj.project_collection_min_entrants,
        productInstanceCollectionValidateAll: obj.project_collection_require_all_valid === 1,
        productCollectionShowTitle: obj.project_collection_show_title === 1,
        productCollectionShowTime: obj.project_collection_show_time === 1,
        productCollectionTitle: obj.project_collection_web_title,
        capacity: obj.capacity,
        personsPerUnit: obj.capacity_per_room ?? null,
        cancellationDeadline: obj.cancellation_time,
        end: tzdate(obj.end, false),
        guestListQuestionnaire: obj.guest_list_entities.map((entity) => ({
            ...entity,
            mandatory: entity.mandatory === 1,
        })),
        isClosedForBooking: obj.closed === 1,
        orderQuestionnaire: obj.order_entities.map((entity) => ({
            ...entity,
            mandatory: entity.mandatory === 1,
        })),
        product,
        requiresGuestListQuestionnaire: obj.guest_list_dependant === 1,
        requiresOrderQuestionnaire: obj.order_entity_dependant === 1,
        start: tzdate(obj.start, false),
        ticketOptions: [],
        cutoffDate: tzdate(new Date(obj.start).getTime() - obj.cutoff_time * 60 * 1000, false),
        cutoffTime: obj.cutoff_time,
        minEntrants: obj.min_entrants,
        minProductsRequiredToBook: null,
        timeslots: [],
        relatedProducts: obj.associated_products?.map(String) ?? [],
        extraProducts: obj.extra_products?.map(String) ?? [],
        showExtrasAsMinimal: obj.show_extras_as_minimal === 1,
        showExtrasAsList: obj.show_extras_as_list === 1,
        showTime: obj.show_extra_time !== 0,
        isExtraProduct: false,
        icons: obj.icons ?? null,
    };
    (productInstance as any).ticketOptions = obj.prices.map((price) =>
        ticketOptionFromPrice(price, productInstance),
    );

    if (configurationAtom.subject.value.enableGodMode) {
        (productInstance as any).isClosedForBooking = false;
        (productInstance as any).cutoffTime = -60 * 24 * 365;
        (productInstance as any).cutoffDate = TZDate.now().add(1, 'year');
    }

    return productInstance;
}

export function productInstancesFromBilberryPackageAvailbility(
    obj: BilberryPackageAvailability[],
    pkg: Package,
): ProductInstance[] {
    return (
        obj.flatMap((x): ProductInstance => {
            const [firstProduct] = x.products
                .flatMap((p) => p.tours)
                .sort((a, b) => tzdate(a.start, false).unix() - tzdate(b.start, false).unix());
            const start = tzdate(firstProduct.start, false);
            const end = tzdate(firstProduct.end, false);

            const product =
                pkg.products.find((p) => p.id === x.products[0]?.plan_post_id.toString()) ?? null;

            const instance = {
                id: x.products.reduce((acc, p) => acc + ', ' + p.package_product_id, ''),
                product: product,
                icons: [],
                ticketOptions:
                    pkg?.ticketOptions.filter((to) => to.id === x.ticket_option_id.toString()) ??
                    [],
                cutoffDate: start,
                cutoffTime: 0,
                cancellationDeadline: 0,
                personsPerUnit: null,
                capacity: x.available ? Number.MAX_VALUE : 0,
                start,
                end,
                timeslots: [],
                minEntrants: 1,
                collectedIds: [
                    x.products.reduce((acc, p) => acc + ', ' + p.package_product_id, ''),
                ],
                isClosedForBooking: false,
                orderQuestionnaire: [],
                guestListQuestionnaire: [],
                minProductsRequiredToBook: 1,
                requiresOrderQuestionnaire: false,
                productInstanceCollectionId: null,
                productInstanceCollectionMaxEntrantsPerBooking: null,
                productInstanceCollectionMinEntrants: null,
                productInstanceCollectionValidateAll: false,
                productCollectionShowTitle: false,
                productCollectionShowTime: false,
                productCollectionTitle: null,
                requiresGuestListQuestionnaire: false,
                relatedProducts: [],
                extraProducts: [],
                showExtrasAsMinimal: false,
                showExtrasAsList: false,
                isExtraProduct: false,
                showTime: false,
            };
            if (configurationAtom.subject.value.enableGodMode) {
                (instance as any).cutoffTime = -60 * 24 * 365;
                (instance as any).cutoffDate = TZDate.now().add(1, 'year');
            }
            return instance;
        }) ?? []
    );
}

export function allToursAsProductInstanceFromBilberryPackageAvailbility(
    obj: BilberryPackageAvailability[],
    pkg: Package,
    date: TZDate | null,
): ProductInstance[] {
    return (
        obj.flatMap((x): ProductInstance[] => {
            return x.products.flatMap((availabilityProduct) => {
                const product =
                    pkg.products.find(
                        (p) =>
                            availabilityProduct.package_product_id.toString() === p.pkgProductId &&
                            p.id === availabilityProduct.plan_post_id.toString(),
                    ) ?? null;

                // Only map tours that are within the current package product's relative start and end
                const filtered = availabilityProduct.tours.filter((tour) => {
                    return (
                        tzdate(tour.start, false).isAfter(
                            date?.add((product?.pkgMinutesRelativeStart ?? 0) - 1, 'minutes'),
                        ) &&
                        tzdate(tour.start, false).isBefore(
                            date?.add((product?.pkgMinutesRelativeEnd ?? 0) + 1, 'minutes'),
                        )
                    );
                });

                return filtered.map((tour) => {
                    const instance = {
                        id: tour.id.toString(),
                        product: product,
                        icons: [],
                        ticketOptions:
                            pkg?.ticketOptions.filter(
                                (to) => to.id === x.ticket_option_id.toString(),
                            ) ?? [],
                        cutoffDate: tzdate(tour.start, false),
                        cutoffTime: 0,
                        cancellationDeadline: 0,
                        personsPerUnit: null,
                        capacity: tour.estimated_capacity ? Number.MAX_VALUE : 0,
                        start: tzdate(tour.start, false),
                        end: tzdate(tour.end, false),
                        timeslots: [],
                        minEntrants: 1,
                        collectedIds: [],
                        isClosedForBooking: false,
                        orderQuestionnaire: [],
                        guestListQuestionnaire: [],
                        minProductsRequiredToBook: 1,
                        requiresOrderQuestionnaire: false,
                        productInstanceCollectionId: null,
                        productInstanceCollectionMaxEntrantsPerBooking: null,
                        productInstanceCollectionMinEntrants: null,
                        productInstanceCollectionValidateAll: false,
                        productCollectionShowTitle: false,
                        productCollectionShowTime: false,
                        productCollectionTitle: null,
                        requiresGuestListQuestionnaire: false,
                        relatedProducts: tour.associated_products.map(String),
                        extraProducts: tour.extra_products.map(String),
                        showExtrasAsMinimal: false,
                        showExtrasAsList: false,
                        isExtraProduct: false,
                        showTime: false,
                    };

                    if (configurationAtom.subject.value.enableGodMode) {
                        (instance as any).cutoffTime = -60 * 24 * 365;
                        (instance as any).cutoffDate = TZDate.now().add(1, 'year');
                    }
                    return instance;
                });
            });
        }) ?? []
    );
}

export function productInstanceFromBilberryTimeslotsProject(
    product: Product,
    obj: BilberryTimeslotsProject,
): ProductInstance {
    const productInstance: ProductInstance = {
        id: obj.id.toString(),
        productInstanceCollectionId: null,
        productInstanceCollectionMaxEntrantsPerBooking: null,
        productInstanceCollectionMinEntrants: null,
        productInstanceCollectionValidateAll: false,
        productCollectionShowTitle: false,
        productCollectionShowTime: false,
        productCollectionTitle: null,
        collectedIds: [obj.id.toString()],
        personsPerUnit: null,
        capacity: obj.total_estimated_capacity ?? Number.MAX_SAFE_INTEGER,
        cancellationDeadline: 0,
        end: tzdate(obj.end, false),
        guestListQuestionnaire: [],
        isClosedForBooking: false,
        orderQuestionnaire: [],
        product,
        requiresGuestListQuestionnaire: false,
        requiresOrderQuestionnaire: false,
        start: tzdate(obj.start, false),
        ticketOptions: product.ticketOptions,
        cutoffDate: tzdate(new Date(obj.start), false).add(1, 'days').startOf('day'),
        cutoffTime: 0,
        minEntrants: null,
        minProductsRequiredToBook: obj.timeslot_min_booking_quantity ?? undefined,
        timeslots: [],
        relatedProducts: [],
        extraProducts: [],
        showExtrasAsMinimal: false,
        showExtrasAsList: false,
        showTime: true,
        isExtraProduct: false,
        icons: product.icons ?? null,
    };
    (productInstance as any).ticketOptions = product.ticketOptions.map((to) => ({
        ...to,
        productInstances: [productInstance],
    }));

    if (configurationAtom.subject.value.enableGodMode) {
        (productInstance as any).cutoffTime = -60 * 24 * 365;
        (productInstance as any).cutoffDate = TZDate.now().add(1, 'year');
    }
    return productInstance;
}

export function productInstanceFromBilberrySmartEventPlan(
    obj: BilberrySmartEventPlan,
    product: Product,
): ProductInstance {
    const productInstance: ProductInstance = {
        id: obj.tour_id.toString(),
        productInstanceCollectionId: null,
        productInstanceCollectionMaxEntrantsPerBooking: null,
        productInstanceCollectionMinEntrants: null,
        productInstanceCollectionValidateAll: false,
        productCollectionShowTitle: false,
        productCollectionShowTime: false,
        productCollectionTitle: null,
        collectedIds: [obj.tour_id.toString()],
        personsPerUnit: null,
        capacity: obj.capacity,
        cancellationDeadline: 0,
        end: tzdate(obj.end, false),
        guestListQuestionnaire: obj.guest_list_entities.map((entity) => ({
            ...entity,
            mandatory: entity.mandatory === 1,
        })),
        isClosedForBooking: obj.closed === 1,
        orderQuestionnaire: obj.order_entities.map((entity) => ({
            ...entity,
            mandatory: entity.mandatory === 1,
        })),
        product,
        requiresGuestListQuestionnaire: obj.guest_list_dependant === 1,
        requiresOrderQuestionnaire: obj.order_entity_dependant === 1,
        start: tzdate(obj.start, false),
        ticketOptions: [],
        cutoffDate: tzdate(obj.start, false),
        cutoffTime: 0,
        minEntrants: obj.min_entrants,
        minProductsRequiredToBook: null,
        timeslots: [],
        relatedProducts: [],
        extraProducts: obj.extra_products?.map(String) ?? [],
        showExtrasAsMinimal: false,
        showExtrasAsList: false,
        isExtraProduct: false,
        showTime: true,
        icons: obj.icons ?? null,
    };
    (productInstance as any).ticketOptions = obj.prices.map((price) =>
        ticketOptionFromPrice(price, productInstance),
    );
    if (configurationAtom.subject.value.enableGodMode) {
        (productInstance as any).isClosedForBooking = false;
        (productInstance as any).cutoffTime = -60 * 24 * 365;
        (productInstance as any).cutoffDate = TZDate.now().add(1, 'year');
    }

    return productInstance;
}

export function groupProductInstancesByResourceCollectionId(
    productInstances: ProductInstance[],
): ProductInstance[] {
    // Group instances that belong to the same tour series by their collection id and start time.
    // If the product type is "days" or "nights", group by start time even if there is no collection id.
    const groupedProductInstances = groupBy(productInstances, (pi) => {
        const isNightsOrDaysProduct = ['days', 'nights'].includes(pi.product?.type as any);
        return pi.start.unix() + (isNightsOrDaysProduct ? '' : pi.productInstanceCollectionId!);
    });

    const val = Object.values(groupedProductInstances).flatMap((group) => {
        const [firstProductInstance] = group;
        const isNightsOrDaysProduct = ['days', 'nights'].includes(
            firstProductInstance.product?.type as any,
        );
        if (firstProductInstance.productInstanceCollectionId === null && !isNightsOrDaysProduct)
            return group;
        return {
            ...firstProductInstance,
            collectedIds: Array.from(new Set(group.flatMap((pi) => pi.collectedIds))),
            ticketOptions: group.flatMap((pi) => pi.ticketOptions),
        };
    });
    return val;
}

export function timeslotFromBilberryTimeslot(
    productInstance: ProductInstance,
    timeslot: BilberryTimeslot,
): Timeslot {
    const mapped = {
        id: timeslot.id.toString(),
        minNumberOfTimeslotsRequiredToBook: timeslot.timeslot_min_booking_quantity,
        capacity: timeslot.capacity ?? Number.MAX_SAFE_INTEGER,
        closed: timeslot.closed === 1,
        cutoffTime: timeslot.cutoff_time,
        duration: timeslot.duration,
        start: tzdate(timeslot.start, false),
        end: tzdate(timeslot.end, false),
        productInstance,
        extraProducts: timeslot.extras?.map(String) ?? [],
        relatedProducts: timeslot.associated_products?.map(String) ?? [],
        showExtrasAsList: timeslot.show_extras_as_list === 1,
        showExtrasAsMinimal: timeslot.show_extras_as_minimal === 1,
    };

    if (configurationAtom.subject.value.enableGodMode) {
        (mapped as any).closed = false;
        (mapped as any).cutoffTime = -60 * 24 * 365;
    }
    return mapped;
}

export function cartHasTimeslotProducts(cart: CartItem[]) {
    return cart.some((item) => item.products[0]?.product?.type === 'timeslot');
}

export function bilberryReservationFromCart(
    cart: CartItem[],
    checkoutInfo: CheckoutInfo,
    rebookFromInvoiceIds: number[],
    giftcardIds: string[],
    promoCode?: string,
) {
    const config = configurationAtom.subject.value;
    const siteUrl = window.location.toString();

    const tours = getToursFromCart(cart, checkoutInfo);
    const packages = getPackagesFromCart(cart, checkoutInfo);
    const accommodations = getAccommodationsFromCart(cart, checkoutInfo);

    const reservation: BilberryReservation = {
        phone: `+${checkoutInfo.contactPerson.phone.dialCode}${checkoutInfo.contactPerson.phone.number}`,
        email: checkoutInfo.contactPerson.email,
        country: countryOptionsEn[checkoutInfo.contactPerson.country as Iso2Code],
        address1: checkoutInfo.contactPerson.address,
        zip: checkoutInfo.contactPerson.postCode,
        city: checkoutInfo.contactPerson.city,
        tours,
        packages,
        extras: [],
        external_data: [],
        url: siteUrl,
        termsUrl: config.termsUrl,
        giftcards: giftcardIds,
        coupon_code: promoCode,
        accommodationReservation: accommodations,
        accept_newsletter: checkoutInfo.contactPerson.receiveNewsletter,
        rebook_from_invoice_ids: rebookFromInvoiceIds,
    };

    if (checkoutInfo.contactType === 'person') {
        (
            reservation as BilberryReservationPerson
        ).name = `${checkoutInfo.contactPerson.firstName} ${checkoutInfo.contactPerson.lastName}`;
    } else if (checkoutInfo.contactType === 'company') {
        (reservation as BilberryReservationCompany).companyName =
            checkoutInfo.companyInfo.companyName;
        (reservation as BilberryReservationCompany).contactPerson =
            checkoutInfo.companyInfo.contactPerson;
        (reservation as BilberryReservationCompany).orgNumber = checkoutInfo.companyInfo.orgNumber;
        (reservation as BilberryReservationCompany).email = checkoutInfo.companyInfo.email;
        (
            reservation as BilberryReservationCompany
        ).phone = `+${checkoutInfo.companyInfo.phone.dialCode}${checkoutInfo.companyInfo.phone.number}`;
        (reservation as BilberryReservationCompany).address1 = checkoutInfo.companyInfo.address;
        (reservation as BilberryReservationCompany).zip = checkoutInfo.companyInfo.postCode;
        (reservation as BilberryReservationCompany).city = checkoutInfo.companyInfo.city;
        (reservation as BilberryReservationCompany).country =
            countryOptionsEn[checkoutInfo.companyInfo.country as Iso2Code];
        (reservation as BilberryReservationCompany).accept_newsletter =
            checkoutInfo.companyInfo.receiveNewsletter;
    }

    return reservation;
}

export function getConsumerFromCheckoutInfo(checkoutInfo: CheckoutInfo): MembershipConsumer {
    return {
        addressLine1: checkoutInfo.contactPerson.address,
        addressLine2: '',
        city: checkoutInfo.contactPerson.city,
        country: countryOptionsEn[checkoutInfo.contactPerson.country ?? 'no'],
        email: checkoutInfo.contactPerson.email,
        firstName: checkoutInfo.contactPerson.firstName,
        lastName: checkoutInfo.contactPerson.lastName,
        phoneNumber: checkoutInfo.contactPerson.phone.number,
        phonePrefix: checkoutInfo.contactPerson.phone.dialCode,
        postalCode: checkoutInfo.contactPerson.postCode,
        receiveNewsletter: checkoutInfo.contactPerson.receiveNewsletter ?? false,
    };
}

export function getTimeslotsFromCart(
    cart: CartItem[],
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
): {
    reservations: {
        productId: number;
        timeslotIds: number[];
        tickets: MembershipTicket[];
    }[];
    checkoutUrl: string;
    priceSummary: PriceSummary;
} | null {
    const applicableCartItems = cart.filter(
        (item) => !item.pkg && item.products[0].product?.type === 'timeslot',
    );

    if (applicableCartItems.length === 0) return null;

    const priceSummary = getPriceSummaryFromCartItems(applicableCartItems);
    return {
        checkoutUrl: window.location.origin + window.location.pathname,
        priceSummary,
        reservations: applicableCartItems.map((item) => ({
            productId: parseInt(item.products[0].product!.id),
            timeslotIds: item.products[0].timeslots.map((t) => parseInt(t.id)),
            tickets: item.ticketOptions
                .filter((to) => to.quantity > 0)
                .flatMap((to) => to.membership?.map((m) => m.ticket))
                .filter((t): t is MembershipTicket => !!t)
                .map((t) => ({
                    ...t,
                    ignoredValueCardIds: valueCardIdsToIgnore,
                    ignoredValueCardProductIds: valueCardProductIdsToIgnore,
                    ignoredValueCardProductTypeIds: valueCardProductTypeIdsToIgnore,
                })),
        })),
    };
}

export function getMembershipActivityReservationsFromCart(
    cart: CartItem[],
    checkoutInfo: CheckoutInfo,
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
): MembershipMultiReservationTours[] {
    const config = configurationAtom.subject.value;

    // One activity reservation per cart item
    return cart
        .filter((item) => item.products[0].product?.type !== 'timeslot')
        .map((item) => {
            const tours = getToursFromCart([item], checkoutInfo)
                .map((tour) => ({
                    ...tour,
                    pax: tour.pax
                        .map((pax) => ({
                            ...pax,
                            ignoredValueCardIds: valueCardIdsToIgnore,
                            ignoredValueCardProductIds: valueCardProductIdsToIgnore,
                            ignoredValueCardProductTypeIds: valueCardProductTypeIdsToIgnore,
                        }))
                        .filter((pax) => pax.quantity > 0),
                }))
                .filter((tour) => tour.pax.length > 0 && tour.pax.some((pax) => pax.quantity > 0));

            // TODO: Add support for packages and accommodations
            const packages = getPackagesFromCart([item], checkoutInfo); // Packages
            const accommodations = getAccommodationsFromCart([item], checkoutInfo); // Visbook

            return {
                tours,
                external_data: [],
                packages,
                termsUrl: config.termsUrl,
            };
        })
        .filter((item) => item.tours.length > 0 || item.packages.length > 0);
}

function getPackagesFromCart(
    cart: CartItem[],
    checkoutInfo: CheckoutInfo,
): BilberryPackageReservation[] {
    const applicableCartItems = cart.filter((item) => !!item.pkg);
    const packageReservations = applicableCartItems.flatMap((item) => {
        const productsByTicketOptionId = Object.entries(
            groupBy(item.products, (p) => p.product?.pkgTicketOptionId),
        );

        return productsByTicketOptionId.flatMap(([ticketOptionId, products]) => {
            const priceCategoryId =
                products[0].ticketOptions.find(({ id }) => id === ticketOptionId)
                    ?.ticketCategoryId ?? -1;

            const { pkgId } = products[0].product!;

            return {
                package_id: parseInt(pkgId!),
                ticket_option_id: parseInt(ticketOptionId),
                price_category_id: priceCategoryId as unknown as number,
                quantity: item.ticketOptions.find((option) => option.id === ticketOptionId)
                    ?.quantity,
                products: products.map((product) => {
                    return {
                        plan_post_id: parseInt(product.product!.id),
                        tour_id: parseInt(product.id),
                        package_product_id: parseInt(product.product!.pkgProductId!),
                        price_category_id: parseInt(product.product!.pkgTicketTypeId!),
                        quantity: 1,
                        guest_list: bilberryGuestListFromCheckoutInfo(
                            checkoutInfo,
                            getCartItemId(item),
                        ),
                        order_entities:
                            bilberryReservationQuestionnaireAnswersFromCheckoutQuestionnaire(
                                checkoutInfo.orderQuestionnaire[getCartItemId(item)],
                            ),
                    };
                }),
            } as BilberryPackageReservation;
        });
    });

    return packageReservations;
}

function getToursFromCart(cart: CartItem[], checkoutInfo: CheckoutInfo): BilberryReservationTour[] {
    const applicableProductTypes = ['timepoint', 'days', 'nights'];
    const applicableCartItems = cart.filter(
        (item) =>
            !item.pkg && applicableProductTypes.includes(item.products[0].product?.type ?? ''),
    );

    const tours = applicableCartItems.flatMap((item) => {
        // For nights, remove the last selected item, as the reservation should be to that date, but not including it.
        const applicableProducts =
            item.products[0].product?.type === 'nights'
                ? item.products.slice(0, item.products.length - 1)
                : item.products;

        const expandedApplicableProducts = applicableProducts.flatMap((product) =>
            product.collectedIds.map((id) => ({
                ...product,
                id: id,
                ticketOptions: product.ticketOptions.filter((to) =>
                    to.productInstances.some((pi) => pi.id === id),
                ),
            })),
        );

        return expandedApplicableProducts.map(
            (product) =>
                ({
                    id: product.id,
                    productCatalogId: parseInt(product.product!.id),
                    pax: item.ticketOptions
                        .filter((to) =>
                            product.ticketOptions.some(
                                (pto) => pto.ticketCategoryId === to.ticketCategoryId,
                            ),
                        )
                        .map((to) => bilberryPaxFromTicketOptionWithQuantity(to, product)),
                    guest_list: bilberryGuestListFromCheckoutInfo(
                        checkoutInfo,
                        getCartItemId(item),
                    ),
                    order_entities:
                        bilberryReservationQuestionnaireAnswersFromCheckoutQuestionnaire(
                            checkoutInfo.orderQuestionnaire[getCartItemId(item)],
                        ),
                } as BilberryReservationTour),
        );
    });

    return tours;
}

function getAccommodationsFromCart(
    cart: CartItem[],
    checkoutInfo: CheckoutInfo,
): BilberryAccommodationReservationRequest | undefined {
    const applicableCartItems = cart.filter(
        (item) => !item.pkg && item.products[0].product?.type === 'accommodation',
    );
    if (applicableCartItems.length === 0) return undefined;

    return {
        from: applicableCartItems[0].products[0].start.getDayjsDate().toDate(),
        to: applicableCartItems[0].products[applicableCartItems[0].products.length - 1].start
            .getDayjsDate()
            .toDate(),
        accommodations: applicableCartItems.map((item) => ({
            accommodationId: item.products[0].id.split('-')[0],
            guests: sumBy(item.ticketOptions, 'quantity'),
            price: {
                id: item.ticketType!.id as unknown as number,
            },
        })),
        externalReference: [],
        notes: applicableCartItems
            .map(
                (item) =>
                    checkoutInfo.orderQuestionnaire[getCartItemId(item)]?.[1]?.answer as string,
            )
            .filter(Boolean),
        guestDetails: {
            firstName:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.firstName
                    : checkoutInfo.companyInfo.contactPerson,
            lastName:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.lastName
                    : checkoutInfo.companyInfo.contactPerson,
            email:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.email
                    : checkoutInfo.companyInfo.email,
            address:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.address
                    : checkoutInfo.companyInfo.address,
            city:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.city
                    : checkoutInfo.companyInfo.city,
            country:
                checkoutInfo.contactType === 'person'
                    ? countryOptionsEn[checkoutInfo.contactPerson.country as Iso2Code]
                    : countryOptionsEn[checkoutInfo.companyInfo.country as Iso2Code],
            phoneNumber:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.phone.number
                    : checkoutInfo.companyInfo.phone.number,
            phoneNumberPrefix:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.phone.dialCode
                    : checkoutInfo.companyInfo.phone.dialCode,
            zipCode:
                checkoutInfo.contactType === 'person'
                    ? checkoutInfo.contactPerson.postCode
                    : checkoutInfo.companyInfo.postCode,
        },
    };
}

function bilberryReservationQuestionnaireAnswersFromCheckoutQuestionnaire(
    questionnaire: CheckoutQuestionnaire,
): BilberryReservationQuestionnaireAnswers {
    return Object.entries(questionnaire).reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key]: value.answer,
        };
    }, {} as BilberryReservationQuestionnaireAnswers);
}

function bilberryGuestListFromCheckoutInfo(
    checkoutInfo: CheckoutInfo,
    cartItemKey: string,
): BilberryReservationGuestList[] {
    return Object.values(checkoutInfo.guestListQuestionnaire[cartItemKey])
        .filter((questionnaire) => Object.entries(questionnaire).length > 0)
        .map((questionnaire) =>
            bilberryReservationQuestionnaireAnswersFromCheckoutQuestionnaire(questionnaire),
        );
}

function bilberryPaxFromTicketOptionWithQuantity(
    ticketOption: TicketOptionWithQuantity,
    productInstance: ProductInstance,
): BilberryReservationTour['pax'][0] {
    const matchingTicketOption = productInstance.ticketOptions.find(
        (to) => to.ticketCategoryId === ticketOption.ticketCategoryId,
    );
    if (!matchingTicketOption) {
        errorLog(
            'No ticket option matching selected quantity found when creating reservation. Ticket type id: ',
            ticketOption.ticketCategoryId,
        );
    }
    return {
        id: matchingTicketOption!.id ?? -1,
        quantity: ticketOption.quantity,
    };
}

function ticketOptionFromPrice(
    price: BilberryProductPrice,
    productInstance: ProductInstance | null,
): TicketOption {
    return {
        id: price.id.toString(),
        defaultId:
            productInstance?.product?.ticketOptions.find(
                (to) => to.ticketCategoryId === price.price_category_id.toString(),
            )?.id ?? price.id.toString(),
        ticketCategoryId: price.price_category_id.toString(),
        price: price.price,
        vatAmount: price.vat_amount,
        vatBreakdown: price.rates.map((rate) => ({
            rate: rate.vat,
            amount: rate.price,
            vatAmount: rate.vat_amount,
        })),
        name: price.name,
        occupancy: price.occupancy,
        guests: price.guests || 1,
        capacity: price.capacity ?? productInstance?.capacity ?? Number.MAX_VALUE,
        minEntrants: price.min_entrants ?? null,
        fromAge: price.age_from,
        toAge: price.age_to,
        productInstances: productInstance ? [productInstance] : [],
        ticketTypes: [],
    };
}

function ticketOptionsFromBilberryPackage(
    obj: BilberryPackage,
    packageAvailability: BilberryPackageAvailability[],
    pkg: Package,
): PackageTicketOptionWithQuantity[] {
    if (!obj.ticket_options) return [];
    const ticketOptions: PackageTicketOptionWithQuantity[] = obj.ticket_options.map((x) => {
        const availability = packageAvailability.find(
            (v) => v.ticket_option_id === x.ticket_option_id,
        );
        return {
            id: x.ticket_option_id.toString(),
            defaultId:
                obj
                    .ticket_options!.find((to) => to.price_category_id === x.price_category_id)
                    ?.ticket_option_id.toString() ?? x.ticket_option_id.toString(),
            name: x.name,
            price: x.price,
            vatAmount: availability?.vats.reduce((acc, cur) => acc + cur.vat_amount, 0) ?? 0,
            vatBreakdown:
                availability?.vats.map((v) => ({
                    amount: v.price,
                    rate: v.vat_percent,
                    vatAmount: v.vat_amount,
                })) ?? [],
            occupancy: 1,
            guests: 1,
            capacity: availability
                ? availability.available
                    ? Number.MAX_VALUE
                    : 0
                : Number.MAX_VALUE,
            minEntrants: null,
            fromAge: null,
            toAge: null,
            ticketCategoryId: x.price_category_id.toString(),
            products: x.products.map((product) => product.product_id.toString()),
            productInstances: [],
            quantity: 0,
            ticketTypes: [],
        };
    });
    ticketOptions.forEach((to) => {
        (to as any).productInstances = productInstancesFromBilberryPackageAvailbility(
            packageAvailability,
            { ...pkg, ticketOptions: ticketOptions },
        ).filter((pi) => pi.ticketOptions.some((ptTo) => to.id === ptTo.id));
    });
    return ticketOptions;
}

function locationFromProductCatalogLocation(
    location: BilberryProductCatalog['location'],
): Location | null {
    return location
        ? {
              address: location.address,
              city: location.city,
              coordinates: location.geometry?.coordinates ?? null,
          }
        : null;
}

function typeFromProductCatalog(obj: BilberryProductCatalog): Product['type'] {
    if (obj.is_rental) return 'days';
    else if (obj.is_accommodation) return 'nights';
    else return 'timepoint';
}

function findMediaCoverImage(media?: BilberryProductMedia | null) {
    if (!media?.image) return null;

    const coverImage = media.image;

    //If we have a specified cover image with a url, use that.
    if (coverImage && coverImage.url) return coverImage;

    // fallback to the first gallery image if there's no cover image specified
    return media.gallery && media.gallery.length > 0 ? media.gallery[0] : null;
}
