import {
    ProductCardButton,
    ProductCardInfoContainer,
    ProductCardInfoContent,
} from 'src/components/domain/product-card-2/ProductCardInfo';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { ProductCard2, ProductCard2Props } from './ProductCard2';
import { Product, UserTextCustomizations } from '@repo/types';
import { Icons } from './ProductCardIcons';

type ActivityProductCardProps = ProductCard2Props & {
    product?: Product;
};

export default function ActivityProductCard(props: ActivityProductCardProps): JSX.Element {
    return (
        <ProductCard2
            {...props}
            image={{ url: props.product?.coverImage.src, alt: props.product?.coverImage.alt }}
            body={
                <ActivityProductCardInfo
                    product={props.product}
                    url={props.url}
                    buttonCustomizationKey={props.buttonCustomizationKey}
                />
            }
        />
    );
}

type ActivityProductCardInfoContentProps = {
    product?: Product | null;
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    customPriceLabel?: string | JSX.Element;
    hidePriceIcon?: boolean;
    readMoreProductId?: string;
    readMoreLinkVariant?: 'href' | 'popup' | 'none';
    hideFromLabel?: boolean;
    hideTitle?: boolean;
    hideDescription?: boolean;
};

export function ActivityProductCardInfoContent(props: ActivityProductCardInfoContentProps) {
    const { product } = props;

    return (
        <ProductCardInfoContent
            {...props}
            title={product?.title}
            fromPrice={product?.fromPrice}
            description={product?.shortDescription}
            icons={<Icons product={product} hidePriceIcon={props.hidePriceIcon} />}
        />
    );
}

export function ActivityProductCardInfo(props: ActivityProductCardInfoContentProps): JSX.Element {
    const { product, url } = props;

    return (
        <ProductCardInfoContainer>
            <ActivityProductCardInfoContent {...props} />
            <ProductCardButton
                url={url}
                buttonCustomizationKey={props.buttonCustomizationKey}
                onClick={() =>
                    product &&
                    dispatchWidgetEvent({
                        eventType: 'viewItem',
                        productType: 'timepoint',
                        product: product,
                    })
                }
            />
        </ProductCardInfoContainer>
    );
}
