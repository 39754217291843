import { createRoot } from 'react-dom/client';
import { getIdAttribute, getYesAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageBooking from './PackageBooking';

class PackageBookingWidget extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const packageId = getIdAttribute(this, false, 'package-id').toString();
        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');
        const positionOffscreen = getYesAttribute(this, 'position-offscreen');

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageBooking
                    packageId={packageId}
                    expandArrowInside={expandArrowInside}
                    positionOffscreen={positionOffscreen}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-package-booking', PackageBookingWidget);
}
