import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';

export type CheckoutStep =
    | 'Package'
    | 'Extras'
    | 'Contact Info'
    | 'Payment'
    | 'Success'
    | 'Cancel'
    | 'Intent View';

/**
 * Get correct step number based on the current CheckoutStep name,
 *
 * Not the cleanest solution, since some steps will be skipped
 * certain cases (activities vs timeslots), but maybe good enough?
 * If we ever combine the checkouts, we will need something like this,
 * since then all these steps can potentially be combined.
 *
 * @param checkoutStep step option
 */
export const getStep = (checkoutStep: CheckoutStep): number => {
    const contactInfoIndex = configurationAtom.subject.value.enableExtras ? 2 : 1;
    switch (checkoutStep) {
        case 'Package':
            return 0;
        case 'Extras':
            return 1;
        case 'Contact Info':
            return contactInfoIndex;
        case 'Intent View':
            return contactInfoIndex + 1;
        case 'Payment':
            return contactInfoIndex + 2;
        case 'Success':
            return contactInfoIndex + 3;
        case 'Cancel':
            return contactInfoIndex + 4;
        default:
            return 0;
    }
};
