import { Purchase } from '@repo/types';
import { LocalStorageKey } from '../localstorage';
import { deserialize, serialize } from '@repo/common-utils/serialize';

export function storePurchase(purchase: Purchase | null) {
    localStorage.setItem(LocalStorageKey.BILBERRY_PURCHASE, serialize(purchase));
}

export function loadPurchase<T = Purchase>() {
    const serialized = localStorage.getItem(LocalStorageKey.BILBERRY_PURCHASE);
    if (serialized) {
        return deserialize(serialized) as T;
    } else {
        return null;
    }
}
