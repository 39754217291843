import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { RefObject } from 'react';
import Modal from 'src/components/common/modal/Modal';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { useProduct } from '@repo/widget-utils/api/api';
import { Icons } from './ProductCardIcons';

type ProductCardReadMoreDialogProps = {
    display: boolean;
    setDisplay: (val: boolean) => void;
    productId: string;
    focusRef: RefObject<HTMLAnchorElement>;
};

export function ProductCardReadMoreDialog(props: ProductCardReadMoreDialogProps) {
    const theme = useTheme();
    const Content = () => {
        const { data: product } = useProduct(props.productId);
        return (
            <Paper elevation={0}>
                <img
                    src={product?.coverImage.src}
                    alt={product?.coverImage.alt}
                    style={{
                        height: '22vh',
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: theme.shape.borderRadius,
                    }}
                />
                <Box sx={{ py: theme.spacing(4) }}>
                    <Icons product={product} containerJustify="flex-start" />
                    <Typography variant="h4" component="h3" sx={{ my: 2 }}>
                        {product?.title ?? ''}
                    </Typography>
                    {parseHtmlToMui(product?.longDescription ?? '')}
                </Box>
            </Paper>
        );
    };

    return (
        <Modal
            refocusElementOnClose={props.focusRef}
            open={props.display}
            onClose={() => props.setDisplay(false)}
            large
            id={props.productId.toString() ?? 'None'}
        >
            {props.display ? <Content /> : <CircularProgress />}
        </Modal>
    );
}
