import Person from '@mui/icons-material/Person';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { capitalize } from 'lodash-es';
import { Fragment } from 'react';
import { useLocale } from '@repo/i18n';
import { ContactPerson, CartItem, CheckoutInfo } from '@repo/types';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { getCartItemDisplayTitle } from '@repo/widget-utils/display-helper';
import Questionnaire from './Questionnaire';

type Props = {
    checkoutInfo: CheckoutInfo;
    cartItem: CartItem;
    showErrors: boolean;
    onChange(checkoutInfo: CheckoutInfo): void;
    contactPerson: ContactPerson | null;
};

export default function CheckoutInfoQuestionnaires(props: Props): JSX.Element {
    const { t } = useLocale();
    const { onChange, checkoutInfo, cartItem } = props;
    const numberOfTravelers = cartItem.ticketOptions.reduce((acc, cur) => acc + cur.quantity, 0);

    const cartItemId = getCartItemId(cartItem);
    const guestListQuestionnaireEntry = Object.entries(
        checkoutInfo.guestListQuestionnaire[cartItemId] ?? {},
    );
    const activityQuestionEntries = Object.entries(
        checkoutInfo.orderQuestionnaire[cartItemId] ?? {},
    );

    // TODO: Fix the check of whether we should show the header
    return (
        <Fragment>
            {(guestListQuestionnaireEntry.some(
                ([, questionnaire]) => Object.entries(questionnaire).length > 0,
            ) ||
                activityQuestionEntries.length > 0) && (
                <Header
                    title={getCartItemDisplayTitle(cartItem)}
                    numberOfTravelers={numberOfTravelers}
                />
            )}

            {guestListQuestionnaireEntry.length > 0 &&
                guestListQuestionnaireEntry.map(([key, questionnaire], i) => (
                    <Questionnaire
                        key={key}
                        title={`${capitalize(t.traveler)} ${i + 1}`}
                        index={i}
                        showErrors={props.showErrors}
                        questionnaire={questionnaire}
                        onChange={(newQuestionnaire) =>
                            onChange({
                                ...checkoutInfo,
                                guestListQuestionnaire: {
                                    ...checkoutInfo.guestListQuestionnaire,
                                    [cartItemId]: {
                                        ...checkoutInfo.guestListQuestionnaire[cartItemId],
                                        [key]: newQuestionnaire,
                                    },
                                },
                            })
                        }
                        contactPerson={props.contactPerson}
                        copyFromContactPerson={checkoutInfo.copyFromContactPerson}
                        onChangeCopyFromContactPerson={(value) =>
                            onChange({ ...checkoutInfo, copyFromContactPerson: value })
                        }
                    />
                ))}

            {activityQuestionEntries.length > 0 && (
                <Questionnaire
                    title={capitalize(t.additional_information)}
                    index={0}
                    showErrors={props.showErrors}
                    questionnaire={checkoutInfo.orderQuestionnaire[cartItemId]}
                    onChange={(newQuestionnaire) =>
                        onChange({
                            ...checkoutInfo,
                            orderQuestionnaire: {
                                ...checkoutInfo.orderQuestionnaire,
                                [cartItemId]: newQuestionnaire,
                            },
                        })
                    }
                />
            )}
        </Fragment>
    );
}

type HeaderProps = {
    title: string;
    numberOfTravelers: number;
};

function Header(props: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                backgroundColor: theme.palette.grey[50],
                marginTop: theme.spacing(3),
                marginBottom: 0,
            }}
            variant="outlined"
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={9}>
                    <Typography color="textSecondary" variant="h6">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end" alignItems="center">
                    <Typography color="textSecondary" variant="h6" mr={1}>
                        {props.numberOfTravelers}x
                    </Typography>
                    <Person sx={{ fontSize: '1.6rem' }} color="secondary" />
                </Grid>
            </Grid>
        </Paper>
    );
}
