import { TZDate } from '@repo/tzdate';

export function getSessions<T extends { id: string; start: TZDate; end: TZDate }>(
    timeslots: T[],
    timeslotsInSession: number,
) {
    const sessions = timeslots.reduce(
        (acc, _, i) => [...acc, timeslots.slice(i, i + timeslotsInSession)],
        [] as T[][],
    );
    const continuousSessions = sessions.filter((session) => {
        if (session.length === 1) return true;
        else if (session.length > 0) {
            for (let i = 0; i < session.length - 1; i++) {
                if (session[i + 1].start.isSame(session[i].end)) return true;
            }
            return false;
        }
        return false;
    });
    return continuousSessions.filter((x) => x.length >= timeslotsInSession);
}
