import { Currency } from '@repo/types';

function objToString(obj: Intl.NumberFormatOptions) {
    return Object.entries(obj)
        .filter(([, v]) => typeof v !== 'object' || v instanceof Date)
        .map(([k, v]) => k + v)
        .join('-');
}

const numberFormatters = new Map<string, Intl.NumberFormat>();

function getNumberFormatter(locale: string, options?: Intl.NumberFormatOptions): Intl.NumberFormat {
    const key = locale + objToString(options ?? {});

    if (numberFormatters.has(key)) return numberFormatters.get(key)!;

    numberFormatters.set(key, new Intl.NumberFormat(locale, options));
    return numberFormatters.get(key)!;
}

export function getLocaleNumberFormatNoDecimals(locale: string, num: number) {
    if (num % 1 === 0) return getNumberFormatter(locale).format(num);
    else return getLocaleNumberFormatTwoDecimals(locale, num);
}

export function getLocaleNumberFormatTwoDecimals(locale: string, num: number) {
    return getNumberFormatter(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'decimal',
    }).format(num);
}

export function formatCurrency(
    locale: string,
    currency: Currency,
    num: number,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
) {
    const formattedCurrency = getNumberFormatter(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
        style: 'currency',
        ...currency,
    }).format(num);

    if (currency.currencyDisplay === 'code' || currency.currencyDisplay === 'name') {
        return formattedCurrency.replace('\xA0', ' ');
    }

    return formattedCurrency;
}

export function formatCurrencyNoDecimalsIfPossible(
    locale: string,
    currency: Currency,
    num: number,
) {
    if (num % 1 === 0) return formatCurrency(locale, currency, num, 0, 2);
    else return formatCurrency(locale, currency, num);
}
