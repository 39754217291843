import { Box } from '@mui/material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
    Appearance,
    PaymentIntent,
    Stripe,
    StripeElementsOptions,
    StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocale } from '@repo/i18n';
import { stripePaymentAtom, useStripePayment } from 'src/state/payment/stripePayment.atom';
declare global {
    namespace React.JSX {
        interface IntrinsicElements {
            'bilberry-stripe-pay-button': any;
            'bilberry-stripe-alert': any;
        }
    }
}

export default function StripePayment() {
    const { stripeKey, stripeClientSecret } = useStripePayment();
    const { locale } = useLocale();

    const stripeLocale = locale.slice(0, 2) as 'en' | 'nb';

    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | undefined>(
        undefined,
    );
    const [isLoadingStripe, setIsLoadingStripe] = useState(false);

    useEffect(() => {
        if (!stripeKey || isLoadingStripe) return;
        setIsLoadingStripe(true);
        const stripe = loadStripe(stripeKey, { locale: stripeLocale });
        setStripePromise(stripe);
    }, [isLoadingStripe, stripeKey, stripeLocale]);

    const appearance: Appearance = {
        theme: 'stripe',
    };

    const elementsOptions: StripeElementsOptions = {
        clientSecret: stripeClientSecret ?? '',
        appearance,
    };

    return (
        <>
            {stripeClientSecret && stripePromise && (
                <Elements options={elementsOptions} stripe={stripePromise}>
                    <StripeForm />
                </Elements>
            )}
        </>
    );
}

function StripeForm() {
    const stripe = useStripe();
    const elements = useElements();

    const { t } = useLocale();

    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(undefined);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const payButtonRef = useRef<any>(null);

    useEffect(() => {
        if (!stripe || !paymentIntent) return;
        stripePaymentAtom.update({
            ...stripePaymentAtom.subject.value,
            paymentStatus: paymentIntent.status,
        });
    }, [stripe, paymentIntent]);

    const handleSubmit = useCallback(
        async (e: any) => {
            e.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsLoading(true);

            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                redirect: 'if_required',
            });

            if (error && error.type === 'card_error') {
                setShowError(true);
                setIsLoading(false);
                setErrorMessage(error.message ?? t.anErrorOccurredDuringPayment);
                return;
            }

            setPaymentIntent(paymentIntent);
            setIsLoading(false);
        },
        [stripe, elements, t.anErrorOccurredDuringPayment],
    );

    useEffect(() => {
        if (payButtonRef.current) {
            payButtonRef.current.handleSubmit = handleSubmit;
        }
    }, [handleSubmit]);

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: 'tabs',
    };

    return (
        <form className="bilberry-stripe-payment" id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
                className="bilberry-stripe-payment"
                id="payment-element"
                options={paymentElementOptions}
            />
            {showError && (
                <bilberry-stripe-alert errormessage={errorMessage}></bilberry-stripe-alert>
            )}
            <Box
                className="bilberry-stripe-payment"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {stripe && elements && (
                    <bilberry-stripe-pay-button
                        ref={payButtonRef}
                        isloading={isLoading}
                    ></bilberry-stripe-pay-button>
                )}
            </Box>
        </form>
    );
}
