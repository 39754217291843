import { Button, Grid, Link, List, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { route } from 'src/components/common/router/Router';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function Receipt(props: { bookingRef: string | null }): JSX.Element {
    const { t } = useLocale();
    const { loggedInUser } = useMemberContext();
    const theme = useTheme();
    const linkUrl = '/';

    return (
        <List
            sx={{
                marginTop: theme.spacing(2),
                color: theme.palette.secondary.main,
                paddingTop: 0,
                minWidth: '300px',
                width: '100%',
                maxWidth: '440px',
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
        >
            <Paper
                sx={{
                    fontWeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    marginBottom: theme.spacing(2),
                    '& > *': {
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        fontWeight: 300,
                    },
                }}
                variant="outlined"
            >
                <Grid container alignItems="center">
                    {props.bookingRef && (
                        <Fragment>
                            <Typography>{capitalize(t.your_booking_reference_is)}&nbsp;</Typography>
                            <Typography variant="h5">{props.bookingRef}</Typography>
                        </Fragment>
                    )}

                    <Typography>{capitalize(t.booking_details_in_email)}.</Typography>
                </Grid>
            </Paper>
            <Stack direction="row" spacing={1}>
                <Link href={linkUrl} className="no-print">
                    <Button variant="outlined" color="primary">
                        {t.see_more_activities}
                    </Button>
                </Link>
                {loggedInUser && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => route('/my-profile')}
                    >
                        {t.goToMyPage}
                    </Button>
                )}
            </Stack>
        </List>
    );
}
