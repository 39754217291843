import { Fragment } from 'react';
import { isGiftcardPaymentInfo } from '@repo/widget-utils/payment-types-helper';
import NetsPaymentWrapper from './NetsPaymentWrapper';
import { GiftcardPaymentInfo, PaymentInfo } from '@repo/types';
import StripePaymentWrapper from './StripePaymentWrapper';

type PaymentProps = {
    paymentInfo: PaymentInfo | GiftcardPaymentInfo;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
};

export default function Payment(props: PaymentProps) {
    const paymentGateway = getPaymentGateway(props.paymentInfo);

    if (paymentGateway === 'nets') return <NetsPaymentWrapper {...props} />;
    if (paymentGateway === 'stripe') return <StripePaymentWrapper {...props} />;
    return <Fragment></Fragment>;
}

function getPaymentGateway(paymentInfo: PaymentInfo | GiftcardPaymentInfo) {
    if (isGiftcardPaymentInfo(paymentInfo)) return paymentInfo.giftcard.payment_gateway;
    else return paymentInfo.payment_gateway;
}
