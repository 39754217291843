import { Button, Link as MuiLink, SvgIcon, SxProps } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';

type LinkButtonProps = {
    text: string;
    onClick: (e: React.MouseEvent) => void;
    Icon?: typeof SvgIcon;
    iconPlacement?: 'start' | 'end';
    color?: 'primary' | 'secondary' | 'link';
    isLowercase?: boolean;
    disabled?: boolean;
    variant?: 'small' | 'large';
    rightAligned?: boolean;
    sx?: SxProps;
};

export default function LinkButton(props: LinkButtonProps) {
    const {
        Icon,
        text,
        onClick,
        sx,
        iconPlacement = 'start',
        isLowercase = false,
        variant = 'large',
        rightAligned = false,
        disabled = false,
    } = props;

    const customizations = useCustomizations();

    const bodyTextVariant = variant === 'large' ? 'body1' : 'body2';

    return (
        <MuiLink
            component={Button}
            onClick={onClick}
            underline="hover"
            disabled={disabled}
            sx={{
                color: customizations.linkColor,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                lineHeight: !Icon ? 1.43 : 1.75,
                width: 'fit-content',
                textAlign: rightAligned ? 'end' : undefined,
                textTransform: 'none',
                padding: '0px',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: customizations.linkColor,
                },
                ...sx,
            }}
            variant={bodyTextVariant}
        >
            {Icon && iconPlacement === 'start' && (
                <Icon sx={{ color: customizations.linkColor, marginRight: 0.5 }} />
            )}
            {isLowercase ? text : text.toUpperCase()}
            {Icon && iconPlacement === 'end' && <Icon sx={{ color: customizations.linkColor }} />}
        </MuiLink>
    );
}
