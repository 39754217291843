import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

import ProductCard2, {
    ActivityProductCardInfo,
} from 'src/components/domain/product-card-2/ActivityProductCard';
import { useLocale } from '@repo/i18n';
import { Product } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';

interface IProps {
    product: Product;
    productUrl?: string;
}

export default function ProductCardWide(props: IProps): JSX.Element {
    const { t } = useLocale();
    const { product, productUrl } = props;
    const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const isPrivateTour = false; // TODO: not yet implemented in Bilberry
    const customizations = useCustomizations();
    const theme = useTheme();

    return isSmScreen ? (
        <ProductCard2 product={product} url={productUrl ?? product?.url} disableMaxWidth={true} />
    ) : (
        <Box
            overflow="hidden"
            position="relative"
            boxShadow={customizations.imageShadow ? theme.shadows[3] : undefined}
            width="100%"
            height="auto"
        >
            <Box display="block" position="relative">
                <ProductImage
                    url={product.coverImage?.src}
                    size="l"
                    disableShadows={true}
                    format="16_9"
                />
                {isPrivateTour && (
                    <Grid
                        padding="0 64px"
                        justifyContent="center"
                        position="absolute"
                        top={0}
                        right={0}
                        sx={{
                            transform: 'rotateZ(45deg) translateX(64px)',
                            background: theme.palette.grey[800],
                            '& > .MuiTypography-root': {
                                color: theme.palette.common.white,
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <Typography>{t.private_tour}</Typography>
                    </Grid>
                )}
            </Box>
            <Box
                position="absolute"
                bottom={0}
                left={theme.spacing(16)}
                maxWidth="436px"
                sx={{
                    '& > div': {
                        borderTopLeftRadius: customizations.borderRadius,
                        borderTopRightRadius: customizations.borderRadius,
                    },
                }}
            >
                <ActivityProductCardInfo product={product} url={productUrl ?? product?.url} />
            </Box>
        </Box>
    );
}
