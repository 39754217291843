import { TZDate } from '@repo/tzdate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BookingBaseForm } from 'src/components/domain/booking-base/BookingBase';
import { useOnClickBookPackage } from 'src/hooks/domain/activities/useOnClickBookPackage';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from '@repo/i18n';
import {
    Package,
    PackageTicketOptionWithQuantity,
    ProductInstance,
    TicketOptionWithQuantity,
} from '@repo/types';
import {
    usePackageProduct,
    usePackageAvailability,
    useAvailablePackageProducts,
} from '@repo/widget-utils/api/api';
import { getPackageTicketOptionsWithTours } from '@repo/widget-utils/packages/getPackageTicketOptionsWithTours';
import { getInitialQuantityData } from '@repo/widget-utils/price-helper';
import { hasPackageProductsCapacity } from '@repo/widget-utils/packages/products';
import { groupBy } from 'lodash-es';
import { debugLog } from '@repo/common-utils/Logger';
import { alpha, Button, Collapse, Stack, Typography } from '@mui/material';
import { verifyQuantitiesValid } from '@repo/widget-utils/booking/bookingHelpers';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import TourSelectionContainer from 'src/components/domain/package/tour-selection/subcomponents/TourSelectionContainer';
import { getFirstAvailableProducts } from 'src/components/domain/package/tour-selection/TourSelection';
import { PackageSummary } from 'src/components/domain/package/tour-selection/PackageSummary';

interface IProps {
    packageId: string;
}

export default function PackageBookingInline(props: IProps): JSX.Element {
    const { packageId } = props;
    const {
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const customizations = useCustomizations();
    const { t } = useLocale();

    const [quantities, setQuantities] = useState<PackageTicketOptionWithQuantity[]>([]);
    const { data: pkg = null } = usePackageProduct(packageId);
    const [retries, setRetries] = useState(0);

    useEffect(() => {
        setQuantities(pkg?.ticketOptions ?? []);
    }, [pkg]);

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>({
        startDay: TZDate.now(),
        endDay: TZDate.now().add(1, 'month'),
    });
    const [selectedDate, setSelectedDate] = useState<TZDate | null>(null);
    const onSelectDate = useCallback((date: TZDate | null) => {
        // Packages always start at the beginning of the day, so we need to set the time to 00:00:00
        setSelectedDate(date?.startOf('day') ?? null);
    }, []);

    const { data: pkgAvailability } = usePackageAvailability(
        pkg,
        availabilitySearchPeriod.startDay ?? TZDate.now(),
        availabilitySearchPeriod.endDay ?? TZDate.now().add(1, 'month'),
        (data) => {
            if (retries > 2 || (data && data.length > 0)) return;

            debugLog('Retrying package availability search. Retry #', retries);
            setRetries((prev) => prev + 1);
            setAvailabilitySearchPeriod((prev) => ({
                startDay: prev.endDay,
                endDay: prev.endDay?.add(1, 'month') ?? null,
            }));
        },
    );

    const pkgAvailabilityWithQuantitiesCheck = useMemo(
        () =>
            Object.values(groupBy(pkgAvailability, (p) => p.start.format('YYYY-MM-DD'))).flatMap(
                (availabilityDay) => {
                    const isAvailable = quantities.every(
                        (q) =>
                            q.quantity === 0 ||
                            availabilityDay.some((a) =>
                                a.ticketOptions.some(
                                    (to) => to.ticketCategoryId === q.ticketCategoryId,
                                ),
                            ),
                    );
                    if (isAvailable) return availabilityDay;
                    else return [];
                },
            ),
        [pkgAvailability, quantities],
    );

    useWidgetEventEffect(
        (pkg) => ({
            eventType: 'viewItem',
            product: pkg,
            productType: 'Package',
        }),
        pkg,
        visible,
    );

    const packagePrices = useMemo(() => (pkg ? pkg.ticketOptions : []), [pkg]);

    const priceQuantities = useMemo(() => {
        if (!quantities || !pkgAvailability || pkgAvailability.length === 0) {
            return quantities;
        }

        return quantities.map((q) => ({
            ...q,
            productInstances: pkgAvailability?.filter((p) => p.product?.pkgTicketOptionId === q.id),
        }));
    }, [quantities, pkgAvailability]);

    const capacityError =
        !!selectedDate && !hasPackageProductsCapacity(pkg, pkgAvailability ?? [], quantities);

    const onClickBook = useOnClickBookPackage(
        pkg,
        quantities,
        packagePrices,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        selectedDate,
        onSelectDate,
    );

    const minEntrants = pkg?.products
        .slice()
        .sort((a, b) => ((a.minEntrants ?? -1) < (b.minEntrants ?? -1) ? -1 : 1))
        .pop()?.minEntrants;

    const { quantityErrors, totalErrors } = verifyQuantitiesValid(
        priceQuantities,
        capacityError
            ? t.one_or_more_activities_or_accommodations_dont_have_enough_capacity
            : undefined,
        hasChosenDate,
    );
    const [showTourSelection, setShowTourSelection] = useState(false);

    return (
        <Stack width="100%">
            <Typography variant="h2" mb={1}>
                {pkg?.title}
            </Typography>
            <Typography variant="body1" mb={3}>
                {pkg?.shortDescription}
            </Typography>
            <Typography variant="h3" fontSize={24} fontWeight={700} lineHeight={1} m={0}>
                Select date and tickets
            </Typography>
            <Stack
                width="100%"
                bgcolor={customizations.bookingWidgetColor}
                color={customizations.bookingWidgetColorContrast}
                mt={1}
                p={3}
                gap={1}
                borderRadius={`${customizations.borderRadius}px`}
                position="relative"
                boxSizing="border-box"
            >
                <BookingBaseForm
                    backgroundColor={customizations.bookingWidgetColor}
                    color={customizations.bookingWidgetColorContrast}
                    labelColor={customizations.bookingWidgetColorContrast}
                    inputFieldBorder={`1px solid ${alpha(
                        customizations.bookingWidgetColorContrast,
                        0.2,
                    )}`}
                    isPackageCalendar
                    travelerQuantities={priceQuantities}
                    defaultQuantities={getInitialQuantityData(
                        pkg?.ticketOptions ?? [],
                        minEntrants,
                    )}
                    setQuantities={
                        setQuantities as unknown as React.Dispatch<
                            React.SetStateAction<TicketOptionWithQuantity[]>
                        >
                    }
                    availabilityData={pkgAvailabilityWithQuantitiesCheck}
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    attemptedBooking={attemptedBooking}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    selectedDate={selectedDate}
                    setSelectedDate={onSelectDate}
                    quantityErrors={quantityErrors}
                    totalErrors={totalErrors}
                />
                <Button
                    variant="contained"
                    onClick={() => setShowTourSelection(true)}
                    sx={{
                        alignSelf: 'flex-end',
                        backgroundColor: customizations.bookingWidgetPrimaryColor,
                        color: customizations.bookingWidgetPrimaryColorContrast,
                        '&:hover': {
                            backgroundColor: alpha(customizations.bookingWidgetPrimaryColor, 0.8),
                            color: customizations.bookingWidgetPrimaryColorContrast,
                        },
                    }}
                    disabled={
                        !quantities.some((q) => q.quantity > 0) ||
                        !selectedDate ||
                        showTourSelection
                    }
                >
                    {t.next}
                </Button>
            </Stack>
            <Collapse in={showTourSelection} timeout={500}>
                {pkg && selectedDate && showTourSelection && (
                    <TourSelection
                        pkg={pkg}
                        date={selectedDate}
                        onBook={(selectedProducts) => {
                            onClickBook(selectedProducts);
                            setSelectedDate(null);
                            setQuantities([]);
                            setShowTourSelection(false);
                        }}
                        ticketOptions={quantities}
                    />
                )}
            </Collapse>
        </Stack>
    );
}

function TourSelection({
    pkg,
    date,
    ticketOptions,
    onBook,
}: {
    pkg: Package;
    date: TZDate;
    ticketOptions: PackageTicketOptionWithQuantity[];
    onBook: (selectedProducts: ProductInstance[]) => void;
}) {
    const { data: availabilities } = useAvailablePackageProducts(pkg, date);
    const [selectedAvailabilities, setSelectedAvailabilities] = useState<ProductInstance[]>([]);
    const { t } = useLocale();

    useEffect(() => {
        const products = getFirstAvailableProducts(
            {
                pkg: { pkg, date },
                ticketOptions,
                products: [],
                requiresPaymentPlans: false, // Packages is not supported by membership api, so this item will disable payment plans
                disablePaymentPlans: true, // Obviously, it then doesn't require payment plans
            },
            availabilities,
        );
        if (products.length > 0) setSelectedAvailabilities(products);
    }, [availabilities, date, pkg, ticketOptions]);

    const ticketOptionsWithTours = getPackageTicketOptionsWithTours(ticketOptions, availabilities);

    const selectedTicketOptionsWithTours = getPackageTicketOptionsWithTours(
        ticketOptions,
        selectedAvailabilities,
    );

    return (
        <Stack width="100%" pt={4}>
            <Typography variant="h3" fontSize={24} fontWeight={700} lineHeight={1} m={0} pb={1}>
                {t.select_products}
            </Typography>
            <TourSelectionContainer
                products={availabilities}
                selectedAvailabilities={selectedAvailabilities}
                setSelectedAvailabilities={setSelectedAvailabilities}
                ticketOptionsWithTours={ticketOptionsWithTours}
                initiallyExpanded
            />
            <Typography
                variant="h3"
                fontSize={24}
                fontWeight={700}
                lineHeight={1}
                m={0}
                pt={4}
                pb={1}
            >
                {t.summary}
            </Typography>
            <PackageSummary
                ticketOptionsWithTours={selectedTicketOptionsWithTours}
                onBook={() => onBook(selectedAvailabilities)}
            />
        </Stack>
    );
}
