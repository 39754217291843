import { Box, Button, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { getCustomOrDefaultText, useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { Product, UserTextCustomizations } from '@repo/types';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getAgeString } from '@repo/widget-utils/mapToTranslations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import OverviewIconRow from 'src/widgets/activities/product/product-overview-2/overview/OverviewIconRow';
import { useAtom } from 'ximple/atoms';

export type ProductOverview2Props = {
    product: Product | null;
    aboutTheTripTextCustomizationKey?: keyof UserTextCustomizations;
    pricesTextCustomizationKey?: keyof UserTextCustomizations;
    departureTextCustomizationKey?: keyof UserTextCustomizations;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
    additionalInfoTextCustomizationKey?: keyof UserTextCustomizations;
    startingTimeTextCustomizationKey?: keyof UserTextCustomizations;
};

export default function ProductOverview2(props: ProductOverview2Props): JSX.Element {
    const { t, locale } = useLocale();
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const {
        product,
        aboutTheTripTextCustomizationKey,
        pricesTextCustomizationKey,
        departureTextCustomizationKey,
        durationTextCustomizationKey,
        difficultyTextCustomizationKey,
        ageTextCustomizationKey,
        pricesFromTextCustomizationKey,
        additionalInfoTextCustomizationKey,
        startingTimeTextCustomizationKey,
    } = props;
    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const theme = useTheme();
    const [currency] = useAtom(currencyAtom);

    const terms = product?.terms;

    return (
        <Grid container p={`${customizations.productOverviewPadding}px`}>
            <Grid
                item
                xs={12}
                container
                mb={theme.spacing(8)}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        marginBottom: theme.spacing(4),
                    },
                }}
            >
                <Grid item xs={12} sm={10} md={8}>
                    {product && (
                        <OverviewIconRow
                            product={product}
                            durationTextCustomizationKey={durationTextCustomizationKey}
                            difficultyTextCustomizationKey={difficultyTextCustomizationKey}
                            ageTextCustomizationKey={ageTextCustomizationKey}
                            pricesFromTextCustomizationKey={pricesFromTextCustomizationKey}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                {product && (
                    <Typography variant="h3">
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            aboutTheTripTextCustomizationKey,
                            locale,
                            t.about_the_trip,
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid item container xs={12}>
                <Grid item container xs={12} sm={8} alignContent="flex-start" mt={theme.spacing(3)}>
                    <Grid item xs={12}>
                        {product?.longDescription && parseHtmlToMui(product.longDescription)}
                    </Grid>
                    {product?.additionalInfo && (
                        <Grid item xs={12}>
                            <Typography variant="h3" m={theme.spacing(3, 0)}>
                                {getCustomOrDefaultText(
                                    configurations.textCustomizations,
                                    additionalInfoTextCustomizationKey,
                                    locale,
                                    t.additional_information,
                                )}
                            </Typography>
                            {parseHtmlToMui(product.additionalInfo)}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    direction="column"
                    pl={theme.spacing(12)}
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: theme.spacing(4),
                        },
                        [theme.breakpoints.down('sm')]: {
                            paddingLeft: 0,
                            paddingTop: theme.spacing(4),
                        },
                    }}
                >
                    <Typography variant="h4">
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            pricesTextCustomizationKey,
                            locale,
                            t.prices,
                        )}
                    </Typography>
                    {product?.ticketOptions &&
                        product.ticketOptions.map((price) => (
                            <Typography key={price.id} fontSize="0.9rem">
                                {capitalize(price.name)}
                                {getAgeString(t, price)}:{' '}
                                <Grid
                                    component="span"
                                    fontSize="1rem"
                                    fontWeight={customizations.boldFontWeight}
                                >
                                    {formatCurrencyNoDecimalsIfPossible(
                                        locale,
                                        currency,
                                        price.price,
                                    )}
                                </Grid>
                            </Typography>
                        ))}
                    {product?.startTimes && (
                        <>
                            <Typography variant="h4" mt={theme.spacing(4)}>
                                {getCustomOrDefaultText(
                                    configurations.textCustomizations,
                                    startingTimeTextCustomizationKey,
                                    locale,
                                    t.starting_time,
                                )}
                            </Typography>
                            {parseHtmlToMui(product.startTimes)}
                        </>
                    )}
                    <Typography variant="h4" mt={theme.spacing(4)}>
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            departureTextCustomizationKey,
                            locale,
                            t.departure,
                        )}
                    </Typography>
                    {product?.location?.city && <Typography>{product.location.city}</Typography>}
                    {terms && (
                        <Box mt={theme.spacing(4)}>
                            <Button
                                sx={{
                                    marginLeft: theme.spacing(-1),
                                    marginTop: theme.spacing(-1),
                                    color: customizations.linkColor,
                                }}
                                variant="text"
                                onClick={() => setShowCancellationPolicy(!showCancellationPolicy)}
                            >
                                {capitalize(
                                    showCancellationPolicy
                                        ? t.hide_cancellation_policy
                                        : t.show_cancellation_policy,
                                )}
                            </Button>
                            <Collapse in={showCancellationPolicy}>{parseHtmlToMui(terms)}</Collapse>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
