import {
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useLocale } from '@repo/i18n';
import { CheckoutInfo } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import CheckoutInfoQuestionnaires from './CheckoutInfoQuestionnaires';
import ContactPersonForm from './ContactPersonForm';
import CompanyInfoForm from './CompanyInfoForm';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useCartContext } from 'src/widgets/CartContext';

type Props = {
    showErrors: boolean;
    checkoutInfo: CheckoutInfo;
    onChange(checkoutInfoData: CheckoutInfo): void;
    showAddressFields: boolean;
};

export default function CheckoutInfoView(props: Props): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();
    const { cartItems } = useCartContext();
    const configuration = useConfigurations();

    return (
        <Stack
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            pt={0}
            minWidth="300px"
            width="100%"
            maxWidth="440px"
            gap={1}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': {
                        marginTop: theme.spacing(1),
                    },
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: theme.spacing(2),
                }}
                variant="outlined"
            >
                {configuration.enableInvoicePayment && (
                    <RadioGroup
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <FormControl>
                            <FormControlLabel
                                value="personal"
                                control={<Radio />}
                                label={capitalize(t.person.singular)}
                                checked={props.checkoutInfo.contactType === 'person'}
                                onChange={() => {
                                    props.onChange({
                                        ...props.checkoutInfo,
                                        contactType: 'person',
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                value="company"
                                control={<Radio />}
                                label={t.company}
                                checked={props.checkoutInfo.contactType === 'company'}
                                onChange={() => {
                                    props.onChange({
                                        ...props.checkoutInfo,
                                        contactType: 'company',
                                    });
                                }}
                            />
                        </FormControl>
                    </RadioGroup>
                )}
                {props.checkoutInfo.contactType === 'company' ? (
                    <Company {...props} />
                ) : (
                    <Personal {...props} />
                )}
            </Paper>

            {cartItems.map((cartItem) => (
                <CheckoutInfoQuestionnaires
                    key={getCartItemId(cartItem)}
                    showErrors={props.showErrors}
                    cartItem={cartItem}
                    checkoutInfo={props.checkoutInfo}
                    contactPerson={props.checkoutInfo.contactPerson}
                    onChange={props.onChange}
                />
            ))}
        </Stack>
    );
}

function Personal(props: Props) {
    const { t } = useLocale();
    return (
        <>
            <Typography color="secondary" variant="h6" mt={0}>
                {capitalize(t.main_contact)}
            </Typography>

            <ContactPersonForm
                contactPerson={props.checkoutInfo.contactPerson}
                showErrors={props.showErrors}
                onChange={(newContactPerson) =>
                    props.onChange({ ...props.checkoutInfo, contactPerson: newContactPerson })
                }
                showAddressFields={props.showAddressFields}
            />
        </>
    );
}

function Company(props: Props) {
    const { t } = useLocale();
    return (
        <>
            <Typography color="secondary" variant="h6" mt={0}>
                {capitalize(t.company)}
            </Typography>

            <CompanyInfoForm
                companyInfo={props.checkoutInfo.companyInfo}
                showErrors={props.showErrors}
                onChange={(newCompany) =>
                    props.onChange({ ...props.checkoutInfo, companyInfo: newCompany })
                }
            />
        </>
    );
}
