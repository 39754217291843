import { cartAtom } from 'src/state/cart/cartAtom';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { LocaleOverrideType, overrideLocale } from '@repo/i18n';
import { customizationAtom } from '../../components/utils/theme/customizations';
import { ThemeType } from '../../components/utils/theme/ThemeType';
import { BilberryWidgetsGlobal } from '@repo/types';
import { debugLog } from '@repo/common-utils/Logger';

declare const window: any;

window.BilberryWidgetsGlobalOverride = function (configurations: Partial<BilberryWidgetsGlobal>) {
    debugLog('BilberryWidgetsGlobalOverride called with', configurations);
    cartAtom.update({ type: 'CLEAR' });
    configurationAtom.update({
        ...(configurationAtom.subject.value ?? {}),
        ...configurations,
    });
};

window.BilberryOverrideLanguage = function (locale: string) {
    overrideLocale(locale, LocaleOverrideType.JS_OVERRIDE);
};

window.BilberryOverrideTheme = function (customizations: Partial<ThemeType>) {
    customizationAtom.update({ ...customizationAtom.subject.value, ...customizations });
};
