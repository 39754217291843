import { WarningMessage } from './BookingCardWarningMessage';
import { useLocale } from '@repo/i18n';

export function MembershipBookingDisabledWarning(props: { color?: string }) {
    const { t } = useLocale();
    return (
        <>
            <WarningMessage color={props.color} label={t.membershipBookingDisabledWarning} />
        </>
    );
}
