import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import toInteger from 'lodash-es/toInteger';
import { Fragment } from 'react';
import { useProduct } from '@repo/widget-utils/api/api';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import AccommodationProduct from './AccommodationProductOverview';
import { createRoot } from 'react-dom/client';

const firstDay = BilberryWidgetsGlobal.openingDate
    ? tzdate(BilberryWidgetsGlobal.openingDate)
    : TZDate.now();
const initialDateRange: DateRange<TZDate> = [firstDay, firstDay.add(1, 'days')];

export function AccommodationProductOverviewWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    id: number;
}): JSX.Element {
    const { data: accommodation, isLoading } = useProduct(
        props.id.toString(),
        'accommodation',
        initialDateRange,
        1,
    );

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            {isLoading || !accommodation ? (
                <Fragment />
            ) : (
                <AccommodationProduct accommodation={accommodation} dateRange={initialDateRange} />
            )}
        </WidgetWrapper>
    );
}

class BilberryAccommodationProductOverview extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        createRoot(this._mountPoint).render(
            <AccommodationProductOverviewWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                id={toInteger(this.id)}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        'bilberry-accommodation-product-overview',
        BilberryAccommodationProductOverview,
    );
}
