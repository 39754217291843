import { createRoot } from 'react-dom/client';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { AccommodationSearchForm } from './form/AccommodationSearchForm';
import {
    getCsvAttribute,
    getStringAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';

export function AccommodationCalendarSearchWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    ids: string[];
    provider: string;
}): JSX.Element {
    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <AccommodationSearchForm
                ids={props.ids}
                provider={props.provider as 'visbook' | 'bilberry'}
            />
        </WidgetWrapper>
    );
}

class BilberryAccommodationCalendarSearch extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const provider = getStringAttribute(this, 'provider', false)!;
        const ids = getCsvAttribute(this, 'product-ids', true);

        createRoot(this._mountPoint).render(
            <AccommodationCalendarSearchWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                ids={ids}
                provider={provider}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        'bilberry-accommodation-calendar-search',
        BilberryAccommodationCalendarSearch,
    );
}
