import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { useCustomizations } from 'src/components/utils/theme/customizations';

type TitleProps = {
    text: string;
    description?: string;
    separator?: string;
    centered?: boolean;
    fullWidth?: boolean;
};

export default function Title({
    text,
    description,
    separator = '-',
    centered = false,
    fullWidth,
}: TitleProps) {
    const theme = useTheme();
    const customizations = useCustomizations();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formattedDescription = ` ${isMobile ? '' : separator} ${description}`;

    return (
        <Typography
            variant="h1"
            sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textAlign: centered ? 'center' : 'left',
                width: fullWidth ? '100%' : 'auto',
                fontWeight: 700,
            }}
        >
            {text}
            {isMobile && <br />}
            {description && <span style={{ fontWeight: 400 }}>{formattedDescription}</span>}
        </Typography>
    );
}
