import EditIcon from '@mui/icons-material/Edit';
import { alpha, Box, Collapse, Stack, Typography } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { Fragment, useEffect, useState } from 'react';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from '@repo/i18n';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import {
    mapPriceQuantitiesToMultipleNumberInputValueType,
    onChangeQuantity,
    verifyQuantitiesValid,
} from '@repo/widget-utils/booking/bookingHelpers';
import { getInitialQuantityData, updateQuantityData } from '@repo/widget-utils/price-helper';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { WarningMessage } from '../booking-base/BookingCardWarningMessage';
import { ProductInstance } from '@repo/types';
import TimeSlots from '../time-slot/TimeSlots';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import { InlineSummary } from '../summary/InlineSummary';
import { checkIfNewCartItemCausesCompatibilityIssue } from 'src/state/cart/cartAtom';
import {
    productDisablePaymentPlans,
    productRequiresPaymentPlans,
} from '@repo/widget-utils/cart/cartUtils';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { MembershipBookingDisabledWarning } from '../booking-base/MembershipBookingDisabledWarning';
import { CartCompatibilityWarning } from '../booking-base/CartCompatibilityWarning';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import usePriceQuantities from 'src/hooks/domain/cart/usePriceQuantities';
import useTicketTypesWithinProduct from 'src/hooks/domain/cart/useTicketTypesWithinProduct';
import useUpdateNewCartItem from 'src/hooks/domain/cart/useUpdateNewCartItem';
import { useCartContext } from 'src/widgets/CartContext';
import { useBookingContext } from 'src/widgets/BookingContext';

export function TourProductCardBookingForm(props: {
    products: ProductInstance[];
    displayDate?: boolean;
    displayTimeBorder?: boolean;
}) {
    const { products, displayDate, displayTimeBorder = true } = props;
    const hasMultipleProducts = props.products.length > 1;
    const hasCapacity = products.some((product) => product.capacity > 0);
    const product = props.products?.[0]?.product;

    const {
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        boxRef,
        shouldShowBasketOnBook,
    } = useBookingState();

    const { t } = useLocale();
    const customizations = useCustomizations();
    const configuration = useConfigurations();
    const { currentSite, loggedInUser } = useMemberContext();

    const { cartItems } = useCartContext();
    const {
        quantities,
        setQuantities,
        selectedProducts,
        setSelectedProducts,
        selectedTimeSlot,
        setSelectedTimeslot,
        selectedTicketType,
        setSelectedTicketType,
    } = useBookingContext();

    const [_dateRange, setDateRange] = useState<DateRange<TZDate>>([null, null]);
    const [_hasChangedQuantities, setHasChangedQuantities] = useState(false);

    const [currentInteraction, setCurrentInteraction] = useState<
        'SELECT_PARTICIPANTS' | 'SELECT_TIME' | undefined
    >(hasMultipleProducts ? 'SELECT_TIME' : 'SELECT_PARTICIPANTS');

    useEffect(() => {
        if (!hasMultipleProducts) {
            setHasChosenDate(true);
            setCurrentInteraction('SELECT_PARTICIPANTS');
            setSelectedTimeslot({
                label: products[0].start.format('HH:mm'),
                product: products[0],
            });
        }
    }, [setHasChosenDate, setCurrentInteraction, setSelectedTimeslot]);

    const newItemDisablePaymentPlans = productDisablePaymentPlans(
        product,
        false,
        configuration.disableMembershipBooking,
    );
    const newItemRequiresPaymentPlans = productRequiresPaymentPlans(product);
    const showCartCompatibilityWarning = checkIfNewCartItemCausesCompatibilityIssue(
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
        cartItems,
    );
    const showMembershipBookingDisabledWarning =
        newItemRequiresPaymentPlans && newItemDisablePaymentPlans;
    const showWarning = showCartCompatibilityWarning || showMembershipBookingDisabledWarning;

    useUpdateNewCartItem(newItemDisablePaymentPlans, newItemRequiresPaymentPlans);

    const ticketTypes = useTicketTypesWithinProduct(product);
    const priceQuantities = usePriceQuantities(product, selectedProducts, quantities);

    useEffect(() => {
        setQuantities((quantities) =>
            updateQuantityData(selectedProducts ?? [], product?.ticketOptions ?? [], quantities),
        );
        if (ticketTypes.length === 1) {
            setSelectedTicketType(ticketTypes[0]);
        } else if (ticketTypes.length > 0) {
            setSelectedTicketType((prev) =>
                prev ? ticketTypes.find((t) => t.id === prev.id) : undefined,
            );
        }
    }, [selectedProducts, product, ticketTypes]);

    const onClickBook = useOnClickBook(
        priceQuantities,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        undefined,
        selectedProducts,
        hasMultipleProducts ? setSelectedProducts : () => null,
        hasMultipleProducts ? setSelectedTimeslot : () => null,
        product,
        setDateRange,
        setSelectedTicketType,
        currentSite,
        loggedInUser,
        selectedTicketType,
        selectedTimeSlot,
    );

    const onBook = () => {
        setCurrentInteraction(hasMultipleProducts ? 'SELECT_TIME' : 'SELECT_PARTICIPANTS');
        onClickBook();
    };
    const defaultQuantities = getInitialQuantityData(
        product?.ticketOptions ?? [],
        product?.minEntrants,
    );

    const { disableBookButton, quantityErrors, totalErrors, getWarningLabel } =
        verifyQuantitiesValid(
            priceQuantities,
            undefined,
            hasMultipleProducts ? hasChosenDate : true,
            attemptedBooking,
        );

    const { multipleNumberInputValues, defaultMultipleNumberInputValues } =
        mapPriceQuantitiesToMultipleNumberInputValueType(
            t,
            quantities,
            defaultQuantities,
            quantityErrors,
        );

    const shouldDisplayTime = products.length > 1 || products[0]?.showTime;

    return (
        <Stack alignItems="center" gap={2} textAlign="left" sx={{ width: '100%' }}>
            {!showWarning && (
                <>
                    {hasCapacity ? (
                        <Fragment>
                            <Box
                                sx={{
                                    width: '100%',
                                    '&& > *': { color: customizations.productCardTextColor },
                                }}
                            >
                                <InlineTimeslotTitle
                                    selectedTimeSlot={selectedTimeSlot}
                                    hasMultipleProducts={hasMultipleProducts}
                                    displayDate={displayDate}
                                    showTime={shouldDisplayTime}
                                    displayTimeBorder={displayTimeBorder}
                                    onClick={() => {
                                        if (hasMultipleProducts) {
                                            setCurrentInteraction('SELECT_TIME');
                                            setSelectedTimeslot(undefined);
                                            setHasChosenDate(false);
                                        }
                                    }}
                                />
                                <Collapse
                                    easing="linear(0,1)"
                                    in={currentInteraction === 'SELECT_TIME'}
                                    exit={currentInteraction !== 'SELECT_TIME'}
                                >
                                    <TimeSlots
                                        selectedTimeSlot={selectedTimeSlot}
                                        onSelectTimeSlot={(ts: TimeSlotType) => {
                                            setSelectedTimeslot(ts);
                                            setSelectedProducts([ts.product]);
                                            setHasChosenDate(true);
                                            setCurrentInteraction('SELECT_PARTICIPANTS');
                                        }}
                                        availabilityData={products}
                                        hideHeader
                                        fullWidth
                                        color={customizations.productCardTextColor}
                                        backgroundColor={customizations.productCardColor}
                                        primaryColor={customizations.productCardPrimaryColor}
                                        primaryColorContrast={
                                            customizations.productCardPrimaryColorContrast
                                        }
                                    />
                                </Collapse>
                                <Collapse
                                    easing="linear(0,1)"
                                    in={currentInteraction === 'SELECT_PARTICIPANTS'}
                                    exit={currentInteraction !== 'SELECT_PARTICIPANTS'}
                                >
                                    {ticketTypes && ticketTypes.length > 0 && (
                                        <Dropdown
                                            value={selectedTicketType ?? null}
                                            options={ticketTypes}
                                            getOptionLabel={(opt) =>
                                                opt?.name ?? t.select_price_type
                                            }
                                            onChange={(opt) =>
                                                setSelectedTicketType?.(opt ?? undefined)
                                            }
                                            labelColor={customizations.bookingWidgetColorContrast}
                                            label={t.select_price_type}
                                            error={
                                                attemptedBooking && !selectedTicketType
                                                    ? t.please_select_price_type
                                                    : undefined
                                            }
                                        />
                                    )}
                                    <MultipleNumberInput
                                        labelText=""
                                        values={multipleNumberInputValues}
                                        defaultValues={defaultMultipleNumberInputValues}
                                        onChange={(id, q) =>
                                            onChangeQuantity(
                                                id,
                                                q,
                                                setHasChangedQuantities,
                                                setQuantities,
                                            )
                                        }
                                        error={totalErrors[0]}
                                        labelColor={customizations.productCardTextColor}
                                        backgroundColor={customizations.productCardColor}
                                        color={customizations.productCardTextColor}
                                        variant="filled"
                                        border={`1px solid ${alpha(
                                            customizations.productCardTextColor,
                                            0.2,
                                        )}`}
                                    />

                                    <Box sx={{ mb: 0.575 }}></Box>
                                </Collapse>
                            </Box>
                            <WarningMessage label={getWarningLabel()} />
                        </Fragment>
                    ) : (
                        <Box sx={{ py: 6.8 }}></Box>
                    )}
                    <InlineSummary
                        onBook={onBook}
                        quantities={quantities}
                        fromPrice={products[0].product?.fromPrice ?? null}
                        hasCapacity={hasCapacity}
                        bookingDisabled={!hasChosenDate || !selectedProducts || disableBookButton}
                    />
                </>
            )}

            {showMembershipBookingDisabledWarning && (
                <MembershipBookingDisabledWarning
                    color={customizations.productCardTextColor}
                ></MembershipBookingDisabledWarning>
            )}
            {!showMembershipBookingDisabledWarning && showCartCompatibilityWarning && (
                <CartCompatibilityWarning
                    color={customizations.productCardTextColor}
                ></CartCompatibilityWarning>
            )}
        </Stack>
    );
}

type InlineTimeslotTitleProps = {
    displayDate?: boolean;
    showTime?: boolean;
    selectedTimeSlot: TimeSlotType | undefined;
    hasMultipleProducts: boolean;
    onClick: () => void;
    displayTimeBorder?: boolean;
};

function InlineTimeslotTitle({
    selectedTimeSlot,
    hasMultipleProducts,
    onClick,
    showTime = true,
    displayDate,
    displayTimeBorder,
}: InlineTimeslotTitleProps) {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    if (!showTime) return null;
    return (
        <Typography
            sx={{
                cursor: 'pointer',
                fontSize: '0.9rem',
                mb: 1.5,
                pb: 0.5,
                lineHeight: 1,
                borderBottom: displayTimeBorder ? '1px solid #00000044' : undefined,
            }}
            onClick={onClick}
        >
            <>
                {displayDate && selectedTimeSlot?.product.start && (
                    <Stack component="span" direction="row" alignItems="center">
                        <Box component="span" fontWeight="bold">
                            {t.date}: {selectedTimeSlot.product.start.format('Do MMM')}
                        </Box>
                    </Stack>
                )}
                {selectedTimeSlot ? (
                    <Stack component="span" direction="row" alignItems="center" fontWeight="bold">
                        <Box component="span" mr={0.5}>
                            {hasMultipleProducts ? t.selected_time : t.time}:
                        </Box>
                        <span>{` ${tzdate(selectedTimeSlot?.product.start).format(
                            'HH:mm',
                        )} - ${tzdate(selectedTimeSlot?.product.end).format('HH:mm')}`}</span>
                        {hasMultipleProducts && (
                            <EditIcon
                                sx={{
                                    ml: 'auto',
                                    fontSize: '1rem',
                                    color: customizations.productCardTextColor,
                                }}
                            />
                        )}
                    </Stack>
                ) : (
                    <span style={{ fontWeight: 'bold' }}>{t.select_time}</span>
                )}
            </>
        </Typography>
    );
}
