import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { useProduct } from '@repo/widget-utils/api/api';
import ProductGallery2, {
    ProductGalleryAlign,
} from 'src/widgets/activities/product/product-gallery-2/gallery/ProductGallery2';

export default function ProductGallery2WidgetRoot(props: {
    productCatalogId: number;
    numVisibleImages: MediaQueryAttributeInput;
    align: ProductGalleryAlign;
}) {
    const { data: product } = useProduct(props.productCatalogId.toString());

    return (
        <ProductGallery2
            imageList={product?.images ?? null}
            numVisibleImages={props.numVisibleImages}
            align={props.align}
        />
    );
}
