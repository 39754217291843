import { Box, Button, Grid, useTheme } from '@mui/material';
import Summary from 'src/components/domain/summary/Summary';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from './ButtonDisplay';
import CheckoutInfoView from 'src/components/domain/checkout-info/CheckoutInfoView';
import LoggedInPanel from 'src/components/domain/timeslots-authentication/logged-in-panel/LoggedInPanel';
import LoggedOutPanel from 'src/components/domain/timeslots-authentication/logged-out-panel/LoggedOutPanel';
import { useAtom } from 'ximple';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { companyAtom } from 'src/state/company';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';
import { useCartCheckoutContext } from '../CartCheckoutContext';

type ContactViewProps = {
    handleGoPayClicked: () => void;
    handleGoPayClickedInvoice?: () => void;
    clickedNext: boolean;
    nextButtonText: string;
};

export function ContactView({
    handleGoPayClicked,
    handleGoPayClickedInvoice,
    clickedNext,
    nextButtonText,
}: ContactViewProps) {
    const theme = useTheme();
    const [{ currentSite }] = useAtom(companyAtom);
    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const { cartItems, appliedPromoCode, checkoutInfo, setCheckoutInfo } = useCartContext();
    const { loggedInUser } = useMemberContext();
    const { activeTab, largeScreen, isMakingReservation, showAddressFields } =
        useCartCheckoutContext();

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Contact Info',
        cartItems: cartItems,
        giftCards: [],
        promoCode: appliedPromoCode,
    }));

    return (
        <Grid
            container
            justifyContent={largeScreen ? 'space-between' : 'center'}
            alignItems={largeScreen ? 'flex-start' : 'center'}
            direction={largeScreen ? 'row' : 'column-reverse'}
        >
            {!largeScreen && (
                <Box
                    maxWidth="440px"
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            marginBottom: theme.spacing(3),
                        },
                    }}
                >
                    <Button
                        sx={{ float: 'right', marginTop: theme.spacing(2) }}
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        disabled={isMakingReservation || cartItems.length === 0}
                        onClick={handleGoPayClicked}
                    >
                        <ButtonDisplay
                            isMakingReservation={isMakingReservation}
                            buttonText={nextButtonText}
                        />
                    </Button>
                </Box>
            )}

            {currentSite && largeScreen && (
                <>{loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="booking" />}</>
            )}

            {checkoutInfo && (
                <CheckoutInfoView
                    checkoutInfo={checkoutInfo}
                    onChange={setCheckoutInfo}
                    showErrors={clickedNext}
                    showAddressFields={showAddressFields}
                />
            )}

            {currentSite && !largeScreen && (
                <>{loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="booking" />}</>
            )}

            <Summary
                showShare
                hideGoPay={!largeScreen}
                isCheckoutSummary={true}
                isMakingReservation={isMakingReservation}
                goPayClicked={handleGoPayClicked}
                goPayClickedInvoice={
                    configurations.enableInvoicePayment && checkoutInfo?.contactType === 'company'
                        ? handleGoPayClickedInvoice
                        : undefined
                }
                activeTab={activeTab}
            />
        </Grid>
    );
}
