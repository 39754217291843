import { useEffect, useState } from 'react';
import RadioButton from 'src/components/common/timeslots-buttons/radio-button/RadioButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import { useLocale } from '@repo/i18n';

type BookingSessionSelectorProps = {
    duration: number;
    minBookingQuantity: number;
    setTimeslotsInSession: (state: number) => void;
};

export default function BookingSessionSelector({
    duration,
    minBookingQuantity,
    setTimeslotsInSession,
}: BookingSessionSelectorProps) {
    const { t } = useLocale();
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setTimeslotsInSession(minBookingQuantity);
    }, [minBookingQuantity]);

    const handleClicked = (i: number, timeslotsInSession: number) => {
        setTimeslotsInSession(timeslotsInSession);
        setSelected(i);
    };

    return (
        <Layout flexDirection="column" justify="center" alignItems="center">
            <Layout justify="space-between" alignItems="center" sx={{ width: '280px' }}>
                {Array.from({ length: 3 }).map((_, i) => {
                    const sessionDuration = ((duration * minBookingQuantity) / 3600) * (i + 1);
                    const timeslotsInSession = minBookingQuantity * (i + 1);
                    return (
                        <RadioButton
                            key={`timeslot-selector-${i}`}
                            text={`${sessionDuration}${t.tHours}`}
                            onClick={() => handleClicked(i, timeslotsInSession)}
                            selected={selected === i}
                            size="large"
                        />
                    );
                })}
            </Layout>
        </Layout>
    );
}
