import { Typography, useTheme } from '@mui/material';
import { upperFirst } from 'lodash-es';

import { useLocale } from '@repo/i18n';

interface IProps {
    capacity: number;
    fewLeft: number;
    showRemaining?: boolean;
    px?: number;
    disableMinWidth?: boolean;
}

export default function TourAvailability({
    capacity,
    fewLeft,
    showRemaining,
    px,
    disableMinWidth,
}: IProps) {
    const theme = useTheme();
    const { t } = useLocale();
    const getCss = () => {
        if (capacity < 1) {
            return { backgroundColor: '#C4C0C0', color: '#000 !important' };
        } else if (capacity <= fewLeft) {
            return { backgroundColor: '#fcba2d', color: '#000 !important' };
        } else {
            return { backgroundColor: '#1A744F', color: '#fff !important' };
        }
    };

    const getText = () => {
        if (capacity < 1) {
            return upperFirst(t.sold_out);
        } else if (capacity <= fewLeft) {
            if (showRemaining) {
                return t.tickets_left.parsed(capacity, capacity);
            } else {
                return upperFirst(t.few_left);
            }
        } else {
            return upperFirst(t.available);
        }
    };

    return (
        <Typography
            py={0.2}
            px={px ?? 1}
            borderRadius="5px"
            minWidth={!disableMinWidth ? '6rem' : 'auto'}
            display="flex"
            justifyContent="center"
            fontSize="0.8rem"
            fontWeight="bold"
            sx={{
                [theme.breakpoints.down('sm')]: {
                    minWidth: !disableMinWidth ? '5rem' : 'auto',
                    fontSize: '0.6rem',
                },
                ...getCss(),
            }}
        >
            {getText()}
        </Typography>
    );
}
