import ClearIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { removeFromCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { CartItem } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import {
    getCartItemId,
    getCartItemSummaryDateInformation,
    isAccomodationOrNightsCartItem,
    isActivityCartItem,
    isExtraCartItem,
    isPackageCartItem,
    isTimeslotCartItem,
} from '@repo/widget-utils/cart/cartUtils';
import { getCartItemDisplayTitle } from '@repo/widget-utils/display-helper';
import SummaryInformation from './SummaryInformation/SummaryInformation';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

interface IProps {
    isCheckoutSummary: boolean;
    cartItems: CartItem[];
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

export default function SummaryBooking(props: IProps): JSX.Element {
    const { cartItems, isCheckoutSummary, invertedColorsClass, isConfirmationPage } = props;
    const { t } = useLocale();
    const theme = useTheme();

    const extraCartItems = cartItems.filter(isExtraCartItem);

    // Filter out the extra cart items, to avoid displaying them twice
    // TODO: maybe this can be done in a more elegant way. But need more insights on the data structure first
    const remainingCartItems = cartItems.filter((item) => !extraCartItems.includes(item));
    const activityCartItems = remainingCartItems.filter(isActivityCartItem);
    const accommodationCartItems = remainingCartItems.filter(isAccomodationOrNightsCartItem);
    const packageCartItems = remainingCartItems.filter(isPackageCartItem);
    const timeslotCartItems = remainingCartItems.filter(isTimeslotCartItem);

    return (
        <Box>
            {accommodationCartItems.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.accommodations}
                    </Typography>
                    {accommodationCartItems.map((item) => (
                        <SummaryBookingItem
                            key={getCartItemId(item)}
                            item={item}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                        />
                    ))}
                </Grid>
            )}
            {activityCartItems.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.activities}
                    </Typography>
                    {activityCartItems.map((item) => (
                        <SummaryBookingItem
                            key={getCartItemId(item)}
                            item={item}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                        />
                    ))}
                </Grid>
            )}
            {packageCartItems.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.packages}
                    </Typography>
                    {packageCartItems.map((item) => (
                        <SummaryBookingItem
                            key={getCartItemId(item)}
                            item={item}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                        />
                    ))}
                </Grid>
            )}
            {timeslotCartItems.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.entrance}
                    </Typography>
                    {timeslotCartItems.map((item) => (
                        <SummaryBookingItem
                            key={getCartItemId(item)}
                            item={item}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                        />
                    ))}
                </Grid>
            )}
            {extraCartItems.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.extras}
                    </Typography>
                    {extraCartItems.map((item) => (
                        <SummaryBookingItem
                            key={getCartItemId(item)}
                            item={item}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                        />
                    ))}
                </Grid>
            )}
        </Box>
    );
}

interface SummaryBookingItemProps {
    item: CartItem;
    isCheckoutSummary: boolean;
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

function SummaryBookingItem(props: SummaryBookingItemProps): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();
    const { currentSite, loggedInUser } = useMemberContext();

    const showTime = props.item.products.length > 1 || props.item.products[0]?.showTime;

    return (
        <Box key={'summary-item-' + getCartItemId(props.item)}>
            <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                borderBottom={`1px solid ${theme.palette.grey[400]}`}
                margin={theme.spacing(1, 0, 1.5, 0)}
            >
                <Stack justifyContent={showTime ? 'flex-end' : 'center'}>
                    <Typography
                        variant="h6"
                        align="left"
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {getCartItemDisplayTitle(props.item)}
                    </Typography>
                    {showTime && (
                        <Typography
                            align="left"
                            color="textSecondary"
                            sx={props.invertedColorsClass}
                        >
                            {getCartItemSummaryDateInformation(props.item)}
                        </Typography>
                    )}
                </Stack>
                {props.isCheckoutSummary && (
                    <IconButton
                        aria-label={`
                            ${capitalize(t.remove_cart_item)}${' '}
                            ${getCartItemDisplayTitle(props.item)}
                        `}
                        color="secondary"
                        sx={{
                            height: 'fit-content',
                            marginRight: '-0.5em',
                            ...props.invertedColorsClass,
                        }}
                        onClick={() =>
                            cartAtom.update(
                                removeFromCartEvent(props.item, currentSite, loggedInUser),
                            )
                        }
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Grid>
            <SummaryInformation
                invertedColorsClass={props.invertedColorsClass}
                isCheckoutSummary={props.isCheckoutSummary}
                cartItem={props.item}
                isConfirmationPage={props.isConfirmationPage}
            />
        </Box>
    );
}
