import { UserTextCustomizations } from '@repo/types';
import { createRoot } from 'react-dom/client';
import BookNow from 'src/widgets/activities/booking-toggler/book-now/BookNow';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryBookingToggle extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const size = this.getAttribute('size')?.toLowerCase();
        const xsSize = this.getAttribute('xs-size')?.toLowerCase();
        const buttonTextCustomizationKey = this.getAttribute(
            'button-text-customization-key',
        ) as keyof UserTextCustomizations;

        let sizeOrDefault: 's' | 'm' | 'l' = 's';
        let xsSizeOrDefault: 's' | 'm' | 'l' = 's';

        if (size === 's' || size === 'm' || size === 'l') {
            sizeOrDefault = size;
        }

        if (xsSize === 's' || xsSize === 'm' || xsSize === 'l') {
            xsSizeOrDefault = xsSize;
        }

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BookNow
                    size={sizeOrDefault}
                    xsSize={xsSizeOrDefault}
                    buttonTextCustomizationKey={buttonTextCustomizationKey ?? undefined}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-booking-button', BilberryBookingToggle);
}
