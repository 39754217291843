import { Grid, Typography } from '@mui/material';

import { useLocale } from '@repo/i18n';
import { Product } from '@repo/types';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';

type AccommodationOverviewDescriptionProps = {
    accommodation: Product;
};

export default function AccommodationOverviewDescription({
    accommodation,
}: AccommodationOverviewDescriptionProps) {
    const { t } = useLocale();
    return (
        <Grid item container>
            <Typography variant="h2" fontSize={22} fontWeight={400}>
                {t.about}
            </Typography>
            <Grid item container alignContent="flex-start">
                <Grid item mb={3}>
                    {parseHtmlToMui(accommodation.longDescription)}
                </Grid>
            </Grid>
        </Grid>
    );
}
