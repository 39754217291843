import { alpha, Box, Button, FormControl, Grid, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { Fragment, RefObject, useCallback, useRef, useState } from 'react';
import HotelCalendar from 'src/components/domain/hotel-calendar/HotelCalendar';
import { GuestInfo } from 'src/components/domain/MultipleRoomSearch/guestInfo';
import { MultipleRoomSearch } from 'src/components/domain/MultipleRoomSearch/MultipleRoomSearch';
import { useLocale } from '@repo/i18n';
import { ProductInstance } from '@repo/types';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { smoothScrollTo } from '@repo/common-utils/smoothScrollTo';
import { customizationAtom, useCustomizations } from 'src/components/utils/theme/customizations';
import { AccommodationList } from './subcomponents/AccommodationList';

interface AccommodationSearchProps {
    showChildrenData: boolean;
}

const initialTodayTomorrowDateRangeState: DateRange<TZDate> = BilberryWidgetsGlobal.openingDate
    ? [
          tzdate(BilberryWidgetsGlobal.openingDate),
          tzdate(BilberryWidgetsGlobal.openingDate).add(1, 'day'),
      ]
    : [tzdate(Date.now()), tzdate(Date.now()).add(1, 'day')];

export default function AccommodationSearch(props: AccommodationSearchProps) {
    const { t } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();

    const [dateRange, setDateRange] = useState<DateRange<TZDate>>(
        initialTodayTomorrowDateRangeState,
    );
    const [guestInfo, setGuestInfo] = useState<GuestInfo[]>([{ id: 0, adults: 1, children: [] }]);

    const [selectedAccommodations, setSelectedAccommodations] = useState<ProductInstance[][]>([]);

    const scrollTargetRef = useRef<HTMLElement | null>(null);
    const [nights, setNights] = useState(0);
    const [guestInfoSearchParams, setGuestInfoSearchParams] = useState<GuestInfo[]>(guestInfo);

    const [dateRangeParams, setDateRangeParams] = useState<DateRange<TZDate>>(dateRange);
    const [showAccommodationList, setShowAccommodationList] = useState(false);
    const [newSearch, setNewSearch] = useState(false);

    const clearRoomSelection = useCallback(() => {
        setSelectedAccommodations([]);
        setShowAccommodationList(false);
    }, []);

    const formItemStyles = {
        width: '100%',
        maxWidth: 350,
        [theme.breakpoints.up('sm')]: {
            width: 350,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    };

    return (
        <Fragment>
            <Grid
                container
                justifyContent="center"
                position="relative"
                width="100%"
                sx={{
                    backgroundColor: customizations.accommodationSearchFormColor,
                    '& .MuiInputBase-root': {
                        border: 'none',
                    },
                    '& .MuiFilledInput-root':
                        TextFieldStyleUtils.bookingSearchFormInputStyle(customizations),
                }}
            >
                <Box
                    boxSizing="border-box"
                    p={theme.spacing(2.5, 2, 2.5, 4)}
                    maxWidth="1280px"
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            padding: theme.spacing(2.5, 0, 3, 0),
                        },
                    }}
                >
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        alignItems="center"
                        px={theme.spacing(1)}
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                            },
                        }}
                    >
                        <Grid
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    flexDirection: 'row',
                                },
                                [theme.breakpoints.up('md')]: {
                                    width: 'auto',
                                    marginRight: theme.spacing(1),
                                },
                            }}
                        >
                            <FormControl sx={formItemStyles}>
                                <HotelCalendar
                                    dateRange={dateRange}
                                    onDateRangeChanged={setDateRange}
                                    minDate={initialTodayTomorrowDateRangeState[0] ?? undefined}
                                />
                            </FormControl>
                            <Box sx={formItemStyles}>
                                <MultipleRoomSearch
                                    showChildrenData={props.showChildrenData}
                                    maxRooms={4}
                                    hotelRoomsInfo={guestInfo}
                                    onHotelRoomsInfoChanged={setGuestInfo}
                                ></MultipleRoomSearch>
                            </Box>
                        </Grid>
                        <FormControl margin="dense">
                            <Button
                                variant="outlined"
                                sx={{
                                    height: 39,
                                    fontWeight: 700,
                                    borderColor:
                                        customizations.accommodationSearchFormColorContrast,
                                    color: customizations.accommodationSearchFormColorContrast,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.common.black, 0.1),
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        marginTop: theme.spacing(2),
                                    },
                                }}
                                onClick={useHandleSearchClick(
                                    scrollTargetRef,
                                    setNewSearch,
                                    selectedAccommodations,
                                    guestInfo,
                                    dateRange,
                                    setNights,
                                    setShowAccommodationList,
                                    setSelectedAccommodations,
                                    setGuestInfoSearchParams,
                                    setDateRangeParams,
                                )}
                            >
                                {t.find_rooms.toUpperCase()}
                            </Button>
                        </FormControl>
                    </Grid>
                </Box>
            </Grid>
            <span ref={scrollTargetRef} />
            {showAccommodationList && (
                <AccommodationList
                    newSearch={newSearch}
                    setNewSearch={setNewSearch}
                    dateRange={dateRangeParams}
                    guestInfoSearchParameters={guestInfoSearchParams}
                    selectedAccommodations={selectedAccommodations}
                    setSelectedAccommodations={setSelectedAccommodations}
                    nights={nights}
                    clearRoomSelection={clearRoomSelection}
                />
            )}
        </Fragment>
    );
}

function useHandleSearchClick(
    scrollTargetRef: RefObject<HTMLElement | null>,
    setNewSearch: React.Dispatch<React.SetStateAction<boolean>>,
    selectedAccommodations: ProductInstance[][],
    guestInfo: GuestInfo[],
    dateRange: DateRange<TZDate>,
    setNights: React.Dispatch<React.SetStateAction<number>>,
    setShowAccommodationList: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedAccommodations: React.Dispatch<React.SetStateAction<ProductInstance[][]>>,
    setGuestInfoSearchParams: React.Dispatch<React.SetStateAction<GuestInfo[]>>,
    setDateRangeParams: React.Dispatch<React.SetStateAction<DateRange<TZDate>>>,
) {
    const customizations = customizationAtom.subject.value;

    return useCallback(() => {
        smoothScrollTo(scrollTargetRef, -customizations.baseUnit);
        setNewSearch(true);
        const newSelectedAccommodations = selectedAccommodations.slice(0, guestInfo.length);
        if (dateRange[0] && dateRange[1]) {
            setNights(dateRange[1].diff(dateRange[0], 'day'));
        } else {
            setNights(0);
        }

        setShowAccommodationList(true);
        setSelectedAccommodations(newSelectedAccommodations);
        setGuestInfoSearchParams(guestInfo);
        setDateRangeParams(dateRange);
    }, [
        selectedAccommodations,
        guestInfo,
        dateRange,
        customizations.baseUnit,
        scrollTargetRef,
        setNights,
        setShowAccommodationList,
        setSelectedAccommodations,
        setGuestInfoSearchParams,
        setDateRangeParams,
        setNewSearch,
    ]);
}
