import { Box, Theme, useTheme } from '@mui/material';

import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';

function getCardFlexBasisAndWidth(
    numCardsPerRow: number,
    theme: Theme,
    shouldAddSpaceForOverflow: boolean,
): string {
    return `calc(${100 / numCardsPerRow}% - ${shouldAddSpaceForOverflow ? theme.spacing(4) : 0})`;
}

type IProps = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    spacingX?: number;
    spacingBottom?: number;
    numProductCatalogs: number;
    children: any;
};

export default function ProductCardListItem(props: IProps) {
    const { scroll, numElements, numProductCatalogs, children } = props;
    const theme = useTheme();
    if (scroll)
        return (
            <Box
                sx={{
                    flexGrow: 0,
                    '& > *': {
                        maxWidth: '100%',
                    },
                    flexBasis: getCardFlexBasisAndWidth(
                        numElements.numLg,
                        theme,
                        numElements.numLg < numProductCatalogs,
                    ),
                    maxWidth: getCardFlexBasisAndWidth(
                        numElements.numLg,
                        theme,
                        numElements.numLg < numProductCatalogs,
                    ),
                    minWidth: getCardFlexBasisAndWidth(
                        numElements.numLg,
                        theme,
                        numElements.numLg < numProductCatalogs,
                    ),
                    [theme.breakpoints.only('md')]: {
                        flexBasis: getCardFlexBasisAndWidth(
                            numElements.numMd,
                            theme,
                            numElements.numMd < numProductCatalogs,
                        ),
                        maxWidth: getCardFlexBasisAndWidth(
                            numElements.numMd,
                            theme,
                            numElements.numMd < numProductCatalogs,
                        ),
                        minWidth: getCardFlexBasisAndWidth(
                            numElements.numMd,
                            theme,
                            numElements.numMd < numProductCatalogs,
                        ),
                    },
                    [theme.breakpoints.only('sm')]: {
                        flexBasis: getCardFlexBasisAndWidth(
                            numElements.numSm,
                            theme,
                            numElements.numSm < numProductCatalogs,
                        ),
                        maxWidth: getCardFlexBasisAndWidth(
                            numElements.numSm,
                            theme,
                            numElements.numSm < numProductCatalogs,
                        ),
                        minWidth: getCardFlexBasisAndWidth(
                            numElements.numSm,
                            theme,
                            numElements.numSm < numProductCatalogs,
                        ),
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexBasis: getCardFlexBasisAndWidth(
                            numElements.numXs,
                            theme,
                            numElements.numXs < numProductCatalogs,
                        ),
                        maxWidth: getCardFlexBasisAndWidth(
                            numElements.numXs,
                            theme,
                            numElements.numXs < numProductCatalogs,
                        ),
                        minWidth: getCardFlexBasisAndWidth(
                            numElements.numXs,
                            theme,
                            numElements.numXs < numProductCatalogs,
                        ),
                    },
                }}
            >
                {children}
            </Box>
        );
    else return children;
}
