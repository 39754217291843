import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { getCsvColorsAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageList from './PackageList';
import { getCsvAttribute } from '@repo/widget-utils/attributes/attribute-helper';

class BilberryPackage extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);
        const packageIds = getCsvAttribute(this, 'package-ids');
        const packageUrls = getCsvAttribute(this, 'package-urls');

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageList
                    packageIds={packageIds}
                    packageUrls={packageUrls}
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-package-list', BilberryPackage);
}
