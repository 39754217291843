import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    getStringAttribute,
    getYesAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';
import { getCsvColorsAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import TourListBookable from './TourListBookable';

class BilberryTourListBookable extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const ids = this.getAttribute('tour-ids')?.split(',') ?? [];
        const hideReadMore = getYesAttribute(this, 'hide-read-more');
        const hideImage = getYesAttribute(this, 'hide-image');
        const hideDescription = getYesAttribute(this, 'hide-description');
        const hideTitle = getYesAttribute(this, 'hide-title');

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);
        const orientation = getStringAttribute(this, 'orientation', true) as
            | 'landscape'
            | 'portrait';

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <TourListBookable
                    ids={ids}
                    hideReadMore={hideReadMore}
                    numElements={numElements}
                    scroll={scroll}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    orientation={orientation}
                    hideImage={hideImage}
                    hideDescription={hideDescription}
                    hideTitle={hideTitle}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-tour-list-bookable', BilberryTourListBookable);
}
