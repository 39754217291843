import { Grid, Typography, useTheme } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import WarningIcon from '@mui/icons-material/WarningRounded';

export function WarningMessage(props: { label?: string; color?: string }) {
    const theme = useTheme();
    const customizations = useCustomizations();

    if (!props.label) return null;

    const color = props.color ?? customizations.bookingWidgetColorContrast;

    return (
        <Grid textAlign="center" display="flex" justifyContent="center">
            <WarningIcon
                sx={{
                    color,
                }}
            ></WarningIcon>
            <Typography color={{ color }} ml={theme.spacing(1)}>
                {props.label}
            </Typography>
        </Grid>
    );
}
