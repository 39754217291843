import { Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { groupBy } from 'lodash-es';
import { SmallerTextInline } from 'src/components/common/smaller-text-inline/SmallerTextInline';
import { useLocale, formatCurrency, getLocaleNumberFormatNoDecimals } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import {
    BilberryPromoCodeStatus,
    Currency,
    PackageTicketOptionWithQuantity,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
    Translations,
} from '@repo/types';
import { getPriceFromTicketOption } from '@repo/widget-utils/price-helper';
import { useAtom } from 'ximple';
import { useCartContext } from 'src/widgets/CartContext';

type PromoCodeUsageContext = {
    promoCodeUsages: Map<string, number>;
    promoCodeUsagesPerOrder: Map<string, number>;
};

export function SummaryInfoFromQuantities({
    quantities,
    ticketType,
    invertedColorsClass,
    isUsingPaymentPlan,
}: {
    quantities: TicketOptionWithQuantity[];
    ticketType?: TicketType;
    invertedColorsClass?: SxProps;
    isUsingPaymentPlan: boolean;
}) {
    const { locale, t } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const { appliedPromoCode } = useCartContext();

    const promoCodeUsageContext: PromoCodeUsageContext = {
        promoCodeUsages: new Map<string, number>(),
        promoCodeUsagesPerOrder: new Map<string, number>(),
    };

    return (
        <>
            {quantities.map((quantity) => {
                return (
                    <SummaryInfoFromQuantity
                        key={quantity.id}
                        quantity={quantity}
                        promoCodeUsageContext={promoCodeUsageContext}
                        ticketType={ticketType}
                        invertedColorsClass={invertedColorsClass}
                        currency={currency}
                        isUsingPaymentPlan={isUsingPaymentPlan}
                        locale={locale}
                        t={t}
                        theme={theme}
                        appliedPromoCode={appliedPromoCode}
                    ></SummaryInfoFromQuantity>
                );
            })}
        </>
    );
}

function SummaryInfoFromQuantity(props: {
    quantity: TicketOptionWithQuantity;
    promoCodeUsageContext: PromoCodeUsageContext;
    ticketType?: TicketType;
    invertedColorsClass?: SxProps;
    currency: Currency;
    isUsingPaymentPlan: boolean;
    locale: string;
    t: Translations;
    theme: Theme;
    appliedPromoCode: BilberryPromoCodeStatus | null;
}) {
    const {
        quantity,
        promoCodeUsageContext,
        ticketType,
        invertedColorsClass,
        currency,
        isUsingPaymentPlan,
        locale,
        t,
        theme,
        appliedPromoCode,
    } = props;

    const productType = quantity.productInstances[0]?.product?.type ?? '';
    const isTimeslot = productType === 'timeslot';
    const isTimepoint = productType === 'timepoint';
    const isNights = productType === 'nights';
    const isDays = productType === 'days';
    const isAccommodation = productType === 'accommodation';
    const isPackage = !!(quantity as PackageTicketOptionWithQuantity).products;

    let duration = 0;
    if (isNights || isAccommodation) duration = getDuration(quantity.productInstances, 'nights');
    else if (isDays) duration = getDuration(quantity.productInstances);

    const {
        creditsUsed = 0,
        totalPriceExDiscounts,
        promoCodeDiscounts,
    } = getPriceFromTicketOption(quantity, ticketType, appliedPromoCode, promoCodeUsageContext);

    let { name } = quantity;
    if (isAccommodation) name = t.rooms.num(1);

    return (
        quantity.quantity > 0 && (
            <Grid container justifyContent="space-between" wrap="nowrap" pt={theme.spacing(1)}>
                <Typography align="left" sx={invertedColorsClass}>
                    {isAccommodation && name}
                    {!isAccommodation && (
                        <>
                            {quantity.quantity}x {name}
                        </>
                    )}
                    <SmallerTextInline size="75%">
                        {!isTimeslot && !isUsingPaymentPlan && (
                            <>
                                {' '}
                                (
                                {(isTimepoint || isPackage) &&
                                    formatCurrency(locale, currency, quantity.price)}
                                {!isPackage &&
                                    (isNights || isAccommodation) &&
                                    `${duration} ${t.night.num(duration)}`}
                                {!isPackage && isDays && `${duration} ${t.days.num(duration)}`})
                            </>
                        )}
                    </SmallerTextInline>
                </Typography>
                <Typography align="right" sx={invertedColorsClass}>
                    {isUsingPaymentPlan && totalPriceExDiscounts === 0 ? (
                        creditsUsed > 0 ? (
                            <>
                                {creditsUsed}&nbsp;
                                {t.credit.num(creditsUsed).toLowerCase()}
                            </>
                        ) : promoCodeDiscounts.length > 0 ? (
                            <s>{formatCurrency(locale, currency, quantity.price)}</s>
                        ) : (
                            t.inclInMembership
                        )
                    ) : (
                        getLocaleNumberFormatNoDecimals(locale, totalPriceExDiscounts)
                    )}
                </Typography>
            </Grid>
        )
    );
}

function getDuration(productInstances: ProductInstance[], type: 'nights' | 'days' = 'days') {
    const grouped = groupBy(productInstances, (instance) => instance.start.format('YYYY-MM-DD'));
    return Object.keys(grouped).length - (type === 'nights' ? 1 : 0);
}
