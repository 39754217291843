import { Error, Info, Warning } from '@mui/icons-material';
import { Grid, lighten, Typography, useTheme } from '@mui/material';

import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';

type InfoType = 'information' | 'warning' | 'error';

type InfoBoxProps = {
    text: string;
    infoType: InfoType;
};

export default function InfoBox({ text, infoType }: InfoBoxProps) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const icon = getIcon(infoType, customizations);

    return (
        <Grid
            container
            width="100%"
            p={theme.spacing(4, 4, 4, 3)}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius={`${customizations.borderRadius}px`}
            sx={getColor(infoType, customizations)}
            wrap="nowrap"
        >
            <Grid item display="flex" justifyContent="center" mr={theme.spacing(3)}>
                {icon}
            </Grid>
            <Typography>{text}</Typography>
        </Grid>
    );
}

function getColor(infoType: InfoType, customizations: ThemeType) {
    switch (infoType) {
        case 'information':
            return {
                backgroundColor: lighten(customizations.informationColor, 0.8),
                border: `1px ${customizations.informationColor} solid`,
            };
        case 'warning':
            return {
                backgroundColor: lighten(customizations.warningColor, 0.8),
                border: `1px ${customizations.warningColor} solid`,
            };
        case 'error':
            return {
                backgroundColor: lighten(customizations.errorColor, 0.8),
                border: `1px ${customizations.errorColor} solid`,
            };
    }
}

function getIcon(infoType: InfoType, customizations: ThemeType) {
    switch (infoType) {
        case 'information':
            return <Info fontSize="large" style={{ color: customizations.bodyColor }} />;
        case 'warning':
            return <Warning fontSize="large" style={{ color: customizations.bodyColor }} />;
        case 'error':
            return <Error fontSize="large" style={{ color: customizations.bodyColor }} />;
    }
}
