import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { FacilitiesAttributes, Product, Translations } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getImportantFacilities } from '@repo/widget-utils/accommodations/accommodationUtils';
import {
    SvgIconComponent,
    Accessible,
    Help,
    Kitchen,
    LocalDining,
    PersonOutlined,
    Pets,
    SmokingRooms,
    Wifi,
} from '@mui/icons-material';

type AccommodationOverviewFacilityIconsProps = {
    accommodation: Product;
    numberOfIcons?: number;
};

export default function AccommodationOverviewFacilityIcons({
    accommodation,
    numberOfIcons,
}: AccommodationOverviewFacilityIconsProps) {
    const { t } = useLocale();

    const notPhoneScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const iconCount = notPhoneScreen ? 5 : 3;
    const icons = getIconSet(
        accommodation,
        numberOfIcons ? numberOfIcons : iconCount,
        t,
        (icon, label, i) => <AccommodationOverviewIcon key={i} Icon={icon} label={label} />,
    );

    return (
        <Grid
            container
            item
            sx={{
                containerType: 'inline-size',
            }}
        >
            <Grid
                container
                direction="row"
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(50px, 1fr))"
                justifyContent="center"
            >
                {icons}
            </Grid>
        </Grid>
    );
}

function AccommodationOverviewIcon(props: { Icon: SvgIconComponent; label: string }) {
    const { Icon, label } = props;
    const customizations = useCustomizations();

    return (
        <Grid
            container
            item
            pb={2}
            sx={{
                width: 'auto',
                boxSizing: 'border-box',
                ['&:last-of-type']: {
                    paddingRight: 0,
                },
            }}
        >
            <Grid container direction="column" flexGrow={1} alignItems="center">
                <Icon
                    sx={{
                        fontSize: '1.5em',
                        ['@container (width > 350px)']: {
                            fontSize: '2.5em',
                        },
                    }}
                />
                <Typography
                    variant="h5"
                    pt={1}
                    fontSize={customizations.fontSize * 0.85}
                    sx={{
                        '& *': {
                            whiteSpace: 'break-spaces',
                        },
                    }}
                >
                    <Box>{label}</Box>
                </Typography>
            </Grid>
        </Grid>
    );
}

export function getIconSet(
    accommodation: Product,
    numberOfIcons: number,
    t: Translations,
    iconDecorator: (icon: SvgIconComponent, label: string, i: number) => JSX.Element,
) {
    const guestIcons = getGuestIcons(t, accommodation.capacity) ?? [];
    const importantFacilities = getImportantFacilities(
        accommodation.accommodationAttributes?.facilities,
    );
    const facilityIcons = importantFacilities.map((facility) => toIconData(facility, t));

    const icons = [...guestIcons, ...facilityIcons]
        .slice(0, numberOfIcons)
        .map(({ icon, label }, i) => iconDecorator(icon, label, i));

    return icons;
}

function toIconData(facility: keyof FacilitiesAttributes, t: Translations) {
    switch (facility) {
        case 'hasWifi':
            return { icon: Wifi, label: t.has_wifi };
        case 'isBreakfastIncluded':
            return { icon: LocalDining, label: t.breakfast };
        case 'isWheelchairAccessible':
            return { icon: Accessible, label: t.accessible };
        case 'isPetsAllowed':
            return { icon: Pets, label: t.pets };
        case 'hasKitchen':
            return { icon: Kitchen, label: t.kitchen };
        case 'isSmokingAllowed':
            return { icon: SmokingRooms, label: t.smoking };
        default:
            return { icon: Help, label: t.unknown };
    }
}

function getGuestIcons(t: Translations, count?: number) {
    if (!count) return [];
    return [
        {
            icon: PersonOutlined,
            label: `${count} ${t.person.num(count)}`,
        },
    ];
}
