import { createRoot } from 'react-dom/client';
import ModalCollection from 'src/widgets/activities/modal-widget/modal/ModalCollection';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class ModalElem extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ModalCollection />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-modal', ModalElem);
}
