import { PackageTicketOptionWithQuantity, ProductInstance } from '@repo/types';

export function getPackageTicketOptionsWithTours(
    ticketOptions: PackageTicketOptionWithQuantity[],
    availabilities: ProductInstance[],
) {
    return ticketOptions
        .map((to) => {
            const tours = availabilities.filter((a) =>
                a.ticketOptions.some(
                    (t) =>
                        to.quantity > 0 &&
                        t.ticketCategoryId === to.ticketCategoryId &&
                        to.products.some((pid) => pid === a.product?.id),
                ),
            );
            return { ticketOption: to, tours };
        })
        .filter((to) => to.ticketOption.quantity > 0);
}
