import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import invert from 'lodash-es/invert';
import { getDialcodes } from './countries';
import { PhoneNumber } from '@repo/types';

export function validateTextField(value: string) {
    return value.length !== 0;
}
export function validateEmail(email: string) {
    return /^.+@.+\..+$/.test(email);
}

export function validatePhone(phone: PhoneNumber) {
    const countriesByDialCode = invert(getDialcodes());
    const isValidDialCode = countriesByDialCode[phone.dialCode];
    const isValidNumber = /^[0-9]{4,}$/.test(phone.number); //At least 4 digits
    return !!isValidDialCode && isValidNumber;
}

export function validateDateRange(value: DateRange<TZDate>) {
    return value.some((date) => date);
}

export function validateOrgNumberByCountry(orgNumber: string, country: string | undefined) {
    if (!country) return false;

    if (country === 'no') {
        return orgNumber.match(/^[0-9]{9}$/) !== null;
    } else {
        return orgNumber.length > 0;
    }
}
