import {
    getStringAttribute,
    getYesAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';
import { getBodyNode } from '@repo/widget-utils/dom-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';

class ActivateBilberryBooking extends BilberryCustomElement {
    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getStringAttribute(this, 'product-catalog-id', false) ?? '';

        const expandArrowInside = this.getAttribute('expand-arrow-inside');
        const positionOffscreen = this.getAttribute('position-offscreen');
        const isAccommodation = getYesAttribute(this, 'accommodation');
        const isTimeslots = getYesAttribute(this, 'timeslots');

        setBookingWidgetActiveProduct(
            productCatalogId,
            expandArrowInside,
            positionOffscreen,
            isAccommodation,
            isTimeslots,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking', ActivateBilberryBooking);
}

function setBookingWidgetActiveProduct(
    productCatalogId: string,
    expandArrowInside: string | null,
    positionOffscreen: string | null,
    isAccommodation?: boolean,
    isTimeslots?: boolean,
) {
    if (document.querySelector('bilberry-booking-widget') !== null) return;

    const booking = document.createElement('bilberry-booking-widget');
    if (productCatalogId) booking.setAttribute('product-catalog-id', productCatalogId.toString());
    if (expandArrowInside) booking.setAttribute('expand-arrow-inside', expandArrowInside);
    if (positionOffscreen) booking.setAttribute('position-offscreen', positionOffscreen);
    if (isAccommodation) booking.setAttribute('accommodation', 'yes');
    if (isTimeslots) booking.setAttribute('timeslots', 'yes');

    const body = getBodyNode();
    body.appendChild(booking);
}
