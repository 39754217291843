import { Box, Grid, Typography, useTheme } from '@mui/material';
import { sumBy } from 'lodash-es';
import { Fragment, useMemo } from 'react';
import { SmallerTextInline } from 'src/components/common/smaller-text-inline/SmallerTextInline';
import { useLocale, getLocaleNumberFormatNoDecimals } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { TicketOptionWithQuantity, Currency, CurrencyType } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple/atoms';

interface IProps {
    title: string;
    toggleButton?: JSX.Element;
    onClick?(): void;
    quantities?: TicketOptionWithQuantity[];
    fromPrice?: number | null;
    currencyType?: CurrencyType;
    justifyTitle?: 'space-between' | 'center';
    addMarginRightToHeader?: boolean;
    expandArrowInside?: boolean;
    hidePrice?: boolean;
}

const CurrencyCost = ({ fromPrice, totalPrice, currencyType, currency }: CurrencyCostProps) => {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const price = getLocaleNumberFormatNoDecimals(locale, fromPrice ? fromPrice : totalPrice);
    if (currencyType === 'money') {
        return (
            <Fragment>
                <SmallerTextInline size="60%">
                    {fromPrice ? t.from : ''} {currency.currency}&nbsp;&nbsp;
                </SmallerTextInline>
                <Box component="span" fontWeight={customizations.boldFontWeight}>
                    {price}
                </Box>
            </Fragment>
        );
    } else if (currencyType === 'credits')
        return (
            <Fragment>
                <Box component="span" fontWeight={customizations.boldFontWeight}>
                    {price}
                </Box>
                <SmallerTextInline size="60%">
                    &nbsp;&nbsp;{t.credit.num(totalPrice)}
                </SmallerTextInline>
            </Fragment>
        );
    return (
        <Fragment>
            <SmallerTextInline size="60%">{t.inclInMembership}</SmallerTextInline>
        </Fragment>
    );
};

export function BookingHeader(props: IProps): JSX.Element {
    const {
        quantities,
        title,
        toggleButton,
        onClick,
        justifyTitle = 'space-between',
        fromPrice,
        addMarginRightToHeader,
        expandArrowInside = false,
        hidePrice,
    } = props;
    const customizations = useCustomizations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const totalPrice = useTotalPrice(quantities);
    const currencyType = getCurrencyTypeFromQuantities(quantities);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={justifyTitle}
                pr={addMarginRightToHeader ? `${theme.spacing(8)} !important` : ''}
                padding={theme.spacing(expandArrowInside ? 4 : 3, 4, 0, 4)}
                mt={0}
                sx={{
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <Typography variant="h4" color={customizations.bookingWidgetHeaderColor}>
                    {title}
                </Typography>
                {quantities && !hidePrice && (
                    <Grid item>
                        <Grid container alignItems="flex-end">
                            <Typography
                                variant="h4"
                                color={customizations.bookingWidgetHeaderColor}
                            >
                                <CurrencyCost
                                    fromPrice={fromPrice}
                                    totalPrice={
                                        currencyType === 'money'
                                            ? totalPrice.money
                                            : totalPrice.credits
                                    }
                                    currencyType={currencyType}
                                    currency={currency}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {toggleButton}
        </Fragment>
    );
}

function getCurrencyTypeFromQuantities(quantities?: TicketOptionWithQuantity[]): CurrencyType {
    const isMembership = quantities?.some((q) => q.membership);
    const isCredit = quantities?.some(
        (q) =>
            q.membership &&
            q.membership.some((m) => m.valueCardUsages.some((vcu) => vcu.creditCost > 0)),
    );
    const isCurrency =
        !isMembership || quantities?.some((q) => q.membership?.some((m) => !!m.currencyCost));
    if (!isCurrency && !isCredit) return 'membership';
    else if (isCredit) return 'credits';
    else return 'money';
}

function useTotalPrice(quantities?: TicketOptionWithQuantity[]) {
    return useMemo(
        () =>
            quantities
                ? quantities.reduce(
                      (acc, val) => {
                          if (val.membership) {
                              acc.credits +=
                                  sumBy(
                                      val.membership.flatMap((m) => m.valueCardUsages),
                                      'creditCost',
                                  ) / 100;
                              acc.money +=
                                  sumBy(val.membership, (m) => m.currencyCost?.price ?? 0) / 100;
                              return acc;
                          } else {
                              acc.money += val.price * val.quantity;
                              return acc;
                          }
                      },
                      { money: 0, credits: 0 },
                  )
                : { money: 0, credits: 0 },
        [quantities],
    );
}

type CurrencyCostProps = {
    fromPrice: number | null | undefined;
    totalPrice: number;
    currencyType: CurrencyType;
    currency: Currency;
};
