import { Fragment } from 'react';
import { ReactComponent as CreditCardIcon } from 'src/assets/icons/creditcard.svg';
import CheckboxPanel from 'src/components/common/timeslots-checkbox-panel/CheckboxPanel';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';

type MoneyPaymentProps = {
    variant?: 'radio' | 'checkbox';
    isChecked?: boolean;
    onClick: () => void;
    disabled: boolean;
};

export default function MoneyPaymentPanel({
    variant = 'radio',
    isChecked = false,
    onClick,
    disabled,
}: MoneyPaymentProps) {
    const { t } = useLocale();

    return variant === 'radio' ? (
        <RadioPanel
            disabled={disabled}
            isChecked={isChecked}
            Icon={CreditCardIcon as any}
            text={capitalize(t.card.singular)}
            onChange={onClick}
            notExpandable
        >
            <Fragment></Fragment>
        </RadioPanel>
    ) : (
        <CheckboxPanel
            disabled={disabled}
            isChecked={isChecked}
            Icon={CreditCardIcon as any}
            text={capitalize(t.card.singular)}
            onChange={onClick}
            notExpandable
        >
            <Fragment></Fragment>
        </CheckboxPanel>
    );
}
