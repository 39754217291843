import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import QuantityPicker from 'src/components/common/quantity-picker/QuantityPicker';
import { useLocale } from '@repo/i18n';
import ChildrenData from './ChildrenData';
import {
    ChildInfo,
    GuestInfo,
    updateAdultCountInRoom,
    updateChildrenInRoom,
    updateRoomChildCount,
} from './guestInfo';

interface Props {
    maxValue?: number;
    name: string;
    hotelRoomIndex: number;
    hotelRoomsInfo: GuestInfo[];
    onHotelRoomsInfoChanged: (param: GuestInfo[]) => void;
    showChildrenData: boolean;
}
export default function SelectGuests(props: Props): JSX.Element {
    const { maxValue, name, hotelRoomIndex, hotelRoomsInfo, onHotelRoomsInfoChanged } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const maxAdults =
        maxValue && hotelRoomsInfo[hotelRoomIndex] && hotelRoomsInfo[hotelRoomIndex].children
            ? maxValue - (hotelRoomsInfo[hotelRoomIndex]?.children.length ?? 0)
            : maxValue;
    const maxChildren =
        maxValue && hotelRoomsInfo[hotelRoomIndex]
            ? maxValue - (hotelRoomsInfo[hotelRoomIndex]?.adults ?? 0)
            : maxValue;

    return (
        <Grid
            component={Paper}
            variant="outlined"
            padding={theme.spacing(2, 1)}
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            borderRadius={0}
            borderColor={theme.palette.secondary.main}
            borderLeft="none"
            borderRight="none"
            sx={{
                backgroundColor: theme.palette.grey[100],
            }}
        >
            <Grid container direction="column" alignItems="center">
                <Typography mb={theme.spacing(1)}>{name}</Typography>
                <Grid container item padding={theme.spacing(0, 3)}>
                    <QuantityPicker
                        maxValue={maxAdults || Infinity}
                        minValue={hotelRoomsInfo[hotelRoomIndex]?.children.length === 0 ? 1 : 0}
                        name={t.adult.plural}
                        value={hotelRoomsInfo[hotelRoomIndex]?.adults}
                        onChange={(newNumberOfAdults) => {
                            handleAdultsChanged(
                                newNumberOfAdults,
                                hotelRoomsInfo,
                                hotelRoomIndex,
                                onHotelRoomsInfoChanged,
                                maxAdults,
                            );
                        }}
                        color={theme.palette.common.black}
                        backgroundColor={theme.palette.common.white}
                    />
                    <QuantityPicker
                        maxValue={maxChildren || Infinity}
                        minValue={hotelRoomsInfo[hotelRoomIndex]?.adults === 0 ? 1 : 0}
                        name={t.children.plural}
                        value={hotelRoomsInfo[hotelRoomIndex]?.children.length}
                        onChange={(newNumberOfChildren) =>
                            handleNumberOfChildrenChanged(
                                newNumberOfChildren,
                                hotelRoomsInfo,
                                hotelRoomIndex,
                                onHotelRoomsInfoChanged,
                                maxChildren,
                            )
                        }
                        color={theme.palette.common.black}
                        backgroundColor={theme.palette.common.white}
                    />
                </Grid>
                {props.showChildrenData &&
                    hotelRoomsInfo[hotelRoomIndex]?.children.map((child) => (
                        <Box key={child.childId} width="100%">
                            <ChildrenData
                                childId={child.childId}
                                child={child}
                                childrenList={hotelRoomsInfo[hotelRoomIndex]?.children}
                                onChildrenListChanged={(newChildrenInfo) =>
                                    handleChildrenListChanged(
                                        newChildrenInfo,
                                        hotelRoomsInfo,
                                        hotelRoomIndex,
                                        onHotelRoomsInfoChanged,
                                    )
                                }
                            />
                        </Box>
                    ))}
            </Grid>
        </Grid>
    );
}

function handleChildrenListChanged(
    newChildrenInfo: ChildInfo[],
    hotelRoomsInfo: GuestInfo[],
    hotelRoomIndex: number,
    onHotelRoomsInfoChanged: (param: GuestInfo[]) => void,
) {
    const updatedRooms = updateChildrenInRoom(hotelRoomsInfo, hotelRoomIndex, newChildrenInfo);
    onHotelRoomsInfoChanged(updatedRooms);
}

function handleAdultsChanged(
    newNumberOfAdults: number,
    hotelRoomsInfo: GuestInfo[],
    hotelRoomIndex: number,
    onHotelRoomsInfoChanged: (param: GuestInfo[]) => void,
    maxValue: number | undefined,
) {
    if (maxValue && newNumberOfAdults > maxValue) return;
    const updatedRooms = updateAdultCountInRoom(hotelRoomsInfo, hotelRoomIndex, newNumberOfAdults);
    onHotelRoomsInfoChanged(updatedRooms);
}

function handleNumberOfChildrenChanged(
    newNumberOfChildren: number,
    hotelRoomsInfo: GuestInfo[],
    hotelRoomIndex: number,
    onHotelRoomsInfoChanged: (param: GuestInfo[]) => void,
    maxValue: number | undefined,
) {
    if (maxValue && newNumberOfChildren > maxValue) return;
    const updatedRooms = updateRoomChildCount(hotelRoomsInfo, hotelRoomIndex, newNumberOfChildren);
    onHotelRoomsInfoChanged(updatedRooms);
}
