import { Stack, SxProps, useTheme } from '@mui/material';

type Spacing = 'none' | 'tiny' | 'dense' | 'small' | 'snug' | 'normal' | 'wide';
type LayoutProps = {
    flexDirection?: 'row' | 'column';
    mobileSx?: SxProps;
    mobileFlexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    justify?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'baseline'
        | 'space-between'
        | 'space-evenly'
        | 'stretch'
        | 'normal';
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' | 'normal';
    spacing?: Spacing;
    children: React.ReactNode[] | React.ReactNode;
    sx?: SxProps;
    onClick?: (value?: any) => void;
    tabbable?: boolean;
};

export default function Layout(props: LayoutProps) {
    const {
        flexDirection = 'row',
        mobileFlexDirection,
        mobileSx,
        justify = 'flex-start',
        alignItems = 'stretch',
        spacing = 'normal',
        children,
        sx,
        onClick,
        tabbable,
    } = props;
    const theme = useTheme();

    const space = spacingAmount(spacing);
    const mobileDirection = mobileFlexDirection ?? flexDirection;

    return (
        <Stack
            onKeyDown={(e: any) => {
                onClick && e.key === 'Enter' && onClick();
            }}
            onClick={onClick}
            tabIndex={tabbable ? 0 : undefined}
            gap={theme.spacing(space)}
            direction={flexDirection}
            sx={{
                ...sx,
                cursor: onClick ? 'pointer' : undefined,
                [theme.breakpoints.down('md')]: {
                    ...mobileSx,
                    flexDirection: mobileDirection,
                },
                justifyContent: justify,
                alignItems: alignItems,
            }}
        >
            {children}
        </Stack>
    );
}

function spacingAmount(spacing: Spacing) {
    switch (spacing) {
        case 'none':
            return 0;
        case 'tiny':
            return 0.125;
        case 'dense':
            return 0.25;
        case 'small':
            return 0.5;
        case 'snug':
            return 0.75;
        case 'normal':
            return 1;
        case 'wide':
            return 1.5;
    }
}
