import { Box, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { route } from 'src/components/common/router/Router';
import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import LoginButton from '../login-button/LoginButton';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function LoggedInPanel() {
    const { t } = useLocale();
    const { loggedInUser: data } = useMemberContext();
    const theme = useTheme();

    const handleNavigateToProfile = () => {
        route('/my-profile');
    };

    return (
        <Panel
            variant="shaded"
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                borderColor: theme.palette.primary.main,
                borderWidth: '2px',
                border: 'solid',
                [theme.breakpoints.down('md')]: { width: 'auto' },
            }}
        >
            <Layout
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                justify="space-between"
                alignItems="center"
            >
                <Box>
                    {data?.firstName && data?.lastName ? (
                        <Fragment>
                            <Text variant="small">
                                <span>{t.loggedInAs}:</span>
                            </Text>
                            <PanelTitle variant="h3">
                                <span>
                                    {data.firstName} {data.lastName}
                                </span>
                            </PanelTitle>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Text variant="small">
                                <span>{t.noUserInfoFound}</span>
                            </Text>
                            <Text variant="small">
                                <span>{t.pleaseFillOutUserInfoInProfile}</span>
                            </Text>
                        </Fragment>
                    )}
                    <span
                        style={{
                            display: 'inline-block',
                            paddingTop: theme.spacing(0.5),
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <LinkButton
                            isLowercase
                            text={t.goToMyPage}
                            onClick={handleNavigateToProfile}
                        />
                    </span>
                </Box>
                <Box sx={{ flex: '0 0 auto' }}>
                    <LoginButton />
                </Box>
            </Layout>
        </Panel>
    );
}
