import { BilberryWidgetsGlobal } from '@repo/types';

export function getAlternateBodySelector() {
    const widgetsConfig = (window as any).BilberryWidgetsGlobal as BilberryWidgetsGlobal;
    return widgetsConfig.alternateBodySelector ?? null;
}

export function getBodyNode() {
    const alternateBodySelector = getAlternateBodySelector();
    return alternateBodySelector
        ? document.body.querySelector(alternateBodySelector)!
        : document.body;
}
