import countBy from 'lodash-es/countBy';
import { debugLog } from '@repo/common-utils/Logger';
import { getStep } from './checkout-step';
import {
    AddToCartEvent,
    BilberryWidgetEvent,
    BookEvent,
    CheckoutStepEvent,
    Currency,
    EventProduct,
    PaymentInfoAddedEvent,
    Product,
    RemoveFromCartEvent,
    StartCheckoutEvent,
    TotalPrice,
    ViewItemEvent,
} from './events.types';

declare global {
    interface Window {
        fbq: any;
    }
}

const mapPrice = (product: EventProduct, currency: Currency) => (price: TotalPrice) => {
    const { priceType, numberOfTravelers, totalIncVat } = price;
    return {
        id: product.productId.toString(),
        name: product.productTitle,
        content_type: priceType,
        value: totalIncVat / numberOfTravelers,
        quantity: numberOfTravelers,
        currency,
    };
};

export function addToCart(event: AddToCartEvent) {
    return {
        contents: event.products
            .map((product) => product.prices.map(mapPrice(product.product, product.currency)))
            .flat(),
        content_type: 'product',
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function removeFromCart(event: RemoveFromCartEvent) {
    return {
        contents: event.products
            .map((product) => product.prices.map(mapPrice(product.product, product.currency)))
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function startCheckout(event: StartCheckoutEvent) {
    return {
        contents: event.products
            .map(({ product, prices }) => {
                return prices.map(mapPrice(product, event.currency));
            })
            .flat(),
        content_category: getContentCategory(event.products),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function paymentInfoAdded(event: PaymentInfoAddedEvent) {
    return {
        contents: event.products
            .map(({ product, prices }) => {
                return prices.map(mapPrice(product, event.currency));
            })
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function book(event: BookEvent) {
    return {
        contents: event.products
            .map(({ product, prices }) => prices.map(mapPrice(product, event.currency)))
            .flat(),
        content_type: 'product',
        num_items: event.products.length,
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function viewItem(event: ViewItemEvent) {
    return {
        contents: event.products
            .map(({ product, prices }) => prices.map(mapPrice(product, event.currency)))
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function checkoutStep(event: CheckoutStepEvent) {
    return {
        checkout_step: getStep(event.checkoutStep),
        checkout_step_title: event.checkoutStep,
    };
}

export function subscriber(event: BilberryWidgetEvent) {
    debugLog('Pixel custom event triggered with data: ', event);

    // Handle event based on type
    switch (event.type) {
        case 'addToCart':
            return window.fbq('track', 'AddToCart', addToCart(event));
        // NOTE: Pixel doesn't seem to natively support "Remove from cart" events in their standard event set,
        // so it is tracked as a custom event.
        case 'removeFromCart':
            return window.fbq('trackCustom', 'remove_from_cart', removeFromCart(event));
        case 'startCheckout':
            return window.fbq('track', 'InitiateCheckout', startCheckout(event));
        case 'paymentInfoAdded':
            return window.fbq('track', 'AddPaymentInfo', paymentInfoAdded(event));
        case 'book':
            return window.fbq('track', 'Purchase', book(event));
        case 'viewItem':
            return window.fbq('track', 'ViewContent', viewItem(event));
        case 'checkoutStep':
            return window.fbq('track', 'ViewContent', checkoutStep(event));
    }
}

function getContentCategory(products: Product[]) {
    const productsByType = countBy(products, (product) => product.product.productType);
    const mostCommonKey = Object.entries(productsByType).reduce(
        (acc, [key, value]) => ({ key, value: Math.max(acc.value, value) }),
        { key: '', value: 0 },
    ).key as Product['product']['productType'];

    switch (mostCommonKey) {
        case 'timepoint':
            return 'activity';
        case 'accommodation':
            return 'accommodation';
        case 'nights':
            return 'accommodation';
        case 'timeslot':
            return 'timeslot';
        case 'ValueCard':
            return 'valuecard';
        case 'Giftcard':
        case 'Applied Giftcard':
            return 'giftcard';
        default:
            return 'unknown';
    }
}
