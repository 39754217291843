import { searchFormDataAtom } from 'src/state/search-form-data/searchFormDataAtom';
import { ProductSearch } from '@repo/types';
import { SearchForm } from 'src/widgets/activities/booking-search/search-form/SearchForm';
import { useAtom } from 'ximple';

export default function BookingSearchFormWidgetRoot(props: {
    resultUrl: string;
    hideDifficulty: boolean;
    hideDuration: boolean;
    hideQuantities: boolean;
    limitToProducts: string[];
    locations: string[];
    excludeProducts: string[];
}): JSX.Element {
    const [searchFormData, setSearchFormData] = useAtom(searchFormDataAtom);
    const onClick = (formData: ProductSearch) => {
        setSearchFormData(formData);
        window.location.href = props.resultUrl;
    };

    return (
        <SearchForm
            onClick={onClick}
            minDuration={0}
            maxDuration={Number.MAX_VALUE}
            defaultFormData={searchFormData}
            hideDifficulty={props.hideDifficulty}
            hideDuration={props.hideDuration}
            hideQuantities={props.hideQuantities}
            locations={props.locations}
            products={props.limitToProducts}
            excludeProducts={props.excludeProducts}
        />
    );
}
