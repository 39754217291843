import { Box, Button, Stack, Typography } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { useAtom } from 'ximple';
import { Package } from '@repo/types';
import { ProductCard2Landscape } from '../../product-card-2/ProductCardLandscape';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { groupBy } from 'lodash-es';

type ItineraryProps = {
    pkg: Package;
};

export default function Itinerary(props: ItineraryProps) {
    const { t } = useLocale();

    const { pkg } = props;

    const [toggleBookingState] = useAtom(toggleBookingAtom);
    const handleBookNowClicked = () =>
        toggleBookingAtom.update({ visible: !toggleBookingState.visible, interaction: 'mouse' });
    const handleBookNowEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            toggleBookingAtom.update({
                visible: !toggleBookingState.visible,
                interaction: 'keyboard',
            });
        }
    };

    const groupedByTicketOption = Object.values(
        groupBy(pkg.products, (product) => product.pkgTicketOptionId),
    ).map((products) => {
        const productGroup = {
            accommodations: Object.values(
                groupBy(
                    products.filter((product) => product.type === 'nights'),
                    (accommodation) => accommodation.id,
                ),
            ),
            activities: products.filter((product) => product.type !== 'nights'),
            ticketOptionName: '',
        };
        productGroup.ticketOptionName = products[0].pkgTicketOptionName ?? '';
        return productGroup;
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h2" mb={1}>
                {pkg.title}
            </Typography>
            <Typography>{parseHtmlToMui(pkg.shortDescription)}</Typography>
            <Stack gap={4} width="100%" my={3}>
                {groupedByTicketOption.map((products, i, groups) => (
                    <Box key={i}>
                        {groups.length > 1 && (
                            <Typography variant="h3" mb={2}>
                                {products.ticketOptionName}
                            </Typography>
                        )}
                        <Stack gap={2}>
                            {products.accommodations.map(([accommodation]) => (
                                <ProductCard2Landscape
                                    product={accommodation}
                                    key={accommodation.id + accommodation.pkgProductId}
                                    readMoreLinkVariant="popup"
                                    readMoreProductId={accommodation.id}
                                    infoAndImageAsLandscape
                                    hideIcons
                                    maxImageHeightDesktop={150}
                                />
                            ))}
                            {products.activities.map((product) => (
                                <ProductCard2Landscape
                                    product={product}
                                    key={product.id + product.pkgProductId}
                                    readMoreLinkVariant="popup"
                                    readMoreProductId={product.id}
                                    hideIcons
                                    infoAndImageAsLandscape
                                    maxImageHeightDesktop={150}
                                />
                            ))}
                        </Stack>
                    </Box>
                ))}
            </Stack>
            <Button
                variant="contained"
                onClick={handleBookNowClicked}
                onKeyDown={handleBookNowEnter}
            >
                {t.bookNow}
            </Button>
        </Box>
    );
}
