import { createRoot } from 'react-dom/client';
import { getIdAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageBookingInline from './PackageBookingInline';

class PackageBookingInlineWidget extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const packageId = getIdAttribute(this, false, 'package-id').toString();

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageBookingInline packageId={packageId} />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-package-booking-inline', PackageBookingInlineWidget);
}
