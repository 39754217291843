import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, Collapse, Stack, SvgIcon, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';

type CheckboxPanelProps = {
    Icon: typeof SvgIcon;
    text: string;
    subtext?: string;
    children: React.ReactNode[] | React.ReactNode;
    isChecked?: boolean;
    onChange: (param: boolean) => void;
    disabled: boolean;
    notExpandable?: boolean;
};

export default function CheckboxPanel(props: CheckboxPanelProps) {
    const {
        Icon,
        text,
        subtext,
        children,
        isChecked = false,
        onChange,
        disabled,
        notExpandable = false,
    } = props;
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxClicked = () => {
        onChange(!isChecked);
    };
    const handlePanelChange = (e: any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <Panel
            sx={{
                padding: theme.spacing(1, 2),
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? 'default' : 'pointer',
            }}
        >
            <Box
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Layout justify="flex-start" alignItems="center" onClick={handleCheckboxClicked}>
                    <Checkbox
                        disabled={disabled}
                        checked={isChecked}
                        color="primary"
                        value={text}
                        name={`${text}-checkbox-button`}
                        inputProps={{ 'aria-label': text }}
                    />
                    <Stack justifyItems="center">
                        <Icon fontSize="large" />
                    </Stack>
                    <PanelTitle>
                        <span>{text}</span>
                    </PanelTitle>
                </Layout>
                {!notExpandable && (
                    <Fragment>
                        {isOpen ? (
                            <ExpandLessIcon fontSize="large" onClick={handlePanelChange} />
                        ) : (
                            <Layout alignItems="center">
                                <Fragment>
                                    <Text variant="small">{subtext}</Text>
                                    <ExpandMoreIcon fontSize="large" onClick={handlePanelChange} />
                                </Fragment>
                            </Layout>
                        )}
                    </Fragment>
                )}
            </Box>
            {
                <Collapse in={isOpen} sx={{ width: '100%' }}>
                    <Layout flexDirection="column" justify="center" alignItems="center">
                        {children}
                    </Layout>
                </Collapse>
            }
        </Panel>
    );
}
