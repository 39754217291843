import { BrowserTracing } from '@sentry/tracing';
import { VERSION, BUILD_VERSION } from '@repo/widget-env/__autogen/env';
import {
    BrowserClient,
    Breadcrumbs,
    Dedupe,
    defaultStackParser,
    getCurrentHub,
    GlobalHandlers,
    makeFetchTransport,
    LinkedErrors,
    setTag,
} from '@sentry/browser';

export function initSentry() {
    // If sentry is disabled in configuration, don't init.
    if (((window as any).BilberryWidgetsGlobal as typeof BilberryWidgetsGlobal)?.disableSentry)
        return;

    let releaseName: string;
    if ((BUILD_VERSION as any) === 'production') {
        releaseName = `bilberry-widgets-${VERSION}`;
    } else if ((BUILD_VERSION as any) === 'latest-test') {
        releaseName = 'bilberry-booking-widgets-dev';
    } else {
        releaseName = BUILD_VERSION;
    }

    const client = new BrowserClient({
        release: releaseName,
        // all options {
        dsn: 'https://260695fe8b2b443595c86c8704993efa@o1299999.ingest.sentry.io/4504196337238016',
        integrations: [
            new Breadcrumbs(),
            new GlobalHandlers(),
            new LinkedErrors(),
            new Dedupe(),
            new BrowserTracing(),
        ],
        tracesSampleRate: 0.25, // Sample a quarter of all errors to reduce volume
        beforeSend(event) {
            if (
                event.exception?.values?.some(
                    (x) =>
                        x.stacktrace?.frames?.some((y) =>
                            y.filename?.includes('data.kraftlauget.no'),
                        ) ||
                        x.stacktrace?.frames?.some((y) =>
                            y.filename?.includes('bilberry-widgets.b-cdn.net'),
                        ),
                )
            ) {
                return event;
            }
            return null;
        },

        transport: makeFetchTransport,
        stackParser: defaultStackParser,
    });
    getCurrentHub().bindClient(client);

    setTag('domain', window.location.host);
}
