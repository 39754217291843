import ProductImage from './ProductImage';
import { useProduct } from '@repo/widget-utils/api/api';

export default function ProductImageWidgetRoot(props: {
    productCatalogId: number;
    format?: string;
}) {
    const { data: product } = useProduct(props.productCatalogId.toString());

    return (
        <ProductImage
            url={product?.coverImage.src}
            size="m"
            format={props.format as any}
            stretchToFit
        />
    );
}
