import { HashHistory, Update } from 'history';
import { useEffect, useMemo, useState } from 'react';
import { history } from 'src/utils/widget/setup';

export function Router({
    onChange,
    children,
    name,
}: {
    onChange?: (update: Update, history: HashHistory) => void;
    children: any;
    name?: string;
}) {
    const [currentPath, setCurrentPath] = useState(window.location.hash?.slice(1) ?? '/');

    useEffect(() => {
        const unlisten = history.listen((update) => {
            if (onChange) {
                onChange(update, history);
            }
            setCurrentPath(update.location.pathname);
        });
        return unlisten;
    }, [onChange, currentPath, name]);

    return children.filter((child: any) => child.props.path === currentPath);
}

export function Route({ path, children }: { path: string; children: any }) {
    return children;
}

export function route(path: string) {
    history.push(path, { path });
}

export function useQueryParams() {
    const { hash } = window.location;
    return useMemo(() => {
        const params = new URLSearchParams(hash.split('?')[1]);
        return Object.fromEntries(params.entries());
    }, [hash]);
}
