import { useEffect } from 'react';
import { getAlternateBodySelector } from '@repo/widget-utils/dom-helper';

/**
 * Function that initializes the required styling for hiding the
 * non-fullscreen elements within the document body. The function
 * should be called before the component declaration within the files
 * of components that need to be fullscreen, such as the checkout
 * components.
 *
 * Note: Stripe Elements is a special case here: The payment form
 * doesn't like being initialized within a shadow root, so it needs to be
 * slotted into the shadow DOM. We therefore need to make sure the Stripe
 * component is visible specifically, by not hiding the
 * .bilberry-stripe-payment class that is set on all parts of the Stripe
 * payment form.
 *
 * @param type Whether we want to show the activities or timetlots checkout.
 */
export function initializeHiddenBodyStyle(type: 'activities' | 'timeslots') {
    const bodySelector = getAlternateBodySelector() ?? 'body';
    const style = document.createElement('style');
    const checkoutType = type === 'activities' ? 'checkout' : 'timeslots';
    style.innerHTML = `${bodySelector} *:not(bilberry-${checkoutType}):not(bilberry-modal):not(.bilberry-stripe-payment):not(bilberry-stripe-pay-button):not(bilberry-stripe-alert):not(bilberry-snackbar):not(iframe) {
    display: none !important;
}`;
    style.id = `bilberry-hide-body`;
    return style;
}

/**
 * Hook that hides and shows the rest of the DOM when the calling component is rendered/destroyed.
 *
 * @param style A style element created by the initializeHiddenBodyStyle function.
 */
export function useHiddenBody(style: HTMLStyleElement) {
    useEffect(() => {
        const el = document.head.querySelector(style.id);
        if (!el) {
            document.head.appendChild(style);
        }

        return () => {
            style.remove();
        };
    });
}
