import { Alert, Snackbar } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { displaySnackbarAtom } from 'src/state/ui/globalSnackbar.atom';
import { hideError } from 'src/utils/widget/error-handling';
import { zIndex } from 'src/utils/widget/zIndex';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { useAtom } from 'ximple';

export function GlobalSnackbarWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
}): JSX.Element {
    const [{ visible, message, severity = 'error' }] = useAtom(displaySnackbarAtom);

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <Snackbar
                open={visible}
                autoHideDuration={8000}
                onClose={hideError}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{ zIndex: zIndex.alwaysVisible + 1 }}
            >
                <Alert severity={severity} color={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </WidgetWrapper>
    );
}
class GlobalSnackbar extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        createRoot(this._mountPoint).render(
            <GlobalSnackbarWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-snackbar', GlobalSnackbar);
}
