import { useEffect, useMemo } from 'react';
import { Product } from '@repo/types';
import { useNextProductAvailability } from '@repo/widget-utils/api/api';
import { TZDate, tzdate } from '@repo/tzdate';

export default function useUpdateSearchPeriodToNextAvailability(
    product: Product | null,
    setAvailabilitySearchPeriod: (newSearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }) => void,
) {
    const isSupportedProductType =
        product && product.type !== 'timeslot' && product.type !== 'accommodation';
    const productIdToQueryForNextAvailability = isSupportedProductType ? product.id : undefined;

    const { data: nextProductAvailability } = useNextProductAvailability(
        productIdToQueryForNextAvailability,
    );

    const nextProductAvailabilityStart = useMemo(() => {
        return nextProductAvailability ? tzdate(nextProductAvailability.next) : undefined;
    }, [nextProductAvailability]);

    useEffect(() => {
        if (!nextProductAvailability) return;

        const searchFrom = tzdate(nextProductAvailability.next).startOf('month');
        const searchTo = searchFrom.add(1, 'month');

        setAvailabilitySearchPeriod({
            startDay: searchFrom,
            endDay: searchTo,
        });
    }, [nextProductAvailability, setAvailabilitySearchPeriod]);

    return { nextProductAvailability, nextProductAvailabilityStart };
}
