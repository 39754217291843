import ProductCardWide from 'src/components/domain/product-card-2/ProductCardWide';
import { useProduct } from '@repo/widget-utils/api/api';

export default function ProductFeatured(props: {
    productCatalogId: string;
    productCatalogUrl?: string;
}) {
    const { data: product } = useProduct(props.productCatalogId);

    if (!product) return null;

    return <ProductCardWide product={product} productUrl={props.productCatalogUrl} />;
}
