import { CartItem, ProductInstance, TicketOptionWithQuantity, TicketType } from '@repo/types';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useMemberContext } from './timeslots/timeslots/MemberContext';
import { useIntentMulti } from '@repo/widget-utils/api/membership-api';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import useIntentMultiRequest from 'src/hooks/domain/cart/useIntentMultiRequest';
import useCartItemsWithMembershipAddedOrRemoved from 'src/hooks/domain/cart/useCartItemsWithMembershipAddedOrRemoved';
import { useCartContext } from './CartContext';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';

type BookingContextState = {
    cartItems: CartItem[];
    isUsingPaymentPlan: boolean;
    isLoadingPaymentPlan: boolean;
    newCartItem: CartItem | null;
    setNewCartItem: (newCartItem: CartItem | null) => void;
    quantities: TicketOptionWithQuantity[];
    setQuantities: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>;
    selectedProducts: ProductInstance[] | undefined;
    setSelectedProducts: React.Dispatch<React.SetStateAction<ProductInstance[] | undefined>>;
    selectedTimeSlot: TimeSlotType | undefined;
    setSelectedTimeslot: React.Dispatch<React.SetStateAction<TimeSlotType | undefined>>;
    selectedTicketType: TicketType | undefined;
    setSelectedTicketType: React.Dispatch<React.SetStateAction<TicketType | undefined>>;
};

const BookingContext = createContext<BookingContextState | undefined>(undefined);

export type BookingContextProps = PropsWithChildren<{
    initialTicketOptions: TicketOptionWithQuantity[];
    initialProducts?: ProductInstance[];
    initialTimeSlot?: TimeSlotType;
    initialTicketType?: TicketType;
}>;
export function BookingContextProvider(props: BookingContextProps) {
    const { loggedInUser, currentSite } = useMemberContext();
    const { cartItems: cartItemsInCart, appliedPromoCode, isCompanyContact } = useCartContext();

    const [quantities, setQuantities] = useState<TicketOptionWithQuantity[]>(
        props.initialTicketOptions,
    );
    const [selectedProducts, setSelectedProducts] = useState<ProductInstance[] | undefined>(
        props.initialProducts,
    );

    const [selectedTimeSlot, setSelectedTimeslot] = useState<TimeSlotType | undefined>(
        props.initialTimeSlot,
    );

    const [selectedTicketType, setSelectedTicketType] = useState<TicketType | undefined>(
        props.initialTicketType,
    );

    const [newCartItem, setNewCartItem] = useState<CartItem | null>(null);

    const cartItemsToFetchPaymentPlansFor = newCartItem
        ? [
              ...cartItemsInCart.filter((x) => getCartItemId(x) !== getCartItemId(newCartItem)),
              newCartItem,
          ]
        : [];

    const { request: currentPaymentPlanRequest, isUsingPaymentPlan } = useIntentMultiRequest(
        isCompanyContact,
        cartItemsToFetchPaymentPlansFor,
        appliedPromoCode,
    );

    const { data: paymentPlanResponse, isLoading: isLoadingPaymentPlan } = useIntentMulti(
        currentPaymentPlanRequest,
        loggedInUser,
        currentSite?.key,
    );

    const cartItems = useCartItemsWithMembershipAddedOrRemoved(
        cartItemsToFetchPaymentPlansFor,
        isUsingPaymentPlan,
        paymentPlanResponse ?? null,
    );

    return (
        <>
            <BookingContext.Provider
                value={{
                    cartItems,
                    isUsingPaymentPlan,
                    isLoadingPaymentPlan,
                    newCartItem,
                    setNewCartItem,
                    quantities,
                    setQuantities,
                    selectedProducts,
                    setSelectedProducts,
                    selectedTimeSlot,
                    setSelectedTimeslot,
                    selectedTicketType,
                    setSelectedTicketType,
                }}
            >
                {props.children}
            </BookingContext.Provider>
        </>
    );
}

export function useBookingContext(): BookingContextState {
    const context = useContext(BookingContext);
    if (context === undefined) {
        throw new Error('useBookingContext must be used within a BookingContextProvider');
    }
    return context;
}
