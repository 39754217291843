import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { useEffect, useState } from 'react';
import BookingBase, { BookingBaseInline } from 'src/components/domain/booking-base/BookingBase';
import BookingToggleOpenButton from 'src/components/domain/booking-widget-card/BookingToggleOpenButton';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from '@repo/i18n';
import { Product, ProductInstance, TicketOptionWithQuantity } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { getProductMaxEntrants } from '@repo/widget-utils/activities/bilberryProductEntrants';
import { useAvailabilities, useProduct } from '@repo/widget-utils/api/api';
import { getInitialQuantityData, updateQuantityData } from '@repo/widget-utils/price-helper';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { upcomingSelectedTourAtom } from 'src/state/upcomingSelectedTour.atom';
import { useAtom } from 'ximple';
import { infoLog } from '@repo/common-utils/Logger';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { checkIfNewCartItemCausesCompatibilityIssue } from 'src/state/cart/cartAtom';
import {
    productDisablePaymentPlans,
    productRequiresPaymentPlans,
} from '@repo/widget-utils/cart/cartUtils';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';
import useTicketTypesWithinProduct from 'src/hooks/domain/cart/useTicketTypesWithinProduct';
import usePriceQuantities from 'src/hooks/domain/cart/usePriceQuantities';
import useUpdateNewCartItem from 'src/hooks/domain/cart/useUpdateNewCartItem';
import { useBookingContext } from 'src/widgets/BookingContext';

interface IProps {
    productCatalogId: string;
    isAccommodation?: boolean;
    isTimeslots?: boolean;
    expandArrowInside?: boolean;
    isInlineBooking?: boolean;
    positionOffscreen?: boolean;
}

export default function Booking(props: IProps): JSX.Element {
    const {
        productCatalogId,
        isAccommodation = false,
        isTimeslots = false,
        expandArrowInside = false,
        positionOffscreen = false,
        isInlineBooking = false,
    } = props;
    const {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const { t, locale } = useLocale();
    const configuration = useConfigurations();
    const { currentSite, loggedInUser } = useMemberContext();

    const { cartItems } = useCartContext();
    const {
        isUsingPaymentPlan,
        quantities,
        setQuantities,
        selectedProducts,
        setSelectedProducts,
        selectedTimeSlot,
        setSelectedTimeslot,
        selectedTicketType,
        setSelectedTicketType,
    } = useBookingContext();
    const [dateRange, setDateRange] = useState<DateRange<TZDate>>([null, null]);
    const { data: product } = useProduct(
        productCatalogId.toString(),
        isAccommodation ? 'accommodation' : isTimeslots ? 'timeslot' : undefined,
        isAccommodation ? dateRange : undefined,
    );

    const newItemDisablePaymentPlans = product
        ? productDisablePaymentPlans(product, false, configuration.disableMembershipBooking)
        : false;
    const newItemRequiresPaymentPlans = product ? productRequiresPaymentPlans(product) : false;

    useUpdateNewCartItem(newItemDisablePaymentPlans, newItemRequiresPaymentPlans);

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>({
        startDay: null,
        endDay: null,
    });

    const configurations = useConfigurations();
    useEffect(() => {
        if (configurations.openingDate) {
            setAvailabilitySearchPeriod({
                startDay: tzdate(configurations.openingDate),
                endDay: tzdate(configurations.openingDate).add(1, 'month'),
            });
        }
    }, [configurations.openingDate]);

    const { data: availabilities } = useAvailabilities(
        product,
        availabilitySearchPeriod.startDay,
        availabilitySearchPeriod.endDay,
    );

    // Update selected products so that they are translated when locale changes
    useEffect(() => {
        setSelectedProducts((selectedProducts) => {
            return selectedProducts?.map((product) => {
                const newAvail = availabilities?.find((avail) => avail.id === product.id);
                return newAvail ?? product;
            });
        });
    }, [locale, availabilities]);

    usePreselectedTour(
        setSelectedTimeslot,
        setSelectedProducts,
        setHasChosenDate,
        onToggleVisible,
        productCatalogId,
        selectedProducts,
        availabilities,
    );

    useWidgetEventEffect(
        (productCatalog) => ({
            eventType: 'viewItem',
            product: productCatalog,
            productType: productCatalog.type,
        }),
        product,
        visible,
    );

    const showCartCompatibilityWarning = checkIfNewCartItemCausesCompatibilityIssue(
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
        cartItems,
    );
    const showMembershipBookingDisabledWarning =
        newItemRequiresPaymentPlans && newItemDisablePaymentPlans;

    const ticketTypes = useTicketTypesWithinProduct(product);
    const priceQuantities = usePriceQuantities(product, selectedProducts, quantities);

    const numPrices = availabilities[0]?.ticketOptions.length;

    function noExtendSetDateRange(newRange: DateRange<TZDate>) {
        // Removes the ability to extend range in both directions in mui calendar
        if (dateRange[0]?.format('YYYY-MM-DD') !== newRange[0]?.format('YYYY-MM-DD')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    useEffect(() => {
        setQuantities((quantities) =>
            updateQuantityData(selectedProducts ?? [], product?.ticketOptions ?? [], quantities),
        );
        if (ticketTypes.length === 1) {
            setSelectedTicketType(ticketTypes[0]);
        } else if (ticketTypes.length > 0) {
            setSelectedTicketType((prev) =>
                prev ? ticketTypes.find((t) => t.id === prev.id) : undefined,
            );
        }
    }, [selectedProducts, product, ticketTypes]);

    const onClickBook = useOnClickBook(
        priceQuantities,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        setHasChosenDate,
        selectedProducts,
        setSelectedProducts,
        setSelectedTimeslot,
        product,
        setDateRange,
        setSelectedTicketType,
        currentSite,
        loggedInUser,
        selectedTicketType,
        selectedTimeSlot,
    );

    if (isInlineBooking) {
        return (
            <BookingBaseInline
                productTitle={product?.title ?? ''}
                setSelectedProducts={setSelectedProducts}
                travelerQuantities={quantities}
                priceQuantities={priceQuantities}
                numPrices={numPrices}
                defaultQuantities={getInitialQuantityData(
                    product?.ticketOptions ?? [],
                    product?.minEntrants,
                )}
                setQuantities={
                    setQuantities as unknown as React.Dispatch<
                        React.SetStateAction<TicketOptionWithQuantity[]>
                    >
                }
                selectedTimeSlot={selectedTimeSlot}
                onSelectTimeSlot={setSelectedTimeslot}
                availabilityData={availabilities}
                rightButtonLabel={capitalize(t.book_now)}
                onClickRightButton={onClickBook}
                availabilitySearchPeriod={availabilitySearchPeriod}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                hasChosenDate={hasChosenDate}
                setHasChosenDate={setHasChosenDate}
                attemptedBooking={attemptedBooking}
                productCapacity={getProductMaxEntrants(selectedProducts)}
                title={capitalize(t.book_now)}
                onSelectDateRange={noExtendSetDateRange}
                dateRangeVariant={getDateRangeVariant(product)}
                selectedDateRange={dateRange}
                bookingCardRef={boxRef}
                selectedTicketType={selectedTicketType}
                setSelectedTicketType={setSelectedTicketType}
                ticketTypes={ticketTypes}
                showCartCompatibilityWarning={showCartCompatibilityWarning}
                showMembershipBookingDisabledWarning={showMembershipBookingDisabledWarning}
                isUsingPaymentPlan={isUsingPaymentPlan}
            />
        );
    }

    return (
        <BookingBase
            bookingCardRef={boxRef}
            hideLeftButton={true}
            productTitle={product?.title ?? ''}
            setSelectedProducts={setSelectedProducts}
            travelerQuantities={quantities}
            priceQuantities={priceQuantities}
            numPrices={numPrices}
            defaultQuantities={getInitialQuantityData(
                product?.ticketOptions ?? [],
                product?.minEntrants,
            )}
            setQuantities={
                setQuantities as unknown as React.Dispatch<
                    React.SetStateAction<TicketOptionWithQuantity[]>
                >
            }
            selectedTimeSlot={selectedTimeSlot}
            onSelectTimeSlot={setSelectedTimeslot}
            availabilityData={availabilities}
            rightButtonLabel={capitalize(t.book_now)}
            onClickRightButton={onClickBook}
            availabilitySearchPeriod={availabilitySearchPeriod}
            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            attemptedBooking={attemptedBooking}
            productCapacity={getProductMaxEntrants(selectedProducts)}
            title={capitalize(t.book_now)}
            fromPrice={product?.fromPrice}
            expandArrowInside={expandArrowInside}
            visible={visible}
            onToggleVisible={onToggleVisible}
            onSelectDateRange={noExtendSetDateRange}
            dateRangeVariant={getDateRangeVariant(product)}
            selectedDateRange={dateRange}
            toggleButton={
                <BookingToggleOpenButton expandArrowInside={expandArrowInside} visible={visible} />
            }
            positionOffscreen={positionOffscreen}
            selectedTicketType={selectedTicketType}
            setSelectedTicketType={setSelectedTicketType}
            ticketTypes={ticketTypes}
            showCartCompatibilityWarning={showCartCompatibilityWarning}
            showMembershipBookingDisabledWarning={showMembershipBookingDisabledWarning}
            isUsingPaymentPlan={isUsingPaymentPlan}
        />
    );
}

export function getDateRangeVariant(product: Product | null) {
    return product?.type === 'nights' || product?.type === 'accommodation'
        ? 'nights'
        : product?.type === 'days'
        ? 'days'
        : undefined;
}

function usePreselectedTour(
    setSelectedTimeslot: (timeslot: TimeSlotType | undefined) => void,
    setSelectedProducts: (products: ProductInstance[] | undefined) => void,
    setHasChosenDate: (hasChosenDate: boolean) => void,
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void,
    productCatalogId: string,
    selectedProducts: ProductInstance[] | undefined,
    availabilities: ProductInstance[] = [],
) {
    const [selectedTour, setSelectedTour] = useAtom(upcomingSelectedTourAtom);
    useEffect(() => {
        if (
            selectedTour &&
            selectedTour.product?.id === productCatalogId &&
            !selectedProducts?.length
        ) {
            infoLog('preselected tour from upcoming tours: ', selectedTour);
            const avail = availabilities.find((avail) => avail.id === selectedTour.id);
            if (!avail) return;
            setSelectedTimeslot({
                product: avail,
                label: avail.product?.title ?? '',
            });
            setSelectedProducts([avail]);
            setHasChosenDate(true);
            onToggleVisible(true, 'keyboard');
            setSelectedTour(null);
        }
    }, [
        selectedTour,
        setSelectedTimeslot,
        setHasChosenDate,
        setSelectedProducts,
        onToggleVisible,
        setSelectedTour,
        productCatalogId,
        selectedProducts,
        availabilities,
    ]);
}
