import { useEffect, useMemo, useState } from 'react';
import {
    getAccommodationProducts,
    useLocaleAndConfigCacheBuster,
    useProducts,
} from '@repo/widget-utils/api/api';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import useSWR from 'swr';
import {
    getAvailableProducts,
    getAvailableProductsRequestAccommodations,
} from '@repo/widget-utils/api/product.api';
import { fetcher } from '@repo/widget-utils/api/api-client-common';
import { ProductInstance } from '@repo/types';

export function useAvailabilities(
    type: 'visbook' | 'bilberry',
    ids: string[],
    dates: DateRange<TZDate>,
) {
    const {
        data: accommodationAvailabilities,
        isLoading: isLoadingAccommodationAvailabilities,
        isValidating: isValidatingAccommodationAvailabilities,
    } = useAccommodationAvailabilities(type, dates, ids);

    const { data: bilberryAvailabilities, isLoading: isLoadingBilberryAvailabilities } =
        useBilberryAvailabilities(type, ids, dates);

    return useMemo(
        () => ({
            data: type === 'visbook' ? accommodationAvailabilities : bilberryAvailabilities,
            isLoading:
                isLoadingAccommodationAvailabilities ||
                isLoadingBilberryAvailabilities ||
                isValidatingAccommodationAvailabilities,
        }),
        [
            bilberryAvailabilities,
            isLoadingBilberryAvailabilities,
            accommodationAvailabilities,
            isLoadingAccommodationAvailabilities,
            type,
            isValidatingAccommodationAvailabilities,
        ],
    );
}

function useBilberryAvailabilities(
    type: 'visbook' | 'bilberry',
    ids: string[],
    dates: DateRange<TZDate>,
) {
    const [allAvailabilities, setAllAvailabilities] = useState<ProductInstance[][] | null>(null);
    const { data: products, isLoading: isLoadingProducts } = useProducts({
        ids: type === 'bilberry' ? ids : [],
    });

    const { data, isLoading, isValidating } = useSWR<ProductInstance[][]>(
        (products?.length ?? 0) > 0
            ? 'bilberry-availabilities' + dates[0]?.unix() + dates[1]?.unix()
            : null,

        {
            fetcher: async () => {
                const allAvails = await Promise.all(
                    products?.map((product) => getAvailableProducts(product, ...dates)) ?? [],
                );
                return allAvails;
            },
            revalidateOnFocus: false,
            keepPreviousData: true,
            dedupingInterval: 5 * 60 * 1000,
        },
    );

    useEffect(() => {
        if (data) {
            setAllAvailabilities((prev) => {
                if (!prev || prev.length === 0) return data;
                const all = prev.slice();
                data.forEach((d) => {
                    const findExistingIdx = all.findIndex(
                        (a) => a[0]?.product?.id === d[0]?.product?.id,
                    );
                    if (findExistingIdx === -1) {
                        all.push(d);
                    } else {
                        const existing = all[findExistingIdx];
                        const newOnes = d.filter(
                            (newOne) =>
                                !existing.some((existingOne) => existingOne.id === newOne.id),
                        );
                        all[findExistingIdx] = existing.concat(newOnes);
                    }
                });
                return all;
            });
        }
    }, [data]);

    return useMemo(
        () => ({
            data: allAvailabilities,
            isLoading: isLoading || isLoadingProducts || isValidating,
        }),
        [allAvailabilities, isLoading, isLoadingProducts, isValidating],
    );
}

function useAccommodationAvailabilities(
    type: 'visbook' | 'bilberry',
    dates: DateRange<TZDate>,
    ids: string[],
) {
    const cacheBuster = useLocaleAndConfigCacheBuster();

    const [allAvailabilities, setAllAvailabilities] = useState<ProductInstance[][] | null>(null);

    const cacheKey =
        type === 'visbook' ? 'accommodation-availability-products' + cacheBuster : null;

    const {
        data: accommodations,
        error: accommodationsError,
        isLoading: accommodationsIsLoading,
        isValidating: accommodationsIsValidating,
    } = useSWR<ProductInstance[][][]>(cacheKey, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        keepPreviousData: true,
        fetcher: () => {
            return getAccommodationProducts(
                ids,
                [null, null],
                [{ adults: 0, children: [], id: 0 }],
            );
        },
    });

    const accommodationIds = useMemo(() => {
        return (
            accommodations?.flatMap((a) => a.flatMap((a2) => a2[0]?.product?.id ?? '')) ?? []
        ).join('-');
    }, [accommodations]);

    const { data, isLoading, isValidating, error } = useSWR<ProductInstance[][]>(
        type === 'visbook' && dates[0] && dates[1] && accommodations
            ? cacheBuster +
                  dates[0].format('YYYY-MM-DD') +
                  dates[1].format('YYYY-MM-DD') +
                  accommodationIds
            : null,
        {
            fetcher: async () => {
                const reqs =
                    accommodations
                        ?.flatMap((a) => a.map((a2) => a2[0]?.product))
                        .filter(Boolean)
                        .map((accommodation) =>
                            getAvailableProductsRequestAccommodations(
                                accommodation,
                                dates[0]?.subtract(1, 'day'),
                                dates[1],
                                1,
                            ),
                        ) ?? [];
                const all = await Promise.all(
                    reqs.map(async (req) => {
                        const data = await fetcher<any>(req.url, req.headers);
                        return req.mapper(data);
                    }),
                );
                return all;
            },
            revalidateOnFocus: false,
            keepPreviousData: true,
            dedupingInterval: 5 * 60 * 1000,
        },
    );

    useEffect(() => {
        if (data) {
            setAllAvailabilities((prev) => {
                if (!prev || prev.length === 0) return data;
                const all = prev.slice();
                data.forEach((d, i) => {
                    const findExistingIdx = all.findIndex((a) =>
                        a.some((existing) => !!d.find((newOne) => newOne.id === existing.id)),
                    );
                    if (findExistingIdx === -1) {
                        all.push(d);
                    } else {
                        const existing = all[findExistingIdx];
                        const newOnes = d.filter(
                            (newOne) =>
                                !existing.some((existingOne) => existingOne.id === newOne.id),
                        );
                        all[findExistingIdx] = existing.concat(newOnes);
                    }
                });
                return all;
            });
        }
    }, [setAllAvailabilities, data]);

    return useMemo(() => {
        return {
            data: allAvailabilities,
            isLoading: isLoading || accommodationsIsLoading,
            isValidating: isValidating || accommodationsIsValidating,
            error: error || accommodationsError,
        };
    }, [
        allAvailabilities,
        isLoading,
        accommodationsIsLoading,
        isValidating,
        accommodationsIsValidating,
        error,
        accommodationsError,
    ]);
}
