import { Box, capitalize, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import Payment from 'src/components/domain/payment/Payment';
import Summary from 'src/components/domain/summary/Summary';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from '@repo/i18n';
import {
    BilberryPromoCodeStatus,
    AppliedGiftCard,
    CompletedPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
} from '@repo/types';
import Receipt from '../../receipt/Receipt';
import { useCartContext } from 'src/widgets/CartContext';

type PaymentViewProps = {
    paymentInfo: PaymentInfo | null;
    largeScreen: boolean;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
    appliedGiftcards: AppliedGiftCard[];
    appliedPromoCode: BilberryPromoCodeStatus | null;
    completedPaymentInfo: CompletedPaymentInfo | null;
};

export function PaymentView({
    paymentInfo,
    largeScreen,
    onPaymentCancelled,
    onPaymentCompleted,
    appliedGiftcards,
    appliedPromoCode,
    completedPaymentInfo,
}: PaymentViewProps) {
    const { t } = useLocale();
    const { cartItems } = useCartContext();

    useWidgetEventEffect(
        () => ({
            eventType: 'checkoutStep',
            checkoutStep: 'Payment',
            cartItems: Object.values(cartItems),
            giftCard: appliedGiftcards
                ? appliedGiftcards.map((appliedGiftcard) => ({
                      giftcardReference: appliedGiftcard.giftcardStatus.id,
                      priceReduction: appliedGiftcard.priceReduction,
                  }))
                : null,
            promoCode: appliedPromoCode,
        }),
        () => !!completedPaymentInfo,
    );

    return (
        <Fragment>
            {paymentInfo && (
                <Fragment>
                    <Grid
                        container
                        justifyContent={largeScreen ? 'space-between' : 'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                    >
                        <Box sx={{ width: largeScreen ? '50%' : '100%' }}>
                            <Payment
                                onPaymentCancelled={onPaymentCancelled}
                                onPaymentCompleted={onPaymentCompleted}
                                paymentInfo={paymentInfo}
                            />
                        </Box>
                        <Summary hideGoPay={true} isCheckoutSummary={false} />
                    </Grid>
                </Fragment>
            )}
            {completedPaymentInfo && (
                <Fragment>
                    <Grid container justifyContent={largeScreen ? 'flex-start' : 'center'}>
                        <Typography variant="h3" color="secondary">
                            {capitalize(t.thank_you_for_booking_with_us)}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent={largeScreen ? 'space-between' : 'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                    >
                        <Receipt bookingRef={completedPaymentInfo.referenceId} />

                        {completedPaymentInfo.purchasedCartItems && (
                            <Summary
                                isCheckoutSummary={false}
                                isConfirmationPage={true}
                                cartItems={completedPaymentInfo.purchasedCartItems}
                                hideGoPay={true}
                            />
                        )}
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    );
}
