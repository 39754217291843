import { MembershipIntentMultiResponse } from '@repo/types';
import { atom } from 'ximple/atoms';

export const paymentIntentAtom = atom<{
    valueCardIdsToIgnore: number[];
    valueCardProductIdsToIgnore: number[];
    valueCardProductTypeIdsToIgnore: number[];
    intents?: MembershipIntentMultiResponse;
    isLoadingIntents?: boolean;
}>({
    initialValue: {
        valueCardIdsToIgnore: [],
        valueCardProductIdsToIgnore: [],
        valueCardProductTypeIdsToIgnore: [],
    },
});

export function resetIgnoredValueCards() {
    paymentIntentAtom.update({
        valueCardIdsToIgnore: [],
        valueCardProductIdsToIgnore: [],
        valueCardProductTypeIdsToIgnore: [],
    });
}
