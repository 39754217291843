import { tzdate } from '@repo/tzdate';
import { maxBy } from 'lodash-es';
import { MembershipDateRange, MembershipUserValueCard, CartItem, Translations } from '@repo/types';

export function getCartItemDisplayStartDate(item?: CartItem) {
    return item?.products.sort((a, b) => a.start.unix() - b.start.unix())?.[0]?.start;
}

export function getCartItemDisplayTitle(cartItem?: CartItem) {
    const product = cartItem?.pkg?.pkg ?? cartItem?.products[0].product;
    return product?.title ?? '';
}

export function getProductDisplayTitle(product?: { title?: string }) {
    return product?.title || '';
}

export function findMaxDateRestrictionEndAt(dateRestrictions: MembershipDateRange[]) {
    return maxBy(dateRestrictions, (dateRange) => dateRange.end)?.end;
}

export function findValueCardValidToDisplayDateText(valueCard: MembershipUserValueCard) {
    const maxDateRestrictionEndAt = findMaxDateRestrictionEndAt(
        valueCard.valueCardProduct.configuration.dateRestrictions,
    );

    const validTo = valueCard.validTo ?? '';

    return maxDateRestrictionEndAt && maxDateRestrictionEndAt > validTo
        ? maxDateRestrictionEndAt
        : validTo;
}

export function getValidUntilDisplayText(validToDateText: string, t: Translations) {
    const validToDate = tzdate(validToDateText, false);

    return `${t.validUntil} ${
        validToDate.isValid() ? validToDate.format('ll') : t.cancellation.toLowerCase()
    }`;
}

export function getValidFromDisplayText(validFromDateText: string, t: Translations) {
    const validFromDate = tzdate(validFromDateText, false);

    return `${t.validFrom} ${
        validFromDate.isValid() ? validFromDate.format('ll') : t.cancellation.toLowerCase()
    }`;
}
