import {
    alpha,
    Box,
    Button,
    CircularProgress,
    Stack,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import Summary from 'src/components/domain/summary/Summary';
import { useLocale } from '@repo/i18n';
import { cartAtom } from 'src/state/cart/cartAtom';
import { CartItem, ProductInstance } from '@repo/types';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { useExtraProductInstancesByIds } from '@repo/widget-utils/api/api';
import { configurationAtom, useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { ButtonDisplay } from './ButtonDisplay';
import { getTabIndex } from './getTabIndex';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { groupToursByProduct } from '@repo/widget-utils/activities/tours';
import TourProductCardList from 'src/components/domain/tour-product-card/TourProductCardList';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { MUITheme } from 'src/components/utils/theme/Theme';

type ExtrasViewProps = {
    cartItems: CartItem[];
    largeScreen: boolean;
    activeTab: Parameters<typeof getTabIndex>[0];
    onNextClicked: () => void;
    isMakingReservation: boolean;
};

export function ExtrasView({
    cartItems,
    largeScreen,
    activeTab,
    onNextClicked,
    isMakingReservation,
}: ExtrasViewProps) {
    const config = useConfigurations();
    const { t, locale } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Extras',
        cartItems: cartAtom.subject.value,
    }));

    const itemsWithExtras = cartItems.filter((item) =>
        item.products.some(
            (p) =>
                !p.isExtraProduct &&
                (p.extraProducts.length > 0 || p.timeslots.some((t) => t.extraProducts.length > 0)),
        ),
    );

    const extraTourIds = Array.from(
        new Set(
            itemsWithExtras.flatMap((item) =>
                item.products.flatMap((p) =>
                    p.extraProducts.slice().concat(p.timeslots.flatMap((t) => t.extraProducts)),
                ),
            ),
        ),
    );

    const {
        data: extraTours,
        isLoading: isExtraToursLoading,
        error: isExtraToursError,
    } = useExtraProductInstancesByIds(extraTourIds);

    const hasExtras = isExtraToursLoading || (extraTours && extraTours.length > 0);

    useEffect(() => {
        // Whenever extras changes check if we got empty, and if so move on
        if (!config.enableExtras || !hasExtras || isExtraToursError) {
            // If we have already passed the first tab, there is no
            // need to navigate to tab 1 again. Often if swr revalidated
            // on this page it returned back to tab 1 as this use effect ran.
            if (activeTab === 'extras') {
                onNextClicked();
            }
        }
    }, [hasExtras, isExtraToursError, activeTab, config.enableExtras, onNextClicked]);

    useEffect(() => {
        const oldValue = configurationAtom.subject.value.skipBasketOnBook;
        configurationAtom.update({
            ...configurationAtom.subject.value,
            skipBasketOnBook: true,
        });
        return () => {
            configurationAtom.update({
                ...configurationAtom.subject.value,
                skipBasketOnBook: oldValue,
            });
        };
    }, []);

    const newCustomizations = {
        ...customizations,
        h2Size: '22px',
        bodySize: '14px',
        productListPadding: 0,
        productCardColor: theme.palette.grey[50],
        productCardTextColor: theme.palette.text.secondary,
        productCardPrimaryColor: theme.palette.primary.main,
        productCardPrimaryColorContrast: theme.palette.primary.contrastText,
        productCardIconColor: theme.palette.text.secondary,
    };
    return (
        <Fragment>
            {isExtraToursLoading && <CircularProgress />}
            {cartItems.length === 0 && (
                <Typography color="textSecondary" variant="h4">
                    {t.no_items_in_cart}
                </Typography>
            )}
            {!isExtraToursLoading && hasExtras && (
                <Stack
                    justifyContent={largeScreen ? 'space-between' : 'center'}
                    alignItems={largeScreen ? 'flex-start' : 'center'}
                    direction={largeScreen ? 'row' : 'column-reverse'}
                    gap={3}
                    position="relative"
                    width="100%"
                >
                    {!largeScreen && (
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            width="100%"
                            sx={{
                                maxWidth: '440px',
                                boxSizing: 'border-box',
                                [theme.breakpoints.down('md')]: {
                                    marginBottom: theme.spacing(3),
                                    maxWidth: '100%',
                                    px: theme.spacing(2),
                                },
                            }}
                        >
                            <Button
                                color="primary"
                                variant={customizations.primaryButtonStyle}
                                disabled={isMakingReservation}
                                onClick={onNextClicked}
                            >
                                <ButtonDisplay
                                    isMakingReservation={isMakingReservation}
                                    buttonText={t.continue_travelers}
                                />
                            </Button>
                        </Stack>
                    )}
                    <Stack mt={4} mb={4} gap={5} width="100%">
                        <CustomizationsContext.Provider value={newCustomizations}>
                            <ThemeProvider theme={MUITheme(newCustomizations)}>
                                {itemsWithExtras
                                    .flatMap((item) => {
                                        if (!item.pkg) return item;
                                        return item.pkg.pkg.products.map((p) => {
                                            const instances = item.products.filter(
                                                (i) => i.product?.pkgProductId === p.pkgProductId,
                                            );
                                            return {
                                                ...item,
                                                products: instances,
                                            };
                                        });
                                    })
                                    .map((item) => {
                                        const extrasForItem = extraTours.filter((extra) =>
                                            item.products.some(
                                                (p) =>
                                                    p.extraProducts.includes(extra.id) ||
                                                    p.timeslots.some((t) =>
                                                        t.extraProducts.includes(extra.id),
                                                    ),
                                            ),
                                        );

                                        extrasForItem.sort(
                                            (a, b) => a.start.unix() - b.start.unix(),
                                        );
                                        const groupedExtras = groupToursByProduct(extrasForItem);
                                        const showExtrasAsMinimal = item.products.some(
                                            (p) =>
                                                p.showExtrasAsMinimal ||
                                                p.timeslots.some((t) => t.showExtrasAsMinimal),
                                        );
                                        const tours = removeImageIfShouldBeMinimalExtras(
                                            showExtrasAsMinimal,
                                            groupedExtras,
                                        );

                                        const showExtrasAsList = item.products.some(
                                            (p) =>
                                                p.showExtrasAsList ||
                                                p.timeslots.some((t) => t.showExtrasAsList),
                                        );
                                        const maxLengthRow = showExtrasAsList ? 1 : tours.length;

                                        if (tours.length === 0) return null;
                                        return (
                                            <Stack
                                                key={getCartItemId({ ...item, pkg: undefined })}
                                                width="100%"
                                            >
                                                <Typography variant="h2" lineHeight={1} mb={2}>
                                                    {item.products[0]?.product?.title}
                                                </Typography>
                                                <Typography
                                                    mb={2}
                                                    fontSize={16}
                                                    borderBottom={`1px solid ${alpha(
                                                        theme.palette.secondary.main,
                                                        0.5,
                                                    )}`}
                                                >
                                                    {item.products[0]?.start.format('LL')},{' '}
                                                    {item.products[0]?.start.format('LT')}
                                                </Typography>
                                                <TourProductCardList
                                                    cardBorder={`1px solid ${theme.palette.grey[200]}`}
                                                    cardBorderRadius={theme.shape.borderRadius / 3}
                                                    orientation={
                                                        showExtrasAsList ? 'landscape' : 'portrait'
                                                    }
                                                    tours={tours}
                                                    scroll={false}
                                                    numElements={{
                                                        numXs: Math.min(1, maxLengthRow),
                                                        numSm: Math.min(1, maxLengthRow),
                                                        numMd: Math.min(2, maxLengthRow),
                                                        numLg: Math.min(2, maxLengthRow),
                                                    }}
                                                    hideReadMore={true}
                                                    hideImageIfMissing
                                                    textColors={[]}
                                                    accentColors={[]}
                                                    primaryColors={[]}
                                                    accentTextColors={[]}
                                                    backgroundColors={[]}
                                                    primaryTextColors={[]}
                                                    maxWidthCard={{ xs: 400, md: 350 }}
                                                    sx={{
                                                        justifyContent: {
                                                            xs: 'center',
                                                            md: 'flex-start',
                                                        },
                                                        px: '0px !important',
                                                    }}
                                                />
                                            </Stack>
                                        );
                                    })}
                            </ThemeProvider>
                        </CustomizationsContext.Provider>
                    </Stack>
                    <Box minWidth={{ xs: 'auto', md: 400 }}>
                        <Summary
                            showShare
                            hideGoPay={!largeScreen}
                            isCheckoutSummary={true}
                            isMakingReservation={isMakingReservation}
                            goPayClicked={onNextClicked}
                            nextButtonText={t.continue_travelers}
                        />
                    </Box>
                </Stack>
            )}
        </Fragment>
    );
}

function removeImageIfShouldBeMinimalExtras(
    showExtrasAsMinimal: boolean,
    productInstance: ProductInstance[][],
): ProductInstance[][] {
    return productInstance.map((group) =>
        group.map((p) => ({
            ...p,
            product: p.product
                ? {
                      ...p.product,
                      coverImage: showExtrasAsMinimal
                          ? { src: undefined, alt: undefined }
                          : p.product.coverImage,
                      icons: showExtrasAsMinimal ? [] : p.product.icons,
                  }
                : null,
            icons: showExtrasAsMinimal ? [] : p.icons,
        })),
    );
}
