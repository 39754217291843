import { BilberryBooking, BilberryGiftcard } from '@repo/types';

export function guardIsValidPaymentGateway(booking: BilberryBooking | BilberryGiftcard) {
    if (
        booking.payment_gateway !== 'nets' &&
        booking.payment_gateway !== 'stripe' &&
        booking.payment_gateway !== 'invoice'
    ) {
        throw new Error(
            `Expected NETS or Stripe payment gateway but was ${booking.payment_gateway}`,
        );
    }
}
