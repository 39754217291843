import { Theme, useMediaQuery, Box, Grid } from '@mui/material';
import Title from 'src/components/common/timeslots-typography/Title';
import { CheckoutTab } from './CheckoutTabs';
import BackButton from 'src/components/domain/checkout/BackButton';

export default function Tab({ tab }: { tab: CheckoutTab }) {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Box
            sx={{
                width: '1280px',
                maxWidth: '100%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {(!tab.hideBackButton || !tab.hideTitle) && (
                <Grid container justifyContent={largeScreen ? 'flex-start' : 'center'}>
                    {!tab.hideBackButton && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                mb: 1,
                            }}
                        >
                            <BackButton
                                label={tab.backButtonText}
                                onClick={
                                    typeof tab.backButtonOnClick !== 'undefined'
                                        ? tab.backButtonOnClick
                                        : undefined
                                }
                            />
                        </Box>
                    )}
                    {!tab.hideTitle && (
                        <Title text={tab.title} description={tab.description ?? ''} />
                    )}
                </Grid>
            )}
            {tab.content}
        </Box>
    );
}
