import { Product, TicketOption, Translations } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';

const difficulty = {
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard',
};

export function getDifficultyText(t: Translations, productCatalog?: Pick<Product, 'difficulty'>) {
    return capitalize(
        productCatalog?.difficulty === difficulty.EASY
            ? t.easy
            : productCatalog?.difficulty === difficulty.MEDIUM
            ? t.medium
            : productCatalog?.difficulty === difficulty.HARD
            ? t.hard
            : '',
    );
}

export function getAgeString(t: Translations, price: TicketOption) {
    if (price.fromAge === null && price.toAge === null) {
        return '';
    } else if (price.toAge === null) {
        return ` (${price.fromAge}+ ${t.years})`;
    } else if (price.fromAge === null) {
        return ` (0-${price.toAge} ${t.years})`;
    } else {
        return ` (${price.fromAge}-${price.toAge} ${t.years})`;
    }
}
