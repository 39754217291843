import { Button, Stack, Typography, darken, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import MultipleNumberInput, {
    MultipleNumberInputValueType,
} from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useLocale } from '@repo/i18n';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import { AccommodationResultList } from '../components/AccommodationResultList';
import { Availabilities } from '../components/Availabilities';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { BREAKPOINT_MD } from '../utils';
import { useAccommodationProducts } from '@repo/widget-utils/api/api';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

type Props = {
    ids: string[];
    provider: 'visbook' | 'bilberry';
};

export function AccommodationSearchForm(props: Props) {
    const { ids, provider = 'visbook' } = props;
    const theme = useTheme();
    const customizations = useCustomizations();
    const { t } = useLocale();
    const config = useConfigurations();
    const [rooms, setRooms] = useState([{ id: 0, value: 1, name: t.rooms.singular, min: 1 }]);
    const [guestsInRoom, setGuestsInRoom] = useState<MultipleNumberInputValueType[][]>([
        [
            { id: 0, value: 1, name: t.adult.singular, min: 0 },
            { id: 1, value: 0, name: t.children.plural, min: 0 },
        ],
    ]);
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<TZDate>>([null, null]);
    const [showAvailability, setShowAvailability] = useState(false);

    const [searchDateRange, setSearchDateRange] = useState<DateRange<TZDate>>([
        TZDate.now(),
        TZDate.now().add(1, 'month'),
    ]);

    const guests = useMemo(
        () =>
            guestsInRoom.map((info, i) => ({
                id: i,
                adults: info[0].value,
                children: new Array(info[1].value)
                    .fill(null)
                    .map((_, idx) => ({ childId: idx, age: null, bed: null })),
            })),
        [guestsInRoom],
    );

    const {
        data: possibleAccommodations,
        isLoading: isLoadingPossibleAccommodations,
        error: isErrorPossibleAccommodations,
    } = useAccommodationProducts(
        ids,
        selectedDateRange,
        guests,
        provider === 'visbook' && selectedDateRange[0] !== null && selectedDateRange[1] !== null,
        true,
    );

    return (
        <Stack
            gap={3}
            p={2}
            pt={3}
            borderRadius={customizations.borderRadius + 'px'}
            bgcolor={customizations.accommodationSearchFormColor}
            color={customizations.accommodationSearchFormColorContrast}
            sx={{
                containerType: 'inline-size',
                containerName: 'accommodationsearchform',
            }}
        >
            <Stack
                gap={2}
                sx={{
                    [`@container (width > ${BREAKPOINT_MD})`]: {
                        flexDirection: 'row',
                    },
                }}
            >
                <Stack
                    gap={2}
                    width="100%"
                    sx={{
                        [`@container (width > ${BREAKPOINT_MD})`]: {
                            maxWidth: '50%',
                        },
                    }}
                >
                    <Typography
                        variant="h3"
                        m={0}
                        color={customizations.accommodationSearchFormColorContrast}
                    >
                        {t.searchAndBookAccommodations}
                    </Typography>
                    <Stack>
                        <MultipleNumberInput
                            onChange={(_id, value) => {
                                setRooms((prev) => [{ ...prev[0], value }]);
                                setSelectedDateRange([null, null]);
                                if (value > guestsInRoom.length) {
                                    const newGuestsInRoom = [...guestsInRoom];
                                    for (let i = guestsInRoom.length; i < value; i++) {
                                        newGuestsInRoom.push([
                                            { id: 0, value: 1, name: t.adult.singular, min: 0 },
                                            { id: 1, value: 0, name: t.children.plural, min: 0 },
                                        ]);
                                    }
                                    setGuestsInRoom(newGuestsInRoom);
                                } else if (value < guestsInRoom.length && value > 0) {
                                    setGuestsInRoom((prev) => prev.slice(0, value));
                                }
                            }}
                            values={rooms}
                            variant="filled"
                            labelText={capitalize(t.number_of_rooms)}
                            defaultValues={[{ id: 0, value: 1, name: t.rooms.singular, min: 1 }]}
                            labelColor={customizations.accommodationSearchFormColorContrast}
                            backgroundColor={customizations.accommodationSearchFormInputColor}
                            color={customizations.accommodationSearchFormInputTextColor}
                            buttonColor={customizations.accommodationSearchFormInputTextColor}
                            border={`1px solid ${theme.palette.grey[200]}`}
                            maxEntrants={config.personsMax}
                        />
                    </Stack>
                    {guestsInRoom.map((guests, guestsIdx) => (
                        <Stack key={guestsIdx} gap={0.5}>
                            <Typography
                                variant="h4"
                                m={0}
                                fontSize={16}
                                fontWeight="bold"
                                color={customizations.accommodationSearchFormColorContrast}
                            >
                                {capitalize(t.rooms.num(guestsIdx + 1)) + ' ' + (guestsIdx + 1)}
                            </Typography>
                            <MultipleNumberInput
                                onChange={(id, value) => {
                                    const index = guests.findIndex((guest) => guest.id === id);
                                    const newGuests = [...guests];
                                    newGuests[index] = { ...newGuests[index], value };
                                    const newGuestsInRoom = [...guestsInRoom];
                                    newGuestsInRoom[guestsIdx] = newGuests;
                                    setGuestsInRoom(newGuestsInRoom);
                                    setSelectedDateRange([null, null]);
                                }}
                                values={guests}
                                variant="filled"
                                labelText={t.selectNumberOfGuests}
                                defaultValues={guests}
                                labelColor={customizations.accommodationSearchFormColorContrast}
                                backgroundColor={customizations.accommodationSearchFormInputColor}
                                color={customizations.accommodationSearchFormInputTextColor}
                                buttonColor={customizations.accommodationSearchFormInputTextColor}
                                border={`1px solid ${theme.palette.grey[200]}`}
                            />
                        </Stack>
                    ))}
                    {!showAvailability && (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                                borderColor: customizations.accommodationSearchFormColorContrast,
                                backgroundColor: customizations.accommodationSearchFormColor,
                                color: customizations.accommodationSearchFormColorContrast,
                                '&:hover': {
                                    backgroundColor: darken(
                                        customizations.accommodationSearchFormColor,
                                        0.2,
                                    ),
                                    color: customizations.accommodationSearchFormColorContrast,
                                },
                            }}
                            size="large"
                            onClick={() => {
                                setShowAvailability(true);
                            }}
                        >
                            {showAvailability ? t.updateAvailability : t.checkAvailability}
                        </Button>
                    )}
                </Stack>
                {showAvailability && (
                    <Availabilities
                        provider={provider}
                        ids={ids}
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        guestsInRoom={guestsInRoom}
                        searchDateRange={searchDateRange}
                        setSearchDateRange={setSearchDateRange}
                    />
                )}
            </Stack>

            <Stack gap={3}>
                {showAvailability &&
                    selectedDateRange[0] &&
                    selectedDateRange[1] &&
                    new Array(rooms[0].value).fill(null).map((_, roomIdx) => {
                        return (
                            <Stack key={roomIdx} gap={1}>
                                <Typography
                                    variant="h4"
                                    m={0}
                                    fontSize={16}
                                    fontWeight="bold"
                                    color={customizations.accommodationSearchFormColorContrast}
                                >
                                    {capitalize(t.rooms.num(roomIdx + 1)) + ' ' + (roomIdx + 1)}
                                </Typography>
                                <AccommodationResultList
                                    selectedDateRange={selectedDateRange}
                                    guestsInRoom={guestsInRoom}
                                    searchDateRange={searchDateRange}
                                    provider={provider}
                                    ids={ids}
                                    roomIdx={roomIdx}
                                    possibleAccommodations={possibleAccommodations}
                                    isErrorPossibleAccommodations={isErrorPossibleAccommodations}
                                    isLoadingPossibleAccommodations={
                                        isLoadingPossibleAccommodations
                                    }
                                />
                            </Stack>
                        );
                    })}
            </Stack>
        </Stack>
    );
}
