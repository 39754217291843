import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { getCustomOrDefaultText, useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { largeButton } from 'src/components/utils/jss/ButtonStyleUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getPrimaryColors } from 'src/components/utils/theme/getPrimaryColors';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';
import { useAtom } from 'ximple';
import ProductCardIcon from '../../product-card-2/ProductCardIcon';
import { ProductCardInfoContainer } from '../../product-card-2/ProductCardInfo';
import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import { ProductCardContainer } from '../../product-card-2/ProductCard2';
import { Package, UserTextCustomizations } from '@repo/types';
import { formatMinutesToDaysHoursMinutes } from '@repo/widget-utils/DateHelpers';

interface IProps {
    pkg?: Package;
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    disableMaxWidth?: boolean;
    hidePriceIcon?: boolean;
}

export function PackageCardHeader(props: {
    url?: string;
    imageUrl?: string;
    category?: string | null;
}) {
    const { url, imageUrl, category } = props;

    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <>
            <Link href={url} display="block" position="relative">
                <ProductImage url={imageUrl} size="s" disableShadows={true} format="16_9" />
            </Link>
            {category && (
                <Grid
                    position="absolute"
                    top={theme.spacing(2)}
                    left={0}
                    padding={theme.spacing(0.5, 2, 0.5, 2.5)}
                    sx={{
                        backgroundColor: customizations.productCardPrimaryColor,
                        '& > .MuiTypography-root': {
                            color: customizations.productCardPrimaryColorContrast,
                            fontWeight: customizations.boldFontWeight,
                        },
                    }}
                >
                    <Typography>{category}</Typography>
                </Grid>
            )}
        </>
    );
}

export default function PackageCard(props: IProps): JSX.Element {
    const { pkg, url, disableMaxWidth = false } = props;

    return (
        <ProductCardContainer disableMaxWidth={disableMaxWidth}>
            <PackageCardHeader url={url} imageUrl={pkg?.coverImage?.src ?? ''} />
            <ProductCardInfo
                pkg={pkg}
                url={url}
                buttonCustomizationKey={props.buttonCustomizationKey}
            />
        </ProductCardContainer>
    );
}

function ProductCardInfo(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const { pkg, url } = props;

    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const theme = useTheme();

    // TODO: fix event error
    return (
        <ProductCardInfoContainer>
            <ProductCardInfoContent {...props} />
            <Grid container direction="row" justifyContent="center" wrap="nowrap">
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        ...largeButton(theme),
                        ...getPrimaryColors(
                            customizations.productCardAccentColor,
                            customizations.productCardAccentColorContrast,
                            customizations.productCardColor,
                            true,
                            false,
                        ),
                    }}
                    onClick={() =>
                        pkg &&
                        dispatchWidgetEvent({
                            eventType: 'viewItem',
                            productType: 'Package',
                            product: pkg,
                        })
                    }
                    href={url}
                >
                    {getCustomOrDefaultText(
                        configurations.textCustomizations,
                        props.buttonCustomizationKey,
                        locale,
                        t.read_more_and_book,
                    )}
                </Button>
            </Grid>
        </ProductCardInfoContainer>
    );
}

function ProductCardInfoContent(props: IProps) {
    const { pkg } = props;
    const [currency] = useAtom(currencyAtom);
    const { t, locale } = useLocale();

    const customizations = useCustomizations();
    const theme = useTheme();

    const iconStyles = {
        '& > .MuiSvgIcon-root': {
            color: customizations.productCardIconColor,
        },
        '& > .MuiTypography-root': {
            padding: theme.spacing(0, 0.5),
            color: customizations.productCardTextColor,
        },
    };

    return (
        <Fragment>
            <Grid
                position="absolute"
                top="-1.5rem"
                display="flex"
                justifyContent="center"
                width="100%"
                sx={{
                    '& > div': {
                        padding: theme.spacing(1, 2),
                        backgroundColor: customizations.productCardAccentColor,
                        color: customizations.productCardAccentColorContrast,
                        borderRadius: customizations.borderRadius
                            ? `${customizations.borderRadius / 2}px`
                            : undefined,
                        '& > .MuiTypography-root': {
                            color: customizations.productCardAccentColorContrast,
                            fontSize: '0.75rem',
                        },
                    },
                }}
            >
                <div>
                    <Typography>
                        <Fragment>
                            {t.from}
                            <Grid
                                component="span"
                                fontWeight={customizations.boldFontWeight}
                                fontSize="1rem"
                            >
                                {' '}
                                {pkg?.fromPrice
                                    ? formatCurrencyNoDecimalsIfPossible(
                                          locale,
                                          currency,
                                          pkg.fromPrice,
                                      )
                                    : '-'}
                            </Grid>
                        </Fragment>
                    </Typography>
                </div>
            </Grid>
            <Typography variant="h2" color={customizations.productCardTextColor}>
                {pkg?.title ?? ''}
            </Typography>
            <Box
                padding={theme.spacing(2, 0)}
                height="100%"
                sx={{
                    '& .MuiTypography-root': {
                        color: customizations.productCardTextColor,
                    },
                }}
            >
                {parseHtmlToMui(pkg?.shortDescription ?? '')}
            </Box>
            <Grid
                container
                direction="row"
                pb={theme.spacing(4)}
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(50px, 1fr))"
                justifyContent="center"
                maxWidth="400px"
            >
                <ProductCardIcon
                    label={formatMinutesToDaysHoursMinutes(t, pkg?.duration ?? 0)}
                    Icon={AccessAlarmsRoundedIcon}
                    sx={iconStyles}
                />
                {!props.hidePriceIcon && (
                    <ProductCardIcon
                        label={`${
                            pkg?.fromPrice
                                ? formatCurrencyNoDecimalsIfPossible(
                                      locale,
                                      currency,
                                      pkg.fromPrice,
                                  )
                                : '-'
                        }`}
                        Icon={LocalOfferRounded}
                        sx={iconStyles}
                    />
                )}
            </Grid>
        </Fragment>
    );
}
