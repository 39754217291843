import { createRoot } from 'react-dom/client';
import { getIdAttribute, getStringAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductImageWidgetRoot from './ProductImageWidgetRoot';

class BilberryProductImage extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        const format = (getStringAttribute(this, 'aspect-ratio') ?? '16/9').replace('/', '_');

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductImageWidgetRoot productCatalogId={productCatalogId} format={format} />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-image', BilberryProductImage);
}
