import { Link, Typography } from '@mui/material';
import { Fragment } from 'react';
import { buildHtmlAst, Tag } from './buildHtmlAst';

export function parseHtmlToMui(html: string) {
    const ast = buildHtmlAst(html);
    return createElementFromTag(ast.tag);
}

function getComponentFromTagName(tag: Tag) {
    switch (tag.name) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'p':
            const variant = tag.name === 'p' ? undefined : tag.name;
            return (props: any) => <Typography variant={variant}>{props.children}</Typography>;
        case 'a':
            return (props: any) => (
                <Link color="primary" href={props.href ? props.href : '#'} target={props.target}>
                    {props.children}
                </Link>
            );
        case 'span':
            return (props: any) => <span>{props.children}</span>;
        case 'em':
            return (props: any) => <em>{props.children}</em>;
        case 'b':
            return (props: any) => <b>{props.children}</b>;
        case 'i':
            return (props: any) => <i>{props.children}</i>;
        case 'strong':
            return (props: any) => <strong>{props.children}</strong>;
        case 'br':
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (_props: any) => <br />;
        case 'hr':
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (_props: any) => <hr />;
        case 'ul':
            return (props: any) => (
                <Typography>
                    <ul>{props.children}</ul>
                </Typography>
            );
        case 'ol':
            return (props: any) => (
                <Typography>
                    <ol>{props.children}</ol>
                </Typography>
            );
        case 'li':
            return (props: any) => <li>{props.children}</li>;
        case 'img':
            return (props: any) => (
                <img
                    src={props.src}
                    height={props.height?.replace('px', '')} // PX not supported here apparently
                    width={props.width?.replace('px', '')}
                    alt={props.alt}
                >
                    {props.children}
                </img>
            );
        case 'textContent':
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (_props: any) => (
                <Fragment>
                    {tag.children.flatMap((x, i) => (
                        <Fragment key={i}>
                            {typeof x === 'string' ? supportHtmlEscapes(x) : (x as any)}
                        </Fragment>
                    ))}
                </Fragment>
            );
        case 'root':
            return (props: any) => <>{props.children}</>;
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (_props: any) => null;
    }
}

function supportHtmlEscapes(text: string) {
    const supportedEscapes = [
        ['&nbsp;', '\u00A0'],
        ['&amp;', '&'],
        ['&lt;', '\u003C'],
        ['&gt;', '\u003E'],
    ];

    let newText = text;
    for (const escapes of supportedEscapes) {
        newText = newText.split(escapes[0]).join(escapes[1]);
    }

    return newText;
}

function createElementFromTag(tag: Tag) {
    const Component = getComponentFromTagName(tag);
    const props = tag.attributes.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {});
    return (
        <Component {...props}>
            {tag.children.map((child, i) => (
                <Fragment key={i}>
                    {typeof child === 'string'
                        ? supportHtmlEscapes(child)
                        : createElementFromTag(child)}
                </Fragment>
            ))}
        </Component>
    );
}
