const RadioChecked = (props: { color: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={props.color}
        >
            <circle
                cx="12"
                cy="12"
                r="8"
                stroke={props.color}
                strokeWidth="1"
                fill="white"
            ></circle>
            <circle
                cx="12"
                cy="12"
                r="4"
                stroke={props.color}
                strokeWidth="2"
                fill={props.color}
            ></circle>
        </svg>
    );
};

export default RadioChecked;
