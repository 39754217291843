import { TextFieldProps, Theme } from '@mui/material';
import { Translations } from '@repo/types';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { ThemeType } from 'src/components/utils/theme/ThemeType';

export function getInputLayoutProps(
    t: Translations,
    theme: Theme,
    customizations: ThemeType,
    showErrorDate: boolean,
    endIcon: JSX.Element,
    backgroundColor?: string,
    color?: string,
    labelColor?: string,
    variant?: 'filled' | 'outlined',
    border?: string,
): Partial<TextFieldProps> {
    const bookingWidgetInputStyle = TextFieldStyleUtils.bookingWidgetInputStyle(
        customizations,
        backgroundColor,
        color,
    );

    return {
        required: true,
        sx: {
            borderRadius: `${customizations.borderRadius}px`,
            '& .MuiFilledInput-input': {
                padding: `${theme.spacing(2)} !important`,
                cursor: 'pointer',
                color: bookingWidgetInputStyle.color,
            },
            '& .MuiFilledInput-root': bookingWidgetInputStyle,
            '& .MuiInputBase-root': {
                cursor: 'pointer',
                border: border,
            },
            '& .MuiSvgIcon-root': {
                fill: bookingWidgetInputStyle.color,
            },
        },
        variant: variant ?? 'filled',
        margin: 'dense',
        size: 'small',
        InputProps: {
            readOnly: true,
            sx: showErrorDate
                ? {
                      color: theme.palette.error.main,
                      '& > *': {
                          color: theme.palette.error.main,
                      },
                      '.MuiSvgIcon-root': {
                          fill: bookingWidgetInputStyle.color,
                      },
                  }
                : undefined,
            endAdornment: endIcon,
        },
        inputProps: {
            'aria-labelledby': 'calendar-label',
        },
        FormHelperTextProps: {
            color: `${labelColor ?? customizations.bookingWidgetColorContrast} !important`,
            sx: {
                '&.Mui-error': {
                    color: labelColor ?? customizations.bookingWidgetColorContrast,
                    mx: 0,
                },
            },
        },
    };
}

export function getInputCallbackProps(
    hasChosenDate: boolean,
    setHasChosenDate: (value: boolean) => void,
    deselectTextInput: (e: React.FocusEvent) => void,
    setAnchorEl: (value: HTMLInputElement | HTMLDivElement | null) => void,
) {
    return {
        onClick: (e: React.MouseEvent) => {
            if (
                e.currentTarget instanceof HTMLInputElement ||
                e.currentTarget instanceof HTMLDivElement
            ) {
                setAnchorEl(e.currentTarget);
                if (!hasChosenDate) setHasChosenDate(true);
            }
        },
        onKeyDown: (e: React.KeyboardEvent) => {
            if (
                (e.currentTarget instanceof HTMLInputElement ||
                    e.currentTarget instanceof HTMLDivElement) &&
                e.key === 'Enter'
            ) {
                setAnchorEl(e.currentTarget);
                if (!hasChosenDate) setHasChosenDate(true);
            }
        },
        onFocus: deselectTextInput,
    };
}
