import { createRoot } from 'react-dom/client';
import { getIdAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import ProductPage2 from 'src/widgets/activities/product/product-page-2/ProductPage2WidgetRoot';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import createWebComponentTagName from 'src/utils/widget/createWebComponentTagName';

class BilberryProduct2 extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductPage2 productCatalogId={productCatalogId} />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(createWebComponentTagName('bilberry-product', 'v2'), BilberryProduct2);
}
