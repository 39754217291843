import { RefObject } from 'react';
import { atom } from 'ximple';

export type ShowBasketState = {
    visible: boolean;
    refocusElementOnClose?: string | RefObject<HTMLElement>;
};

export const showBasketReducer = (state: ShowBasketState, newState: Partial<ShowBasketState>) => ({
    ...state,
    ...newState,
});

export const showBasketAtom = atom<ShowBasketState, Partial<ShowBasketState>>({
    initialValue: {
        visible: false,
    },
    update: showBasketReducer,
});
