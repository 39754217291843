import { Grid, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { createRef } from 'react';
import { ProductInstance, TicketType, UserTextCustomizations } from '@repo/types';
import AccommodationCard from '../accommodation-card/AccommodationCard';
import { GuestInfo } from '../MultipleRoomSearch/guestInfo';

interface IProps {
    accommodationIds: string[];
    accommodationUrls?: string[];
    accommodations: ProductInstance[][];
    guestInfo?: GuestInfo;
    accommodationCardButtonCustomizationKeys?: (keyof UserTextCustomizations)[];
    bookDirectly: boolean;
    onSelectRoom: (
        selectedAccommodation: ProductInstance[],
        selectedTicketType: TicketType,
    ) => void;
    dateRange: DateRange<TZDate>;
    nights: number;
    selectedRoomId?: string;
}

export default function AccommodationCardList(props: IProps): JSX.Element {
    const {
        accommodationIds,
        accommodationUrls,
        accommodationCardButtonCustomizationKeys,
        bookDirectly,
        onSelectRoom,
        guestInfo,
        dateRange,
        accommodations = [],
        nights,
        selectedRoomId,
    } = props;

    const theme = useTheme();
    const container = createRef<HTMLDivElement>();

    const accommodationCardList = accommodations?.map((accommodation) => {
        const numGuests = (guestInfo?.adults ?? 1) + (guestInfo?.children.length ?? 0);
        const unavailable = accommodation.some(
            (a) => a.capacity <= 0 || !a.personsPerUnit || a.personsPerUnit < numGuests,
        );

        return (
            <AccommodationCard
                key={accommodation.map((a) => a.id).join('-')}
                isUnavailable={unavailable}
                accommodation={accommodation}
                guestInfo={guestInfo}
                url={
                    accommodationUrls?.[
                        accommodationIds.indexOf(accommodation[0].product?.id ?? '0')
                    ]
                }
                buttonCustomizationKey={
                    accommodationCardButtonCustomizationKeys
                        ? accommodationCardButtonCustomizationKeys[
                              accommodationIds.indexOf(accommodation[0].product?.id ?? '0')
                          ]
                        : undefined
                }
                bookDirectly={bookDirectly}
                numberOfCards={accommodations.length}
                onSelectRoom={onSelectRoom}
                dateRange={dateRange}
                nights={nights}
                selected={selectedRoomId === accommodation[0].product?.id}
            />
        );
    });

    return (
        <Grid container justifyContent="center" sx={{ containerType: 'inline-size' }}>
            <Grid
                item
                sx={{
                    padding: theme.spacing(2, 0),
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    ['@supports not (contain: inline-size)']: {
                        [`@media screen and (min-width: ${800}px)`]: {
                            gridTemplateColumns: '1fr 1fr',
                        },
                        [`@media screen and (min-width: ${1250}px)`]: {
                            gridTemplateColumns: '1fr 1fr 1fr',
                        },
                        [`@media screen and (min-width: ${1680}px)`]: {
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        },
                    },
                    ['@supports (contain: inline-size)']: {
                        ['@container (width > 500px)']: {
                            gridTemplateColumns: '1fr 1fr',
                        },
                        ['@container (width > 800px)']: {
                            gridTemplateColumns: '1fr 1fr 1fr',
                        },
                        ['@container (width > 1100px)']: {
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        },
                    },
                }}
                ref={container}
                gap={2}
            >
                {accommodations.length !== 0 && accommodationCardList}
            </Grid>
        </Grid>
    );
}
