import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    getCsvAttribute,
    getStringAttribute,
    getYesAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';
import { getCsvColorsAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { ProductListBookable } from './ProductListBookable';

class BilberryProductListBookable extends BilberryCustomElement {
    connectedCallback() {
        super.connectedCallback();
        const productCollectionId = getStringAttribute(this, 'product-collection-id');
        const productCatalogIds = !productCollectionId
            ? getCsvAttribute(this, 'product-catalog-ids')
            : undefined;
        const productCatalogUrls = getCsvAttribute(this, 'product-catalog-urls');

        if (productCollectionId === undefined && productCatalogIds === undefined) {
            throw new Error(
                'You must specify either a product-collection-id or product-catalog-ids attribute',
            );
        }

        const hideReadMore = getYesAttribute(this, 'hide-read-more');
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);
        const orientation = getStringAttribute(this, 'orientation', true) as
            | 'landscape'
            | 'portrait';

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductListBookable
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    productCollectionId={productCollectionId}
                    productCatalogIds={productCatalogIds}
                    productCatalogUrls={productCatalogUrls}
                    hideReadMore={hideReadMore}
                    orientation={orientation}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-list-bookable', BilberryProductListBookable);
}
