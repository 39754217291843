import { Box, CircularProgress, Grid } from '@mui/material';

import { useBilberrySettings } from '@repo/widget-utils/api/api';

export default function GiftTypeSelectionBody(): JSX.Element {
    const { bilberrySettings, isError, isLoading } = useBilberrySettings();

    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            maxWidth="100%"
            sx={{
                backgroundImage: 'linear-gradient(135deg, #B4B4B4 0%,  #DBDBDB 100%)',
            }}
        >
            {!isLoading && !isError ? (
                <Box
                    component="img"
                    maxWidth="100%"
                    src={bilberrySettings?.giftcard_dummy_image ?? ''}
                    alt="giftcard image"
                />
            ) : (
                <CircularProgress
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            )}
        </Grid>
    );
}
