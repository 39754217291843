import { FacilitiesAttributes, Translations } from '@repo/types';

export function facilityAsReadableText(facility: keyof FacilitiesAttributes, t: Translations) {
    switch (facility) {
        case 'hasAirconditioning':
            return t.has_airconditioning;
        case 'hasBalcony':
            return t.has_balcony;
        case 'hasBicycles':
            return t.has_bicycles;
        case 'hasBoat':
            return t.has_boat;
        case 'hasCoffeeMaker':
            return t.has_coffee_maker;
        case 'hasDishwasher':
            return t.has_dishwasher;
        case 'hasDryer':
            return t.has_dryer;
        case 'hasFireplace':
            return t.has_fireplace;
        case 'hasGarage':
            return t.has_garage;
        case 'hasGardenFurniture':
            return t.has_garden_furniture;
        case 'hasGrill':
            return t.has_grill;
        case 'hasKitchen':
            return t.has_kitchen;
        case 'hasMinibar':
            return t.has_minibar;
        case 'hasMooringSpace':
            return t.has_mooring_space;
        case 'hasPool':
            return t.has_pool;
        case 'hasRoomService':
            return t.has_roomservice;
        case 'hasSauna':
            return t.has_sauna;
        case 'hasTv':
            return t.has_tv;
        case 'hasWashingMachine':
            return t.has_washing_machine;
        case 'hasWifi':
            return t.has_wifi;
        case 'isBreakfastIncluded':
            return t.is_breakfast_included;
        case 'isCancellationIncluded':
            return t.is_cancellation_included;
        case 'isPetsAllowed':
            return t.is_pets_allowed;
        case 'isSmokingAllowed':
            return t.is_smoking_allowed;
        case 'isTowelsAndBedlinesIncluded':
            return t.is_towels_and_bedlines_included;
        case 'isWheelchairAccessible':
            return t.is_wheelchair_accessible;
    }
}

export function getImportantFacilities(facilities?: FacilitiesAttributes) {
    if (!facilities) return [];

    const importantKeys = [
        'isBreakfastIncluded',
        'hasWifi',
        'isWheelchairAccessible',
        'hasKitchen',
        'isPetsAllowed',
        'isSmokingAllowed',
    ];

    return importantKeys.filter((facility) =>
        Object.entries(facilities).some(([key, value]) => key === facility && !!value),
    ) as (keyof FacilitiesAttributes)[];
}
