import ActivityProductCard from 'src/components/domain/product-card-2/ActivityProductCard';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { useProducts } from '@repo/widget-utils/api/api';
import { ProductCatalogAttributes } from '@repo/widget-utils/attributes/product-catalog-attribute-helper';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';

interface IProps {
    productCatalogAttributes: ProductCatalogAttributes[];
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    productCollectionId?: number;
    isTimeslots?: boolean;
}

export default function ProductCardList2(props: IProps): JSX.Element {
    const {
        productCatalogAttributes,
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        productCollectionId = null,
        isTimeslots,
    } = props;
    const customizations = useCustomizations();
    const productCatalogIds = productCatalogAttributes.map((x) => x.id.toString());
    const { data: products } = useProducts({
        ids: productCatalogIds,
        collectionId: productCollectionId?.toString(),
        isTimeslots,
    });

    const productCardList = products.map((product, i) => {
        const url =
            productCollectionId !== null
                ? product.url
                : productCatalogAttributes[i].url ?? product.url ?? '#';
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={product.id}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={products.length}
                >
                    <ActivityProductCard
                        product={product}
                        url={url}
                        buttonCustomizationKey={
                            productCollectionId === null
                                ? productCatalogAttributes[i].textCustomizationKey
                                : undefined
                        }
                    />
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}
