import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from './ButtonDisplay';
import { useAtom } from 'ximple';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { cartAtom } from 'src/state/cart/cartAtom';
import Summary from 'src/components/domain/summary/Summary';
import TourSelection from 'src/components/domain/package/tour-selection/TourSelection';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useCartContext } from 'src/widgets/CartContext';

type PackageViewProps = {
    largeScreen: boolean;
    onNextClicked: () => void;
    nextButtonText: string;
    isMakingReservation: boolean;
};

export default function PackageView(props: PackageViewProps) {
    const { largeScreen, isMakingReservation, onNextClicked, nextButtonText } = props;
    const theme = useTheme();
    const customizations = useCustomizations();
    const { cartItems } = useCartContext();

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Package',
        cartItems,
    }));

    const packageItems = cartItems.filter((item) => item.pkg);

    return (
        <Stack
            justifyContent={largeScreen ? 'space-between' : 'center'}
            alignItems={largeScreen ? 'flex-start' : 'center'}
            direction={largeScreen ? 'row' : 'column-reverse'}
            gap={3}
            position="relative"
            width="100%"
        >
            {!largeScreen && (
                <Box
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            marginBottom: theme.spacing(3),
                        },
                    }}
                >
                    <Button
                        sx={{ float: 'right', marginTop: theme.spacing(2) }}
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        disabled={isMakingReservation}
                        onClick={onNextClicked}
                    >
                        <ButtonDisplay
                            buttonText={nextButtonText}
                            isMakingReservation={isMakingReservation}
                        />
                    </Button>
                </Box>
            )}

            <Stack gap={3} width="100%" mt={2}>
                {packageItems.map((item) => (
                    <Box key={getCartItemId(item)}>
                        <Typography
                            variant="h3"
                            sx={{ fontWeight: 'bold' }}
                            mb={1}
                            pb={0.5}
                            borderBottom={1}
                            borderColor={theme.palette.grey[500]}
                        >
                            {item.pkg?.pkg.title ?? ''} - {item.pkg?.date?.format('L') ?? ''}
                        </Typography>
                        <TourSelection item={item} />
                    </Box>
                ))}
            </Stack>

            <Summary
                showShare
                hideGoPay={!largeScreen}
                goPayClicked={onNextClicked}
                nextButtonText={nextButtonText}
                isCheckoutSummary={true}
                isMakingReservation={isMakingReservation}
            />
        </Stack>
    );
}
