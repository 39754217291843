import WarningIcon from '@mui/icons-material/WarningRounded';
import { Paper, Button, Grid, Typography, darken, capitalize, alpha, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { RefObject, useState } from 'react';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useLocale } from '@repo/i18n';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import {
    mapPriceQuantitiesToMultipleNumberInputValueType,
    onChangeQuantity,
    QuantityError,
    verifyQuantitiesValid,
} from '@repo/widget-utils/booking/bookingHelpers';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import ControlledInputVisibilitySection from '../../common/inputs/ControlledInputVisibilitySection';
import BookingWidgetCard from '../booking-widget-card/BookingWidgetCard';
import { ensurePixelValue } from 'src/components/utils/styleUtils';
import { ProductInstance, TicketOptionWithQuantity, TicketType, Timeslot } from '@repo/types';
import { CostSummary } from './cost-summary/CostSummary';
import Calendar from 'src/components/common/calendar/Calendar';
import PackageCalendar from 'src/components/common/calendar/PackageCalendar';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import { MembershipBookingDisabledWarning } from './MembershipBookingDisabledWarning';
import { CartCompatibilityWarning } from './CartCompatibilityWarning';

const DEFAULT_NUM_PRICES = 3;

interface IProps {
    title: string;
    visible: boolean;
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void;
    toggleButton?: JSX.Element;
    productTitle: string;
    travelerQuantities: TicketOptionWithQuantity[];
    priceQuantities: TicketOptionWithQuantity[];
    defaultQuantities: TicketOptionWithQuantity[];
    setQuantities: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>;
    selectedTimeSlot?: TimeSlotType;
    onSelectTimeSlot?: (timeslot: TimeSlotType | undefined) => void;
    selectedDateRange?: DateRange<TZDate>;
    onSelectDateRange?: (newRange: DateRange<TZDate>) => void;
    availabilityData: ProductInstance[];
    numPrices?: number;
    setSelectedProducts?(products: ProductInstance[] | undefined): void;
    leftButtonLabel?: string;
    rightButtonLabel: string;
    onClickLeftButton?(): void;
    onClickRightButton(): void;
    availabilitySearchPeriod: { startDay: TZDate | null; endDay: TZDate | null };
    setAvailabilitySearchPeriod(availabilitySearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }): void;
    hasChosenDate: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    attemptedBooking: boolean;
    fromPrice?: number | null;
    hideLeftButton?: boolean;
    productCapacity?: number;
    disableFixedPosition?: boolean;
    addMarginRightToHeader?: boolean;
    bookingCardRef?: RefObject<HTMLDivElement>;
    expandArrowInside: boolean;
    dateRangeVariant?: 'days' | 'nights';
    minDate?: TZDate;
    jsxSectionEnd?: React.ReactNode;
    isPackageCalendar?: boolean;
    setSelectedDate?: (value: TZDate | null) => void;
    selectedDate?: TZDate | null;
    capacityWarningLabel?: string;
    positionOffscreen: boolean;
    ticketTypes?: TicketType[];
    setSelectedTicketType?: (ticketType?: TicketType) => void;
    selectedTicketType?: TicketType;
    availableTimeslots?: Timeslot[];
    showCartCompatibilityWarning: boolean;
    showMembershipBookingDisabledWarning: boolean;
    isUsingPaymentPlan: boolean;
}

export default function BookingBase(props: IProps): JSX.Element {
    const {
        productTitle,
        priceQuantities,
        leftButtonLabel,
        onClickLeftButton,
        onClickRightButton,
        rightButtonLabel,
        hasChosenDate,
        onToggleVisible,
        visible,
        title,
        toggleButton,
        hideLeftButton,
        disableFixedPosition,
        fromPrice,
        addMarginRightToHeader,
        bookingCardRef,
        expandArrowInside,
        numPrices = DEFAULT_NUM_PRICES,
        capacityWarningLabel,
        positionOffscreen,
        selectedTicketType,
        showCartCompatibilityWarning,
        showMembershipBookingDisabledWarning,
        isUsingPaymentPlan,
    } = props;

    const [hasChangedQuantities, setHasChangedQuantities] = useState(false);

    const showFromPrice = fromPrice && !visible && !hasChangedQuantities;

    function handleRightClick() {
        onClickRightButton();
    }

    const { disableBookButton, quantityErrors, totalErrors, getWarningLabel } =
        verifyQuantitiesValid(priceQuantities, capacityWarningLabel, hasChosenDate);

    const showWarning = showCartCompatibilityWarning || showMembershipBookingDisabledWarning;

    return (
        <BookingWidgetCard
            bookingCardRef={bookingCardRef}
            visible={visible}
            onToggleVisible={onToggleVisible}
            title={title}
            toggleButton={toggleButton}
            rightButtonLabel={rightButtonLabel}
            onClickRightButton={handleRightClick}
            leftButtonLabel={leftButtonLabel}
            onClickLeftButton={onClickLeftButton}
            hideLeftButton={hideLeftButton}
            hideRightButton={showWarning}
            quantities={priceQuantities}
            fromPrice={showFromPrice ? fromPrice : null}
            disableFixedPosition={disableFixedPosition}
            addMarginRightToHeader={addMarginRightToHeader}
            expandArrowInside={expandArrowInside}
            disableBookButton={disableBookButton}
            positionOffscreen={positionOffscreen}
            hideExpandedPrice={showWarning}
        >
            {!showWarning && (
                <>
                    <BookingBaseForm
                        {...props}
                        setHasChangedQuantities={setHasChangedQuantities}
                        quantityErrors={quantityErrors}
                        totalErrors={totalErrors}
                    />
                    <CostSummary
                        header={productTitle}
                        quantities={hasChosenDate ? priceQuantities : []}
                        numPrices={numPrices}
                        ticketType={selectedTicketType}
                        isUsingPaymentPlan={isUsingPaymentPlan}
                    />
                    <WarningMessage label={!hasChosenDate ? null : getWarningLabel()} />
                </>
            )}
            {showMembershipBookingDisabledWarning && (
                <MembershipBookingDisabledWarning></MembershipBookingDisabledWarning>
            )}
            {!showMembershipBookingDisabledWarning && showCartCompatibilityWarning && (
                <CartCompatibilityWarning></CartCompatibilityWarning>
            )}
        </BookingWidgetCard>
    );
}

export function BookingBaseInline(
    props: Pick<
        IProps,
        | 'title'
        | 'productTitle'
        | 'travelerQuantities'
        | 'priceQuantities'
        | 'defaultQuantities'
        | 'setQuantities'
        | 'setSelectedProducts'
        | 'selectedTimeSlot'
        | 'onSelectTimeSlot'
        | 'availabilityData'
        | 'numPrices'
        | 'rightButtonLabel'
        | 'onClickRightButton'
        | 'availabilitySearchPeriod'
        | 'setAvailabilitySearchPeriod'
        | 'hasChosenDate'
        | 'setHasChosenDate'
        | 'attemptedBooking'
        | 'productCapacity'
        | 'onSelectDateRange'
        | 'selectedDateRange'
        | 'dateRangeVariant'
        | 'minDate'
        | 'capacityWarningLabel'
        | 'bookingCardRef'
        | 'ticketTypes'
        | 'selectedTicketType'
        | 'setSelectedTicketType'
        | 'selectedDate'
        | 'showCartCompatibilityWarning'
        | 'showMembershipBookingDisabledWarning'
        | 'isUsingPaymentPlan'
    >,
): JSX.Element {
    const {
        productTitle,
        priceQuantities,
        onClickRightButton,
        rightButtonLabel,
        hasChosenDate,
        numPrices = DEFAULT_NUM_PRICES,
        capacityWarningLabel,
        showCartCompatibilityWarning,
        showMembershipBookingDisabledWarning,
        isUsingPaymentPlan,
    } = props;

    const { disableBookButton, quantityErrors, totalErrors, getWarningLabel } =
        verifyQuantitiesValid(priceQuantities, capacityWarningLabel, hasChosenDate);

    const customizations = useCustomizations();
    const theme = useTheme();

    const showWarning = showCartCompatibilityWarning || showMembershipBookingDisabledWarning;

    return (
        <Grid component={'div'}>
            <Paper
                elevation={5}
                sx={{
                    backgroundColor: customizations.bookingWidgetColor,
                    color: customizations.bookingWidgetColorContrast,
                    borderRadius: `calc(${ensurePixelValue(theme.shape.borderRadius)} * 2)`,
                    padding: 3,
                    maxWidth: '640px',
                    margin: '0 auto',
                }}
            >
                <Stack gap={2}>
                    {!showWarning && (
                        <>
                            <BookingBaseForm
                                {...props}
                                quantityErrors={quantityErrors}
                                totalErrors={totalErrors}
                            />
                            <CostSummary
                                header={productTitle}
                                quantities={hasChosenDate ? priceQuantities : []}
                                ticketType={props.selectedTicketType}
                                numPrices={numPrices}
                                isUsingPaymentPlan={isUsingPaymentPlan}
                            />
                            <WarningMessage label={!hasChosenDate ? null : getWarningLabel()} />
                            <Button
                                sx={{
                                    flex: '0 0 auto',
                                    margin: '0 auto',
                                    display: 'block',
                                    minWidth: '10rem',
                                    backgroundColor:
                                        customizations.primaryButtonStyle === 'contained'
                                            ? customizations.bookingWidgetPrimaryColor
                                            : 'transparent',
                                    color:
                                        customizations.primaryButtonStyle === 'contained'
                                            ? customizations.bookingWidgetPrimaryColorContrast
                                            : customizations.bookingWidgetPrimaryColor,
                                    borderColor:
                                        customizations.primaryButtonStyle === 'contained'
                                            ? 'transparent'
                                            : customizations.bookingWidgetPrimaryColor,
                                    '&:hover': {
                                        backgroundColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? darken(
                                                      customizations.bookingWidgetPrimaryColor,
                                                      0.2,
                                                  )
                                                : alpha(
                                                      customizations.bookingWidgetPrimaryColor,
                                                      0.2,
                                                  ),
                                        color:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? darken(
                                                      customizations.bookingWidgetPrimaryColorContrast,
                                                      0.2,
                                                  )
                                                : undefined,
                                    },
                                    '&:focus': {
                                        boxShadow: `0 0 0 2px ${customizations.bookingWidgetPrimaryColorContrast}, \
                                                    0 0 0 3px ${customizations.bookingWidgetPrimaryColor}`,
                                    },
                                    '&:disabled': {
                                        backgroundColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? darken(
                                                      customizations.bookingWidgetPrimaryColor,
                                                      0.5,
                                                  )
                                                : 'transparent',
                                        color:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? customizations.bookingWidgetColor
                                                : customizations.bookingWidgetPrimaryColor,
                                        borderColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? 'transparent'
                                                : darken(
                                                      customizations.bookingWidgetPrimaryColor,
                                                      0.5,
                                                  ),
                                    },
                                }}
                                onClick={onClickRightButton}
                                aria-label={rightButtonLabel}
                                variant={customizations.primaryButtonStyle}
                                color="button"
                                disabled={disableBookButton}
                            >
                                {capitalize(rightButtonLabel)}
                            </Button>
                        </>
                    )}
                    {showMembershipBookingDisabledWarning && (
                        <MembershipBookingDisabledWarning></MembershipBookingDisabledWarning>
                    )}
                    {!showMembershipBookingDisabledWarning && showCartCompatibilityWarning && (
                        <CartCompatibilityWarning></CartCompatibilityWarning>
                    )}
                </Stack>
            </Paper>
        </Grid>
    );
}

export function WarningMessage(props: { label?: string | null }) {
    const theme = useTheme();
    const customizations = useCustomizations();

    if (!props.label) return null;

    return (
        <Grid textAlign="center" display="flex" justifyContent="center">
            <WarningIcon
                sx={{
                    color: customizations.bookingWidgetColorContrast,
                }}
            ></WarningIcon>
            <Typography
                color={{ color: customizations.bookingWidgetColorContrast }}
                ml={theme.spacing(1)}
            >
                {props.label}
            </Typography>
        </Grid>
    );
}

export function BookingBaseForm(
    props: Pick<
        IProps,
        | 'defaultQuantities'
        | 'travelerQuantities'
        | 'selectedTimeSlot'
        | 'setQuantities'
        | 'onSelectTimeSlot'
        | 'availabilityData'
        | 'setSelectedProducts'
        | 'availabilitySearchPeriod'
        | 'setAvailabilitySearchPeriod'
        | 'hasChosenDate'
        | 'setHasChosenDate'
        | 'attemptedBooking'
        | 'productCapacity'
        | 'selectedDateRange'
        | 'onSelectDateRange'
        | 'dateRangeVariant'
        | 'minDate'
        | 'jsxSectionEnd'
        | 'isPackageCalendar'
        | 'selectedDate'
        | 'setSelectedDate'
        | 'ticketTypes'
        | 'selectedTicketType'
        | 'setSelectedTicketType'
        | 'availableTimeslots'
    > & {
        setHasChangedQuantities?: React.Dispatch<React.SetStateAction<boolean>>;
        quantityErrors: QuantityError[];
        totalErrors: QuantityError[];
        backgroundColor?: string;
        color?: string;
        labelColor?: string;
        variant?: 'filled' | 'outlined';
        id?: string;
        inputFieldBorder?: string;
    },
) {
    const {
        defaultQuantities,
        travelerQuantities,
        selectedTimeSlot,
        setQuantities,
        onSelectTimeSlot,
        availabilityData,
        setSelectedProducts,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        productCapacity,
        selectedDateRange,
        onSelectDateRange,
        dateRangeVariant,
        minDate,
        jsxSectionEnd,
        setHasChangedQuantities,
        quantityErrors,
        totalErrors,
        isPackageCalendar,
        selectedDate,
        setSelectedDate,
        id,
        backgroundColor,
        color,
        labelColor,
        variant,
        ticketTypes,
        selectedTicketType,
        setSelectedTicketType,
        availableTimeslots,
        inputFieldBorder,
    } = props;

    const { t } = useLocale();
    const customizations = useCustomizations();
    const config = useConfigurations();

    const { multipleNumberInputValues, defaultMultipleNumberInputValues } =
        mapPriceQuantitiesToMultipleNumberInputValueType(
            t,
            travelerQuantities,
            defaultQuantities,
            quantityErrors,
        );

    const isPackage = isPackageCalendar && setSelectedDate;

    return (
        <ControlledInputVisibilitySection>
            {ticketTypes && ticketTypes.length > 0 && (
                <Dropdown
                    value={selectedTicketType ?? null}
                    options={ticketTypes}
                    getOptionLabel={(opt) => opt?.name ?? t.select_price_type}
                    onChange={(opt) => setSelectedTicketType?.(opt ?? undefined)}
                    labelColor={customizations.bookingWidgetColorContrast}
                    label={t.select_price_type}
                    error={
                        attemptedBooking && !selectedTicketType
                            ? t.please_select_price_type
                            : undefined
                    }
                />
            )}
            <MultipleNumberInput
                values={multipleNumberInputValues}
                defaultValues={defaultMultipleNumberInputValues}
                onChange={(id, q) =>
                    onChangeQuantity(id, q, setHasChangedQuantities, setQuantities)
                }
                error={totalErrors[0]}
                labelColor={labelColor ?? customizations.bookingWidgetColorContrast}
                backgroundColor={backgroundColor}
                color={color}
                variant={variant}
                maxEntrants={productCapacity ?? config.personsMax}
                border={inputFieldBorder}
            />

            {isPackage && (
                <PackageCalendar
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    selectedDate={selectedDate ?? null}
                    setSelectedDate={setSelectedDate}
                    availabilityData={availabilityData}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    minDate={minDate}
                    attemptedBooking={attemptedBooking}
                    backgroundColor={backgroundColor}
                    color={color}
                    labelColor={labelColor}
                    variant={variant}
                    id={id}
                    border={inputFieldBorder}
                />
            )}
            {!isPackage && (
                <Calendar
                    dateRangeVariant={dateRangeVariant}
                    onSelectDateRange={onSelectDateRange}
                    selectedDateRange={selectedDateRange}
                    selectedTimeSlot={selectedTimeSlot}
                    onSelectTimeSlot={onSelectTimeSlot}
                    setSelectedProducts={setSelectedProducts}
                    availabilityData={availabilityData}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    attemptedBooking={attemptedBooking}
                    minDate={minDate}
                    id={id}
                    backgroundColor={backgroundColor}
                    color={color}
                    labelColor={labelColor}
                    variant={variant}
                    quantities={travelerQuantities}
                    availableTimeslots={availableTimeslots}
                    border={inputFieldBorder}
                />
            )}
            {jsxSectionEnd ? <>{jsxSectionEnd}</> : <></>}
        </ControlledInputVisibilitySection>
    );
}
