import { getUrlWithParams } from './api-client-common';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import { BASE_SERVICE_URL, USE_SERVICE_PROXY } from '@repo/widget-env/__autogen/env';
import { BilberryWidgetsGlobal } from '@repo/types';

const accommodationsProxyPath = '/api/v2/accommodations/proxy';

export function getHotelApiBaseUrl(config: BilberryWidgetsGlobal) {
    const baseUrlFromGlobal = config.bilberryBaseApiUrl;
    if (baseUrlFromGlobal) return baseUrlFromGlobal + accommodationsProxyPath;

    // Needed for local development through node-proxy
    return USE_SERVICE_PROXY ? accommodationsProxyPath : BASE_SERVICE_URL + accommodationsProxyPath;
}

export function getDefaultHotelHeaders(locale: string, config: BilberryWidgetsGlobal) {
    return new Headers({
        'Content-Type': 'text/json',
        'Accept-Language': locale ?? 'en',
        Authorization: `Bearer ${config.bilberryAccessToken}`,
    });
}

export function getDefaultHotelRequest(
    subdirectory: string,
    locale: string,
    config: BilberryWidgetsGlobal,
    queryParams: Record<string, any> = {},
) {
    const endpoint = `${getHotelApiBaseUrl(config)}${subdirectory}`;
    return {
        url: getUrlWithParams(endpoint, locale, queryParams),
        headers: getDefaultHotelHeaders(locale, config),
    };
}

export function hotelDateParams(dateRange: DateRange<TZDate>) {
    const startDate = dateRange[0] ? dateRange[0].getDayjsDate().format('YYYY-MM-DD') : '';
    const endDate = () => {
        if (dateRange[0] && !dateRange[1]) {
            return dateRange[0].add(1, 'days').getDayjsDate().format('YYYY-MM-DD');
        } else if (dateRange[1]) {
            return dateRange[1].getDayjsDate().format('YYYY-MM-DD');
        } else {
            return '';
        }
    };

    return {
        from: startDate,
        to: endDate(),
    };
}
