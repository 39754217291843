import invert from "lodash-es/invert";
import { Iso2Code } from "@repo/types";

export const norway = "no";
export const norwegianLocales = ["no", "nb", "nb-no", "nn-no"];

const dialCodes: Record<Iso2Code, string> = {
  no: "47",
  af: "93",
  al: "355",
  dz: "213",
  as: "1",
  ad: "376",
  ao: "244",
  ai: "1",
  ag: "1",
  ar: "54",
  am: "374",
  aw: "297",
  au: "61",
  at: "43",
  az: "994",
  bs: "1",
  bh: "973",
  bd: "880",
  bb: "1",
  by: "375",
  be: "32",
  bz: "501",
  bj: "229",
  bm: "1",
  bt: "975",
  bo: "591",
  ba: "387",
  bw: "267",
  br: "55",
  io: "246",
  vg: "1",
  bn: "673",
  bg: "359",
  bf: "226",
  bi: "257",
  kh: "855",
  cm: "237",
  ca: "1",
  cv: "238",
  bq: "599",
  ky: "1",
  cf: "236",
  td: "235",
  cl: "56",
  cn: "86",
  co: "57",
  km: "269",
  cd: "243",
  cg: "242",
  ck: "682",
  cr: "506",
  ci: "225",
  hr: "385",
  cu: "53",
  cw: "599",
  cy: "357",
  cz: "420",
  dk: "45",
  dj: "253",
  dm: "1",
  do: "1",
  ec: "593",
  eg: "20",
  sv: "503",
  gq: "240",
  er: "291",
  ee: "372",
  et: "251",
  fk: "500",
  fo: "298",
  fj: "679",
  fi: "358",
  fr: "33",
  gf: "594",
  pf: "689",
  ga: "241",
  gm: "220",
  ge: "995",
  de: "49",
  gh: "233",
  gi: "350",
  gr: "30",
  gl: "299",
  gd: "1",
  gp: "590",
  gu: "1",
  gt: "502",
  gn: "224",
  gw: "245",
  gy: "592",
  ht: "509",
  hn: "504",
  hk: "852",
  hu: "36",
  is: "354",
  in: "91",
  id: "62",
  ir: "98",
  iq: "964",
  ie: "353",
  il: "972",
  it: "39",
  jm: "1",
  jp: "81",
  jo: "962",
  kz: "7",
  ke: "254",
  ki: "686",
  xk: "383",
  kw: "965",
  kg: "996",
  la: "856",
  lv: "371",
  lb: "961",
  ls: "266",
  lr: "231",
  ly: "218",
  li: "423",
  lt: "370",
  lu: "352",
  mo: "853",
  mk: "389",
  mg: "261",
  mw: "265",
  my: "60",
  mv: "960",
  ml: "223",
  mt: "356",
  mh: "692",
  mq: "596",
  mr: "222",
  mu: "230",
  mx: "52",
  fm: "691",
  md: "373",
  mc: "377",
  mn: "976",
  me: "382",
  ms: "1",
  ma: "212",
  mz: "258",
  mm: "95",
  na: "264",
  nr: "674",
  np: "977",
  nl: "31",
  nc: "687",
  nz: "64",
  ni: "505",
  ne: "227",
  ng: "234",
  nu: "683",
  nf: "672",
  kp: "850",
  mp: "1",
  om: "968",
  pk: "92",
  pw: "680",
  ps: "970",
  pa: "507",
  pg: "675",
  py: "595",
  pe: "51",
  ph: "63",
  pl: "48",
  pt: "351",
  pr: "1",
  qa: "974",
  re: "262",
  ro: "40",
  ru: "7",
  rw: "250",
  bl: "590",
  sh: "290",
  kn: "1",
  lc: "1",
  mf: "590",
  pm: "508",
  vc: "1",
  ws: "685",
  sm: "378",
  st: "239",
  sa: "966",
  sn: "221",
  rs: "381",
  sc: "248",
  sl: "232",
  sg: "65",
  sx: "1",
  sk: "421",
  si: "386",
  sb: "677",
  so: "252",
  za: "27",
  kr: "82",
  ss: "211",
  es: "34",
  lk: "94",
  sd: "249",
  sr: "597",
  sz: "268",
  se: "46",
  ch: "41",
  sy: "963",
  tw: "886",
  tj: "992",
  tz: "255",
  th: "66",
  tl: "670",
  tg: "228",
  tk: "690",
  to: "676",
  tt: "1",
  tn: "216",
  tr: "90",
  tm: "993",
  tc: "1",
  tv: "688",
  vi: "1",
  ug: "256",
  ua: "380",
  ae: "971",
  gb: "44",
  us: "1",
  uy: "598",
  uz: "998",
  vu: "678",
  va: "39",
  ve: "58",
  vn: "84",
  wf: "681",
  ye: "967",
  yt: "175",
  zm: "260",
  zw: "263",
};

export const countryOptionsEn: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "United Arab Emirates",
  af: "Afghanistan",
  ag: "Antigua and Barbuda",
  ai: "Anguilla",
  al: "Albania",
  dz: "Algeria",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "American Samoa",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  az: "Azerbaijan",
  ba: "Bosnia and Herzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgium",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint Barthelemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivia",
  br: "Brazil",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Belarus",
  bz: "Belize",
  ca: "Canada",
  cd: "Congo, Democratic Republic of the",
  cf: "Central African Republic",
  cg: "Congo, Republic of the",
  ch: "Switzerland",
  ci: "Cote d'Ivoire",
  ck: "Cook Islands",
  cl: "Chile",
  cm: "Cameroon",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Cape Verde",
  bq: "Caribbean Netherlands",
  cw: "Curacao",
  cy: "Cyprus",
  cz: "Czech Republic",
  de: "Germany",
  dj: "Djibouti",
  dk: "Denmark",
  dm: "Dominica",
  do: "Dominican Republic",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egypt",
  er: "Eritrea",
  es: "Spain",
  et: "Ethiopia",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falkland Islands (Malvinas)",
  fm: "Micronesia, Federated States of",
  fo: "Faroe Islands",
  fr: "France",
  ga: "Gabon",
  gb: "United Kingdom",
  gd: "Grenada",
  ge: "Georgia",
  gf: "French Guiana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Greenland",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Equatorial Guinea",
  gr: "Greece",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Croatia",
  ht: "Haiti",
  hu: "Hungary",
  id: "Indonesia",
  ie: "Ireland",
  il: "Israel",
  in: "India",
  io: "British Indian Ocean Territory",
  iq: "Iraq",
  ir: "Iran, Islamic Republic of",
  is: "Iceland",
  it: "Italy",
  jm: "Jamaica",
  jo: "Jordan",
  jp: "Japan",
  ke: "Kenya",
  kg: "Kyrgyzstan",
  kh: "Cambodia",
  ki: "Kiribati",
  km: "Comoros",
  kn: "Saint Kitts and Nevis",
  kp: "Korea, Democratic People's Republic of",
  kr: "Korea, Republic of",
  kw: "Kuwait",
  ky: "Cayman Islands",
  kz: "Kazakhstan",
  la: "Lao People's Democratic Republic",
  lb: "Lebanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  ly: "Libya",
  ma: "Morocco",
  mc: "Monaco",
  md: "Moldova, Republic of",
  me: "Montenegro",
  mf: "Saint Martin (French part)",
  mg: "Madagascar",
  mh: "Marshall Islands",
  mk: "Macedonia, the Former Yugoslav Republic of",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolia",
  mo: "Macao",
  mp: "Northern Mariana Islands",
  mq: "Martinique",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldives",
  mw: "Malawi",
  mx: "Mexico",
  my: "Malaysia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "New Caledonia",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Netherlands",
  no: "Norway",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "New Zealand",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "French Polynesia",
  pg: "Papua New Guinea",
  ph: "Philippines",
  pk: "Pakistan",
  pl: "Poland",
  pm: "Saint Pierre and Miquelon",
  pr: "Puerto Rico",
  ps: "Palestine, State of",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Reunion",
  ro: "Romania",
  rs: "Serbia",
  ru: "Russian Federation",
  rw: "Rwanda",
  sa: "Saudi Arabia",
  sb: "Solomon Islands",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Sweden",
  sg: "Singapore",
  sh: "Saint Helena",
  si: "Slovenia",
  sk: "Slovakia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "South Sudan",
  st: "Sao Tome and Principe",
  sv: "El Salvador",
  sx: "Sint Maarten (Dutch part)",
  sy: "Syrian Arab Republic",
  sz: "Swaziland",
  tc: "Turks and Caicos Islands",
  td: "Chad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tajikistan",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turkey",
  tt: "Trinidad and Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Province of China",
  tz: "United Republic of Tanzania",
  ua: "Ukraine",
  ug: "Uganda",
  us: "United States",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Holy See (Vatican City State)",
  vc: "Saint Vincent and the Grenadines",
  ve: "Venezuela",
  vg: "British Virgin Islands",
  vi: "US Virgin Islands",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis and Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yemen",
  yt: "Mayotte",
  za: "South Africa",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const countryOptionsNb: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "De forente arabiske emirater",
  af: "Afghanistan",
  ag: "Antigua og Barbuda",
  ai: "Anguilla",
  al: "Albania",
  dz: "Algerie",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Amerikansk Samoa",
  at: "Østerrike",
  au: "Australia",
  aw: "Aruba",
  az: "Azerbaijan",
  ba: "Bosnia-Hercegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgia",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthelemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivia",
  br: "Brasil",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Hviterussland",
  bz: "Belize",
  ca: "Canada",
  cd: "Den demokratiske republikken Kongo",
  cf: "Den sentralafrikanske republikk",
  cg: "Kongo",
  ch: "Sveits",
  ci: "Elfenbenskysten",
  ck: "Cookøyene",
  cl: "Chile",
  cm: "Kamerun",
  cn: "Kina",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Kapp Verde",
  bq: "Caribbean Netherlands",
  cw: "Curacao",
  cy: "Kypros",
  cz: "Tsjekkia",
  de: "Tyskland",
  dj: "Djibouti",
  dk: "Danmark",
  dm: "Dominica",
  do: "Den dominikanske republikk",
  ec: "Ecuador",
  ee: "Estland",
  eg: "Egypt",
  er: "Eritrea",
  es: "Spania",
  et: "Etiopia",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falklandsøyene",
  fm: "Mikronesiaføderasjonen",
  fo: "Færøyene",
  fr: "Frankrike",
  ga: "Gabon",
  gb: "Storbritannia",
  gd: "Grenada",
  ge: "Georgia",
  gf: "Fransk Guyana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grønland",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Ekvatorial-Guinea",
  gr: "Hellas",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkong",
  hn: "Honduras",
  hr: "Kroatia",
  ht: "Haiti",
  hu: "Ungarn",
  id: "Indonesia",
  ie: "Irland",
  il: "Israel",
  in: "India",
  io: "Det britiske territoriet i Indiahavet",
  iq: "Irak",
  ir: "Iran",
  is: "Island",
  it: "Italia",
  jm: "Jamaica",
  jo: "Jordan",
  jp: "Japan",
  ke: "Kenya",
  kg: "Kirgisistan",
  kh: "Kambodsja",
  ki: "Kiribati",
  km: "Komorene",
  kn: "Saint Kitts og Nevis",
  kp: "Nord-Korea",
  kr: "Sør-Korea",
  kw: "Kuwait",
  ky: "Caymanøyene",
  kz: "Kasakhstan",
  la: "Laos",
  lb: "Libanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Litauen",
  lu: "Luxemburg",
  lv: "Latvia",
  ly: "Libya",
  ma: "Marokko",
  mc: "Monaco",
  md: "Moldova",
  me: "Montenegro",
  mf: "Saint Martin (Fransk del)",
  mg: "Madagaskar",
  mh: "Marshalløyene",
  mk: "Makedonia",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolia",
  mo: "Macao",
  mp: "Nord-Marianene",
  mq: "Martinique",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldivene",
  mw: "Malawi",
  mx: "Mexico",
  my: "Malaysia",
  mz: "Mosambik",
  na: "Namibia",
  nc: "Ny-Caledonia",
  ne: "Niger",
  nf: "Norfolkøyene",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Nederland",
  no: "Norge",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "New Zealand",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Fransk Polynesia",
  pg: "Papua Ny-Guinea",
  ph: "Filippinene",
  pk: "Pakistan",
  pl: "Polen",
  pm: "Saint Pierre og Miquelon",
  pr: "Puerto Rico",
  ps: "Palestina",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Reunion",
  ro: "Romania",
  rs: "Serbia",
  ru: "Russland",
  rw: "Rwanda",
  sa: "Saudi Arabia",
  sb: "Salomonøyene",
  sc: "Seychellene",
  sd: "Sudan",
  se: "Sverige",
  sg: "Singapore",
  sh: "Sankt Helena",
  si: "Slovenia",
  sk: "Slovakia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Surinam",
  ss: "Sør-Sudan",
  st: "Sao Tome og Principe",
  sv: "El Salvador",
  sx: "Sint Maarten ",
  sy: "Syria",
  sz: "Eswatini",
  tc: "Turks- og Caicosøyene",
  td: "Tjad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tadsjikistan",
  tk: "Tokelau",
  tl: "Øst-Timor",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Tyrkia",
  tt: "Trinidad og Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Republikken Kina",
  tz: "Tanzania",
  ua: "Ukraina",
  ug: "Uganda",
  us: "USA (De Forente Stater",
  uy: "Uruguay",
  uz: "Usbekistan",
  va: "Vatikanstaten",
  vc: "Saint Vincent og the Grenadinene",
  ve: "Venezuela",
  vg: "De Britiske Jomfruøyer",
  vi: "De Amerikanske Jomfruøyer",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis og Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Sør-Afrika",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const countryOptionsDk: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "De Forenede Arabiske Emirater",
  af: "Afghanistan",
  ag: "Antigua og Barbuda",
  ai: "Anguilla",
  al: "Albanien",
  dz: "Algeriet",
  am: "Armenien",
  ao: "Angola",
  ar: "Argentina",
  as: "Amerikansk Samoa",
  at: "Østrig",
  au: "Australien",
  aw: "Aruba",
  az: "Aserbajdsjan",
  ba: "Bosnien-Hercegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgien",
  bf: "Burkina Faso",
  bg: "Bulgarien",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint Barthelemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivia",
  br: "Brasilien",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Hviderusland",
  bz: "Belize",
  ca: "Canada",
  cd: "Congo, Den Demokratiske Republik",
  cf: "Centralafrikanske Republik",
  cg: "Congo, Republikken",
  ch: "Schweiz",
  ci: "Côte d'Ivoire",
  ck: "Cook-øerne",
  cl: "Chile",
  cm: "Cameroun",
  cn: "Kina",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Kap Verde",
  bq: "Caribien Nederlandene",
  cw: "Curaçao",
  cy: "Cypern",
  cz: "Tjekkiet",
  de: "Tyskland",
  dj: "Djibouti",
  dk: "Danmark",
  dm: "Dominica",
  do: "Den Dominikanske Republik",
  ec: "Ecuador",
  ee: "Estland",
  eg: "Egypten",
  er: "Eritrea",
  es: "Spanien",
  et: "Etiopien",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falklandsøerne (Malvinas)",
  fm: "Mikronesien, Forbundsstaterne",
  fo: "Færøerne",
  fr: "Frankrig",
  ga: "Gabon",
  gb: "Det Forenede Kongerige",
  gd: "Grenada",
  ge: "Georgien",
  gf: "Fransk Guyana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grønland",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Ækvatorial Guinea",
  gr: "Grækenland",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Kroatien",
  ht: "Haiti",
  hu: "Ungarn",
  id: "Indonesien",
  ie: "Irland",
  il: "Israel",
  in: "Indien",
  io: "Det britiske territorium i Det Indiske Ocean",
  iq: "Irak",
  ir: "Iran, Den Islamiske Republik",
  is: "Island",
  it: "Italien",
  jm: "Jamaica",
  jo: "Jordan",
  jp: "Japan",
  ke: "Kenya",
  kg: "Kirgisistan",
  kh: "Cambodja",
  ki: "Kiribati",
  km: "Comorerne",
  kn: "Saint Kitts og Nevis",
  kp: "Korea, Den Demokratiske Folkerepublik",
  kr: "Korea, Republikken",
  kw: "Kuwait",
  ky: "Caymanøerne",
  kz: "Kasakhstan",
  la: "Den Demokratiske Folkerepublik Laos",
  lb: "Libanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Litauen",
  lu: "Luxembourg",
  lv: "Letland",
  ly: "Libyen",
  ma: "Marokko",
  mc: "Monaco",
  md: "Moldova, Republikken",
  me: "Montenegro",
  mf: "Saint Martin (den franske del)",
  mg: "Madagaskar",
  mh: "Marshalløerne",
  mk: "Makedonien, Den Tidligere Jugoslaviske Republik",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongoliet",
  mo: "Macao",
  mp: "Nordmarianerne",
  mq: "Martinique",
  mr: "Mauretanien",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldiverne",
  mw: "Malawi",
  mx: "Mexico",
  my: "Malaysia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "Ny Kaledonien",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Nederlandene",
  no: "Norge",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "New Zealand",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Fransk Polynesien",
  pg: "Papua Ny Guinea",
  ph: "Filippinerne",
  pk: "Pakistan",
  pl: "Polen",
  pm: "Saint Pierre og Miquelon",
  pr: "Puerto Rico",
  ps: "Palæstina, Staten",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Reunion",
  ro: "Rumænien",
  rs: "Serbien",
  ru: "Den Russiske Føderation",
  rw: "Rwanda",
  sa: "Saudi-Arabien",
  sb: "Salomonøerne",
  sc: "Seychellerne",
  sd: "Sudan",
  se: "Sverige",
  sg: "Singapore",
  sh: "Saint Helena",
  si: "Slovenien",
  sk: "Slovakiet",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Surinam",
  ss: "Sydsudan",
  st: "Sao Tome og Principe",
  sv: "El Salvador",
  sx: "Sint Maarten (den hollandske del)",
  sy: "Den Syriske Arabiske Republik",
  sz: "Swaziland",
  tc: "Turks- og Caicosøerne",
  td: "Tchad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tadsjikistan",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkmenistan",
  tn: "Tunesien",
  to: "Tonga",
  tr: "Tyrkiet",
  tt: "Trinidad og Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Kina",
  tz: "De Forenede Republik Tanzania",
  ua: "Ukraine",
  ug: "Uganda",
  us: "USA",
  uy: "Uruguay",
  uz: "Usbekistan",
  va: "Vatikanstaten (Hellige Stol)",
  vc: "Saint Vincent og Grenadinerne",
  ve: "Venezuela",
  vg: "De Britiske Jomfruøer",
  vi: "De Amerikanske Jomfruøer",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis og Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sydafrika",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const countryOptionsDe: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "Vereinigte Arabische Emirate",
  af: "Afghanistan",
  ag: "Antigua und Barbuda",
  ai: "Anguilla",
  al: "Albanien",
  dz: "Algerien",
  am: "Armenien",
  ao: "Angola",
  ar: "Argentinien",
  as: "Amerikanisch-Samoa",
  at: "Österreich",
  au: "Australien",
  aw: "Aruba",
  az: "Aserbaidschan",
  ba: "Bosnien und Herzegowina",
  bb: "Barbados",
  bd: "Bangladesch",
  be: "Belgien",
  bf: "Burkina Faso",
  bg: "Bulgarien",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivien",
  br: "Brasilien",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Weißrussland",
  bz: "Belize",
  ca: "Kanada",
  cd: "Kongo, Demokratische Republik",
  cf: "Zentralafrikanische Republik",
  cg: "Kongo, Republik",
  ch: "Schweiz",
  ci: "Elfenbeinküste",
  ck: "Cookinseln",
  cl: "Chile",
  cm: "Kamerun",
  cn: "China",
  co: "Kolumbien",
  cr: "Costa Rica",
  cu: "Kuba",
  cv: "Kap Verde",
  bq: "Karibische Niederlande",
  cw: "Curaçao",
  cy: "Zypern",
  cz: "Tschechische Republik",
  de: "Deutschland",
  dj: "Dschibuti",
  dk: "Dänemark",
  dm: "Dominica",
  do: "Dominikanische Republik",
  ec: "Ecuador",
  ee: "Estland",
  eg: "Ägypten",
  er: "Eritrea",
  es: "Spanien",
  et: "Äthiopien",
  fi: "Finnland",
  fj: "Fidschi",
  fk: "Falklandinseln (Malwinen)",
  fm: "Mikronesien, Föderierte Staaten von",
  fo: "Färöer-Inseln",
  fr: "Frankreich",
  ga: "Gabun",
  gb: "Vereinigtes Königreich",
  gd: "Grenada",
  ge: "Georgien",
  gf: "Französisch-Guayana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grönland",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Äquatorialguinea",
  gr: "Griechenland",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkong",
  hn: "Honduras",
  hr: "Kroatien",
  ht: "Haiti",
  hu: "Ungarn",
  id: "Indonesien",
  ie: "Irland",
  il: "Israel",
  in: "Indien",
  io: "Britisches Territorium im Indischen Ozean",
  iq: "Irak",
  ir: "Iran, Islamische Republik",
  is: "Island",
  it: "Italien",
  jm: "Jamaika",
  jo: "Jordanien",
  jp: "Japan",
  ke: "Kenia",
  kg: "Kirgisistan",
  kh: "Kambodscha",
  ki: "Kiribati",
  km: "Komoren",
  kn: "St. Kitts und Nevis",
  kp: "Korea, Demokratische Volksrepublik",
  kr: "Korea, Republik",
  kw: "Kuwait",
  ky: "Kaimaninseln",
  kz: "Kasachstan",
  la: "Laos, Demokratische Volksrepublik",
  lb: "Libanon",
  lc: "St. Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Litauen",
  lu: "Luxemburg",
  lv: "Lettland",
  ly: "Libyen",
  ma: "Marokko",
  mc: "Monaco",
  md: "Republik Moldau",
  me: "Montenegro",
  mf: "Saint-Martin (französischer Teil)",
  mg: "Madagaskar",
  mh: "Marshallinseln",
  mk: "Mazedonien, ehemalige jugoslawische Republik",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolei",
  mo: "Macao",
  mp: "Nördliche Marianen",
  mq: "Martinique",
  mr: "Mauretanien",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Malediven",
  mw: "Malawi",
  mx: "Mexiko",
  my: "Malaysia",
  mz: "Mosambik",
  na: "Namibia",
  nc: "Neukaledonien",
  ne: "Niger",
  nf: "Norfolkinsel",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Niederlande",
  no: "Norwegen",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Neuseeland",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Französisch-Polynesien",
  pg: "Papua-Neuguinea",
  ph: "Philippinen",
  pk: "Pakistan",
  pl: "Polen",
  pm: "St. Pierre und Miquelon",
  pr: "Puerto Rico",
  ps: "Palästinensisches Gebiet",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Katar",
  re: "Réunion",
  ro: "Rumänien",
  rs: "Serbien",
  ru: "Russische Föderation",
  rw: "Ruanda",
  sa: "Saudi-Arabien",
  sb: "Salomonen",
  sc: "Seychellen",
  sd: "Sudan",
  se: "Schweden",
  sg: "Singapur",
  sh: "St. Helena",
  si: "Slowenien",
  sk: "Slowakei",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "Südsudan",
  st: "São Tomé und Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten (niederländischer Teil)",
  sy: "Syrien, Arabische Republik",
  sz: "Swasiland",
  tc: "Turks- und Caicosinseln",
  td: "Tschad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tadschikistan",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkmenistan",
  tn: "Tunesien",
  to: "Tonga",
  tr: "Türkei",
  tt: "Trinidad und Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Provinz China",
  tz: "Vereinigte Republik Tansania",
  ua: "Ukraine",
  ug: "Uganda",
  us: "Vereinigte Staaten",
  uy: "Uruguay",
  uz: "Usbekistan",
  va: "Heiliger Stuhl (Vatikanstadt)",
  vc: "St. Vincent und die Grenadinen",
  ve: "Venezuela",
  vg: "Britische Jungferninseln",
  vi: "Amerikanische Jungferninseln",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis und Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Südafrika",
  zm: "Sambia",
  zw: "Simbabwe",
};

const countryOptionsEs: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "Emiratos Árabes Unidos",
  af: "Afganistán",
  ag: "Antigua y Barbuda",
  ai: "Anguila",
  al: "Albania",
  dz: "Argelia",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Samoa Americana",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  az: "Azerbaiyán",
  ba: "Bosnia y Herzegovina",
  bb: "Barbados",
  bd: "Bangladés",
  be: "Bélgica",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Baréin",
  bi: "Burundi",
  bj: "Benín",
  bl: "San Bartolomé",
  bm: "Bermudas",
  bn: "Brunéi Darussalam",
  bo: "Bolivia",
  br: "Brasil",
  bs: "Bahamas",
  bt: "Bután",
  bw: "Botsuana",
  by: "Bielorrusia",
  bz: "Belice",
  ca: "Canadá",
  cd: "República Democrática del Congo",
  cf: "República Centroafricana",
  cg: "Congo, República del",
  ch: "Suiza",
  ci: "Costa de Marfil",
  ck: "Islas Cook",
  cl: "Chile",
  cm: "Camerún",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Cabo Verde",
  bq: "Caribe neerlandés",
  cw: "Curazao",
  cy: "Chipre",
  cz: "República Checa",
  de: "Alemania",
  dj: "Yibuti",
  dk: "Dinamarca",
  dm: "Dominica",
  do: "República Dominicana",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egipto",
  er: "Eritrea",
  es: "España",
  et: "Etiopía",
  fi: "Finlandia",
  fj: "Fiyi",
  fk: "Islas Malvinas",
  fm: "Micronesia",
  fo: "Islas Feroe",
  fr: "Francia",
  ga: "Gabón",
  gb: "Reino Unido",
  gd: "Granada",
  ge: "Georgia",
  gf: "Guayana Francesa",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenlandia",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadalupe",
  gq: "Guinea Ecuatorial",
  gr: "Grecia",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bisáu",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Croacia",
  ht: "Haití",
  hu: "Hungría",
  id: "Indonesia",
  ie: "Irlanda",
  il: "Israel",
  in: "India",
  io: "Territorio Británico del Océano Índico",
  iq: "Irak",
  ir: "Irán, República Islámica de",
  is: "Islandia",
  it: "Italia",
  jm: "Jamaica",
  jo: "Jordania",
  jp: "Japón",
  ke: "Kenia",
  kg: "Kirguistán",
  kh: "Camboya",
  ki: "Kiribati",
  km: "Comoras",
  kn: "San Cristóbal y Nieves",
  kp: "Corea del Norte",
  kr: "Corea del Sur",
  kw: "Kuwait",
  ky: "Islas Caimán",
  kz: "Kazajistán",
  la: "Laos, República Democrática Popular",
  lb: "Líbano",
  lc: "Santa Lucía",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesoto",
  lt: "Lituania",
  lu: "Luxemburgo",
  lv: "Letonia",
  ly: "Libia",
  ma: "Marruecos",
  mc: "Mónaco",
  md: "Moldavia, República de",
  me: "Montenegro",
  mf: "San Martín (parte francesa)",
  mg: "Madagascar",
  mh: "Islas Marshall",
  mk: "Macedonia del Norte",
  ml: "Malí",
  mm: "Myanmar",
  mn: "Mongolia",
  mo: "Macao",
  mp: "Islas Marianas del Norte",
  mq: "Martinica",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauricio",
  mv: "Maldivas",
  mw: "Malaui",
  mx: "México",
  my: "Malasia",
  mz: "Mozambique",
  na: "Namibia",
  nc: "Nueva Caledonia",
  ne: "Níger",
  nf: "Isla Norfolk",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Países Bajos",
  no: "Noruega",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nueva Zelanda",
  om: "Omán",
  pa: "Panamá",
  pe: "Perú",
  pf: "Polinesia Francesa",
  pg: "Papúa Nueva Guinea",
  ph: "Filipinas",
  pk: "Pakistán",
  pl: "Polonia",
  pm: "San Pedro y Miquelón",
  pr: "Puerto Rico",
  ps: "Territorios Palestinos",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Reunión",
  ro: "Rumania",
  rs: "Serbia",
  ru: "Federación Rusa",
  rw: "Ruanda",
  sa: "Arabia Saudita",
  sb: "Islas Salomón",
  sc: "Seychelles",
  sd: "Sudán",
  se: "Suecia",
  sg: "Singapur",
  sh: "Santa Elena",
  si: "Eslovenia",
  sk: "Eslovaquia",
  sl: "Sierra Leona",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Surinam",
  ss: "Sudán del Sur",
  st: "Santo Tomé y Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten (parte neerlandesa)",
  sy: "República Árabe Siria",
  sz: "Esuatini",
  tc: "Islas Turcas y Caicos",
  td: "Chad",
  tg: "Togo",
  th: "Tailandia",
  tj: "Tayikistán",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkmenistán",
  tn: "Túnez",
  to: "Tonga",
  tr: "Turquía",
  tt: "Trinidad y Tobago",
  tv: "Tuvalu",
  tw: "Taiwán, Provincia de China",
  tz: "República Unida de Tanzanía",
  ua: "Ucrania",
  ug: "Uganda",
  us: "Estados Unidos",
  uy: "Uruguay",
  uz: "Uzbekistán",
  va: "Ciudad del Vaticano",
  vc: "San Vicente y las Granadinas",
  ve: "Venezuela",
  vg: "Islas Vírgenes Británicas",
  vi: "Islas Vírgenes de los Estados Unidos",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis y Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sudáfrica",
  zm: "Zambia",
  zw: "Zimbabue",
};

const countryOptionsFr: Record<Iso2Code, string> = {
  ad: "Andorre",
  ae: "Émirats arabes unis",
  af: "Afghanistan",
  ag: "Antigua-et-Barbuda",
  ai: "Anguilla",
  al: "Albanie",
  dz: "Algérie",
  am: "Arménie",
  ao: "Angola",
  ar: "Argentine",
  as: "Samoa américaines",
  at: "Autriche",
  au: "Australie",
  aw: "Aruba",
  az: "Azerbaïdjan",
  ba: "Bosnie-Herzégovine",
  bb: "Barbade",
  bd: "Bangladesh",
  be: "Belgique",
  bf: "Burkina Faso",
  bg: "Bulgarie",
  bh: "Bahreïn",
  bi: "Burundi",
  bj: "Bénin",
  bl: "Saint-Barthélemy",
  bm: "Bermudes",
  bn: "Brunéi Darussalam",
  bo: "Bolivie",
  br: "Brésil",
  bs: "Bahamas",
  bt: "Bhoutan",
  bw: "Botswana",
  by: "Bélarus",
  bz: "Belize",
  ca: "Canada",
  cd: "Congo, République démocratique du",
  cf: "République centrafricaine",
  cg: "Congo, République du",
  ch: "Suisse",
  ci: "Côte d'Ivoire",
  ck: "Îles Cook",
  cl: "Chili",
  cm: "Cameroun",
  cn: "Chine",
  co: "Colombie",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Cap-Vert",
  bq: "Pays-Bas caribéens",
  cw: "Curaçao",
  cy: "Chypre",
  cz: "République tchèque",
  de: "Allemagne",
  dj: "Djibouti",
  dk: "Danemark",
  dm: "Dominique",
  do: "République dominicaine",
  ec: "Équateur",
  ee: "Estonie",
  eg: "Égypte",
  er: "Érythrée",
  es: "Espagne",
  et: "Éthiopie",
  fi: "Finlande",
  fj: "Fidji",
  fk: "Îles Malouines (Falkland)",
  fm: "Micronésie, États fédérés de",
  fo: "Îles Féroé",
  fr: "France",
  ga: "Gabon",
  gb: "Royaume-Uni",
  gd: "Grenade",
  ge: "Géorgie",
  gf: "Guyane française",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenland",
  gm: "Gambie",
  gn: "Guinée",
  gp: "Guadeloupe",
  gq: "Guinée équatoriale",
  gr: "Grèce",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinée-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Croatie",
  ht: "Haïti",
  hu: "Hongrie",
  id: "Indonésie",
  ie: "Irlande",
  il: "Israël",
  in: "Inde",
  io: "Territoire britannique de l'océan Indien",
  iq: "Irak",
  ir: "Iran, République islamique d'",
  is: "Islande",
  it: "Italie",
  jm: "Jamaïque",
  jo: "Jordanie",
  jp: "Japon",
  ke: "Kenya",
  kg: "Kirghizistan",
  kh: "Cambodge",
  ki: "Kiribati",
  km: "Comores",
  kn: "Saint-Christophe-et-Niévès",
  kp: "Corée du Nord",
  kr: "Corée du Sud",
  kw: "Koweït",
  ky: "Îles Caïmans",
  kz: "Kazakhstan",
  la: "République démocratique populaire lao",
  lb: "Liban",
  lc: "Sainte-Lucie",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Libéria",
  ls: "Lesotho",
  lt: "Lituanie",
  lu: "Luxembourg",
  lv: "Lettonie",
  ly: "Libye",
  ma: "Maroc",
  mc: "Monaco",
  md: "Moldova, République de",
  me: "Monténégro",
  mf: "Saint-Martin (partie française)",
  mg: "Madagascar",
  mh: "Îles Marshall",
  mk: "Macédoine du Nord",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolie",
  mo: "Macao",
  mp: "Îles Mariannes du Nord",
  mq: "Martinique",
  mr: "Mauritanie",
  ms: "Montserrat",
  mt: "Malte",
  mu: "Maurice",
  mv: "Maldives",
  mw: "Malawi",
  mx: "Mexique",
  my: "Malaisie",
  mz: "Mozambique",
  na: "Namibie",
  nc: "Nouvelle-Calédonie",
  ne: "Niger",
  nf: "Île Norfolk",
  ng: "Nigéria",
  ni: "Nicaragua",
  nl: "Pays-Bas",
  no: "Norvège",
  np: "Népal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nouvelle-Zélande",
  om: "Oman",
  pa: "Panama",
  pe: "Pérou",
  pf: "Polynésie française",
  pg: "Papouasie-Nouvelle-Guinée",
  ph: "Philippines",
  pk: "Pakistan",
  pl: "Pologne",
  pm: "Saint-Pierre-et-Miquelon",
  pr: "Porto Rico",
  ps: "Palestine, État de",
  pt: "Portugal",
  pw: "Palaos",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Roumanie",
  rs: "Serbie",
  ru: "Fédération de Russie",
  rw: "Rwanda",
  sa: "Arabie saoudite",
  sb: "Îles Salomon",
  sc: "Seychelles",
  sd: "Soudan",
  se: "Suède",
  sg: "Singapour",
  sh: "Sainte-Hélène",
  si: "Slovénie",
  sk: "Slovaquie",
  sl: "Sierra Leone",
  sm: "Saint-Marin",
  sn: "Sénégal",
  so: "Somalie",
  sr: "Suriname",
  ss: "Soudan du Sud",
  st: "Sao Tomé-et-Principe",
  sv: "El Salvador",
  sx: "Saint-Martin (partie néerlandaise)",
  sy: "République arabe syrienne",
  sz: "Eswatini",
  tc: "Îles Turques-et-Caïques",
  td: "Tchad",
  tg: "Togo",
  th: "Thaïlande",
  tj: "Tadjikistan",
  tk: "Tokelau",
  tl: "Timor-Leste",
  tm: "Turkménistan",
  tn: "Tunisie",
  to: "Tonga",
  tr: "Turquie",
  tt: "Trinité-et-Tobago",
  tv: "Tuvalu",
  tw: "Taïwan, Province de Chine",
  tz: "République unie de Tanzanie",
  ua: "Ukraine",
  ug: "Ouganda",
  us: "États-Unis",
  uy: "Uruguay",
  uz: "Ouzbékistan",
  va: "Saint-Siège (Cité du Vatican)",
  vc: "Saint-Vincent-et-les-Grenadines",
  ve: "Venezuela",
  vg: "Îles Vierges britanniques",
  vi: "Îles Vierges des États-Unis",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis-et-Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yémen",
  yt: "Mayotte",
  za: "Afrique du Sud",
  zm: "Zambie",
  zw: "Zimbabwe",
};

const countryOptionsIt: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "Emirati Arabi Uniti",
  af: "Afghanistan",
  ag: "Antigua e Barbuda",
  ai: "Anguilla",
  al: "Albania",
  dz: "Algeria",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentina",
  as: "Samoa americane",
  at: "Austria",
  au: "Australia",
  aw: "Aruba",
  az: "Azerbaigian",
  ba: "Bosnia ed Erzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgio",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrein",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivia",
  br: "Brasile",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Bielorussia",
  bz: "Belize",
  ca: "Canada",
  cd: "Congo, Repubblica Democratica del",
  cf: "Repubblica Centrafricana",
  cg: "Congo, Repubblica del",
  ch: "Svizzera",
  ci: "Costa d'Avorio",
  ck: "Isole Cook",
  cl: "Cile",
  cm: "Camerun",
  cn: "Cina",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Capo Verde",
  bq: "Caraibi Olandesi",
  cw: "Curaçao",
  cy: "Cipro",
  cz: "Repubblica Ceca",
  de: "Germania",
  dj: "Gibuti",
  dk: "Danimarca",
  dm: "Dominica",
  do: "Repubblica Dominicana",
  ec: "Ecuador",
  ee: "Estonia",
  eg: "Egitto",
  er: "Eritrea",
  es: "Spagna",
  et: "Etiopia",
  fi: "Finlandia",
  fj: "Figi",
  fk: "Isole Falkland (Malvine)",
  fm: "Micronesia, Stati Federati di",
  fo: "Isole Faroe",
  fr: "Francia",
  ga: "Gabon",
  gb: "Regno Unito",
  gd: "Grenada",
  ge: "Georgia",
  gf: "Guyana Francese",
  gh: "Ghana",
  gi: "Gibilterra",
  gl: "Groenlandia",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadalupa",
  gq: "Guinea Equatoriale",
  gr: "Grecia",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Croazia",
  ht: "Haiti",
  hu: "Ungheria",
  id: "Indonesia",
  ie: "Irlanda",
  il: "Israele",
  in: "India",
  io: "Territorio Britannico dell'Oceano Indiano",
  iq: "Iraq",
  ir: "Iran, Repubblica Islamica dell'",
  is: "Islanda",
  it: "Italia",
  jm: "Giamaica",
  jo: "Giordania",
  jp: "Giappone",
  ke: "Kenya",
  kg: "Kirghizistan",
  kh: "Cambogia",
  ki: "Kiribati",
  km: "Comore",
  kn: "Saint Kitts e Nevis",
  kp: "Corea del Nord",
  kr: "Corea del Sud",
  kw: "Kuwait",
  ky: "Isole Cayman",
  kz: "Kazakistan",
  la: "Laos, Repubblica Democratica Popolare del",
  lb: "Libano",
  lc: "Santa Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Lituania",
  lu: "Lussemburgo",
  lv: "Lettonia",
  ly: "Libia",
  ma: "Marocco",
  mc: "Monaco",
  md: "Moldova, Repubblica di",
  me: "Montenegro",
  mf: "Saint Martin (parte francese)",
  mg: "Madagascar",
  mh: "Isole Marshall",
  mk: "Macedonia del Nord",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolia",
  mo: "Macao",
  mp: "Isole Marianne Settentrionali",
  mq: "Martinica",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldive",
  mw: "Malawi",
  mx: "Messico",
  my: "Malaysia",
  mz: "Mozambico",
  na: "Namibia",
  nc: "Nuova Caledonia",
  ne: "Niger",
  nf: "Isola Norfolk",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Paesi Bassi",
  no: "Norvegia",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nuova Zelanda",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Polinesia Francese",
  pg: "Papua Nuova Guinea",
  ph: "Filippine",
  pk: "Pakistan",
  pl: "Polonia",
  pm: "Saint Pierre e Miquelon",
  pr: "Porto Rico",
  ps: "Palestina, Stato di",
  pt: "Portogallo",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Romania",
  rs: "Serbia",
  ru: "Federazione Russa",
  rw: "Ruanda",
  sa: "Arabia Saudita",
  sb: "Isole Solomon",
  sc: "Seychelles",
  sd: "Sudan",
  se: "Svezia",
  sg: "Singapore",
  sh: "Sant'Elena",
  si: "Slovenia",
  sk: "Slovacchia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "Sud Sudan",
  st: "São Tomé e Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten (parte olandese)",
  sy: "Siria, Repubblica Araba Siriana",
  sz: "Eswatini",
  tc: "Isole Turks e Caicos",
  td: "Ciad",
  tg: "Togo",
  th: "Thailandia",
  tj: "Tagikistan",
  tk: "Tokelau",
  tl: "Timor Est",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turchia",
  tt: "Trinidad e Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Provincia della Cina",
  tz: "Repubblica Unita di Tanzania",
  ua: "Ucraina",
  ug: "Uganda",
  us: "Stati Uniti",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Città del Vaticano (Stato della Città del Vaticano)",
  vc: "Saint Vincent e Grenadine",
  ve: "Venezuela",
  vg: "Isole Vergini Britanniche",
  vi: "Isole Vergini Americane",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis e Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Yemen",
  yt: "Mayotte",
  za: "Sudafrica",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const countryOptionsNl: Record<Iso2Code, string> = {
  ad: "Andorra",
  ae: "Verenigde Arabische Emiraten",
  af: "Afghanistan",
  ag: "Antigua en Barbuda",
  ai: "Anguilla",
  al: "Albanië",
  dz: "Algerije",
  am: "Armenië",
  ao: "Angola",
  ar: "Argentinië",
  as: "Amerikaans Samoa",
  at: "Oostenrijk",
  au: "Australië",
  aw: "Aruba",
  az: "Azerbeidzjan",
  ba: "Bosnië en Herzegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "België",
  bf: "Burkina Faso",
  bg: "Bulgarije",
  bh: "Bahrein",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei Darussalam",
  bo: "Bolivia",
  br: "Brazilië",
  bs: "Bahamas",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Wit-Rusland",
  bz: "Belize",
  ca: "Canada",
  cd: "Congo, Democratische Republiek",
  cf: "Centraal-Afrikaanse Republiek",
  cg: "Congo, Republiek",
  ch: "Zwitserland",
  ci: "Ivoorkust",
  ck: "Cookeilanden",
  cl: "Chili",
  cm: "Kameroen",
  cn: "China",
  co: "Colombia",
  cr: "Costa Rica",
  cu: "Cuba",
  cv: "Kaapverdië",
  bq: "Caribisch Nederland",
  cw: "Curaçao",
  cy: "Cyprus",
  cz: "Tsjechië",
  de: "Duitsland",
  dj: "Djibouti",
  dk: "Denemarken",
  dm: "Dominica",
  do: "Dominicaanse Republiek",
  ec: "Ecuador",
  ee: "Estland",
  eg: "Egypte",
  er: "Eritrea",
  es: "Spanje",
  et: "Ethiopië",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falklandeilanden (Malvinas)",
  fm: "Micronesië, Federale Staten van",
  fo: "Faeröer",
  fr: "Frankrijk",
  ga: "Gabon",
  gb: "Verenigd Koninkrijk",
  gd: "Grenada",
  ge: "Georgië",
  gf: "Frans-Guyana",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Groenland",
  gm: "Gambia",
  gn: "Guinee",
  gp: "Guadeloupe",
  gq: "Equatoriaal-Guinea",
  gr: "Griekenland",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinee-Bissau",
  gy: "Guyana",
  hk: "Hong Kong",
  hn: "Honduras",
  hr: "Kroatië",
  ht: "Haïti",
  hu: "Hongarije",
  id: "Indonesië",
  ie: "Ierland",
  il: "Israël",
  in: "India",
  io: "Brits Indische Oceaanterritorium",
  iq: "Irak",
  ir: "Iran, Islamitische Republiek",
  is: "IJsland",
  it: "Italië",
  jm: "Jamaica",
  jo: "Jordanië",
  jp: "Japan",
  ke: "Kenia",
  kg: "Kirgizië",
  kh: "Cambodja",
  ki: "Kiribati",
  km: "Comoren",
  kn: "Saint Kitts en Nevis",
  kp: "Noord-Korea",
  kr: "Zuid-Korea",
  kw: "Koeweit",
  ky: "Kaaimaneilanden",
  kz: "Kazachstan",
  la: "Laos, Democratische Volksrepubliek",
  lb: "Libanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Litouwen",
  lu: "Luxemburg",
  lv: "Letland",
  ly: "Libië",
  ma: "Marokko",
  mc: "Monaco",
  md: "Moldavië, Republiek",
  me: "Montenegro",
  mf: "Saint-Martin (Frans deel)",
  mg: "Madagaskar",
  mh: "Marshalleilanden",
  mk: "Noord-Macedonië",
  ml: "Mali",
  mm: "Myanmar",
  mn: "Mongolië",
  mo: "Macao",
  mp: "Noordelijke Marianeneilanden",
  mq: "Martinique",
  mr: "Mauritanië",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Maldiven",
  mw: "Malawi",
  mx: "Mexico",
  my: "Maleisië",
  mz: "Mozambique",
  na: "Namibië",
  nc: "Nieuw-Caledonië",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Nederland",
  no: "Noorwegen",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nieuw-Zeeland",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Frans-Polynesië",
  pg: "Papoea-Nieuw-Guinea",
  ph: "Filipijnen",
  pk: "Pakistan",
  pl: "Polen",
  pm: "Saint-Pierre en Miquelon",
  pr: "Puerto Rico",
  ps: "Palestina, Staat",
  pt: "Portugal",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Roemenië",
  rs: "Servië",
  ru: "Russische Federatie",
  rw: "Rwanda",
  sa: "Saudi-Arabië",
  sb: "Salomonseilanden",
  sc: "Seychellen",
  sd: "Soedan",
  se: "Zweden",
  sg: "Singapore",
  sh: "Sint-Helena",
  si: "Slovenië",
  sk: "Slowakije",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalië",
  sr: "Suriname",
  ss: "Zuid-Soedan",
  st: "Sao Tomé en Principe",
  sv: "El Salvador",
  sx: "Sint Maarten (Nederlands deel)",
  sy: "Syrische Arabische Republiek",
  sz: "Eswatini",
  tc: "Turks- en Caicoseilanden",
  td: "Tsjaad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tadzjikistan",
  tk: "Tokelau",
  tl: "Oost-Timor",
  tm: "Turkmenistan",
  tn: "Tunesië",
  to: "Tonga",
  tr: "Turkije",
  tt: "Trinidad en Tobago",
  tv: "Tuvalu",
  tw: "Taiwan, Provincie China",
  tz: "Verenigde Republiek Tanzania",
  ua: "Oekraïne",
  ug: "Oeganda",
  us: "Verenigde Staten",
  uy: "Uruguay",
  uz: "Oezbekistan",
  va: "Heilige Stoel (Vaticaanstad)",
  vc: "Saint Vincent en de Grenadines",
  ve: "Venezuela",
  vg: "Britse Maagdeneilanden",
  vi: "Amerikaanse Maagdeneilanden",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis en Futuna",
  ws: "Samoa",
  xk: "Kosovo",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Zuid-Afrika",
  zm: "Zambia",
  zw: "Zimbabwe",
};

const countryOptions = {
  de: countryOptionsDe,
  es: countryOptionsEs,
  fr: countryOptionsFr,
  it: countryOptionsIt,
  nl: countryOptionsNl,
  no: countryOptionsNb,
  en: countryOptionsEn,
};

export const getCountries = (locale: string): Record<Iso2Code, string> => {
  const [firstPartOfLocale] = locale.split("-");
  if (
    norwegianLocales.includes(locale) ||
    norwegianLocales.some((x) => x.startsWith(firstPartOfLocale))
  ) {
    return countryOptionsNb;
  } else {
    return (
      countryOptions[firstPartOfLocale as keyof typeof countryOptions] ??
      countryOptionsEn
    );
  }
};

export const getDialcodes = () => dialCodes;
export const DialcodeValues = Object.values(dialCodes);

export function getUserCountry(locale: string, data?: string): Iso2Code {
  if (!data) return norway;

  const countries = getCountries(locale);
  const isIsoCode = Object.keys(countries).includes(data);
  if (isIsoCode) return data as Iso2Code;

  const isValidCountry = data in invert(countries);
  if (!isValidCountry) return norway;

  return invert(countries)[data] as Iso2Code;
}

export function getCountryFromSettings(locale: string): Iso2Code {
  const [_lang, countryCode] = locale.toLowerCase().split("-");
  return countryOptionsEn[countryCode as Iso2Code]
    ? (countryCode as Iso2Code)
    : norway;
}
