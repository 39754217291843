import { Translations } from '@repo/types';
import { MarkType } from 'src/components/common/duration-slider/DurationSlider';

export function getDurationMarks(t: Translations): MarkType[] {
    return [
        {
            value: -1,
            minuteValue: -1,
            compareValue: 0,
            labelValue: '15',
            label: t.minutes.num(15),
        },
        {
            value: 1,
            minuteValue: 30,
            compareValue: 30,
            labelValue: '30',
            label: t.minutes.num(30),
        },
        {
            value: 2,
            minuteValue: 45,
            compareValue: 45,
            labelValue: '45',
            label: t.minutes.num(45),
        },
        ...new Array(12).fill(1).map((_, i) => ({
            value: 2 + (i + 1),
            minuteValue: (i + 1) * 60,
            compareValue: (i + 1) * 60,
            labelValue: `${i + 1}`,
            label: t.hours.num(i + 1),
        })),
        ...new Array(5).fill(1).map((_, i) => ({
            value: 2 + 12 + (i + 1),
            minuteValue: (i + 1) * 60 * 24,
            compareValue: (i + 1) * 60 * 24,
            labelValue: `${i + 1}`,
            label: t.days.num(i + 1),
        })),
        {
            value: Number.MAX_VALUE,
            compareValue: 6 * 60 * 24,
            minuteValue: Number.MAX_VALUE,
            labelValue: '6+',
            label: t.days.plural,
        },
    ];
}
