import { Grid, Typography, useTheme } from '@mui/material';
import { useLocale, getLocaleNumberFormatTwoDecimals } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';

export default function SummaryGiftcardVat(props: { giftcardPrice: number }): JSX.Element | null {
    const { t, locale } = useLocale();
    const theme = useTheme();
    const [configuration] = useAtom(configurationAtom);

    if (configuration.hideVat) return null;

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            borderBottom={`1px solid ${theme.palette.grey[400]}`}
            borderTop={`1px solid ${theme.palette.grey[400]}`}
            p={theme.spacing(1, 0)}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex={3}
                flexShrink={1}
            >
                <Typography align="right" color="textSecondary">
                    {capitalize(t.excl)}&nbsp;{t.vat}
                </Typography>

                <Typography align="right" color="textSecondary">
                    {t.vat}&nbsp;{`0%`}
                </Typography>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex={1}
                flexShrink={1}
            >
                <Typography align="right" color="textSecondary">
                    {getLocaleNumberFormatTwoDecimals(locale, props.giftcardPrice)}
                </Typography>

                <Typography align="right" color="textSecondary">
                    {getLocaleNumberFormatTwoDecimals(locale, 0)}
                </Typography>
            </Grid>
        </Grid>
    );
}
