import { FormControl, InputLabel, Paper, Popover, TextField, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import MultipleNumberInput, {
    MultipleNumberInputValueType,
} from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { zIndex } from 'src/utils/widget/zIndex';

export function MultipleNumberPicker(props: {
    quantities: MultipleNumberInputValueType[];
    defaultQuantities: MultipleNumberInputValueType[];
    onChangeQuantities: (id: number, value: number) => void;
    labelColor?: string;
    variant?: 'standard' | 'outlined' | 'filled';
    color?: 'primary' | 'secondary';
}) {
    const {
        quantities,
        onChangeQuantities,
        defaultQuantities,
        labelColor,
        variant = 'outlined',
        color = 'primary',
    } = props;
    const theme = useTheme();
    const config = useConfigurations();
    const mountPoint = useContext(MountPointContext);
    const { t } = useLocale();
    const [multipleNumberInputRef, setMultipleNumberInputRef] = useState<HTMLInputElement | null>(
        null,
    );
    const noTravelers = quantities.every((q) => q.value === 0);

    return (
        <>
            <FormControl fullWidth={true}>
                <InputLabel
                    id="multiple-number-picker-label"
                    sx={{
                        ...TextFieldStyleUtils.positionInputLabelAboveField,
                        color: labelColor ? `${labelColor} !important` : undefined,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {t.how_many_are_going}
                </InputLabel>
                <TextField
                    sx={{ position: 'relative' }}
                    variant={variant}
                    color={color}
                    margin="dense"
                    size="small"
                    onClick={(e: any) => setMultipleNumberInputRef(e.currentTarget)}
                    inputProps={{
                        'aria-labelledby': 'multiple-number-picker-label',
                    }}
                    value={
                        noTravelers
                            ? t.select_travelers
                            : quantities
                                  .map(
                                      (q) =>
                                          `${q.value} ${
                                              typeof q.name === 'function'
                                                  ? q.name(q.value)
                                                  : q.name
                                          }`,
                                  )
                                  .join(', ')
                    }
                />
            </FormControl>
            <Popover
                open={Boolean(multipleNumberInputRef)}
                anchorEl={multipleNumberInputRef}
                sx={{
                    zIndex: `${zIndex.alwaysVisible} !important` as any,
                    mt: theme.spacing(1),

                    '& > .MuiPaper-root': {
                        width: Math.max(
                            multipleNumberInputRef?.getBoundingClientRect().width ?? 0,
                            270,
                        ),
                    },
                }}
                container={mountPoint.popover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setMultipleNumberInputRef(null)}
                aria-modal={true}
                disableEnforceFocus={true}
            >
                <Paper sx={{ padding: 1 }}>
                    <MultipleNumberInput
                        values={quantities}
                        defaultValues={defaultQuantities}
                        onChange={onChangeQuantities}
                        labelText=""
                        maxEntrants={config.personsMax}
                        color={theme.palette.common.black}
                        backgroundColor={theme.palette.common.white}
                    />
                </Paper>
            </Popover>
        </>
    );
}
