import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { getCsvColorsAttributes } from '@repo/widget-utils/attributes/color-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { ListOfSmartEvents } from './ListOfSmartEvents';
import { getCsvAttribute } from '@repo/widget-utils/attributes/attribute-helper';

class BilberryListOfSmartEvents extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const smartEventIds = this.getAttribute('smart-event-ids')?.split(',') ?? [];
        const urls = getCsvAttribute(this, 'urls') ?? [];

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);

        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ListOfSmartEvents
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    ids={smartEventIds}
                    urls={urls}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-list-of-smart-events', BilberryListOfSmartEvents);
}
