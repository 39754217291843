import { Fragment } from 'react';
import Itinerary from 'src/components/domain/package/itinerary/Itinerary';
import { usePackageProduct } from '@repo/widget-utils/api/api';

// type ProductType = 'hotel' | 'activity' | 'transfer';

// type ItineraryItem = {
//     id: string;
//     index: number;
//     dayIndex: number;
//     title: string;
//     productType: ProductType;
//     subtitle: string;
//     category: string;
// };

export default function PackageContainer({ id }: { id: string }) {
    const { data: pkg } = usePackageProduct(id);

    if (!pkg) {
        return <Fragment></Fragment>;
    }

    return <Itinerary pkg={pkg} />;
}
