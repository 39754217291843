import { tzdate } from '@repo/tzdate';
import {
    CartItem,
    PackageTicketOptionWithQuantity,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
    GuestInfo,
    BilberryWidgetsGlobal,
} from '@repo/types';
import {
    getAvailableProducts,
    getAvailableTimeslots,
    getPackageProduct,
    getProduct,
    getProductInstanceByIds,
} from '../api/product.api';
import { getAccommodationProducts } from '../api/api';
import { debugLog } from '@repo/common-utils/Logger';
import {
    productInstancesDisablePaymentPlans,
    productInstancesRequiresPaymentPlans,
} from '../cart/cartUtils';

export function serializeCartToQueryParams(cart: CartItem[]) {
    const cartParam = cart.map(asQueryParam).join('~~');
    const asBase64 = encodeURIComponent(btoa(cartParam));
    const cartItemParams = 'cart=' + asBase64;
    return cartItemParams;
}

function asQueryParam(cartItem: CartItem) {
    const pkg = cartItem.pkg
        ? `${cartItem.pkg.pkg.id},${encodeURIComponent(
              cartItem.pkg.date.toISOString(),
          )},${cartItem.pkg.pkg.products
              .map(
                  (pp) =>
                      `${pp.pkgProductId};${cartItem.products
                          .filter((p) => p.product?.pkgProductId === pp.pkgProductId)
                          .map((p) => p.id)
                          .join('£')}`,
              )
              .join('_')}`
        : null;

    let productInstances = null as null | string;
    if (cartItem.products.some((p) => p.product?.type !== 'accommodation')) {
        productInstances = `${cartItem.products
            .flatMap((product) =>
                product.collectedIds.length > 0 ? product.collectedIds : [product?.id],
            )
            .join(',')}£${cartItem.products
            .map((product) => (product.isExtraProduct ? 1 : 0))
            .join(',')}£${cartItem.products[0].product?.id}£${cartItem.products[0].timeslots
            .map((t) => t.id)
            .join(',')}`;
    }

    let accommodationProduct = null as null | string;
    if (cartItem.products.some((p) => p.product?.type === 'accommodation')) {
        const sorted = cartItem.products.toSorted((a, b) => a.start.unix() - b.start.unix());
        const accommodationProductId = sorted[0].product?.id;
        const start = sorted[0].start.format('YYYY-MM-DD');
        const end = sorted[sorted.length - 1].start.format('YYYY-MM-DD');
        const ticketType = cartItem.ticketType?.id;
        accommodationProduct = `${accommodationProductId}£${start}£${end}£${ticketType}`;
    }

    const ticketOptions = (cartItem.ticketOptions as PackageTicketOptionWithQuantity[])
        .map((to) => `${to.id}£${to.quantity}£${to.products?.join('_') ?? ''}`)
        .join(',');

    return `${pkg ?? ''}@${[productInstances, accommodationProduct, ticketOptions].join(';')}`;
}

export async function loadCartFromQueryParameters(config: BilberryWidgetsGlobal) {
    const url = new URL(window.location.href);
    const cartItems = await deserializeCartFromQueryParameters(url, config);
    return cartItems;
}

export async function deserializeCartFromQueryParameters(url: URL, config: BilberryWidgetsGlobal) {
    const cartParam = url.searchParams.get('cart');
    const decoded = cartParam ? decodeURIComponent(atob(cartParam)) : '';
    const stringItems = decoded?.split('~~') ?? [];
    const items = await Promise.all(
        stringItems.map(async (item) => {
            const [pkgQuery, productsQuery] = item.split('@');
            if (pkgQuery) return await deserializePackageProduct(pkgQuery, productsQuery);
            return await deserializeProduct(config, productsQuery);
        }),
    );
    return items.filter(Boolean) as CartItem[];
}

async function deserializeProduct(
    config: BilberryWidgetsGlobal,
    productsQuery?: string,
): Promise<CartItem | null> {
    if (!productsQuery) return null;

    const [productInstances, accommodationProduct, ticketOptionsRaw] =
        productsQuery.split(';') ?? [];

    let products = [] as ProductInstance[];
    let ticketType = undefined as undefined | TicketType;
    let idPrefix = undefined;

    if (productInstances !== '') {
        products = await deserializeBilberryProduct(productInstances);
    } else if (accommodationProduct) {
        const deserializedAccommodationProduct = await deserializeAccommodationProduct(
            accommodationProduct,
            ticketOptionsRaw,
        );
        products = deserializedAccommodationProduct?.products ?? [];
        ticketType = deserializedAccommodationProduct?.ticketType;
        idPrefix = deserializedAccommodationProduct?.idPrefix;
    }

    const ticketOptions = deserializeTicketOptions(products, ticketOptionsRaw);

    const disablePaymentPlans = productInstancesDisablePaymentPlans(
        products,
        false,
        config.disableMembershipBooking,
    );
    const requiresPaymentPlans = productInstancesRequiresPaymentPlans(products);

    return {
        products,
        ticketOptions,
        ticketType,
        idPrefix,
        disablePaymentPlans,
        requiresPaymentPlans,
    };
}

function deserializeTicketOptions(products: ProductInstance[], ticketOptionsRaw: string) {
    const allTicketOptions = products.flatMap((pi) => pi.ticketOptions);

    const ticketOptions = ticketOptionsRaw
        .split(',')
        .map((to) => {
            const [toId, quantity] = to.split('£');
            const ticketOption = allTicketOptions.find((t) => t.id === toId);
            if (!ticketOption) return null;
            return {
                ...ticketOption,
                productInstances: products,
                quantity: parseInt(quantity),
            };
        })
        .filter(Boolean) as TicketOptionWithQuantity[];
    return ticketOptions;
}

async function deserializeBilberryProduct(productInstances: string) {
    const [productIds, isExtraProducts, productId, timeslotIds] = productInstances.split('£');
    let products: ProductInstance[] = [];
    if (timeslotIds.length > 0) {
        products = await deserializeTimeslotProduct(productIds, productId, timeslotIds);
    } else {
        products = await getProductInstanceByIds(productIds.split(','));
    }
    const isExtraProductsSplit = isExtraProducts.split(',');
    products = products.map((p, i) => ({
        ...p,
        isExtraProduct: isExtraProductsSplit[i] === '1',
    }));
    return products;
}

async function deserializeTimeslotProduct(
    productIds: string,
    productId: string,
    timeslotIds: string,
) {
    const [product, timeslots] = await Promise.all([
        getProduct(productId, 'timeslot'),
        getAvailableTimeslots({
            id: productIds.split(',')[0],
        } as ProductInstance),
    ]);
    const productInstances = await getAvailableProducts(
        product,
        timeslots[0].start.startOf('day'),
        timeslots[0].end.endOf('day'),
    );
    const productInstance = productInstances.find((pi) => pi.id === productIds.split(',')[0]);
    if (productInstance) {
        (productInstance as any).timeslots = timeslots
            .filter((t) => timeslotIds.split(',').includes(t.id))
            .map((t) => ({ ...t, productInstance }));

        return [productInstance];
    }
    return [];
}

async function deserializeAccommodationProduct(
    accommodationProduct: string,
    ticketOptionsRaw: string,
) {
    const [productId, start, end, ticketTypeId] = accommodationProduct.split('£');
    const ticketOptionValues = ticketOptionsRaw
        .split(',')
        .map((to) => to.split('£'))
        .map(([id, quantity]) => [id, parseInt(quantity)] as [string, number]);
    const guestInfo: GuestInfo[] = [
        {
            id: 0,
            adults: ticketOptionValues.find(([id]) => id === '0')?.[1] ?? 0,
            children: new Array(ticketOptionValues.find(([id]) => id === '1')?.[1] ?? 0).fill({
                childId: 0,
                age: '8',
                bed: null,
            }),
        },
    ];
    const [accommodationResults] = await getAccommodationProducts(
        [],
        [tzdate(start), tzdate(end)],
        guestInfo,
        true,
    );

    const roomIdx = accommodationResults.findIndex((p) => p[0].product?.id === productId);
    if (roomIdx === -1) {
        debugLog(
            'Not able to deserialize accommodation product. ',
            productId,
            accommodationResults,
        );
        return null;
    }
    const ticketType = accommodationResults[roomIdx]
        .flatMap((p) => p.ticketOptions.flatMap((t) => t.ticketTypes))
        .find((tt) => tt.id === ticketTypeId);
    const products = accommodationResults[roomIdx];

    if (!ticketType || !products || products.length === 0) {
        debugLog('Not able to deserialize accommodation product. ', ticketType, products);
        return null;
    }
    return { products, ticketType, idPrefix: `room-${roomIdx}` };
}

async function deserializePackageProduct(pkgQuery: string, productQuery: string) {
    const [pkgId, date, pkgProducts] = pkgQuery.split(',');
    const pkgData = await getPackageProduct(pkgId);
    const pkgProductsSplit = pkgProducts.split('_');
    const [productInstancesRaw, ticketOptions] = productQuery.split(';') ?? [];
    const productInstanceIds = productInstancesRaw.split(',');
    const productInstancesInitial = await getProductInstanceByIds(productInstanceIds);
    const productInstances = pkgProductsSplit
        .flatMap((p) => {
            const [pkgProductId, productInstanceIds] = p.split(';');
            const product = pkgData.products.find((pp) => pp.pkgProductId === pkgProductId);
            if (!product) return null;
            const productInstanceIdsSplit = productInstanceIds.split('£');
            const productInstances = productInstancesInitial.filter((pi) =>
                productInstanceIdsSplit.includes(pi.id),
            );
            return productInstances.map((pi) => ({
                ...pi,
                product,
            }));
        })
        .filter(Boolean) as ProductInstance[];

    const ticketOptionsFixed = ticketOptions
        .split(',')
        .map((to) => {
            const [toId, quantity, productCatalogIds] = to.split('£');
            const fullTicketOption = pkgData.ticketOptions.find((t) => t.id === toId);
            if (!fullTicketOption) return null;
            return {
                ...fullTicketOption,
                quantity: parseInt(quantity),
                products: productCatalogIds.split('_'),
                productInstances: productInstances.filter(
                    (p) => p.product?.pkgTicketOptionId === toId,
                ),
            };
        })
        .filter(Boolean) as PackageTicketOptionWithQuantity[];

    return {
        pkg: { pkg: pkgData, date: tzdate(date) },
        ticketOptions: ticketOptionsFixed,
        products: productInstances,
    };
}
