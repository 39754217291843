import { alpha, Box, Button, darken, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { useLocale } from '@repo/i18n';
import { ProductInstance } from '@repo/types';
import { bookingWidgetInputStyle } from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getAvailableTimeSlots } from '@repo/widget-utils/DateHelpers';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';

interface IProps {
    availabilityData: ProductInstance[] | undefined;
    selectedTimeSlot: TimeSlotType | undefined;
    onSelectTimeSlot(timeSlot: TimeSlotType): void;
    isTimeSlotsEqual?: (timeSlotA: TimeSlotType, timeSlotB: TimeSlotType) => boolean;
    hideHeader?: boolean;
    fullWidth?: boolean;
    color?: string;
    backgroundColor?: string;
    primaryColor?: string;
    primaryColorContrast?: string;
}

export default function TimeSlots(props: IProps): JSX.Element {
    const {
        isTimeSlotsEqual = (a: TimeSlotType, b: TimeSlotType) => a.product.id === b.product.id,
    } = props;
    const { t } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const {
        selectedTimeSlot,
        onSelectTimeSlot,
        availabilityData,
        color = theme.palette.common.black,
        backgroundColor = theme.palette.common.white,
        primaryColor = customizations.bookingWidgetColor,
        primaryColorContrast = customizations.bookingWidgetColorContrast,
    } = props;

    const availableTimeSlots = getAvailableTimeSlots(availabilityData);

    const colors = bookingWidgetInputStyle(customizations, backgroundColor, color);

    const timeSlots = availableTimeSlots.map((timeSlot) => {
        const isSelected = selectedTimeSlot && isTimeSlotsEqual(selectedTimeSlot, timeSlot);
        const isAtCapacity = timeSlot.product.capacity === 0;
        const isPassedCutoff = timeSlot.product.start
            .subtract(timeSlot.product.cutoffTime ?? 0, 'minutes')
            .isBefore(Date.now());

        return (
            <Grid
                key={`time-slot-${timeSlot.product.id}-${
                    timeSlot.product.product?.pkgProductId ?? ''
                }-${timeSlot.product.product?.pkgTicketOptionId ?? ''}`}
                component={Button}
                height="fit-content"
                border={`1px solid ${
                    isAtCapacity ? alpha(color, 0.5) : isSelected ? primaryColor : colors.color
                }`}
                borderRadius={`${theme.shape.borderRadius}px`}
                disabled={isAtCapacity || isPassedCutoff}
                sx={{
                    px: theme.spacing(1.5),
                    py: timeSlot.sublabel ? theme.spacing(0.5) : theme.spacing(1.5),
                    backgroundColor: backgroundColor,
                    color: color,
                    ...(isSelected && {
                        backgroundColor: primaryColor,
                        color: primaryColorContrast,
                        '&:hover': {
                            backgroundColor: () =>
                                customizations.primaryButtonStyle === 'contained'
                                    ? darken(primaryColor, 0.2)
                                    : 'rgba(0, 0, 0, 30%)',
                        },
                    }),
                }}
                onClick={() => !isAtCapacity && onSelectTimeSlot(timeSlot)}
            >
                <Box>
                    <Typography
                        sx={{
                            color: `${isSelected ? primaryColorContrast : colors.color} !important`,
                            ...(isAtCapacity && {
                                textDecoration: 'line-through',
                            }),
                        }}
                    >
                        {timeSlot.label}
                    </Typography>
                    {timeSlot.sublabel && (
                        <Typography
                            sx={{
                                mt: -0.5,
                                fontSize: '0.75rem',
                                color: `${
                                    isSelected ? primaryColorContrast : colors.color
                                } !important`,
                                ...(isAtCapacity && {
                                    textDecoration: isAtCapacity ? 'none' : 'line-through',
                                }),
                            }}
                        >
                            {isAtCapacity ? t.full : timeSlot.sublabel}
                        </Typography>
                    )}
                </Box>
            </Grid>
        );
    });

    return (
        <Box
            sx={{
                padding: props.fullWidth ? undefined : theme.spacing(0, 1.5),
                mb: theme.spacing(1),
            }}
        >
            {!props.hideHeader && (
                <Grid container direction="row" alignItems="center">
                    <Typography color={{ color: colors.color }}>
                        {capitalize(t.select_time)}
                    </Typography>
                    <Box
                        flex="1"
                        borderTop={`1px solid ${colors.color}`}
                        ml={theme.spacing(1)}
                        sx={{
                            opacity: 0.2,
                        }}
                    />
                </Grid>
            )}
            <Stack
                direction="row"
                padding={props.hideHeader ? 0 : theme.spacing(1, 0)}
                flexWrap="wrap"
                gap={theme.spacing(1)}
            >
                {availabilityData && timeSlots}
                {!availabilityData && (
                    <Fragment>
                        <Grid
                            item
                            xs={4}
                            sm={3}
                            container
                            justifyContent="center"
                            visibility="hidden"
                        >
                            <Grid
                                component={Button}
                                display="flex"
                                justifyContent="center"
                                alignContent="center"
                                height="fit-content"
                                border={`1px solid ${colors.color}`}
                                borderRadius={`${theme.shape.borderRadius}px`}
                                padding={theme.spacing(1.5)}
                            >
                                <Typography>placeholder</Typography>
                            </Grid>
                        </Grid>
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            width="100%"
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography textAlign="center" fontSize="0.9rem">
                                {t.select_date_to_view_timeslots}
                            </Typography>
                        </Box>
                    </Fragment>
                )}
            </Stack>
        </Box>
    );
}
