import { Box } from '@mui/material';
import { UserTextCustomizations } from '@repo/types';
import { useProduct } from '@repo/widget-utils/api/api';
import ProductOverview2 from 'src/widgets/activities/product/product-overview-2/overview/ProductOverview2';

export default function ProductOverview2WidgetRoot(props: {
    productCatalogId: number;
    aboutTheTripTextCustomizationKey?: keyof UserTextCustomizations;
    pricesTextCustomizationKey?: keyof UserTextCustomizations;
    departureTextCustomizationKey?: keyof UserTextCustomizations;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
    additionalInfoTextCustomizationKey?: keyof UserTextCustomizations;
    startingTimeTextCustomizationKey?: keyof UserTextCustomizations;
}) {
    const { data: product } = useProduct(props.productCatalogId.toString());

    return (
        <Box>
            <ProductOverview2
                product={product}
                aboutTheTripTextCustomizationKey={props.aboutTheTripTextCustomizationKey}
                pricesTextCustomizationKey={props.pricesTextCustomizationKey}
                departureTextCustomizationKey={props.departureTextCustomizationKey}
                durationTextCustomizationKey={props.durationTextCustomizationKey}
                difficultyTextCustomizationKey={props.difficultyTextCustomizationKey}
                ageTextCustomizationKey={props.ageTextCustomizationKey}
                pricesFromTextCustomizationKey={props.pricesFromTextCustomizationKey}
                additionalInfoTextCustomizationKey={props.additionalInfoTextCustomizationKey}
                startingTimeTextCustomizationKey={props.startingTimeTextCustomizationKey}
            />
        </Box>
    );
}
