import { Box, Button, Skeleton, Stack, Theme, Typography, alpha, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale, getLocaleNumberFormatNoDecimals } from '@repo/i18n';
import { TZDate } from '@repo/tzdate';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
    CartItem,
    Product,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
} from '@repo/types';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { useAtom } from 'ximple';
import { uniqBy } from 'lodash-es';
import { cartAtom } from 'src/state/cart/cartAtom';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../utils';
import { useAvailabilities } from '../hooks/availabilities';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import AccommodationOverviewModal from 'src/components/domain/accommodation-card/accommodation-overview-modal/AccommodationOverviewModal';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { MultipleNumberInputValueType } from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useCartContext } from 'src/widgets/CartContext';

export function AccommodationResultList(props: {
    ids: string[];
    provider: 'visbook' | 'bilberry';
    searchDateRange: DateRange<TZDate>;
    selectedDateRange: DateRange<TZDate>;
    guestsInRoom: MultipleNumberInputValueType[][];
    roomIdx: number;
    possibleAccommodations: ProductInstance[][][] | null;
    isLoadingPossibleAccommodations: boolean;
    isErrorPossibleAccommodations: boolean;
    bilberryProviderProducts: Product[];
}) {
    const customizations = useCustomizations();
    const {
        selectedDateRange,
        guestsInRoom,
        provider,
        searchDateRange,
        ids,
        roomIdx,
        possibleAccommodations,
        isLoadingPossibleAccommodations,
        isErrorPossibleAccommodations,
        bilberryProviderProducts,
    } = props;
    const [selectedTicketType, setSelectedTicketType] = useState<(TicketType | null)[]>([]);
    const { cartItems: cart } = useCartContext();

    const [itemAddedToCart, setItemAddedToCart] = useState<CartItem | null>(null);

    const theme = useTheme();
    const { t } = useLocale();

    const numGuests = useMemo(
        () => guestsInRoom[roomIdx].reduce((acc, g) => acc + g.value, 0),
        [guestsInRoom, roomIdx],
    );

    const {
        accommodations,
        accommodationsToShow,
        accommodationsToShowConsideringCapacity,
        possibleWithRoomChange,
    } = useProcessedAccommodationData(
        provider,
        ids,
        selectedDateRange,
        searchDateRange,
        possibleAccommodations,
        roomIdx,
        cart,
        numGuests,
        guestsInRoom,
        itemAddedToCart,
        selectedTicketType,
        bilberryProviderProducts,
    );

    useConnectToItemInCart(
        cart,
        selectedTicketType,
        provider,
        accommodations,
        guestsInRoom,
        roomIdx,
        setItemAddedToCart,
        itemAddedToCart,
    );

    const ticketTypes = useMemo(() => {
        return accommodations.map((accommodations) => {
            return uniqBy(
                accommodations.flatMap((a) => a.ticketOptions.flatMap((to) => to.ticketTypes)),
                (item) => item.id,
            );
        });
    }, [accommodations]);

    useEffect(() => {
        setSelectedTicketType(
            new Array(ticketTypes.length).fill(null).map((_, i) => ticketTypes[i][0]),
        );
    }, [ticketTypes]);

    const onClickBook = useCallback(
        (i: number) => {
            const fn = async () => {
                const productInstancesOfIdx = accommodationsToShow[i];

                const cartItem = createCartItem(
                    provider,
                    productInstancesOfIdx,
                    guestsInRoom,
                    roomIdx,
                    selectedTicketType,
                    i,
                );

                if (itemAddedToCart) {
                    await cartAtom.update({
                        type: 'REMOVE_BY_ID',
                        data: getCartItemId(itemAddedToCart),
                    });
                }

                await cartAtom.update(cartItem);
                await showBasketAtom.update({
                    visible: true,
                });

                setItemAddedToCart(cartItem.data);
            };
            fn();
        },
        [
            accommodationsToShow,
            provider,
            guestsInRoom,
            roomIdx,
            selectedTicketType,
            itemAddedToCart,
        ],
    );

    if (provider === 'visbook' && isLoadingPossibleAccommodations) {
        return (
            <Stack gap={2}>
                <Skeleton
                    animation="pulse"
                    width="100%"
                    height={155}
                    sx={{ transform: 'scale(1)' }}
                />
                <Skeleton
                    animation="pulse"
                    width="100%"
                    height={155}
                    sx={{ transform: 'scale(1)' }}
                />
            </Stack>
        );
    }

    if (isErrorPossibleAccommodations) {
        return (
            <Stack
                bgcolor={theme.palette.error.main}
                py={2}
                px={3}
                borderRadius={customizations.borderRadius + 'px'}
                border={`1px solid ${theme.palette.error.dark}`}
                direction="row"
                gap={2}
                alignItems="center"
            >
                <Stack height="100%" justifyContent="center">
                    <WarningRounded
                        fontSize="large"
                        sx={{
                            color: theme.palette.error.contrastText,
                        }}
                    />
                </Stack>
                <Typography m={0} color={theme.palette.error.contrastText}>
                    {t.anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage}
                </Typography>
            </Stack>
        );
    }

    return (
        <ThemeProvider
            theme={{
                ...theme,
                typography: {
                    ...theme.typography,
                    body1: {
                        ...theme.typography.body1,
                        color: theme.palette.common.black,
                    },
                },
            }}
        >
            <Stack
                gap={2}
                sx={{
                    containerType: 'inline-size',
                }}
            >
                {!itemAddedToCart &&
                    selectedDateRange[0] &&
                    selectedDateRange[1] &&
                    accommodationsToShowConsideringCapacity.length === 0 &&
                    !possibleWithRoomChange && (
                        <Stack
                            bgcolor={theme.palette.warning.main}
                            py={2}
                            px={3}
                            borderRadius={customizations.borderRadius + 'px'}
                            border={`1px solid ${theme.palette.warning.dark}`}
                            direction="row"
                            gap={2}
                            alignItems="center"
                        >
                            <Stack height="100%" justifyContent="center">
                                <WarningRounded
                                    fontSize="large"
                                    sx={{
                                        color: theme.palette.warning.contrastText,
                                    }}
                                />
                            </Stack>
                            <Typography m={0} color={theme.palette.warning.contrastText}>
                                {t.no_accommodations_found}
                            </Typography>
                        </Stack>
                    )}
                {!itemAddedToCart &&
                    selectedDateRange[0] &&
                    selectedDateRange[1] &&
                    possibleWithRoomChange && (
                        <Stack
                            bgcolor={theme.palette.warning.main}
                            py={2}
                            px={3}
                            borderRadius={customizations.borderRadius + 'px'}
                            border={`1px solid ${theme.palette.warning.dark}`}
                            direction="row"
                            gap={2}
                            alignItems="center"
                        >
                            <Stack height="100%" justifyContent="center">
                                <WarningRounded
                                    fontSize="large"
                                    sx={{
                                        color: theme.palette.warning.contrastText,
                                    }}
                                />
                            </Stack>
                            <Typography m={0} color={theme.palette.warning.contrastText}>
                                {t.you_will_have_to_switch_rooms_during_your_stay}
                            </Typography>
                        </Stack>
                    )}
                {itemAddedToCart && accommodationsToShow.length === 0 && (
                    <AccommodationResultItem
                        provider={provider}
                        idx={0}
                        accommodations={itemAddedToCart.products}
                        ticketTypes={[]}
                        selectedTicketType={[]}
                        setSelectedTicketType={() => null}
                        onClickBook={() => null}
                        onClickRemove={() =>
                            cartAtom.update({
                                type: 'REMOVE_BY_ID',
                                data: getCartItemId(itemAddedToCart),
                            })
                        }
                        selectedDateRange={selectedDateRange}
                        numGuests={numGuests}
                        selected
                    />
                )}
                {accommodationsToShow.map((accommodations, i) => {
                    const cartItemId = getIdAsCartItem(
                        provider,
                        accommodations,
                        guestsInRoom,
                        roomIdx,
                        selectedTicketType,
                        i,
                    );
                    const selected =
                        itemAddedToCart && cartItemId === getCartItemId(itemAddedToCart);
                    return (
                        <AccommodationResultItem
                            provider={provider}
                            key={i}
                            idx={i}
                            accommodations={accommodations}
                            ticketTypes={ticketTypes}
                            selectedTicketType={selectedTicketType}
                            setSelectedTicketType={setSelectedTicketType}
                            onClickBook={onClickBook}
                            onClickRemove={() =>
                                cartAtom.update({
                                    type: 'REMOVE_BY_ID',
                                    data: cartItemId,
                                })
                            }
                            selectedDateRange={selectedDateRange}
                            numGuests={numGuests}
                            selected={selected}
                            disabled={
                                !selected &&
                                accommodations.some(
                                    (a) =>
                                        a.capacityConsideringCart !== undefined &&
                                        a.capacityConsideringCart <= 0,
                                )
                            }
                        />
                    );
                })}
            </Stack>
        </ThemeProvider>
    );
}

function useConnectToItemInCart(
    cart: CartItem[],
    selectedTicketType: (TicketType | null)[],
    provider: 'bilberry' | 'visbook',
    accommodations: (ProductInstance & { capacityConsideringCart: number })[][],
    guestsInRoom: MultipleNumberInputValueType[][],
    roomIdx: number,
    setItemAddedToCart: React.Dispatch<React.SetStateAction<CartItem | null>>,
    itemAddedToCart: CartItem | null,
) {
    useEffect(() => {
        try {
            const itemInCart = cart.find((c) => {
                const allRelevantIds = selectedTicketType.map((_, i) => {
                    return getIdAsCartItem(
                        provider,
                        accommodations[i] ?? [],
                        guestsInRoom,
                        roomIdx,
                        selectedTicketType,
                        i,
                    );
                });
                if (allRelevantIds.length === 0) {
                    allRelevantIds.push(
                        getIdAsCartItem(
                            provider,
                            accommodations[roomIdx] ?? [],
                            guestsInRoom,
                            roomIdx,
                            selectedTicketType,
                            0,
                        ),
                    );
                }
                return allRelevantIds.includes(getCartItemId(c));
            });
            if (itemInCart) {
                setItemAddedToCart(itemInCart);
            } else {
                setItemAddedToCart(null);
            }
        } catch (e) {
            // Do nothing
        }
    }, [
        accommodations,
        cart,
        guestsInRoom,
        itemAddedToCart,
        provider,
        roomIdx,
        selectedTicketType,
        setItemAddedToCart,
    ]);
}

function createCartItem(
    provider: 'bilberry' | 'visbook',
    accommodations: ProductInstance[],
    guestsInRoom: MultipleNumberInputValueType[][],
    roomIdx: number,
    selectedTicketType: (TicketType | null)[],
    i: number,
) {
    const ticketOptions = getTicketOptionsForIdx(provider, accommodations, guestsInRoom, roomIdx);
    const cartItem = createAddProductsToCartEvent(
        accommodations,
        ticketOptions,
        true, // Accomodation is not supported by membership api, so this item will disable payment plans
        false, // Obviously, it then doesn't require payment plans
        undefined,
        selectedTicketType[i] ?? undefined,
        'room-' + roomIdx,
    );
    return cartItem;
}

function getIdAsCartItem(
    provider: 'bilberry' | 'visbook',
    accommodations: ProductInstance[],
    guestsInRoom: MultipleNumberInputValueType[][],
    roomIdx: number,
    selectedTicketType: (TicketType | null)[],
    i: number,
) {
    return getCartItemId(
        createCartItem(provider, accommodations, guestsInRoom, roomIdx, selectedTicketType, i).data,
    );
}

function getTicketOptionsForIdx(
    provider: 'visbook' | 'bilberry',
    productInstancesOfIdx: ProductInstance[],
    guestsInRoom: MultipleNumberInputValueType[][],
    roomIdx: number,
): TicketOptionWithQuantity[] {
    if (provider === 'visbook') {
        return productInstancesOfIdx[0]?.ticketOptions.map((to) => {
            return {
                ...to,
                quantity:
                    to.id === '0'
                        ? (guestsInRoom[roomIdx][0]?.value ?? 1)
                        : (guestsInRoom[roomIdx][1]?.value ?? 0),
                productInstances: productInstancesOfIdx,
            };
        });
    }

    return [
        {
            ...productInstancesOfIdx[0].ticketOptions[0],
            quantity: 1,
            productInstances: productInstancesOfIdx,
            price: productInstancesOfIdx
                .slice(0, -1)
                .reduce((acc, a) => acc + a.ticketOptions[0].price, 0),
        },
    ];
}

function AccommodationResultItem(props: {
    provider: 'visbook' | 'bilberry';
    idx: number;
    accommodations: ProductInstance[];
    ticketTypes: TicketType[][];
    selectedTicketType: (TicketType | null)[];
    setSelectedTicketType: React.Dispatch<React.SetStateAction<(TicketType | null)[]>>;
    onClickBook: (i: number) => void;
    onClickRemove: () => void;
    selectedDateRange: DateRange<TZDate>;
    numGuests: number;
    selected?: null | boolean;
    disabled?: boolean;
}) {
    const {
        provider,
        idx,
        accommodations,
        ticketTypes,
        selectedTicketType,
        setSelectedTicketType,
        onClickBook,
        onClickRemove,
        selectedDateRange,
        numGuests,
        selected = false,
        disabled = false,
    } = props;
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const customizations = useCustomizations();
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const ticketTypesForAccommodation = ticketTypes[idx];

    return (
        <Box
            bgcolor={disabled ? theme.palette.grey[100] : theme.palette.common.white}
            borderRadius={customizations.borderRadius + 'px'}
            border={`1px solid ${theme.palette.grey[200]}`}
            position="relative"
        >
            <Stack
                bgcolor={(theme: Theme) =>
                    selected ? alpha(theme.palette.primary.main, 0.25) : theme.palette.common.white
                }
                padding={2}
                borderRadius={customizations.borderRadius + 'px'}
                justifyContent="space-between"
                sx={{
                    [`@container (width > ${BREAKPOINT_MD})`]: {
                        flexDirection: 'row',
                    },
                }}
            >
                <Stack
                    gap={2}
                    alignItems="flex-start"
                    sx={{
                        [`@container (width > ${BREAKPOINT_SM})`]: {
                            flexDirection: 'row',
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            aspectRatio: '16/9',
                            width: '100%',
                            borderRadius: customizations.borderRadius + 'px',
                            overflow: 'hidden',
                            flexShrink: 0,
                            [`@container (width > ${BREAKPOINT_MD})`]: {
                                width: 250,
                            },
                            [`@container (width > ${BREAKPOINT_SM})`]: {
                                width: 150,
                            },
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                objectPosition: 'center center',
                            }}
                            src={accommodations[0]?.product?.coverImage.src}
                            alt={accommodations[0]?.product?.coverImage.alt}
                        />
                    </Box>
                    <Stack gap={1} alignItems="flex-start">
                        <Typography
                            variant="h4"
                            m={0}
                            fontSize={18}
                            fontWeight="bold"
                            sx={{ color: theme.palette.common.black }}
                        >
                            {accommodations[0]?.product?.title}
                        </Typography>
                        <Box m={0} sx={{ color: theme.palette.common.black }}>
                            {parseHtmlToMui(accommodations[0]?.product?.shortDescription ?? '')}
                        </Box>
                        <Button variant="text" onClick={() => setIsOverlayOpen(true)}>
                            {t.read_more}
                        </Button>
                    </Stack>
                </Stack>

                <Stack gap={1} mt={2}>
                    {selected && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            gap={1}
                            sx={{
                                position: 'absolute',
                                top: { xs: theme.spacing(3), md: theme.spacing(1) },
                                right: { xs: theme.spacing(3), md: theme.spacing(0) },
                                backgroundColor: { xs: theme.palette.primary.main, md: 'inherit' },
                                py: 1,
                                px: 2,
                                borderRadius: 500,
                            }}
                        >
                            <Typography
                                fontStyle="italic"
                                color={{
                                    xs: theme.palette.primary.contrastText,
                                    md: theme.typography.body1.color,
                                }}
                            >
                                {t.selected}
                            </Typography>
                        </Stack>
                    )}
                    {!disabled &&
                        ticketTypesForAccommodation &&
                        ticketTypesForAccommodation.length > 1 && (
                            <Dropdown
                                value={selectedTicketType[idx] ?? null}
                                options={ticketTypesForAccommodation}
                                getOptionLabel={(opt) => opt?.name ?? t.select_price_type}
                                onChange={(opt) =>
                                    setSelectedTicketType((prev) =>
                                        prev.toSpliced(idx, 1, opt ?? null),
                                    )
                                }
                                label={t.select_price_type}
                            />
                        )}
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                        alignItems="flex-end"
                        height="100%"
                    >
                        {!disabled && (
                            <Stack direction="row" alignItems="flex-end" gap={1} flexWrap="nowrap">
                                <Typography variant="h4" fontWeight={400}>
                                    &nbsp;{currency.currency}
                                </Typography>
                                <Typography variant="h4" fontWeight={700}>
                                    {getLocaleNumberFormatNoDecimals(
                                        locale,
                                        getPriceOfRoom(
                                            provider,
                                            accommodations,
                                            selectedTicketType,
                                            ticketTypes,
                                            idx,
                                        ),
                                    )}
                                </Typography>
                            </Stack>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ whiteSpace: 'nowrap' }}
                            onClick={() => (selected ? onClickRemove() : onClickBook(idx))}
                            disabled={disabled}
                        >
                            {selected ? t.remove : disabled ? t.unavailable : t.bookNow}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            {isOverlayOpen && accommodations[0].product && (
                <AccommodationOverviewModal
                    accommodation={accommodations[0].product}
                    onCloseRoomInfoOverlay={() => setIsOverlayOpen(false)}
                    dateRange={selectedDateRange}
                    guests={numGuests}
                />
            )}
        </Box>
    );
}

function getPriceOfRoom(
    provider: 'visbook' | 'bilberry',
    accommodations: ProductInstance[],
    selectedTicketType: (TicketType | null)[],
    ticketTypes: TicketType[][],
    idx: number,
): number {
    if (provider === 'visbook') {
        return (selectedTicketType[idx] ?? ticketTypes[idx]?.[0])?.price;
    }

    return accommodations.slice(0, -1).reduce((acc, a) => acc + a.ticketOptions[0].price, 0);
}

function useProcessedAccommodationData(
    provider: 'visbook' | 'bilberry',
    ids: string[],
    selectedDateRange: DateRange<TZDate>,
    searchDateRange: DateRange<TZDate>,
    possibleAccommodations: ProductInstance[][][] | null,
    roomIdx: number,
    cart: CartItem[],
    numGuests: number,
    guestsInRoom: MultipleNumberInputValueType[][],
    itemAddedToCart: CartItem | null,
    selectedTicketType: (TicketType | null)[],
    bilberryProviderProducts: Product[],
) {
    const { data: availabilities } = useAvailabilities(
        provider,
        ids,
        searchDateRange,
        bilberryProviderProducts,
    );

    const availabilitiesConsideringCart = useMemo(() => {
        return (availabilities ?? []).map((avails) =>
            avails.map((a) => {
                const countInCart = cart.reduce((acc, item) => {
                    if (item.products.some((p) => p.id === a.id)) {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                return {
                    ...a,
                    capacityConsideringCart: a.capacity - countInCart,
                };
            }),
        );
    }, [availabilities, cart]);

    const accommodations = useMemo((): (ProductInstance & {
        capacityConsideringCart: number;
    })[][] => {
        if (provider === 'bilberry') {
            return (availabilitiesConsideringCart ?? []).map((avails) =>
                avails.filter(
                    (a) =>
                        a.start.isAfter(selectedDateRange[0]?.startOf('day').subtract(1, 'day')) &&
                        a.start.isBefore(selectedDateRange[1]?.startOf('day').add(1, 'day')),
                ),
            );
        } else if (provider === 'visbook') {
            return (
                possibleAccommodations?.[roomIdx]?.map((accommodations) =>
                    accommodations.map((a) => {
                        const countInCart = cart.reduce((acc, item) => {
                            if (item.products.some((p) => p.id === a.id)) {
                                return acc + 1;
                            }
                            return acc;
                        }, 0);
                        return {
                            ...a,
                            capacityConsideringCart: a.capacity - countInCart,
                        };
                    }),
                ) ?? []
            );
        }
        return [];
    }, [
        provider,
        availabilitiesConsideringCart,
        selectedDateRange,
        possibleAccommodations,
        roomIdx,
        cart,
    ]);

    const accommodationsToShow = useMemo((): (ProductInstance & {
        capacityConsideringCart?: number;
    })[][] => {
        const all = accommodations.filter(
            (accommodations) =>
                accommodations.length > 0 &&
                accommodations.every((a) => a.personsPerUnit && a.personsPerUnit >= numGuests),
        );
        const isItemAddedToCartIncluded =
            itemAddedToCart &&
            all.find((a, i) => {
                if (a.length === 0) return false;
                const cartItemId = getIdAsCartItem(
                    provider,
                    a,
                    guestsInRoom,
                    roomIdx,
                    selectedTicketType,
                    i,
                );
                return cartItemId === getCartItemId(itemAddedToCart);
            });
        if (itemAddedToCart && !isItemAddedToCartIncluded) {
            return [itemAddedToCart?.products ?? [], ...all];
        }
        return all;
    }, [
        accommodations,
        itemAddedToCart,
        numGuests,
        provider,
        guestsInRoom,
        roomIdx,
        selectedTicketType,
    ]);

    const accommodationsToShowConsideringCapacity = useMemo(
        () =>
            accommodationsToShow.filter(
                (accommodations) =>
                    !accommodations.some(
                        (a) =>
                            a.capacityConsideringCart !== undefined &&
                            a.capacityConsideringCart <= 0,
                    ),
            ),
        [accommodationsToShow],
    );

    const possibleWithRoomChange = useMemo(() => {
        const availabilitiesFlat = availabilitiesConsideringCart?.flat(1);

        return (
            accommodationsToShowConsideringCapacity.length === 0 &&
            selectedDateRange[0] &&
            selectedDateRange[1] &&
            availabilitiesFlat &&
            new Array(Math.abs(selectedDateRange[0].diff(selectedDateRange[1], 'day') ?? 0))
                .fill(null)
                .every((_, i) => {
                    const date = selectedDateRange[0]!.add(i, 'day');
                    return availabilitiesFlat!.some(
                        (a) =>
                            a.start.isSame(date, 'day') &&
                            a.personsPerUnit &&
                            a.personsPerUnit >= numGuests &&
                            a.capacity > 0 &&
                            (a.capacityConsideringCart === undefined ||
                                a.capacityConsideringCart > 0),
                    );
                })
        );
    }, [
        availabilitiesConsideringCart,
        selectedDateRange,
        accommodationsToShowConsideringCapacity.length,
        numGuests,
    ]);

    return useMemo(
        () => ({
            accommodations,
            accommodationsToShow,
            accommodationsToShowConsideringCapacity,
            possibleWithRoomChange,
        }),
        [
            accommodations,
            accommodationsToShow,
            accommodationsToShowConsideringCapacity,
            possibleWithRoomChange,
        ],
    );
}
