import { SxProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { Fragment, useState } from 'react';
import DateRangePickerInput from './subcomponents/DateRangePickerInput';
import DateRangePickerPopover from './subcomponents/DateRangePickerPopover';

export const initialDateRangeState: DateRange<TZDate> = [null, null];

type IProps = {
    onSelectDateRange(dateRange: DateRange<TZDate>): void;
    dateRange: DateRange<TZDate>;
    label: string;
    showError?: boolean;
    required?: boolean;
    labelColor?: string;
    minDate?: TZDate;
    variant?: 'filled' | 'outlined';
    dateRangeVariant?: 'days' | 'nights' | undefined;
    sx?: SxProps;
    color?: string;
    backgroundColor?: string;
};

export function DateRangePicker(props: IProps) {
    const {
        label,
        showError = false,
        required = false,
        labelColor,
        onSelectDateRange,
        dateRange,
        minDate,
        variant = 'filled',
        dateRangeVariant,
        sx,
        color,
        backgroundColor,
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    return (
        <Fragment>
            <DateRangePickerInput
                labelColor={labelColor}
                label={label}
                showError={showError}
                required={required}
                setAnchorEl={setAnchorEl}
                dateRange={dateRange}
                variant={variant}
                sx={sx}
                textColor={color}
                backgroundColor={backgroundColor}
            ></DateRangePickerInput>
            <DateRangePickerPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                dateRange={dateRange}
                minDate={minDate}
                onSelectDateRange={onSelectDateRange}
                dateRangeVariant={dateRangeVariant}
            ></DateRangePickerPopover>
        </Fragment>
    );
}
