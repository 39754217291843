import { Box, useTheme } from '@mui/material';

import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import LoginButton from '../login-button/LoginButton';

export default function LoggedOutPanel(props: { checkout: 'booking' | 'valueCard' }) {
    const { checkout } = props;
    const { t } = useLocale();
    const theme = useTheme();

    return (
        <Panel
            variant="shaded"
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                borderColor: theme.palette.primary.main,
                borderWidth: '2px',
                border: 'solid',
                [theme.breakpoints.down('md')]: { width: 'auto' },
            }}
        >
            <Layout justify="space-between" alignItems="center">
                <Box>
                    <PanelTitle>
                        <span>
                            {checkout === 'booking' ? t.doYouHaveValueCards : t.doYouHaveAnAccount}
                        </span>
                    </PanelTitle>
                    <Text variant="small">
                        <span>
                            {checkout === 'booking' ? t.pleaseLogInToBook : t.pleaseLogInToPurchase}
                        </span>
                    </Text>
                </Box>
                <Box sx={{ flex: '0 0 auto' }}>
                    <LoginButton></LoginButton>
                </Box>
            </Layout>
        </Panel>
    );
}
