import { TZDate } from '@repo/tzdate';

export enum DifficultyEnum {
    Any,
    Easy = 'easy',
    Medium = 'medium',
    Hard = 'hard',
}

export type ProductSearch = {
    adults?: number | null;
    children?: number | null;
    start?: TZDate | null;
    end?: TZDate | null;
    difficulty: DifficultyEnum;
    minDuration?: number | null;
    maxDuration?: number | null;
    location?: string | null;
    products: string[];
    excludeProducts: string[];
};
