import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import ProductCardListContainer from '../product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from '../product/product-list-2/product-card-list-2/ProductCardListItem';
import PackageCardInfo from 'src/components/domain/package/package-list/PackageCard';
import { usePackageProducts } from '@repo/widget-utils/api/api';

type IProps = {
    packageIds: string[];
    packageUrls: string[];
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
};

export default function PackageList(props: IProps): JSX.Element {
    const {
        packageIds,
        packageUrls,
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
    } = props;
    const customizations = useCustomizations();
    const { data: pkgs } = usePackageProducts(packageIds);

    const packageList = pkgs?.map((pkg, i) => {
        const url = packageUrls?.[i] ?? pkg.url;
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={i}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={pkgs.length}
                >
                    <PackageCardInfo pkg={pkg} url={url} />
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {packageList.length > 0 && packageList}
        </ProductCardListContainer>
    );
}
