import { TZDate } from '@repo/tzdate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BookingBase from 'src/components/domain/booking-base/BookingBase';
import BookingToggleOpenButton from 'src/components/domain/booking-widget-card/BookingToggleOpenButton';
import { useOnClickBookPackage } from 'src/hooks/domain/activities/useOnClickBookPackage';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from '@repo/i18n';
import { PackageTicketOptionWithQuantity, TicketOptionWithQuantity } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { usePackageProduct, usePackageAvailability } from '@repo/widget-utils/api/api';
import { getInitialQuantityData } from '@repo/widget-utils/price-helper';
import { hasPackageProductsCapacity } from '@repo/widget-utils/packages/products';
import { groupBy } from 'lodash-es';
import { debugLog } from '@repo/common-utils/Logger';
import { checkIfNewCartItemCausesCompatibilityIssue } from 'src/state/cart/cartAtom';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

interface IProps {
    packageId: string;
    expandArrowInside: boolean;
    positionOffscreen?: boolean;
}

export default function PackageBooking(props: IProps): JSX.Element {
    const { packageId, expandArrowInside, positionOffscreen = false } = props;

    const {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const { t } = useLocale();
    const { cartItems, isUsingPaymentPlan } = useCartContext();
    const { currentSite, loggedInUser } = useMemberContext();

    const [quantities, setQuantities] = useState<PackageTicketOptionWithQuantity[]>([]);
    const { data: pkg = null } = usePackageProduct(packageId);
    const [retries, setRetries] = useState(0);

    useEffect(() => {
        setQuantities(pkg?.ticketOptions ?? []);
    }, [pkg]);

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>({
        startDay: TZDate.now(),
        endDay: TZDate.now().add(1, 'month'),
    });
    const [selectedDate, setSelectedDate] = useState<TZDate | null>(null);
    const onSelectDate = useCallback((date: TZDate | null) => {
        // Packages always start at the beginning of the day, so we need to set the time to 00:00:00
        setSelectedDate(date?.startOf('day') ?? null);
    }, []);

    const { data: pkgAvailability } = usePackageAvailability(
        pkg,
        availabilitySearchPeriod.startDay ?? TZDate.now(),
        availabilitySearchPeriod.endDay ?? TZDate.now().add(1, 'month'),
        (data) => {
            if (retries > 2 || (data && data.length > 0)) return;

            debugLog('Retrying package availability search. Retry #', retries);
            setRetries((prev) => prev + 1);
            setAvailabilitySearchPeriod((prev) => ({
                startDay: prev.endDay,
                endDay: prev.endDay?.add(1, 'month') ?? null,
            }));
        },
    );

    const pkgAvailabilityWithQuantitiesCheck = useMemo(
        () =>
            Object.values(groupBy(pkgAvailability, (p) => p.start.format('YYYY-MM-DD'))).flatMap(
                (availabilityDay) => {
                    const isAvailable = quantities.every(
                        (q) =>
                            q.quantity === 0 ||
                            availabilityDay.some((a) =>
                                a.ticketOptions.some(
                                    (to) => to.ticketCategoryId === q.ticketCategoryId,
                                ),
                            ),
                    );
                    if (isAvailable) return availabilityDay;
                    else return [];
                },
            ),
        [pkgAvailability, quantities],
    );

    useWidgetEventEffect(
        (pkg) => ({
            eventType: 'viewItem',
            product: pkg,
            productType: 'Package',
        }),
        pkg,
        visible,
    );

    const packagePrices = useMemo(() => (pkg ? pkg.ticketOptions : []), [pkg]);
    const packageFromPrice = Math.min(...packagePrices.map((p) => p.price));

    const priceQuantities = useMemo(() => {
        if (!quantities || !pkgAvailability || pkgAvailability.length === 0) {
            return quantities;
        }

        return quantities.map((q) => ({
            ...q,
            productInstances: pkgAvailability?.filter((p) => p.product?.pkgTicketOptionId === q.id),
        }));
    }, [quantities, pkgAvailability]);

    const capacityError =
        !!selectedDate && !hasPackageProductsCapacity(pkg, pkgAvailability ?? [], quantities);

    const newItemDisablePaymentPlans = true;
    const newItemRequiresPaymentPlans = false;
    const showCartCompatibilityWarning = checkIfNewCartItemCausesCompatibilityIssue(
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
        cartItems,
    );
    const showMembershipBookingDisabledWarning =
        newItemRequiresPaymentPlans && newItemDisablePaymentPlans;

    const onClickBook = useOnClickBookPackage(
        pkg,
        quantities,
        packagePrices,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        selectedDate,
        onSelectDate,
        currentSite,
        loggedInUser,
    );

    const minEntrants = pkg?.products
        .slice()
        .sort((a, b) => ((a.minEntrants ?? -1) < (b.minEntrants ?? -1) ? -1 : 1))
        .pop()?.minEntrants;

    return (
        <BookingBase
            bookingCardRef={boxRef}
            isPackageCalendar
            hideLeftButton
            productTitle={pkg?.title ?? ''}
            travelerQuantities={priceQuantities}
            priceQuantities={priceQuantities}
            defaultQuantities={getInitialQuantityData(pkg?.ticketOptions ?? [], minEntrants)}
            setQuantities={
                setQuantities as unknown as React.Dispatch<
                    React.SetStateAction<TicketOptionWithQuantity[]>
                >
            }
            availabilityData={pkgAvailabilityWithQuantitiesCheck}
            rightButtonLabel={capitalize(t.book_now)}
            onClickRightButton={onClickBook}
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            attemptedBooking={attemptedBooking}
            availabilitySearchPeriod={availabilitySearchPeriod}
            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
            selectedDate={selectedDate}
            setSelectedDate={onSelectDate}
            title={capitalize(t.book_now)}
            fromPrice={packageFromPrice}
            expandArrowInside={expandArrowInside}
            visible={visible}
            onToggleVisible={onToggleVisible}
            toggleButton={
                <BookingToggleOpenButton expandArrowInside={expandArrowInside} visible={visible} />
            }
            capacityWarningLabel={
                capacityError
                    ? t.one_or_more_activities_or_accommodations_dont_have_enough_capacity
                    : undefined
            }
            positionOffscreen={positionOffscreen}
            showCartCompatibilityWarning={showCartCompatibilityWarning}
            showMembershipBookingDisabledWarning={showMembershipBookingDisabledWarning}
            isUsingPaymentPlan={isUsingPaymentPlan}
        />
    );
}
