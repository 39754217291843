import { Theme, useMediaQuery } from '@mui/material';
import { tzdate } from '@repo/tzdate';
import { createRef, useContext, useEffect, useState } from 'react';
import CheckoutTabs from 'src/components/domain/checkout/CheckoutTabs';
import {
    CompletedPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
    Purchase,
    CartItem,
    CheckoutInfo,
    AppliedGiftCard,
    BilberryPromoCodeStatus,
    Translations,
} from '@repo/types';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';
import { useLocale } from '@repo/i18n';
import { storeCheckoutInfoState } from 'src/state/checkout-info/checkout-info.localstorage';
import { loadPurchase, storePurchase } from 'src/state/payment/purchase.localstorage';
import { showError } from 'src/utils/widget/error-handling';
import { capitalize } from '@repo/common-utils/TextUtils';
import { BilberryApiError } from '@repo/widget-utils/api/BilberryApiError';
import { getBookingClosedItems } from '@repo/widget-utils/cart/cartUtils';
import {
    createInitialCompletedPaymentInfo,
    validateCheckoutInfoData,
} from '@repo/widget-utils/checkout-info-helper';
import {
    getCartItemDisplayStartDate,
    getCartItemDisplayTitle,
} from '@repo/widget-utils/display-helper';
import { tryFindPaymentRedirectContext } from '@repo/widget-utils/payment-redirect-helper';
import { guardIsValidPaymentGateway } from '@repo/widget-utils/reservation-helper';
import { configurationAtom, useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import { getTabIndex } from './views/getTabIndex';
import { cartAtom } from 'src/state/cart/cartAtom';
import {
    bilberryReservationFromCart,
    getConsumerFromCheckoutInfo,
    getMembershipActivityReservationsFromCart,
    getTimeslotsFromCart,
} from '@repo/widget-utils/ProductMapper';
import { createReservation } from '@repo/widget-utils/api/product.api';
import { ExtrasView } from './views/ExtrasView';
import { ContactView } from './views/ContactView';
import { PaymentView } from './views/PaymentView';
import PackageView from './views/PackageView';
import { companyAtom } from 'src/state/company';
import { postMultiReservation } from '@repo/widget-utils/api/membership-api';
import IntentView from 'src/components/domain/timeslots-views/IntentView';
import { errorLog } from '@repo/common-utils/Logger';
import { clearCartEvent } from 'src/state/cart/cart.reducer';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import {
    calculateAppliedGiftcards,
    getPriceSummaryFromCartItems,
    PriceSummary,
} from '@repo/widget-utils/price-helper';
import { RelatedProductsView } from './views/RelatedProductsView';
import { paymentIntentAtom, resetIgnoredValueCards } from 'src/state/paymentIntentAtom.atom';
import { ActiveTabContext } from './ActiveTabContext';
import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

const hiddenBodyStyle = initializeHiddenBodyStyle('activities');

export function Cart(): JSX.Element {
    const { t, locale } = useLocale();
    const sentinelRef = createRef<HTMLDivElement>();
    const configurations = useConfigurations();

    const {
        cartItems,
        giftcards,
        appliedPromoCode,
        checkoutInfo: checkoutInfoData,
        setCheckoutInfo: setCheckoutInfoData,
        isUsingPaymentPlan,
    } = useCartContext();

    const [{ valueCardIdsToIgnore, valueCardProductIdsToIgnore, valueCardProductTypeIdsToIgnore }] =
        useAtom(paymentIntentAtom);

    const config = useConfigurations();

    const priceSummary = getPriceSummaryFromCartItems(cartItems, appliedPromoCode);
    const appliedGiftcards = calculateAppliedGiftcards(giftcards, priceSummary.totalPrice);

    const isPackageInCart = Object.values(cartItems).some((item) => item.pkg);

    useEffect(() => {
        // Reset ignored value cards when the checkout process starts
        resetIgnoredValueCards();
    }, []);

    const idxOpts = {
        hasExtras: config.enableExtras,
        hasPackage: isPackageInCart,
        display: true,
        isUsingPaymentPlan,
        hasRelatedProducts: config.enableRelatedProducts,
    };

    const paymentRedirectContext = tryFindPaymentRedirectContext();

    const storedPurchase = loadPurchase();
    const initialCompletedPaymentInfo = createInitialCompletedPaymentInfo(
        storedPurchase,
        paymentRedirectContext,
    );

    const { activeTab, setActiveTab } = useContext(ActiveTabContext);

    useEffect(() => {
        sentinelRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAddressFields = cartItems.some((item) =>
        item.products.some(
            (p) => p.product?.type === 'accommodation' || p.product?.type === 'timeslot',
        ),
    );

    const isCheckoutInfoDataValid =
        checkoutInfoData !== null
            ? validateCheckoutInfoData(checkoutInfoData, false) // showAddressFields)
            : false;

    const [hidePaymentTabHeader, setHidePaymentTabHeader] = useState<boolean>(false);
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [isMakingReservation, setIsMakingReservation] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
    const [completedPaymentInfo, setCompletedPaymentInfo] = useState<CompletedPaymentInfo | null>(
        initialCompletedPaymentInfo,
    );

    const onConfirmationPage = completedPaymentInfo !== null;
    const { handleGoPayClicked, onPaymentCancelled, onPaymentCompleted, clickedNext } = usePayment(
        t,
        locale,
        priceSummary,
        checkoutInfoData,
        setActiveTab,
        setPaymentInfo,
        setCompletedPaymentInfo,
        setIsMakingReservation,
        showAddressFields,
        configurations.enableGiftcards ? appliedGiftcards : [],
        configurations.enablePromoCodes ? appliedPromoCode : null,
        idxOpts,
        valueCardIdsToIgnore,
        valueCardProductIdsToIgnore,
        valueCardProductTypeIdsToIgnore,
        isUsingPaymentPlan,
    );

    const itemsWithExtras = cartItems.filter((item) =>
        item.products.some(
            (p) =>
                p.extraProducts.length > 0 || p.timeslots.some((t) => t.extraProducts.length > 0),
        ),
    );

    const hasExtras = config.enableExtras && itemsWithExtras.length > 0;

    useHiddenBody(hiddenBodyStyle);

    // Dispatch book event if redirected from Vipps)
    useWidgetEventEffect(
        (cartItems, giftCards, promoCode) => ({
            eventType: 'book',
            cartItems: Object.values(cartItems),
            giftCards: giftCards
                ? giftCards.map((giftCard) => ({
                      priceReduction: giftCard.priceReduction,
                      giftcardReference: giftCard.giftcardStatus.id,
                  }))
                : undefined,
            promoCode: promoCode,
        }),
        cartItems,
        appliedGiftcards,
        appliedPromoCode,
        () => !paymentRedirectContext,
    );
    const defaultTabset = {
        ordered: false,
        tabs: [
            ...(config.enableRelatedProducts
                ? [
                      {
                          name: `${getTabIndex('relatedproducts', idxOpts)} ${t.related_products}`,
                          title: capitalize(t.related_products),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          identifier: 'relatedproducts',
                          content: (
                              <RelatedProductsView
                                  activeTab={activeTab!}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() =>
                                      setActiveTab(hasExtras ? 'extras' : 'contactinfo')
                                  }
                                  hasExtras={hasExtras}
                              />
                          ),
                          disabled: onConfirmationPage,
                      },
                  ]
                : []),
            ...(config.enableExtras
                ? [
                      {
                          name: `${getTabIndex('extras', idxOpts)} ${t.extras}`,
                          title: capitalize(t.extras),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          identifier: 'extras',
                          content: (
                              <ExtrasView
                                  activeTab={activeTab!}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() => setActiveTab('contactinfo')}
                              />
                          ),
                          disabled: onConfirmationPage || !hasExtras,
                          sx: {
                              ...(!hasExtras && { textDecoration: 'line-through' }),
                          },
                      },
                  ]
                : []),
            {
                name: `${getTabIndex('contactinfo', idxOpts)} ${t.contact_details}`,
                identifier: 'contactinfo',
                title: capitalize(t.contact_details),
                description: capitalize(t.please_register),
                backButtonText: t.back_to_webshop,
                content: (
                    <ContactView
                        largeScreen={largeScreen}
                        isMakingReservation={isMakingReservation}
                        checkoutInfo={checkoutInfoData}
                        showAddressFields={showAddressFields}
                        setCheckoutInfo={setCheckoutInfoData}
                        clickedNext={clickedNext}
                        handleGoPayClickedInvoice={
                            isUsingPaymentPlan || checkoutInfoData?.contactType !== 'company'
                                ? undefined
                                : () => handleGoPayClicked('invoice')
                        }
                        handleGoPayClicked={
                            isUsingPaymentPlan
                                ? () => Promise.resolve(setActiveTab('intent'))
                                : () => handleGoPayClicked()
                        }
                        nextButtonText={isUsingPaymentPlan ? t.choose_payment_method : t.go_and_pay}
                    />
                ),
                disabled: onConfirmationPage,
            },
            ...(isUsingPaymentPlan
                ? [
                      {
                          name: `${getTabIndex('intent', idxOpts)} ${t.choose_payment_method}`,
                          identifier: 'intent',
                          title: capitalize(t.choose_payment_method),
                          description: '',
                          backButtonText: t.back_to_webshop,
                          content: (
                              <IntentView
                                  largeScreen={largeScreen}
                                  isMakingReservation={isMakingReservation}
                                  handleGoPayClicked={handleGoPayClicked}
                              />
                          ),
                          disabled: onConfirmationPage,
                      },
                  ]
                : []),
            {
                name: `${getTabIndex('payment', idxOpts)} ${t.payment}`,
                identifier: 'payment',
                title: capitalize(t.payment),
                description: capitalize(t.please_select),
                backButtonText: t.back_to_webshop,
                hideTitle: completedPaymentInfo ? true : false,
                content: (
                    <PaymentView
                        paymentInfo={paymentInfo}
                        largeScreen={largeScreen}
                        onPaymentCancelled={onPaymentCancelled}
                        onPaymentCompleted={onPaymentCompleted(idxOpts)}
                        appliedGiftcards={appliedGiftcards}
                        appliedPromoCode={appliedPromoCode}
                        completedPaymentInfo={completedPaymentInfo}
                    />
                ),
                disabled: !isCheckoutInfoDataValid || onConfirmationPage,
            },
        ],
    };

    const packageTabset = {
        ordered: false,
        tabs: [
            {
                name: `${getTabIndex('package', idxOpts)} ${t.packages}`,
                identifier: 'package',
                title: capitalize(t.package),
                description: capitalize(t.pleaseChoose),
                backButtonText: t.back_to_webshop,
                content: (
                    <PackageView
                        largeScreen={largeScreen}
                        isMakingReservation={isMakingReservation}
                        onNextClicked={() =>
                            setActiveTab(
                                config.enableRelatedProducts
                                    ? 'relatedproducts'
                                    : hasExtras
                                    ? 'extras'
                                    : 'contactinfo',
                            )
                        }
                        nextButtonText={
                            config.enableRelatedProducts
                                ? t.related_products
                                : hasExtras
                                ? t.extras
                                : t.contact_details
                        }
                    />
                ),
                disabled: onConfirmationPage,
            },
            ...(config.enableRelatedProducts
                ? [
                      {
                          name: `${getTabIndex('relatedproducts', idxOpts)} ${t.related_products}`,
                          title: capitalize(t.related_products),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          identifier: 'relatedproducts',
                          content: (
                              <RelatedProductsView
                                  activeTab={activeTab!}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() =>
                                      setActiveTab(hasExtras ? 'extras' : 'contactinfo')
                                  }
                                  hasExtras={hasExtras}
                              />
                          ),
                          disabled: onConfirmationPage,
                      },
                  ]
                : []),
            ...(config.enableExtras
                ? [
                      {
                          name: `${getTabIndex('extras', idxOpts)} ${t.extras}`,
                          identifier: 'extras',
                          title: capitalize(t.extras),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          content: (
                              <ExtrasView
                                  activeTab={activeTab!}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() => setActiveTab('contactinfo')}
                              />
                          ),
                          disabled: onConfirmationPage || !hasExtras,
                          sx: {
                              ...(!hasExtras && { textDecoration: 'line-through' }),
                          },
                      },
                  ]
                : []),
            defaultTabset.tabs[
                getTabIndex('contactinfo', { ...idxOpts, hasPackage: false, display: false })
            ],
            defaultTabset.tabs[
                getTabIndex('payment', { ...idxOpts, hasPackage: false, display: false })
            ],
        ],
    };

    const tabset = isPackageInCart ? packageTabset : defaultTabset;
    const activeTabNumber = getTabIndex(activeTab!, { ...idxOpts, display: false });

    return (
        <CheckoutTabs
            tabset={tabset}
            currentTab={activeTabNumber}
            onChange={(e) =>
                setActiveTab(tabset.tabs[e].identifier as Parameters<typeof getTabIndex>[0])
            }
        />
    );
}

function usePayment(
    t: Translations,
    locale: string,
    priceSummary: PriceSummary,
    checkoutInfoData: CheckoutInfo | null,
    setActiveTab: (arg: Parameters<typeof getTabIndex>[0]) => void,
    setPaymentInfo: (paymentInfo: PaymentInfo | null) => void,
    setCompletedPaymentInfo: (paymentInfo: CompletedPaymentInfo | null) => void,
    setIsMakingReservation: (isMakingReservation: boolean) => void,
    showAddressFields: boolean,
    appliedGiftcards: AppliedGiftCard[],
    appliedPromoCode: BilberryPromoCodeStatus | null,
    tabIndexOpts: Record<string, any>,
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
    isUsingPaymentPlan: boolean,
) {
    const { mutateBookingsForUser, mutateValueCardsForUser } = useMemberContext();
    const [clickedNext, setClickedNext] = useState(false);

    async function handleGoPayClicked(paymentMethod?: 'invoice') {
        setClickedNext(true);

        if (!checkoutInfoData) return;

        const isCheckoutInfoDataValid = validateCheckoutInfoData(
            checkoutInfoData,
            showAddressFields,
        );

        if (!isCheckoutInfoDataValid) {
            showError(capitalize(t.please_fill_in_all_required_information));
            return;
        }

        const cartItems = cartAtom.subject.value;

        const bookingClosedItems = getBookingClosedItems(cartItems);
        if (bookingClosedItems.length > 0) {
            const productNames = bookingClosedItems.map((item) =>
                capitalize(
                    t.booking_is_closed.parsed(
                        `"${getCartItemDisplayTitle(item)} - ${tzdate(
                            getCartItemDisplayStartDate(item),
                        ).format('lll')}"`,
                    ),
                ),
            );
            showError(productNames.join(', '));
            return;
        }

        reserve(
            t,
            cartItems,
            priceSummary,
            checkoutInfoData,
            setActiveTab,
            setPaymentInfo,
            setIsMakingReservation,
            appliedGiftcards,
            appliedPromoCode,
            tabIndexOpts,
            onPaymentCompleted,
            valueCardIdsToIgnore,
            valueCardProductIdsToIgnore,
            valueCardProductTypeIdsToIgnore,
            isUsingPaymentPlan,
            paymentMethod,
        );
    }

    function onPaymentCancelled() {
        // TODO: what if the user go back, make some minor changes, and then want to proceed...
        // now we will make a new reservation.

        dispatchWidgetEvent({
            eventType: 'checkoutStep',
            cartItems: cartAtom.subject.value,
            giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                giftcardReference: appliedGiftcard.giftcardStatus.id,
                priceReduction: appliedGiftcard.priceReduction,
            })),
            promoCode: appliedPromoCode,
            checkoutStep: 'Cancel',
        });

        setPaymentInfo(null);
        setActiveTab('contactinfo');
    }

    function onPaymentCompleted(tabIndexOpts: Record<string, any>) {
        return async function (
            paymentInfo: PaymentInfo | GiftcardPaymentInfo,
            paymentGatewayResponse: any,
        ) {
            const knownPaymentInfoType = paymentInfo as PaymentInfo;
            const purchasedCartItems = cartAtom.subject.value;
            const completedPaymentInfo: CompletedPaymentInfo = {
                paymentInfo: knownPaymentInfoType,
                paymentGatewayResponse,
                purchasedCartItems,
                paymentId: knownPaymentInfoType.payment_id,
                referenceId: knownPaymentInfoType.reference,
            };

            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                cartItems: purchasedCartItems,
                promoCode: appliedPromoCode,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                checkoutStep: 'Success',
            });

            dispatchWidgetEvent({
                eventType: 'book',
                cartItems: purchasedCartItems,
                promoCode: appliedPromoCode,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                reference:
                    knownPaymentInfoType.booking?.id.toString() ?? knownPaymentInfoType.reference,
            });

            await cartAtom.update(clearCartEvent());
            storeCheckoutInfoState(null);
            setPaymentInfo(null);
            setCompletedPaymentInfo(completedPaymentInfo);
            setActiveTab('payment');
            storePurchase(null);
            mutateBookingsForUser();
            mutateValueCardsForUser();

            const bbRebookReturnUrl = findUrlQueryParam('bbRebookReturnUrl');

            if (bbRebookReturnUrl) {
                window.location.href =
                    bbRebookReturnUrl + '?newInvoiceId=' + completedPaymentInfo.referenceId;
            }
        };
    }

    return {
        handleGoPayClicked,
        onPaymentCancelled,
        onPaymentCompleted,
        clickedNext,
    };
}

async function reserve(
    t: Translations,
    cartItems: CartItem[],
    priceSummary: PriceSummary,
    checkoutInfoData: CheckoutInfo,
    setActiveTab: (arg: Parameters<typeof getTabIndex>[0]) => void,
    setPaymentInfo: (paymentInfo: PaymentInfo | null) => void,
    setIsMakingReservation: (isMakingReservation: boolean) => void,
    appliedGiftcards: AppliedGiftCard[],
    appliedPromoCode: BilberryPromoCodeStatus | null,
    tabIndexOpts: Record<string, any>,
    onPaymentCompleted: (
        tabIndexOptions: Record<string, any>,
    ) => (paymentInfo: PaymentInfo | GiftcardPaymentInfo, paymentGatewayResponse: any) => void,
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
    isUsingPaymentPlan: boolean,
    paymentMethod?: 'invoice',
) {
    setIsMakingReservation(true);

    const appliedGiftcardIds = appliedGiftcards.map((x) => x.giftcardStatus.id);

    try {
        let paymentInfo: PaymentInfo | null = null;
        const purchase: Purchase = {
            referenceId: '',
            paymentId: '',
            amount: 0,
            cartItems: cartItems,
            giftcards: [],
            isAnalyticsNotified: false,
        };

        if (isUsingPaymentPlan && paymentMethod !== 'invoice') {
            const timeslotsReservation = getTimeslotsFromCart(
                cartItems,
                valueCardIdsToIgnore,
                valueCardProductIdsToIgnore,
                valueCardProductTypeIdsToIgnore,
            );

            const tours = getMembershipActivityReservationsFromCart(
                cartItems,
                checkoutInfoData,
                valueCardIdsToIgnore,
                valueCardProductIdsToIgnore,
                valueCardProductTypeIdsToIgnore,
            );

            const consumer = getConsumerFromCheckoutInfo(checkoutInfoData);

            const booking = await postMultiReservation(
                companyAtom.subject.value.currentSite!.key,
                consumer,
                {
                    timeslotReservations: timeslotsReservation?.reservations ?? [],
                    activityReservations: tours,
                    giftcardReferences: appliedGiftcardIds,
                    coupon_code: appliedPromoCode?.coupon_code,
                    checkoutUrl: window.location.origin + window.location.pathname,
                },
            );
            paymentInfo = {
                payment_gateway: 'nets',
                payment_id: booking?.paymentId ?? null,
                reference: booking?.orderReference ?? null,
                checkout_key: companyAtom.subject.value.currentSite!.netsCheckoutKey,
            };
            purchase.referenceId = booking?.orderReference ?? '';
            purchase.paymentId = booking?.paymentId ?? '';

            purchase.amount = priceSummary.totalPrice;
        } else {
            const bbRebookFromInvoiceIds = findUrlQueryParam('bbRebookFromInvoiceIds');

            const rebookFromInvoiceIds = bbRebookFromInvoiceIds
                ? bbRebookFromInvoiceIds.split(',').map((id) => parseInt(id))
                : [];

            const reservation = bilberryReservationFromCart(
                cartItems,
                checkoutInfoData,
                rebookFromInvoiceIds,
                appliedGiftcardIds,
                appliedPromoCode?.coupon_code,
            );
            const booking = await createReservation(
                reservation,
                paymentMethod,
                checkoutInfoData.contactType,
            );
            guardIsValidPaymentGateway(booking);
            paymentInfo = {
                payment_gateway: booking?.payment_gateway as PaymentInfo['payment_gateway'],
                payment_id: booking.payment_id,
                reference: booking.id.toString(),
                checkout_key: booking.checkout_key ?? undefined,
                reservation,
                booking,
            };
            purchase.referenceId = booking?.id.toString() ?? '';
            purchase.paymentId = booking?.payment_id ?? '';
            purchase.amount = booking.total;
        }

        storePurchase(purchase);

        // Bilberry backoffice specific behavior
        if (configurationAtom.subject.value.backoffice_skipPayment) {
            onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
            return;
        }

        if (
            (paymentMethod === 'invoice' && paymentInfo.payment_gateway === 'invoice') ||
            (!paymentInfo.payment_id &&
                paymentInfo.reference &&
                !paymentInfo.booking?.stripe_key) ||
            (paymentInfo.booking?.paid_at && !paymentInfo.payment_id && !paymentInfo.checkout_key)
        ) {
            // Due to applied giftcard or invoice payment
            // Go straight to receipt
            //
            onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
        } else {
            // Proceed to the NETS payment
            setPaymentInfo(paymentInfo);
            setActiveTab('payment');
            dispatchWidgetEvent({
                eventType: 'paymentInfoAdded',
                cartItems: cartItems,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                promoCode: appliedPromoCode,
            });
        }
    } catch (error) {
        errorLog('error: ', error);
        const errorOccuredMessage = capitalize(t.error_occurred_when_creating_reservation);
        let apiErrorMessages: string[] = [];

        if (error instanceof BilberryApiError) {
            apiErrorMessages = await error.errorMessages();
        }

        showError(`${errorOccuredMessage}. ${apiErrorMessages.join(',')}`);
    }
    setIsMakingReservation(false);
}
