import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import {
    Box,
    Button,
    Collapse,
    FormHelperText,
    Grid,
    IconButton,
    Stack,
    SxProps,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useEffect, useState, useTransition } from 'react';
import { useLocale, getLocaleNumberFormatTwoDecimals } from '@repo/i18n';
import { BilberryPromoCodeStatus } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { usePromocodeStatus } from '@repo/widget-utils/api/api';
import { useAtom } from 'ximple/atoms';
import { promoCodeAtom } from 'src/state/cart/promoCodeAtom';
import { CancelRounded } from '@mui/icons-material';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useCartContext } from 'src/widgets/CartContext';

interface Props {
    invertedColorsClass?: SxProps;
    writeable?: boolean;
    onPromoCodeApplied: (appliedPromoCode: BilberryPromoCodeStatus | null) => void;
    appliedPromoCode?: BilberryPromoCodeStatus | null;
    currentPriceReduction: number;
    validatePromoCode?: (appliedPromoCode?: BilberryPromoCodeStatus) => {
        result: BilberryPromoCodeStatus | null;
        error: string | null;
    };
}

export default function SummaryPromoCode(props: Props): JSX.Element | null {
    const { appliedPromoCode } = useCartContext();

    if (!props.writeable) {
        return appliedPromoCode ? (
            <PromoCodeStatusDisplay
                promoCodeStatus={appliedPromoCode}
                currentPriceReduction={props.currentPriceReduction}
                invertedColorsClass={props.invertedColorsClass}
            />
        ) : null;
    }

    return <SummaryPromoCodeWriteable {...props} />;
}

export function SummaryPromoCodeWriteable(props: Props): JSX.Element {
    const customizations = useCustomizations();
    const theme = useTheme();
    const configuration = useConfigurations();
    const [promoCode, setPromoCode] = useAtom(promoCodeAtom);
    const [expanded, setExpanded] = useState(!!props.appliedPromoCode || !!promoCode);
    const { t } = useLocale();
    const [inputValue, setInputValue] = useState(promoCode);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [, updatePromoCodeTransition] = useTransition();

    const promoCodeStatus = usePromocodeStatusData(
        promoCode,
        setPromoCode,
        setShowError,
        setErrorMessage,
        props.onPromoCodeApplied,
        props.validatePromoCode,
    );

    useEffect(() => {
        if (props.appliedPromoCode || promoCode) {
            setExpanded(true);
            setInputValue(promoCode);
        }
    }, [props.appliedPromoCode, promoCode]);

    useEffect(() => {
        // Add the global promo code if present, the promo code is writable, and there is no existing promo code applied
        // Use the promo code atom directly because we cannot add the promoCode to the dependency array, as that would lock the global promo code
        if (props.writeable && !promoCodeAtom.subject.value && configuration.globalPromoCode) {
            updatePromoCodeTransition(() => {
                setPromoCode(configuration.globalPromoCode ?? '');
            });
        }
    }, [configuration.globalPromoCode, props.writeable, setPromoCode]);

    return (
        <Box borderBottom={`1px solid ${theme.palette.grey[400]}`}>
            <Grid
                container
                onClick={() => {
                    setExpanded(!expanded);
                }}
                alignItems="center"
            >
                <Button
                    color="primary"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: customizations.linkColor,
                        },
                        color: customizations.linkColor,
                        padding: 0,
                    }}
                >
                    {t.enter_promo_code}
                </Button>
                <IconButton
                    sx={[
                        {
                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.standard,
                            }),
                            '& .MuiSvgIcon-root': {
                                color: customizations.linkColor,
                            },
                            color: customizations.linkColor,
                        },
                        expanded && { transform: 'rotate(180deg)' },
                    ]}
                >
                    <KeyboardArrowDownRounded />
                </IconButton>
            </Grid>
            <Collapse
                in={expanded}
                sx={{ paddingBottom: theme.spacing(2) }}
                timeout="auto"
                unmountOnExit
            >
                <Fragment>
                    <Stack direction="row" flexWrap="nowrap" justifyContent="space-between">
                        <Stack>
                            <TextField
                                error={showError}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    marginRight: theme.spacing(1),
                                    width: '264px',
                                    [`@media screen and (max-width: ${768}px)`]: {
                                        width: '232px',
                                    },
                                }}
                                onChange={(e) => {
                                    setInputValue(e.currentTarget.value);
                                    showError && setShowError(false);
                                }}
                                onKeyDown={(ev: React.KeyboardEvent) => {
                                    if (ev.code?.toLowerCase() === 'enter') {
                                        setPromoCode(inputValue);
                                    }
                                }}
                                variant="outlined"
                                value={inputValue}
                                color="secondary"
                                placeholder="Enter code"
                                disabled={props.appliedPromoCode !== null}
                                size="small"
                            />
                            {showError && (
                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                    {errorMessage}
                                </FormHelperText>
                            )}
                        </Stack>
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: theme.spacing(1),
                                borderColor: theme.palette.primary.main,
                                height: theme.spacing(5),
                            }}
                            onClick={() => setPromoCode(inputValue)}
                        >
                            {t.apply.toUpperCase()}
                        </Button>
                    </Stack>
                </Fragment>
            </Collapse>
            {promoCodeStatus && (
                <PromoCodeStatusDisplay
                    invertedColorsClass={props.invertedColorsClass}
                    promoCodeStatus={promoCodeStatus}
                    currentPriceReduction={props.currentPriceReduction}
                    onClear={() => {
                        updatePromoCodeTransition(() => {
                            setInputValue('');
                            setShowError(false);
                            setPromoCode('');
                            props.onPromoCodeApplied?.(null);
                        });
                    }}
                    writable={props.writeable}
                />
            )}
        </Box>
    );
}

function usePromocodeStatusData(
    promoCode: string,
    setPromoCode: (newPromoCode: string) => void,
    setShowError: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    onPromoCodeApplied?: (appliedPromoCode: BilberryPromoCodeStatus | null) => void,
    validatePromoCode?: (appliedPromoCode?: BilberryPromoCodeStatus) => {
        result: BilberryPromoCodeStatus | null;
        error: string | null;
    },
) {
    const { t } = useLocale();
    const { data: promoCodeStatus } = usePromocodeStatus(
        promoCode,
        () => {
            setErrorMessage(t.couldnt_apply_promocode_is_the_code_correct);
            setShowError(true);
            setPromoCode('');
            onPromoCodeApplied?.(null);
        },
        (promoCodeStatus) => {
            const { result, error } = validatePromoCode?.(promoCodeStatus) ?? {
                result: promoCodeStatus,
                error: null,
            };
            if (error) {
                setErrorMessage(error);
                setShowError(true);
            }
            onPromoCodeApplied?.(result);
        },
    );
    return validatePromoCode ? validatePromoCode(promoCodeStatus).result : promoCodeStatus;
}

function PromoCodeStatusDisplay({
    invertedColorsClass,
    promoCodeStatus,
    currentPriceReduction,
    onClear,
    writable,
}: {
    invertedColorsClass?: SxProps;
    promoCodeStatus: BilberryPromoCodeStatus;
    currentPriceReduction: number;
    onClear?: () => void;
    writable?: boolean;
}) {
    const { locale } = useLocale();
    const theme = useTheme();

    return (
        <Stack direction="row" flexWrap="nowrap" alignItems="center">
            <Typography color="secondary" align="right" sx={{ flex: 3, ...invertedColorsClass }}>
                {promoCodeStatus?.coupon_code}
            </Typography>

            <Stack
                direction="row"
                justifyContent="flex-end"
                flexWrap="nowrap"
                gap={2}
                alignItems="center"
                flex={1}
            >
                <Typography color="secondary" align="right" pl={2} sx={invertedColorsClass}>
                    -{getLocaleNumberFormatTwoDecimals(locale, currentPriceReduction)}
                </Typography>
                {onClear && writable && (
                    <IconButton
                        sx={{
                            alignSelf: 'flex-start',
                            padding: 0,
                            '& .MuiSvgIcon-root': {
                                color: theme.palette.common.black,
                            },
                            color: theme.palette.common.black,
                        }}
                        onClick={onClear}
                    >
                        <CancelRounded fontSize="small" />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
}
