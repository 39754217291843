import { getLocaleNumberFormatTwoDecimals, useLocale } from '@repo/i18n';
import { ProductInstance, TicketOptionWithQuantity } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { alpha, Button, Paper, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';

export function PackageSummary({
    ticketOptionsWithTours,
    onBook,
    sx,
}: {
    ticketOptionsWithTours: { tours: ProductInstance[]; ticketOption: TicketOptionWithQuantity }[];
    onBook?: () => void;
    sx?: SxProps;
}) {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const [currency] = useAtom(currencyAtom);
    return (
        <Stack gap={1} sx={sx}>
            <Stack
                gap={2}
                bgcolor={customizations.productCardColor}
                borderRadius={`${customizations.borderRadius}px`}
                component={Paper}
                variant="outlined"
                px={3}
                py={4}
            >
                {ticketOptionsWithTours.map((to) => (
                    <PackageSummaryItem key={to.ticketOption.id} item={to} />
                ))}
                <Stack gap={2} alignItems="flex-end">
                    <Stack
                        width="100%"
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        flexWrap="nowrap"
                        gap={1}
                    >
                        <Typography
                            variant="h6"
                            align="right"
                            color="textSecondary"
                            pr={theme.spacing(1.5)}
                            lineHeight={1}
                        >
                            {t.total.toUpperCase()}&nbsp;{currency.currency}&nbsp;
                        </Typography>
                        <Typography
                            variant="h4"
                            align="right"
                            fontSize={28}
                            fontWeight={customizations.boldFontWeight}
                            mb={-0.5}
                            lineHeight={1}
                            color="textSecondary"
                        >
                            {getLocaleNumberFormatTwoDecimals(
                                locale,
                                ticketOptionsWithTours.reduce(
                                    (acc, { ticketOption }) =>
                                        acc + ticketOption.quantity * ticketOption.price,
                                    0,
                                ),
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            {onBook && (
                <Stack width="100%" alignItems={{ md: 'flex-end' }} pt={1}>
                    <Button variant="contained" onClick={() => onBook()}>
                        {t.book}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
}

export function PackageSummaryItem({
    item,
}: {
    item: { tours: ProductInstance[]; ticketOption: TicketOptionWithQuantity };
}) {
    const { tours, ticketOption } = item;
    const customizations = useCustomizations();
    const { t } = useLocale();

    const borderAndPaddingSx = {
        borderBottom: 1,
        borderColor: alpha(customizations.productCardTextColor, 0.25),
    };

    return (
        <Stack
            sx={{
                containerType: 'inline-size',
            }}
            gap={2}
        >
            <Stack direction="column" gap={1} justifyContent="flex-end">
                <Typography variant="h4" fontSize={20} fontWeight={700} m={0} lineHeight={1}>
                    {ticketOption.quantity}x {capitalize(ticketOption.name)}
                </Typography>
            </Stack>
            <Stack pl={2} gap={1}>
                <Typography variant="h5" fontSize={18}>
                    {t.whats_included}
                </Typography>
                {tours.map((tour) => (
                    <Stack
                        key={tour.id}
                        gap={1}
                        sx={{
                            ...borderAndPaddingSx,
                        }}
                        p={1}
                        pl={2}
                    >
                        <Typography variant="h6" m={0} lineHeight={1} fontSize={16}>
                            {tour.product?.title}
                        </Typography>
                        <Typography m={0} lineHeight={1}>
                            {tour.start.isSame(tour.end, 'day')
                                ? `${t.from} ${tour.start.format('DD. MMM YYYY LT')} ${
                                      t.to
                                  } ${tour.end.format('LT')}`
                                : `${t.from} ${tour.start.format('DD. MMM YYYY LT')} ${
                                      t.to
                                  } ${tour.end.format('DD. MMM YYYY LT')}`}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}
