import { TZDate, tzdate } from '@repo/tzdate';
import { groupBy, mapValues } from 'lodash-es';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { CartItem, PackageTicketOptionWithQuantity, ProductInstance } from '@repo/types';
import { useAvailablePackageProducts } from '@repo/widget-utils/api/api';
import TourSelectionContainer from './subcomponents/TourSelectionContainer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { getPackageTicketOptionsWithTours } from '@repo/widget-utils/packages/getPackageTicketOptionsWithTours';
import { Stack, Typography } from '@mui/material';
import { PackageSummary } from './PackageSummary';
import { useLocale } from '@repo/i18n';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function TourSelection({
    item,
    initiallyExpanded,
}: {
    item: CartItem;
    initiallyExpanded?: boolean;
}) {
    const pkg = item.pkg?.pkg ?? null;
    const { data: availabilities } = useAvailablePackageProducts(
        pkg,
        item.pkg?.date ?? TZDate.now(),
    );
    const [selectedAvailabilities, setSelectedAvailabilities] = useState(item.products);
    const { t } = useLocale();
    const { currentSite, loggedInUser } = useMemberContext();

    const onSelectAvailabilities = useCallback(
        async (selected: ProductInstance[]) => {
            setSelectedAvailabilities(selected);
            if (!item.pkg) return;
            cartAtom.update(
                createAddProductsToCartEvent(
                    selected,
                    item.ticketOptions,
                    true, // Packages is not supported by membership api, so this item will disable payment plans
                    false, // Obviously, it then doesn't require payment plans,
                    currentSite,
                    loggedInUser,
                    item.pkg,
                ),
            );
        },
        [item, currentSite, loggedInUser],
    );

    useEffect(() => {
        if (selectedAvailabilities.length > 0) return;
        const products = getFirstAvailableProducts(item, availabilities);
        setSelectedAvailabilities(products);
        if (products.length > 0) onSelectAvailabilities(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilities, selectedAvailabilities, item]);

    const [ticketOptionsWithTours, ticketOptionsWithSelectedTours] = useMemo(
        () => [
            getPackageTicketOptionsWithTours(
                item.ticketOptions as PackageTicketOptionWithQuantity[],
                availabilities,
            ),
            getPackageTicketOptionsWithTours(
                item.ticketOptions as PackageTicketOptionWithQuantity[],
                selectedAvailabilities,
            ),
        ],
        [item.ticketOptions, availabilities, selectedAvailabilities],
    );

    return (
        <Stack gap={2}>
            <Stack gap={1}>
                <Typography variant="h4" fontSize={20} fontWeight={700} lineHeight={1} m={0} pt={2}>
                    {t.change_selected_products}
                </Typography>
                <TourSelectionContainer
                    products={availabilities}
                    selectedAvailabilities={selectedAvailabilities}
                    setSelectedAvailabilities={onSelectAvailabilities}
                    ticketOptionsWithTours={ticketOptionsWithTours}
                    initiallyExpanded={initiallyExpanded}
                />
            </Stack>
            <Typography
                variant="h4"
                fontSize={20}
                fontWeight={700}
                lineHeight={1}
                m={0}
                pt={1}
                mb={-1}
            >
                {t.summary}
            </Typography>
            <PackageSummary ticketOptionsWithTours={ticketOptionsWithSelectedTours} />
        </Stack>
    );
}

export function getFirstAvailableProducts(
    item: CartItem,
    products: ProductInstance[],
): ProductInstance[] {
    if (!item.pkg) return [];
    const { pkg } = item.pkg;
    const packageProductsByTicketOptionId = mapValues(
        groupBy(pkg.products, (p) => p.pkgTicketOptionId),
        (ps, i) =>
            products.reduce((acc: ProductInstance[], cur) => {
                const newProducts = ps
                    .filter(
                        (p) =>
                            p.id === cur.product?.id &&
                            p.pkgProductId === cur.product?.pkgProductId,
                    )
                    .map((p) => ({ ...cur, product: p }));
                acc.push(...newProducts);
                return acc;
            }, []),
    );

    const sorted = mapValues(packageProductsByTicketOptionId, (ps) =>
        ps.sort((a, b) =>
            tzdate(a.start).isSame(tzdate(b.start))
                ? 0
                : tzdate(a.start).isBefore(tzdate(b.start))
                ? -1
                : 1,
        ),
    );

    const prods = mapValues(sorted, (p) => {
        return p.reduce((acc, cur) => {
            if (
                !!cur.product?.pkgProductId &&
                !acc.find((x) => x.product?.pkgProductId === cur.product?.pkgProductId) &&
                (item.ticketOptions.find((to) => to.id === cur.product?.pkgTicketOptionId)
                    ?.quantity ?? 0) > 0
            ) {
                acc.push(cur);
            }
            return acc;
        }, [] as ProductInstance[]);
    });

    return Object.values(prods).flat() as ProductInstance[];
}
