import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { cloneDeep } from 'lodash-es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import enGB from 'dayjs/locale/en-gb';
import nb from 'dayjs/locale/nb';
import de from 'dayjs/locale/de';
import nl from 'dayjs/locale/nl';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import enCA from 'dayjs/locale/en-ca';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

/**
 * A class that wraps the dayjs library to make handling timezones for the widgets easier.
 *
 * When using dates in this project, you should always use TZDate instead of Dayjs.
 * This way we can ensure that we are always working in the timezone of the Bilberry instance, instead of in the user's timezone.
 * TZDate is a wrapper around Dayjs, so everything in Dayjs should work as expected with the same API.
 *
 * The concept here is simple, but make sure you follow it when extending functionality:
 * 1. When working with dates, we are in the timezone of the Bilberry instance. Dates are moved to the Bilberry instance's timezone when created, keeping the local time.
 * 2. All dates loaded from the server is already in the instances's timezone, and should not be moved, so make sure to use `keepLocalTime = false` when creating these dates.
 * 3. Some dates, such as expiry of value cards, gift cards etc. should be showing in the user's timezone, so use `keepLocalTime = false` here as well.
 *
 * One caveat to using TZDate is that before using it with MUI calendars, you must extract the internal Dayjs date object instead.
 * Do this by calling TZDate.getDateForCalendar(). This moves returns the Dayjs object in the timezone of the user, so that
 * the date is what the calendar expects it to be (since the calendar is in the user's timezone). This is safe to do without memoizing.
 *
 * @export
 * @class TZDate
 */
export class TZDate {
    // Date in the Bilberry instance's timezone
    private _date: Dayjs;
    // Date in the user's timezone
    private _dateInCalendarTZ: Dayjs;

    // Locale for formatting of the date. If overriding the locale of the application, remember to set this as well.
    public static locale = 'en';
    // Timezone for the date. If overriding the timezone of the application, remember to set this as well.
    public static timezone = 'UTC';

    constructor(date?: Parameters<typeof dayjs>[0] | TZDate, keepLocalTime = true) {
        // If the constructor argument is a TZDate, clone the Dayjs date into _date.
        if (date instanceof TZDate) {
            this._date = cloneDeep(date._date);
        } else if (date && isDayjs(date)) {
            this._date = cloneDeep(date).tz(TZDate.timezone, keepLocalTime);
        } else {
            // Move the date into the instance's timezone, and by default keep the local time.
            this._date = dayjs(date === null ? undefined : date).tz(TZDate.timezone, keepLocalTime);
        }

        // Move the date into that timezone and save it.
        const timestampWithoutTimezone = this._date.format('YYYY-MM-DDTHH:mm:ss.SSS');
        this._dateInCalendarTZ = dayjs(timestampWithoutTimezone);
    }

    public add(amount: number, unit?: Parameters<Dayjs['add']>[1]) {
        const newDate = new TZDate(this);
        newDate._date = newDate._date.add(amount, unit);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.add(amount, unit);
        return newDate;
    }

    public subtract(amount: number, unit?: Parameters<Dayjs['subtract']>[1]) {
        const newDate = new TZDate(this);
        newDate._date = newDate._date.subtract(amount, unit);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.subtract(amount, unit);
        return newDate;
    }

    public startOf(unit: Parameters<Dayjs['startOf']>[0]) {
        const newDate = new TZDate(this);
        newDate._date = newDate._date.startOf(unit);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.startOf(unit);
        return newDate;
    }
    public endOf(unit: Parameters<Dayjs['endOf']>[0]) {
        const newDate = new TZDate(this);
        newDate._date = newDate._date.endOf(unit);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.endOf(unit);
        return newDate;
    }

    public date(): number;
    public date(value: number): TZDate;
    public date(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.date();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.date(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.date(value);
        return newDate;
    }

    public month(): number;
    public month(value: number): TZDate;
    public month(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.month();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.month(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.month(value);
        return newDate;
    }

    public year(): number;
    public year(value: number): TZDate;
    public year(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.year();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.year(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.year(value);
        return newDate;
    }
    public hour(): number;
    public hour(value: number): TZDate;
    public hour(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.hour();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.hour(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.hour(value);
        return newDate;
    }

    public minute(): number;
    public minute(value: number): TZDate;
    public minute(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.minute();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.minute(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.minute(value);
        return newDate;
    }

    public second(): number;
    public second(value: number): TZDate;
    public second(value?: number): number | TZDate {
        if (value === null || value === undefined) return this._date.second();
        const newDate = new TZDate(this);
        newDate._date = newDate._date.second(value);
        newDate._dateInCalendarTZ = newDate._dateInCalendarTZ.second(value);
        return newDate;
    }

    public weekday(): number {
        return this._date.weekday();
    }

    public day(): number {
        return this._date.day();
    }

    public locale(): string;
    public locale(locale: string): TZDate;
    public locale(locale?: string): string | TZDate {
        if (locale === null || locale === undefined) return TZDate.locale;
        const newDate = new TZDate(this);
        TZDate.locale = locale;
        return newDate;
    }

    public diff(date?: TZDate | null, unit?: Parameters<Dayjs['diff']>[1]) {
        return this._date.diff(date?._date, unit);
    }

    public isBefore(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isBefore']>[1],
    ) {
        if (date === null && date === undefined) return this._date.isBefore();
        return this._date.isBefore(date instanceof TZDate ? date._date : date, unit);
    }

    public isAfter(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isAfter']>[1],
    ) {
        if (date === null && date === undefined) return this._date.isAfter();
        return this._date.isAfter(date instanceof TZDate ? date._date : date, unit);
    }

    public isSame(
        date?: TZDate | Date | number | string | null,
        unit?: Parameters<Dayjs['isSame']>[1],
    ) {
        if (date === null && date === undefined) return true;
        return this._date.isSame(date instanceof TZDate ? date._date : date, unit);
    }

    public unix() {
        return this._date.unix();
    }

    /**
     * Format a date in the timezone of the Bilberry instance
     *
     * @param {string} [fmt] Identical to formats in Dayjs
     * @return {string} The formatted date string
     * @memberof TZDate
     */
    public format(fmt?: string) {
        const locale = getTZDateLocale(TZDate.locale);
        return this._date.locale(locale).format(fmt);
    }

    /**
     * Format the date in the timezone of the user.
     *
     * @param {string} [fmt] Identical to formats in Dayjs
     * @return {string} The formatted date string
     * @memberof TZDate
     */
    public formatInCalendarTimezone(fmt?: string) {
        const locale = getTZDateLocale(TZDate.locale);
        return this._dateInCalendarTZ.locale(locale).format(fmt);
    }

    /**
     * Get the internal Dayjs date in the timezone of the Bilberry instance
     *
     * @return {Dayjs} The internal Dayjs date
     * @memberof TZDate
     */
    public getDayjsDate() {
        return this._date;
    }

    /**
     * Get the internal Dayjs date in the timezone of the user
     *
     * @return {Dayjs} The internal Dayjs date
     * @memberof TZDate
     */
    public getDayjsDateInCalendarTZ() {
        return this._dateInCalendarTZ;
    }

    /**
     * Formats the date so it can be stored in localstorage and similar,
     * and recreated by providing the string to the tzdate function.
     *
     * @return {string} The formatted date string
     * @memberof TZDate
     */
    public toStringForLocalStorage() {
        return this._dateInCalendarTZ.toISOString();
    }

    public toString() {
        return this._date.toString();
    }

    public toISOString() {
        return this._date.toISOString();
    }

    public toDate() {
        return this._date.toDate();
    }

    public valueOf() {
        return this._date.valueOf();
    }

    public isValid() {
        return this._date.isValid();
    }

    /**
     * Returns a TZDate representing the current moment in time.
     * The timezone is moved to that of the Bilberry instance, but localtime is not kept.
     *
     * Use this to provide minimum dates / validations relevant for checking the current moment etc.
     *
     * @static
     * @return {TZDate} A TZDate representing right now.
     * @memberof TZDate
     */
    public static now() {
        return new TZDate(null, false);
    }
}

/**
 * Creates a new instance of TZDate. This function is identical to the `dayjs` function.
 *
 * @export
 * @param {(Parameters<typeof dayjs>[0] | TZDate)} [arg]
 * @param {boolean} [keepLocalTime=true]
 * @return {*}
 */
export function tzdate(arg?: Parameters<typeof dayjs>[0] | TZDate, keepLocalTime = true) {
    return new TZDate(arg, keepLocalTime);
}

function getTZDateLocale(locale: string) {
    const norwegianLocales = ['nb-NO', 'nn-NO', 'no-NO', 'no', 'nb', 'nn'];

    if (locale === 'en-US') {
        return 'en';
    } else if (locale === 'en-CA') {
        return enCA;
    } else if (norwegianLocales.includes(locale)) {
        return nb;
    } else if (locale === 'de') {
        return de;
    } else if (locale === 'nl') {
        return nl;
    } else if (locale === 'es') {
        return es;
    } else if (locale === 'fr') {
        return fr;
    } else if (locale === 'it') {
        return it;
    }

    return enGB;
}
