import { ProductSearch } from '@repo/types';
import { VERSION } from '@repo/widget-env/__autogen/env';
import { atom } from 'ximple/atoms';
import { LocalStorageKey } from '../localstorage';
import { deserialize, serialize } from '@repo/common-utils/serialize';

export const searchFormDataAtom = atom<ProductSearch | null>({
    persistKey: LocalStorageKey.BILBERRY_SEARCH_FORM_DATA,
    appVersion: VERSION,
    initialValue: null,
    transformOnSerialize: serialize,
    transformOnDeserialize: deserialize,
});
