import { debugLog } from '@repo/common-utils/Logger';
import { getStep } from './checkout-step';
import {
    AddToCartEvent,
    EventProduct,
    RemoveFromCartEvent,
    TotalPrice,
    Currency,
    StartCheckoutEvent,
    PaymentInfoAddedEvent,
    BookEvent,
    BilberryWidgetEvent,
    ViewItemEvent,
    CheckoutStepEvent,
    AuthenticationEvent,
} from './events.types';

declare global {
    interface Window {
        dataLayer: any; // Google Tag Manager
        gtag: any;
    }
}

const mapPrice =
    (product: EventProduct, currency: Currency, promoCode?: string) => (price: TotalPrice) => {
        const { priceType, numberOfTravelers, totalIncVat } = price;
        return {
            item_id: product.productId.toString(),
            item_name: product.productTitle,
            item_category: priceType,
            price: totalIncVat / numberOfTravelers,
            quantity: numberOfTravelers,
            currency,
            coupon: promoCode,
        };
    };

export function addToCart(event: AddToCartEvent) {
    return {
        items: event.products
            .map((product) => product.prices.map(mapPrice(product.product, product.currency)))
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function removeFromCart(event: RemoveFromCartEvent) {
    return {
        items: event.products
            .map((product) => product.prices.map(mapPrice(product.product, product.currency)))
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function startCheckout(event: StartCheckoutEvent) {
    return {
        items: event.products
            .map(({ product, prices }) => {
                return prices.map(mapPrice(product, event.currency));
            })
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function paymentInfoAdded(event: PaymentInfoAddedEvent) {
    return {
        items: event.products
            .map(({ product, prices }) => {
                return prices.map(mapPrice(product, event.currency, event.promoCode?.code));
            })
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
        coupon: event.promoCode,
    };
}

export function book(event: BookEvent) {
    return {
        items: event.products
            .map(({ product, prices }) =>
                prices.map(mapPrice(product, event.currency, event.promoCode?.code)),
            )
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
        transaction_id: event.reference,
        coupon: event.promoCode,
    };
}

export function viewItem(event: ViewItemEvent) {
    return {
        items: event.products
            .map(({ product, prices }) => prices.map(mapPrice(product, event.currency)))
            .flat(),
        value: event.priceIncVat,
        currency: event.currency,
    };
}

export function checkoutStep(event: CheckoutStepEvent) {
    return {
        checkout_step: getStep(event.checkoutStep),
        checkout_step_title: event.checkoutStep,
    };
}

export function login(_event: AuthenticationEvent) {
    return {
        method: 'Bilberry',
    };
}

function subscriber(
    event: BilberryWidgetEvent,
    fn: (
        type: 'event',
        event:
            | 'add_to_cart'
            | 'remove_from_cart'
            | 'begin_checkout'
            | 'add_payment_info'
            | 'purchase'
            | 'view_item'
            | 'set_checkout_step'
            | 'login',
        data: any,
    ) => void,
) {
    debugLog('GA4 custom event triggered with data: ', event);

    // Handle event based on type
    switch (event.type) {
        case 'addToCart':
            return fn('event', 'add_to_cart', addToCart(event));
        case 'removeFromCart':
            return fn('event', 'remove_from_cart', removeFromCart(event));
        case 'startCheckout':
            return fn('event', 'begin_checkout', startCheckout(event));
        case 'paymentInfoAdded':
            return fn('event', 'add_payment_info', paymentInfoAdded(event));
        case 'book':
            return fn('event', 'purchase', book(event));
        case 'viewItem':
            return fn('event', 'view_item', viewItem(event));
        case 'checkoutStep':
            return fn('event', 'set_checkout_step', checkoutStep(event));
        case 'login':
            return fn('event', 'login', login(event));
    }
}

export function subscriberGtag(event: BilberryWidgetEvent) {
    subscriber(event, window.gtag);
}

// Compatibility with the old GTM event types from <= v3.6.1 (of the widgets).
// Note that the object shape has changed.
const gtmEvents: any = {
    add_to_cart: 'addToCart',
    remove_from_cart: 'removeFromCart',
    begin_checkout: 'checkout',
    purchase: 'purchase',
    view_item: 'productClick',
    set_checkout_step: 'checkoutOption',
    login: 'login',
};

export function subscriberGTM(event: BilberryWidgetEvent) {
    subscriber(event, (type: string, event: string, data: any) => {
        window.dataLayer = window.dataLayer ?? [];
        if (gtmEvents[event]) {
            window.dataLayer.push({ event: gtmEvents[event], ecommerce: data });
        }
    });
}
