import { Box, CircularProgress, useTheme } from '@mui/material';

import { useMemo, useEffect, useState } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { getSessions } from '@repo/widget-utils/traversals/timeslots';
import { ProductInstance } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { timeslotsContainerStyles } from '../../booking-styles/BookingStyles';
import { useAvailableTimeslots } from '@repo/widget-utils/api/api';
import BookingSessionSelector from './booking-session-length/booking-session-length-buttons/BookingSessionSelector';
import TimeslotButtonGroup from 'src/components/domain/timeslots-timeslot-button-group/TimeslotButtonGroup';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import { atom, useAtom } from 'ximple';

export const showSessionSelectorAtom = atom({ initialValue: false });

type Props = {
    productInstance?: ProductInstance;
    selectedTimeslot?: TimeSlotType;
    onSelectTimeslot: (timeslot?: TimeSlotType) => void;
    participants: number;
};

export default function BookingTimeslots({
    productInstance,
    onSelectTimeslot,
    selectedTimeslot,
    participants,
}: Props): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();
    const [showSessionSelector] = useAtom(showSessionSelectorAtom);

    const [timeslotsInSession, setTimeslotsInSession] = useState(
        productInstance?.minProductsRequiredToBook ?? 1,
    );
    const { data: timeslots, isLoading } = useAvailableTimeslots(productInstance);
    const sessions = useMemo(
        () => getSessions(timeslots ?? [], timeslotsInSession),
        [timeslots, timeslotsInSession],
    );

    useEffect(() => {
        if (selectedTimeslot?.label === '00:00' && sessions?.length > 0) {
            const firstAvailableSession = sessions.find((session, i) => {
                const finalTimeslot = session[session.length - 1];
                const isFinalSessionToday =
                    i === sessions.length - 1 && finalTimeslot.start.isSame(new Date(), 'day');

                const disabled = session.some(
                    (s) =>
                        (s.capacity < participants ||
                            s.closed ||
                            s.start.subtract(s.cutoffTime, 'minutes').isBefore(Date.now())) &&
                        !isFinalSessionToday,
                );
                return !disabled;
            });

            if (firstAvailableSession) {
                const [firstTimeslot] = firstAvailableSession;
                onSelectTimeslot({
                    label: '',
                    product: firstTimeslot.productInstance,
                    products: firstAvailableSession,
                });
            }
        }
    }, [sessions, selectedTimeslot?.label, onSelectTimeslot, participants]);

    return (
        <Layout flexDirection="column" justify="space-between" sx={{ height: '100%' }}>
            {showSessionSelector && !isLoading && sessions && timeslots && sessions.length > 0 && (
                <BookingSessionSelector
                    duration={timeslots[0].duration}
                    minBookingQuantity={productInstance?.minProductsRequiredToBook ?? 1}
                    setTimeslotsInSession={setTimeslotsInSession}
                />
            )}

            {!isLoading && !(sessions && sessions.length > 0) && (
                <Text sx={{ marginTop: theme.spacing(2), textAlign: 'center' }}>
                    {t.noAvailableTimeslots}
                </Text>
            )}

            <Box sx={timeslotsContainerStyles(theme)}>
                {!isLoading && sessions && timeslots && sessions.length > 0 && (
                    <TimeslotButtonGroup
                        cols={4}
                        sessions={sessions}
                        timeslotsInSession={timeslotsInSession}
                        borderColor={customizations.bookingWidgetColor}
                        selectedTimeslot={selectedTimeslot}
                        onSelectTimeslot={onSelectTimeslot}
                        participants={participants ?? 1}
                    />
                )}
                {isLoading && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            margin: 'auto',
                        }}
                        color="primary"
                    />
                )}
            </Box>
        </Layout>
    );
}
