import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { useSmartEvent } from '@repo/widget-utils/api/api';

export default function SmartEventDetails(props: { id: number }): JSX.Element {
    const theme = useTheme();
    const { event, isLoading } = useSmartEvent(props.id);

    return (
        <Box>
            <Box
                sx={{
                    height: '55vh',
                    width: '100%',
                    overflow: 'hidden',
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                }}
            >
                {isLoading ? (
                    <Skeleton variant="rectangular" />
                ) : (
                    <img src={event?.media.image?.url} width="100%" height="100%" />
                )}
            </Box>
            <Box mx="auto" mt={3}>
                <Typography variant="h2" component="h1" sx={{ mb: theme.spacing(1) }}>
                    {event?.title ?? ''}
                </Typography>
                <Typography variant="h4" component="h2" sx={{ mb: theme.spacing(5) }}>
                    {event?.subline ?? ''}
                </Typography>
                <Box
                    p={theme.spacing(0, 2, 4, 0)}
                    sx={{
                        [theme.breakpoints.down('lg')]: {
                            padding: theme.spacing(0, 0, 4, 0),
                        },
                    }}
                >
                    {parseHtmlToMui(event?.body ?? '')}
                </Box>
            </Box>
        </Box>
    );
}
