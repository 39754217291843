export function getTabIndex(
    tabType: 'package' | 'extras' | 'contactinfo' | 'intent' | 'payment' | 'relatedproducts',
    opts: Partial<{
        hasPackage: boolean;
        hasExtras: boolean;
        hasRelatedProducts: boolean;
        display: boolean;
        isUsingPaymentPlan: boolean;
    }>,
) {
    const format = (n: number) => {
        if (opts.display) {
            return n + 1;
        } else {
            return n;
        }
    };

    let tabs = ['package', 'relatedproducts', 'extras', 'contactinfo', 'intent', 'payment'];
    if (!opts.hasPackage) tabs = tabs.filter((t) => t !== 'package');
    if (!opts.hasExtras) tabs = tabs.filter((t) => t !== 'extras');
    if (!opts.hasRelatedProducts) tabs = tabs.filter((t) => t !== 'relatedproducts');
    if (!opts.isUsingPaymentPlan) tabs = tabs.filter((t) => t !== 'intent');

    return format(tabs.indexOf(tabType));
}
