import produce from 'immer';
import { getCompanyConfig } from '@repo/widget-utils/api/membership-api';
import { Company, MembershipSite, MembershipValueCardProduct } from '@repo/types';
import { atom } from 'ximple';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { TZDate } from '@repo/tzdate';
import { showError } from 'src/utils/widget/error-handling';

type Actions = { type: 'UPDATE_COMPANY'; value: Company };

export const companyReducer = produce((_draft: Partial<Company>, action: Actions) => {
    const config = configurationAtom.subject.value;

    switch (action.type) {
        case 'UPDATE_COMPANY':
            const currentSite =
                action.value.sites.find((site: MembershipSite) => site.key === config.siteKey) ??
                undefined;

            action.value.company.valueCardProducts = findValueCardProductsAvailableForPurchase(
                action.value.company.valueCardProducts,
                config.siteKey,
            );

            return { ...action.value, currentSite } as Company;
        default:
            break;
    }
});

export const companyAtom = atom<Partial<Company>, Actions>({
    initialValue: {
        company: undefined,
        sites: [],
        currentSite: undefined,
    },
    update: companyReducer,
});

export async function configureCompany() {
    const config = configurationAtom.subject.value;
    if (!config.companyKey || !config.siteKey) return;
    try {
        const company = await getCompanyConfig(config.companyKey ?? '', config.siteKey ?? '');
        if (!company) return;
        companyAtom.update({ type: 'UPDATE_COMPANY', value: company });
    } catch (e: any) {
        showError(e.toString());
    }
}

export function isCompany(partial: Partial<Company>): partial is Company {
    return !!partial.company && !!partial.currentSite;
}

function checkIfSiteIsAllowed(valueCardProduct: MembershipValueCardProduct, siteKey: string) {
    return (
        valueCardProduct.configuration?.restrictedToSiteKeys.length === 0 ||
        valueCardProduct.configuration?.restrictedToSiteKeys.includes(siteKey)
    );
}

function checkIfSiteIsAllowedForPurchase(
    valueCardProduct: MembershipValueCardProduct,
    siteKey: string,
) {
    return (
        valueCardProduct.configuration?.purchaseRestrictedToSiteKeys.length === 0 ||
        valueCardProduct.configuration?.purchaseRestrictedToSiteKeys.includes(siteKey)
    );
}

function checkIfAvailableForPurchaseNow(availableFrom: string | null, availableTo: string | null) {
    const now = TZDate.now();

    if (availableFrom && now.isBefore(availableFrom)) return false;
    if (availableTo && now.isAfter(availableTo)) return false;

    return true;
}

function findValueCardProductsAvailableForPurchase(
    valueCardProducts?: MembershipValueCardProduct[],
    companyKey?: string,
) {
    if (!companyKey || !valueCardProducts) return [];

    return valueCardProducts.filter((v) => {
        const isSiteAllowed = checkIfSiteIsAllowed(v, companyKey);
        const isSiteAllowedForPurchase = checkIfSiteIsAllowedForPurchase(v, companyKey);
        const isAvailableForPurchaseNow = checkIfAvailableForPurchaseNow(
            v.availableFrom,
            v.availableTo,
        );

        return isSiteAllowed && isSiteAllowedForPurchase && isAvailableForPurchaseNow;
    });
}
