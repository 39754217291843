import { debounce } from 'lodash-es';
import { BUILD_VERSION, VERSION } from '@repo/widget-env/__autogen/env';
import { debugLog } from '@repo/common-utils/Logger';
import { customizationAtom } from 'src/components/utils/theme/customizations';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { atom } from 'ximple/atoms';

const INGEST_URL =
    (BUILD_VERSION as string) === 'production'
        ? 'https://widget-metrics-ingest-api-prod-hw4q7.ondigitalocean.app/ingest'
        : 'https://widget-metrics-ingest-api-dev-jmiz3.ondigitalocean.app/ingest';

type WidgetUsage = {
    name: string;
    attributes: Array<[string, string | null]>;
};

const queue: WidgetUsage[] = [];

const widgetUsageAtom = atom({
    initialValue: {
        usages: [] as WidgetUsage[],
        isReporting: false,
        lastReportedConfigTimeStamp: null as null | number,
    },
    persistKey: getPersistKey(),
});

// Initialize lastReport
try {
    const lastReport = sessionStorage.getItem('lastReport' + getPersistKey());
    if (!lastReport) {
        sessionStorage.setItem('lastReport' + getPersistKey(), Date.now().toString());
    }
} catch (error: any) {
    debugLog(error);
}

const updateAtom = debounce(async () => {
    try {
        const consumedQueue = queue.splice(0, queue.length);
        await widgetUsageAtom.update({
            ...widgetUsageAtom.subject.value,
            usages: [...widgetUsageAtom.subject.value.usages, ...consumedQueue],
        });

        const lastReport = parseInt(sessionStorage.getItem('lastReport' + getPersistKey()) ?? '0');

        if (Date.now() - lastReport > 5000 && !widgetUsageAtom.subject.value.isReporting) {
            report();
        } else {
            setTimeout(report, 5000);
        }
    } catch (error: any) {
        debugLog(error);
    }
}, 100);

export async function registerWidgetUsage(element: HTMLElement) {
    try {
        const name = element.tagName.toLowerCase();
        const attributes = getAllAttributes(element);

        queue.push({ name, attributes: attributes.filter(([, value]) => value !== null) });
        updateAtom();
    } catch (error: any) {
        debugLog(error);
    }
}

async function report() {
    let reportedConfig = false;
    try {
        const { usages } = widgetUsageAtom.subject.value;
        widgetUsageAtom.subject.next({
            ...widgetUsageAtom.subject.value,
            usages: [],
            isReporting: true,
        });
        sessionStorage.setItem('lastReport' + getPersistKey(), Date.now().toString());
        const usagesByWidget = usages.reduce(
            (acc, { name, attributes }) => {
                if (!acc[name]) {
                    acc[name] = {
                        value: 0,
                        attributes: [],
                    };
                }
                acc[name].value = acc[name].value + 1;
                // Add attributes of the usage to accumulator, but ensure there are no duplicates.
                // A duplicates is when all elements of an attribute is equal to another
                acc[name].attributes = attributes.reduce((acc, current) => {
                    if (acc.some((attr) => isAttributeEqual(attr, current))) {
                        return acc;
                    }
                    acc.push(current);
                    return acc;
                }, acc[name].attributes);
                return acc;
            },
            {} as Record<
                string,
                {
                    value: number;
                    attributes: Array<[string, string | null]>;
                }
            >,
        );

        const version = VERSION;
        const configuration = configurationAtom.subject.value;

        const data: any = {
            version,
            url: window.location.origin,
            instance: configuration.bilberryBaseApiUrl,
            widgetUsages: usagesByWidget,
        };

        // Only report configuration 20% of the time, and only once a day
        const shouldReportConfig =
            (!widgetUsageAtom.subject.value.lastReportedConfigTimeStamp ||
                Date.now() - widgetUsageAtom.subject.value.lastReportedConfigTimeStamp >
                    1000 * 60 * 60 * 24) &&
            Math.random() > 0.8;

        if (shouldReportConfig) {
            const theme = customizationAtom.subject.value;
            data.configuration = configuration;
            data.theme = theme;
            data.textCustomizations = configuration.textCustomizations;
        }

        const hasReportedLoadThisSession = sessionStorage.getItem(
            'hasReportedLoadThisSession' + getPersistKey(),
        );

        if (!hasReportedLoadThisSession) {
            const scripts = Array.from(document.getElementsByTagName('script'))
                .map((script) => script.src)
                .filter((script) => script?.includes('bilberry-widgets.b-cdn.net'));
            data.widgetScripts = scripts;
        }

        if (!shouldReportConfig && hasReportedLoadThisSession && usages.length === 0) {
            widgetUsageAtom.subject.next({
                ...widgetUsageAtom.subject.value,
                isReporting: false,
            });
            return;
        }

        await fetch(INGEST_URL, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        sessionStorage.setItem('hasReportedLoadThisSession' + getPersistKey(), 'true');
        reportedConfig = shouldReportConfig;
    } catch (error: any) {
        debugLog(error);
    }

    widgetUsageAtom.subject.next({
        ...widgetUsageAtom.subject.value,
        isReporting: false,
        lastReportedConfigTimeStamp: reportedConfig
            ? Date.now()
            : widgetUsageAtom.subject.value.lastReportedConfigTimeStamp,
    });
}

function isAttributeEqual(a: [string, string | null], b: [string, string | null]): boolean {
    return a.every((attr, index) => attr === b[index]);
}

export function getAllAttributes(element: HTMLElement): Array<[string, string | null]> {
    return Array.from(element.attributes).map((attr) => [attr.name, attr.value]);
}

// Sites can have multiple configurations, so we need to persist the widget config based on a few key differentiators
function getPersistKey() {
    return (
        'no.bilberry.config-' +
        configurationAtom.subject.value.bilberryBaseApiUrl +
        configurationAtom.subject.value.companyKey +
        configurationAtom.subject.value.siteKey +
        configurationAtom.subject.value.disableMembershipBooking
    );
}
