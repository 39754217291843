import { Box, SxProps, TextField, TextFieldProps, useTheme } from '@mui/material';
import {
    DateRange,
    DateRangePickerDay,
    DateRangePickerDayProps,
    LocalizationProvider,
    StaticDateRangePicker as DateRangePicker,
    DateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tzdate, TZDate } from '@repo/tzdate';
import { Fragment, useCallback, useMemo } from 'react';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { Dayjs } from 'dayjs';
import { Translations } from '@repo/types';

interface IProps {
    dateRange: DateRange<TZDate>;
    onChange: (newDateRange: DateRange<TZDate>) => void;
    date?: string;
    minDate?: TZDate;
    maxDate?: TZDate;
    isDateUnavailable?: (date: string) => boolean;
    onMonthChange?: (date: TZDate | null) => void;
    loading?: boolean;
    dateRangeVariant?: 'days' | 'nights';
    renderCustomDay?: (date: TZDate) => JSX.Element;
    customDaySx?: SxProps;
}

function allDatesBetweenAreAvailable(
    day1: TZDate,
    day2: TZDate,
    isDateUnavailable?: (date: string) => boolean,
) {
    let day = tzdate(day1);
    if (!isDateUnavailable) return true;

    while (day.isBefore(day2)) {
        if (isDateUnavailable(day.format('YYYY-MM-DD'))) return false;
        day = day.add(1, 'days');
    }

    return true;
}

const DateRangeComponentOverrides = {
    ActionBar: () => null,
};

export default function StaticDateRangePicker(
    props: IProps & Partial<DateRangePickerProps<TZDate, TZDate>>,
): JSX.Element {
    const {
        date,
        dateRange,
        onChange,
        minDate,
        maxDate,
        isDateUnavailable,
        onMonthChange,
        loading,
        dateRangeVariant = 'days',
        renderCustomDay,
        customDaySx,
        ...rest
    } = props;

    const { t, dayjsLocale } = useLocale();

    const shouldDisableDate = useCallback(
        (d: Dayjs) => {
            const date = tzdate(d);
            if (date.isSame(dateRange[0], 'day')) {
                return true;
            } else if (isDateUnavailable) {
                if (isDateUnavailable && (dateRange[1] !== null || dateRange[0] === null)) {
                    return isDateUnavailable(date.format('YYYY-MM-DD'));
                } else {
                    if (date?.isBefore(dateRange[0])) {
                        return isDateUnavailable(date.format('YYYY-MM-DD'));
                    } else if (dateRange[0] && date) {
                        return !allDatesBetweenAreAvailable(dateRange[0], date, isDateUnavailable);
                    }
                }
            }
            return false;
        },
        [dateRange, isDateUnavailable],
    );

    const defaultMonth = useMemo(
        () => (minDate && minDate.isAfter(tzdate(date)) ? minDate : tzdate(date)),
        [date, minDate],
    );
    const selectedDatesInUserTimezone = useMemo(() => {
        return dateRange.map((date) => {
            if (date) {
                return date.getDayjsDateInCalendarTZ();
            }
            return null;
        }) as DateRange<Dayjs | null>;
    }, [dateRange]);

    const onChangeDateRange = useCallback(
        (range: DateRange<Dayjs>) => {
            onChange([
                range[0] ? tzdate(range[0]) : null,
                range[1] ? tzdate(range[1]) : null,
            ] as DateRange<TZDate>);
        },
        [onChange],
    );

    const renderDay = useCallback(
        (date: Dayjs, dateRangePickerDayProps: DateRangePickerDayProps<Dayjs>) =>
            getRenderDay(
                dateRangePickerDayProps,
                isDateUnavailable,
                tzdate(date),
                dateRangeVariant,
                renderCustomDay,
                customDaySx,
            ),
        [isDateUnavailable, dateRangeVariant, renderCustomDay, customDaySx],
    );

    const renderInput = useCallback(
        (startProps: TextFieldProps, endProps: TextFieldProps) =>
            getRenderInput(startProps, endProps, t),
        [t],
    );

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={dayjsLocale}
            localeText={{
                start: capitalize(t.date_range_start),
                end: capitalize(t.date_range_end),
            }}
        >
            <DateRangePicker
                {...(rest as DateRangePickerProps<Dayjs, Dayjs>)}
                value={selectedDatesInUserTimezone}
                disablePast={!minDate}
                autoFocus={true}
                onChange={onChangeDateRange}
                minDate={minDate?.getDayjsDateInCalendarTZ()}
                maxDate={maxDate?.getDayjsDateInCalendarTZ()}
                defaultCalendarMonth={defaultMonth.getDayjsDateInCalendarTZ()}
                renderDay={renderDay}
                renderInput={renderInput}
                toolbarTitle=""
                disableHighlightToday={true}
                inputFormat="DD.MM.YYYY"
                mask="__.__.____"
                shouldDisableDate={shouldDisableDate}
                onMonthChange={(d: Dayjs) => onMonthChange?.(tzdate(d))}
                loading={loading}
                components={DateRangeComponentOverrides}
            />
        </LocalizationProvider>
    );
}

function getRenderInput(startProps: TextFieldProps, endProps: TextFieldProps, t: Translations) {
    return (
        <Fragment>
            <TextField
                {...startProps}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Box sx={{ mx: 2 }}> {t.to} </Box>
            <TextField
                {...endProps}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Fragment>
    );
}

function getRenderDay(
    { key, ...dateRangePickerDayProps }: DateRangePickerDayProps<Dayjs>,
    isDateUnavailable: ((date: string) => boolean) | undefined,
    date: TZDate,
    dateRangeVariant: 'days' | 'nights',
    renderCustomDay?: (date: TZDate) => JSX.Element,
    customDaySx?: SxProps,
) {
    if (
        dateRangePickerDayProps.selected ||
        dateRangePickerDayProps.isPreviewing ||
        dateRangePickerDayProps.outsideCurrentMonth ||
        dateRangePickerDayProps.isHighlighting || //highligted
        !isDateUnavailable || // no availabilityFunction
        (!isDateUnavailable(date.format('YYYY-MM-DD')) &&
            !isDateUnavailable(date.subtract(1, 'day').format('YYYY-MM-DD'))) ||
        dateRangeVariant === 'days'
    ) {
        if (renderCustomDay) {
            return (
                <DateRangePickerDay
                    tabIndex={0}
                    key={key}
                    {...dateRangePickerDayProps}
                    sx={customDaySx}
                >
                    {renderCustomDay(date)}
                </DateRangePickerDay>
            );
        }
        return <DateRangePickerDay tabIndex={0} key={key} {...dateRangePickerDayProps} />;
    } else {
        return (
            <DateRangePickerDay
                tabIndex={0}
                key={key}
                {...dateRangePickerDayProps}
                sx={{
                    position: 'relative',
                    '&:hover, &:focus, &:active': {
                        '& .bilberry-period-unavailable-edge': {
                            background: 'unset',
                        },
                    },
                    ...(customDaySx || {}),
                }}
            >
                {!isDateUnavailable(date.subtract(1, 'day').format('YYYY-MM-DD')) ? (
                    <StartOfUnavailablePeriod />
                ) : !isDateUnavailable(date.format('YYYY-MM-DD')) ? (
                    <EndOfUnavailablePeriod />
                ) : (
                    <UnavailablePeriod />
                )}
                {renderCustomDay
                    ? renderCustomDay(date)
                    : dateRangePickerDayProps.day.date().toString()}
            </DateRangePickerDay>
        );
    }
}

function StartOfUnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                width: '107%',
                height: '75%',
                top: '50%',
                transform: 'translateY(-50%) !important',
            }}
        >
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    ml: '25%',
                    width: '50%',
                    height: '100%',
                    background: `linear-gradient(to bottom right, transparent 50%,${theme.palette.grey[100]} 50%);`,
                    '&:hover,&focus,&active': {
                        background: 'unset',
                    },
                }}
            />
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    width: '25%',
                    height: '100%',
                    background: theme.palette.grey[100],
                    '&:hover,&focus,&active': {
                        background: 'unset',
                    },
                }}
            />
        </Box>
    );
}

function EndOfUnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                width: '107%',
                height: '75%',
                top: '50%',
                transform: 'translateY(-50%) !important',
            }}
        >
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    width: '25%',
                    height: '100%',
                    background: theme.palette.grey[100],
                }}
            />
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    mr: '25%',
                    width: '50%',
                    height: '100%',
                    background: `linear-gradient(to top left, transparent 50%,${theme.palette.grey[100]} 50%);`,
                }}
            />
        </Box>
    );
}

function UnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                background: theme.palette.grey[100],
                width: '107%',
                height: '75%',
                top: '50%',
                transform: 'translateY(-50%) !important',
            }}
        ></Box>
    );
}
