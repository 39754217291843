import { createContext, useState } from 'react';
import { cartAtom } from 'src/state/cart/cartAtom';
import { tryFindPaymentRedirectContext } from '@repo/widget-utils/payment-redirect-helper';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';
import { getTabIndex } from './views/getTabIndex';
import { useCartContext } from 'src/widgets/CartContext';

type ActiveTabContextType = {
    activeTab: Parameters<typeof getTabIndex>[0] | null;
    setActiveTab: (tab: Parameters<typeof getTabIndex>[0]) => void;
};
export const ActiveTabContext = createContext<ActiveTabContextType>({
    activeTab: null,
    setActiveTab: () => null,
});

export function ActiveTabContextProvider(props: { children: any }) {
    const config = useConfigurations();
    const { cartItems } = useCartContext();
    const isPackageInCart = Object.values(cartItems).some((item) => item.pkg);

    const paymentRedirectContext = tryFindPaymentRedirectContext();
    const initialActiveTab =
        paymentRedirectContext !== null
            ? 'payment'
            : isPackageInCart
            ? 'package'
            : config.enableRelatedProducts
            ? 'relatedproducts'
            : config.enableExtras
            ? 'extras'
            : 'contactinfo';

    const [activeTab, setActiveTab] = useState<Parameters<typeof getTabIndex>[0]>(initialActiveTab);

    return (
        <ActiveTabContext.Provider
            value={{
                activeTab,
                setActiveTab,
            }}
        >
            {props.children}
        </ActiveTabContext.Provider>
    );
}
