import { TZDate } from '@repo/tzdate';
import {
    CartItem,
    MembershipIntentMultiResponse,
    MembershipSite,
    MembershipUser,
    Package,
    Product,
    ProductInstance,
    TicketOptionWithQuantity,
    Timeslot,
} from '@repo/types';
import { getTicketOptionsWithPaymentPlans } from '../booking/createIntent';

export function getCartItemId(item: CartItem) {
    if (item.pkg) {
        return (
            (item.idPrefix ?? '') +
            (item.pkg?.pkg.id ?? '') +
            '-' +
            (item.pkg?.date?.format() ?? '')
        );
    }
    return (
        (item.idPrefix ?? '') +
        item.products[0]?.product?.id +
        '-' +
        item.products.map((x) => x.id).join('-')
    );
}

export function getCartItemSummaryDateInformation(cartItem: CartItem) {
    if (cartItem.pkg) {
        const [start, end] = getPackageDuration(
            cartItem.pkg.date,
            cartItem.pkg.pkg,
            cartItem.products,
        );
        return start.format('ll') + ' - ' + end.format('ll');
    } else if (cartItem.products.length > 1) {
        return (
            cartItem.products[0].start.format('ll') +
            ' - ' +
            cartItem.products[cartItem.products.length - 1].start.format('ll')
        );
    } else {
        if (cartItem.products[0].timeslots.length > 0) {
            return cartItem.products[0].timeslots[0].start.format('lll');
        }
        return cartItem.products[0].start.format('lll');
    }
}

export function getBookingClosedItems(cartItems: CartItem[]) {
    return cartItems.filter((item) =>
        item.products.some((product) => product.cutoffDate?.isBefore(TZDate.now())),
    );
}

export function getPackageDuration(
    date: TZDate,
    pkg: Package,
    selectedProducts?: ProductInstance[],
): [TZDate, TZDate] {
    if (selectedProducts) {
        const earliestStart = selectedProducts.reduce((acc, cur) => {
            return !acc || cur.start.isBefore(acc) ? cur.start : acc;
        }, null as null | TZDate);
        const latestEnd = selectedProducts.reduce((acc, cur) => {
            return !acc || cur.end.isAfter(acc) ? cur.end : acc;
        }, null as null | TZDate);

        if (earliestStart && latestEnd) {
            return [earliestStart, latestEnd];
        }
    }
    const start = Math.min(
        ...pkg.ticketOptions.flatMap((to) =>
            pkg.products
                .filter((p) => to.products.includes(p.id))
                .map((p) => p.pkgMinutesRelativeStart ?? 0),
        ),
    );
    const end = Math.max(
        ...pkg.ticketOptions.flatMap((to) =>
            pkg.products
                .filter((p) => to.products.includes(p.id))
                .map((p) => p.pkgMinutesRelativeEnd ?? 0),
        ),
    );

    return [date.add(start, 'minutes'), date.add(end, 'minutes')];
}

export function isActivityCartItem(item: CartItem): boolean {
    return !item.pkg && item.products.some(isActivityProductInstance);
}

export function isActivityProductInstance(productInstance: ProductInstance): boolean {
    return isActivityProduct(productInstance.product) && !isExtraProductInstance(productInstance);
}

export function isActivityProduct(product: Product | null): boolean {
    if (!product) return false;
    return ['days', 'timepoint'].includes(product.type);
}

export function isPackageCartItem(item: CartItem): boolean {
    return !!item.pkg;
}

export function isExtraCartItem(item: CartItem): boolean {
    return item.products.length > 0 && item.products.every(isExtraProductInstance);
}

export function isExtraProductInstance(productInstance: ProductInstance): boolean {
    return productInstance.isExtraProduct;
}

export function isTimeslotCartItem(item: CartItem): boolean {
    return item.products.some(isTimeslotProductInstance);
}

export function isTimeslotProductInstance(productInstance: ProductInstance): boolean {
    return isTimeslotProduct(productInstance.product);
}

export function isTimeslotProduct(product: Product | null): boolean {
    if (!product) return false;
    return product.type === 'timeslot';
}

export function isAccomodationOrNightsCartItem(item: CartItem): boolean {
    return !item.pkg && item.products.some(isAccomodationOrNightsProductInstance);
}

export function isAccomodationOrNightsProductInstance(productInstance: ProductInstance): boolean {
    return isAccomodationOrNightsProduct(productInstance.product);
}

export function isAccomodationOrNightsProduct(product: Product | null): boolean {
    if (!product) return false;
    return ['nights', 'accommodation'].includes(product.type);
}

export function isPureAccomodationProduct(product: Product | null): boolean {
    if (!product) return false;
    return product.type === 'accommodation';
}

export function productInstancesRequiresPaymentPlans(productInstances: ProductInstance[]): boolean {
    return productInstances.some(productInstanceRequiresPaymentPlans);
}

export function productInstanceRequiresPaymentPlans(productInstance: ProductInstance): boolean {
    return productRequiresPaymentPlans(productInstance.product);
}

export function productRequiresPaymentPlans(product: Product | null): boolean {
    if (!product) return false;

    return isTimeslotProduct(product);
}

export function productInstancesDisablePaymentPlans(
    productInstances: ProductInstance[],
    isPackageProduct: boolean,
    configDisableMembershipBooking?: boolean,
): boolean {
    return productInstances.some((productInstance) =>
        productInstanceDisablePaymentPlans(
            productInstance,
            isPackageProduct,
            configDisableMembershipBooking,
        ),
    );
}

export function productInstanceDisablePaymentPlans(
    productInstance: ProductInstance,
    isPackageProduct: boolean,
    configDisableMembershipBooking?: boolean,
): boolean {
    return productDisablePaymentPlans(
        productInstance.product,
        isPackageProduct,
        configDisableMembershipBooking,
    );
}

export function productDisablePaymentPlans(
    product: Product | null,
    isPackageProduct: boolean,
    configDisableMembershipBooking?: boolean,
): boolean {
    if (!product) return false;

    return (
        isPureAccomodationProduct(product) || isPackageProduct || !!configDisableMembershipBooking
    );
}

export function cartRequiresPaymentPlans(cartItems: CartItem[]) {
    return cartItems.some((item) => item.requiresPaymentPlans);
}

export function cartHasDisabledPaymentPlans(cartItems: CartItem[]) {
    return cartItems.some((item) => item.disablePaymentPlans);
}

export function shouldUsePaymentPlansForCart(
    cartItems: CartItem[],
    currentSite: MembershipSite | null,
    isCompanyContact: boolean,
): boolean {
    if (isCompanyContact) return false;
    if (cartItems.length === 0) return false;

    const isMembershipSite = !!currentSite;

    const requiresPaymentPlans = cartRequiresPaymentPlans(cartItems);
    const isMembershipSiteAndNothingPreventsUsFromUsingPaymentPlans =
        isMembershipSite && !cartHasDisabledPaymentPlans(cartItems);

    return requiresPaymentPlans || isMembershipSiteAndNothingPreventsUsFromUsingPaymentPlans;
}

export function createNewCartItem(
    selectedProducts: ProductInstance[],
    ticketOptions: TicketOptionWithQuantity[],
    disablePaymentPlans: boolean,
    requiresPaymentPlans: boolean,
    timeslots?: Timeslot[],
): CartItem {
    return {
        products: selectedProducts.map((p) => ({
            ...p,
            timeslots: timeslots ?? [],
        })),
        ticketOptions: ticketOptions,
        disablePaymentPlans: disablePaymentPlans,
        requiresPaymentPlans: requiresPaymentPlans,
    };
}

export function addMembershipToCartItems(
    cartItems: CartItem[],
    paymentPlanResponse: MembershipIntentMultiResponse,
): CartItem[] {
    return cartItems.map((item) => {
        const ticketOptionsWithPaymentPlans = getTicketOptionsWithPaymentPlans(
            item,
            paymentPlanResponse,
        );

        const newTicketOptions = ticketOptionsWithPaymentPlans.map(
            (ticketOptionWithPaymentPlans) => {
                return {
                    ...ticketOptionWithPaymentPlans.ticketOption,
                    membership: ticketOptionWithPaymentPlans.paymentPlans,
                } as TicketOptionWithQuantity;
            },
        );

        return {
            ...item,
            ticketOptions: newTicketOptions,
        };
    });
}

export function removeMembershipFromCartItems(cartItems: CartItem[]): CartItem[] {
    return cartItems.map((item) => {
        const newTicketOptions = item.ticketOptions.map((ticketOption) => {
            return {
                ...ticketOption,
                membership: undefined,
            } as TicketOptionWithQuantity;
        });

        return {
            ...item,
            ticketOptions: newTicketOptions,
        };
    });
}
