import { Stack, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import TimeSlots from 'src/components/domain/time-slot/TimeSlots';
import { useLocale } from '@repo/i18n';
import { ProductInstance } from '@repo/types';

type Props = {
    availableProducts: ProductInstance[];
    selectedProduct: ProductInstance;
    setSelectedProduct: (product: ProductInstance) => void;
};

export function TourSelectionBookingForm(props: Props) {
    const { t } = useLocale();
    const { availableProducts, selectedProduct, setSelectedProduct } = props;
    const productsByDay = useMemo(() => {
        return Object.values(groupBy(availableProducts, (p) => p.start.format('YYYY-MM-DD')));
    }, [availableProducts]);

    return (
        <Stack width="100%" gap={1}>
            {availableProducts.length > 1 && (
                <Typography variant="h5" fontWeight={700} fontSize={18} my={0}>
                    {t.select_time}
                </Typography>
            )}
            <Stack gap={2}>
                {productsByDay.map((products) => (
                    <Stack key={products.reduce((acc, p) => acc + p.id, '')} gap={1}>
                        <Typography
                            variant="h6"
                            borderBottom={1}
                            fontWeight={700}
                            fontSize={16}
                            my={0}
                        >
                            {products[0].start.format('LL')}
                        </Typography>
                        <TimeSlots
                            hideHeader
                            fullWidth
                            availabilityData={products}
                            selectedTimeSlot={
                                selectedProduct
                                    ? {
                                          label: selectedProduct.start.format('HH:mm'),
                                          product: selectedProduct,
                                      }
                                    : undefined
                            }
                            isTimeSlotsEqual={(ts1, ts2) =>
                                ts1.product.id === ts2.product.id &&
                                ts1.product.product?.pkgTicketOptionId ===
                                    ts2.product.product?.pkgTicketOptionId
                            }
                            onSelectTimeSlot={(t) => setSelectedProduct(t.product)}
                        />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}
