import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import StripePayment from 'src/components/domain/payment/StripePayment';
import { customizationAtom } from 'src/components/utils/theme/customizations';
import { MUITheme } from 'src/components/utils/theme/Theme';
import StripeAlertWidget from './StripeAlertWidget';
import StripePayButtonWidget from './StripePayButtonWidget';

StripePayButtonWidget();
StripeAlertWidget();

export function initStripePayment(parent: HTMLElement) {
    const stripeMountPoint = document.createElement('div');
    stripeMountPoint.className = 'bilberry-stripe-payment';
    stripeMountPoint.slot = 'stripe-payment-slot';

    const customizations = customizationAtom.subject.value;

    const cache = createCache({
        key: 'css',
        prepend: true,
        container: stripeMountPoint,
    });

    createRoot(stripeMountPoint).render(
        <CacheProvider value={cache}>
            <ThemeProvider theme={MUITheme(customizations)}>
                <StripePayment />
            </ThemeProvider>
        </CacheProvider>,
    );

    stripeMountPoint;

    parent.appendChild(stripeMountPoint);
}
