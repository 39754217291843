import { UpcomingToursBase, UpcomingToursProps } from './UpcomingToursBase';
import UpcomingTour from './Tour/UpcomingTour';

export function UpcomingTours(props: Omit<UpcomingToursProps, 'toursList'>) {
    return (
        <UpcomingToursBase
            {...props}
            toursList={(fewLeft, upcomingTours, showRemaining) => (
                <>
                    {upcomingTours.map((tour) => (
                        <UpcomingTour
                            key={tour.id}
                            tour={tour}
                            fewLeft={fewLeft}
                            showRemaining={showRemaining}
                        ></UpcomingTour>
                    ))}
                </>
            )}
        />
    );
}
