import {
    BASE_MEMBERSHIP_API_URL,
    SERVICE_PROXY_URL,
    USE_SERVICE_PROXY,
} from '@repo/widget-env/__autogen/env';
import { localeAtom } from '@repo/i18n';
import {
    Company,
    MembershipBooking,
    MembershipConsumer,
    MembershipIntentMultiResponse,
    MembershipMultiReservationTours,
    MembershipOrder,
    MembershipReserveResponse,
    MembershipUser,
    MembershipUserValueCard,
    MembershipValueCardProduct,
    TimeslotReservation,
    UpdateValueCardSubscriptionResponse,
} from '@repo/types';
import { getBilberryLanguageFromLocale } from './api-client-common';
import useSWR, { mutate } from 'swr';
import { BilberryApiError } from './BilberryApiError';

const API_URL = USE_SERVICE_PROXY ? SERVICE_PROXY_URL : BASE_MEMBERSHIP_API_URL;
const API_VERSION = 'v1';

const apiUrl = () => API_URL + '/' + API_VERSION;

// Before using the api client, make sure to initialize the token with the value from the authentication provider.
let token: string | null = null;
export function setMembershipApiToken(newToken: string | null) {
    token = newToken;
}

async function get<T = any>(url: string, errorMessage?: string): Promise<T> {
    const response = await fetch(url, {
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-type': 'application/json',
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error(errorMessage);

    return response.json();
}

async function post(url: string, body?: any, errorMessage?: string) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        const body = await response.json();
        throw new Error(errorMessage + '. Server message: ' + body.title);
    }
    try {
        return await response.json();
    } catch (error) {
        return null;
    }
}

async function put(url: string, body?: any, errorMessage?: string) {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error(errorMessage);
    return response.json();
}

async function del(url: string, body?: any, errorMessage?: string) {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const message = response.statusText;
        throw new BilberryApiError(message, response);
    }

    try {
        return await response.json();
    } catch (error) {
        return null;
    }
}

function getUrlWithParams(url: string, locale: string, queryParams: Record<string, any> = {}) {
    const langQueryParam = `lang=${getBilberryLanguageFromLocale(locale)}`.toLowerCase();

    let fullUrl = `${url}?${langQueryParam}`;

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString.length > 0) fullUrl += `&${queryString}`;
    return fullUrl;
}

export function updateUser(user: MembershipUser) {
    const { locale } = localeAtom.subject.value;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const url = apiUrl() + getUrlWithParams('/Users/me', locale, {});
    return mutate(url, putUser(user), true);
}

export async function getUser() {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    const response = await get<MembershipUser>(url);
    return response;
}

export async function putUser(
    consumer: Omit<MembershipUser, 'receiveNewsletter'>,
): Promise<MembershipUser> {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    return put(url, consumer);
}

export async function getCompanyConfig(key: string, siteKey: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Config/company/${key}/site/${siteKey}`, locale, {});

    const response = await get<Company>(
        url,
        localeAtom.subject.value.t.couldntGetCompanyInformation,
    );
    return response;
}

export type MembershipIntentMultiRequest = {
    consumer: any;
    timeslotReservations: any;
    activityReservations: any;
    checkoutUrl: string;
    coupon_code?: string;
};

export async function postMultiReservation(
    siteKey: string,
    consumer: MembershipConsumer,
    reservation: {
        timeslotReservations: TimeslotReservation[];
        activityReservations: MembershipMultiReservationTours[];
        giftcardReferences: string[];
        coupon_code?: string;
        checkoutUrl: string;
    },
) {
    const locale = localeAtom.subject.value;
    const url = getUrlWithParams(
        apiUrl() + `/Bookings/site/${siteKey}/reserve-multi`,
        locale.locale,
        {},
    );

    const response = await post(url, {
        consumer,
        ...reservation,
    });
    return response as MembershipReserveResponse;
}

export async function cancelReservation(orderReference: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/order/${orderReference}/cancel`, locale, {});
    await post(url);
}

export async function cancelMembership(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/cancel`,
        locale,
        {},
    );
    const cancellation = await post(url);
    return cancellation as MembershipReserveResponse;
}

export async function getOrder(orderId: number) {
    const { locale } = localeAtom.subject.value;
    const url = getUrlWithParams(apiUrl() + `/Orders/${orderId}`, locale, {});
    const response = await get(url);
    return response;
}

export async function postValueCardReservation(
    consumer: MembershipUser,
    valueCardProductId: number,
    siteKey: string,
    checkoutUrl: string,
    giftcardReferences: string[],
    promoCodeReference: string | null,
    validFrom?: string,
    campaignId?: number,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Purchase/valueCard`, locale, {});
    const response = await post(url, {
        valueCardProductId,
        validFrom: validFrom === '' ? new Date().toISOString() : validFrom,
        siteKey,
        checkoutUrl,
        consumer,
        campaignId,
        giftcardReferences,
        promoCodeReference,
    });
    return response as MembershipReserveResponse;
}

export async function updateValueCardSubscription(valueCardId: number, checkoutUrl: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/update`,
        locale,
        {},
    );
    const response = await post(url, {
        checkoutUrl,
    });
    return response as UpdateValueCardSubscriptionResponse;
}

export function useLatestValueCardSubscriptionOrder(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/latest-order`,
        locale,
        {},
    );
    const { data, error, isLoading, mutate } = useSWR<MembershipOrder>(url, get);

    return {
        order: data ?? undefined,
        error: error,
        isLoading: isLoading,
        mutate,
    };
}

export async function retrySubscriptionPayment(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/retry-payment`,
        locale,
        {},
    );
    const response = await post(url);
    return response as MembershipOrder;
}

export async function deleteUserProfile() {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    await del(url);
}

export function useLoggedInUser(apiToken: string | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + '/Users/me', locale);
    const key = apiToken ? url : null;
    const { data, error, isLoading, mutate } = useSWR<MembershipUser>(
        key,
        async () => {
            var response = await get(url);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useAvailableValueCardProductsForUser(apiToken: string | null, siteKey?: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/availableValueCardProducts/site/${siteKey}`,
        locale,
    );
    const key = apiToken && siteKey ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipValueCardProduct[]>(
        key,
        async () => {
            var response = await get(url, localeAtom.subject.value.t.couldntGetValueCardProducts);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useValueCardsForUser(apiToken: string | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me/valueCards`, locale);
    const key = apiToken ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipUserValueCard[]>(
        key,
        async () => {
            var response = await get(url, localeAtom.subject.value.t.couldntGetValueCards);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useBookingsForUser(user: MembershipUser | null) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me/bookings`, locale);
    const key = user ? url : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipBooking[]>(
        key,
        async () => {
            var response = await get(url);
            return response;
        },
        {
            revalidateOnFocus: false,
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}

export function useIntentMulti(
    request: MembershipIntentMultiRequest | null,
    user: MembershipUser | null,
    siteKey?: string,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/site/${siteKey}/intent-multi`, locale, {});

    const keyIfFetch = {
        userId: user?.id,
        activityReservations: request?.activityReservations,
        timeslotReservations: request?.timeslotReservations,
        coupon_code: request?.coupon_code,
    };

    const key = siteKey && request ? keyIfFetch : null;

    const { data, error, isLoading, mutate } = useSWR<MembershipIntentMultiResponse>(
        key,
        async () => {
            var response = await post(url, request);
            return response;
        },
        {
            revalidateOnFocus: false,
            dedupingInterval: 1000 * 10, // We'll assume the prices are valid for at least 10 seconds.
        },
    );

    return {
        data: data,
        isError: error,
        isLoading: isLoading,
        mutate,
    };
}
