import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Link,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment } from 'react';
import { getDialcodes } from '@repo/common-utils/countries';
import CountrySelect from 'src/components/common/country-select/CountrySelect';
import PhoneInput from 'src/components/common/phone-input/PhoneInput';
import { PreactCheckbox } from 'src/components/common/preact-checkbox/PreactCheckbox';
import { useLocale } from '@repo/i18n';
import { CompanyInfo, Iso2Code, PhoneNumber } from '@repo/types';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { validateCompanyInfo } from '@repo/widget-utils/checkout-info-helper';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

type Props = {
    companyInfo: CompanyInfo;
    showErrors: boolean;
    onChange(companyInfo: CompanyInfo): void;
};

export default function CompanyInfoForm(props: Props): JSX.Element {
    const { t } = useLocale();
    const config = useConfigurations();
    const { companyInfo, onChange } = props;
    const theme = useTheme();

    const {
        isContactPersonValid,
        isOrgNumberValid,
        isCompanyNameValid,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isPostCodeValid,
        isCityValid,
    } = validateCompanyInfo(companyInfo);

    const showHasAcceptedPrivacyValidationError =
        !companyInfo.hasAcceptedPrivacy && props.showErrors;
    const showHasAcceptedTermsValidationError = !companyInfo.hasAcceptedTerms && props.showErrors;
    const showCompanyNameValidationError = !isCompanyNameValid && props.showErrors;
    const showOrganizationNumberValidationError = !isOrgNumberValid && props.showErrors;
    const showContactPersonValidationError = !isContactPersonValid && props.showErrors;
    const showPhoneNumberValidationError = !isPhoneValid && props.showErrors;
    const showEmailValidationError = !isEmailValid && props.showErrors;
    const showAddressValidationError = !isAddressValid && props.showErrors;
    const showPostCodeValidationError = !isPostCodeValid && props.showErrors;
    const showCityValidationError = !isCityValid && props.showErrors;

    const configurations = useConfigurations();
    const inputLabelStyles = {
        ...TextFieldStyleUtils.positionInputLabelAboveField,
        color: theme.palette.secondary.main,
    };
    const errorStyles = {
        color: '#f44336',
        '& > *': {
            color: '#f44336',
        },
    };

    return (
        <Fragment>
            <FormControl>
                <InputLabel
                    htmlFor="bilberry-company-name"
                    error={showCompanyNameValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.company_name)}
                </InputLabel>
                <TextField
                    id="bilberry-company-name"
                    required={true}
                    error={showCompanyNameValidationError}
                    value={companyInfo.companyName}
                    onChange={(e) =>
                        onChange({ ...companyInfo, companyName: e.currentTarget.value })
                    }
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="companyName"
                    autoComplete="organization"
                />
            </FormControl>
            <FormControl>
                <InputLabel
                    htmlFor="bilberry-contact-person-name"
                    error={showCompanyNameValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.main_contact)}
                </InputLabel>
                <TextField
                    id="bilberry-contact-person-name"
                    required={true}
                    error={showContactPersonValidationError}
                    value={companyInfo.contactPerson}
                    onChange={(e) =>
                        onChange({ ...companyInfo, contactPerson: e.currentTarget.value })
                    }
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="contactPerson"
                    autoComplete="name"
                />
            </FormControl>
            <FormControl>
                <InputLabel
                    htmlFor="bilberry-org-number"
                    error={showOrganizationNumberValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.org_number)}
                </InputLabel>
                <TextField
                    id="bilberry-org-number"
                    required={true}
                    error={showOrganizationNumberValidationError}
                    value={companyInfo.orgNumber}
                    onChange={(e) => onChange({ ...companyInfo, orgNumber: e.currentTarget.value })}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="orgNumber"
                />
            </FormControl>

            <CountrySelect
                country={companyInfo.country}
                onChange={(country: Iso2Code) =>
                    onChange({
                        ...companyInfo,
                        country,
                        phone: { ...companyInfo.phone, dialCode: getDialcodes()[country] },
                    })
                }
                secondaryColor={true}
            />

            <FormControl>
                <InputLabel
                    error={showAddressValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.address)}
                </InputLabel>
                <TextField
                    error={showAddressValidationError}
                    required={true}
                    value={companyInfo.address}
                    onChange={(e) => onChange({ ...companyInfo, address: e.currentTarget.value })}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    name="address"
                    autoComplete="street-address"
                />
            </FormControl>
            <FormControl>
                <InputLabel
                    error={showPostCodeValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.post_code)}
                </InputLabel>
                <TextField
                    error={showPostCodeValidationError}
                    required={true}
                    value={companyInfo.postCode}
                    onChange={(e) => onChange({ ...companyInfo, postCode: e.currentTarget.value })}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    name="postCode"
                    autoComplete="postal-code"
                />
            </FormControl>
            <FormControl>
                <InputLabel error={showCityValidationError} required={true} sx={inputLabelStyles}>
                    {capitalize(t.city)}
                </InputLabel>
                <TextField
                    error={showCityValidationError}
                    required={true}
                    value={companyInfo.city}
                    onChange={(e) => onChange({ ...companyInfo, city: e.currentTarget.value })}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    name="city"
                    autoComplete="address-level2"
                />
            </FormControl>

            <PhoneInput
                phoneNumber={companyInfo.phone}
                error={showPhoneNumberValidationError}
                onChange={(phone: PhoneNumber) => onChange({ ...companyInfo, phone })}
            ></PhoneInput>

            <FormControl>
                <InputLabel
                    htmlFor="bilberry-email"
                    error={showEmailValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.email)}
                </InputLabel>
                <TextField
                    id="bilberry-email"
                    error={showEmailValidationError}
                    required={true}
                    value={companyInfo.email}
                    onChange={(e) => {
                        if (e.target instanceof HTMLInputElement)
                            onChange({ ...companyInfo, email: e.target.value });
                    }}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="email"
                    autoComplete="email"
                />
            </FormControl>
            <FormControl required={true} error={showHasAcceptedPrivacyValidationError}>
                <FormControlLabel
                    sx={[showHasAcceptedPrivacyValidationError && errorStyles]}
                    control={
                        <PreactCheckbox
                            required={true}
                            sx={[showHasAcceptedPrivacyValidationError && errorStyles]}
                            color="secondary"
                            checked={companyInfo.hasAcceptedPrivacy}
                            onChange={(checked) =>
                                onChange({
                                    ...companyInfo,
                                    hasAcceptedPrivacy: checked,
                                })
                            }
                        />
                    }
                    labelPlacement="end"
                    label={
                        (
                            <Typography color="textSecondary" variant="body1">
                                {capitalize(t.i_have_read_and_accepted_the)}{' '}
                                <Link
                                    color="primary"
                                    href={configurations.privacyUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {capitalize(t.privacy_policy)}
                                </Link>
                            </Typography>
                        ) as JSX.Element
                    }
                />
                {showHasAcceptedPrivacyValidationError && (
                    <FormHelperText>You must accept the Privacy Policy to continue</FormHelperText>
                )}
            </FormControl>

            <FormControl required={true} error={showHasAcceptedTermsValidationError}>
                <FormControlLabel
                    sx={[showHasAcceptedTermsValidationError && errorStyles, { mt: 0 }]}
                    control={
                        <PreactCheckbox
                            sx={[showHasAcceptedTermsValidationError && errorStyles]}
                            checked={companyInfo.hasAcceptedTerms}
                            color="secondary"
                            onChange={(checked) =>
                                onChange({
                                    ...companyInfo,
                                    hasAcceptedTerms: checked,
                                })
                            }
                        />
                    }
                    labelPlacement="end"
                    label={
                        (
                            <Typography color="textSecondary" variant="body1">
                                {capitalize(t.i_have_read_and_accepted_the)}{' '}
                                <Link
                                    color="primary"
                                    href={configurations.termsUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {capitalize(t.terms_and_conditions)}
                                </Link>
                            </Typography>
                        ) as JSX.Element
                    }
                    color="textSecondary"
                />
                {showHasAcceptedTermsValidationError && (
                    <FormHelperText>
                        You must accept the Terms and Conditions to continue
                    </FormHelperText>
                )}
            </FormControl>
            {config.enableNewsletter && (
                <FormControl required={false}>
                    <FormControlLabel
                        control={
                            <PreactCheckbox
                                checked={companyInfo.receiveNewsletter}
                                color="secondary"
                                onChange={(checked) =>
                                    onChange({
                                        ...companyInfo,
                                        receiveNewsletter: checked,
                                    })
                                }
                            />
                        }
                        labelPlacement="end"
                        label={
                            (
                                <Typography color="textSecondary" variant="body1">
                                    {capitalize(t.iWishToRecieveNewsletters)}
                                </Typography>
                            ) as JSX.Element
                        }
                        color="textSecondary"
                    />
                </FormControl>
            )}
        </Fragment>
    );
}
