import { BilberryPromoCodeStatus } from '@repo/types';
import SummaryPromoCode from '../summary/SummaryPromoCode';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useLocale } from '@repo/i18n';
import { Box } from '@mui/material';

type SummaryPromoCodeProps = {
    onPromoCodeApplied: (appliedPromoCode: BilberryPromoCodeStatus | null) => void;
    appliedPromoCode?: BilberryPromoCodeStatus | null;
    currentPriceReduction: number;
    writable?: boolean;
};

/**
 * Wrapper for SummaryPromoCode that only displays the promo code if the feature flag is enabled.
 * @param props
 * @returns
 */
export function GiftCardPromoCode(props: SummaryPromoCodeProps) {
    const config = useConfigurations();
    const { t } = useLocale();

    const promoSupportsGiftcard = props.appliedPromoCode?.for_giftcard;

    if (config.enableGiftcardPromoCodes) {
        return (
            <Box pt={1}>
                <SummaryPromoCode
                    appliedPromoCode={promoSupportsGiftcard ? props.appliedPromoCode : null}
                    validatePromoCode={(promoCodeStatus) => {
                        if (promoCodeStatus && promoCodeStatus.for_giftcard) {
                            return { result: promoCodeStatus, error: null };
                        } else {
                            return {
                                result: null,
                                error: t.promo_code_not_supported_for_gift_cards,
                            };
                        }
                    }}
                    onPromoCodeApplied={(promoCodeStatus) =>
                        props.onPromoCodeApplied(promoCodeStatus)
                    }
                    currentPriceReduction={promoSupportsGiftcard ? props.currentPriceReduction : 0}
                    writeable={props.writable}
                />
            </Box>
        );
    }
    return null;
}
