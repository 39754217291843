import { Box, Button, Grid, useTheme } from '@mui/material';
import Summary from 'src/components/domain/summary/Summary';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from './ButtonDisplay';
import { CheckoutInfo } from '@repo/types';
import CheckoutInfoView from 'src/components/domain/checkout-info/CheckoutInfoView';
import LoggedInPanel from 'src/components/domain/timeslots-authentication/logged-in-panel/LoggedInPanel';
import LoggedOutPanel from 'src/components/domain/timeslots-authentication/logged-out-panel/LoggedOutPanel';
import { useAtom } from 'ximple';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { cartAtom } from 'src/state/cart/cartAtom';
import { companyAtom } from 'src/state/company';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

type ContactViewProps = {
    largeScreen: boolean;
    isMakingReservation: boolean;
    handleGoPayClicked: () => void;
    handleGoPayClickedInvoice?: () => void;
    checkoutInfo: CheckoutInfo | null;
    setCheckoutInfo: (val: CheckoutInfo | null) => void;
    showAddressFields: boolean;
    clickedNext: boolean;
    nextButtonText: string;
};

export function ContactView({
    largeScreen,
    isMakingReservation,
    handleGoPayClicked,
    handleGoPayClickedInvoice,
    showAddressFields,
    checkoutInfo,
    setCheckoutInfo,
    clickedNext,
    nextButtonText,
}: ContactViewProps) {
    const theme = useTheme();
    const [{ currentSite }] = useAtom(companyAtom);
    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const { cartItems, appliedPromoCode } = useCartContext();
    const { loggedInUser } = useMemberContext();

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Contact Info',
        cartItems: cartAtom.subject.value,
        giftCards: [],
        promoCode: appliedPromoCode,
    }));

    return (
        <Grid
            container
            justifyContent={largeScreen ? 'space-between' : 'center'}
            alignItems={largeScreen ? 'flex-start' : 'center'}
            direction={largeScreen ? 'row' : 'column-reverse'}
        >
            {!largeScreen && (
                <Box
                    maxWidth="440px"
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            marginBottom: theme.spacing(3),
                        },
                    }}
                >
                    <Button
                        sx={{ float: 'right', marginTop: theme.spacing(2) }}
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        disabled={isMakingReservation || cartItems.length === 0}
                        onClick={handleGoPayClicked}
                    >
                        <ButtonDisplay
                            isMakingReservation={isMakingReservation}
                            buttonText={nextButtonText}
                        />
                    </Button>
                </Box>
            )}

            {currentSite && largeScreen && (
                <>{loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="booking" />}</>
            )}

            {checkoutInfo && (
                <CheckoutInfoView
                    checkoutInfo={checkoutInfo}
                    onChange={setCheckoutInfo}
                    showErrors={clickedNext}
                    showAddressFields={showAddressFields}
                />
            )}

            {currentSite && !largeScreen && (
                <>{loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="booking" />}</>
            )}

            <Summary
                showShare
                hideGoPay={!largeScreen}
                isCheckoutSummary={true}
                isMakingReservation={isMakingReservation}
                goPayClicked={handleGoPayClicked}
                goPayClickedInvoice={
                    configurations.enableInvoicePayment && checkoutInfo?.contactType === 'company'
                        ? handleGoPayClickedInvoice
                        : undefined
                }
            />
        </Grid>
    );
}
