import { Grid, Typography } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { AccommodationAttributes, Product } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { facilityAsReadableText } from '@repo/widget-utils/accommodations/accommodationUtils';

type AccommodationOverviewFacilitiesListProps = {
    accommodation: Product;
};

export default function AccommodationOverviewFacilitiesList({
    accommodation,
}: AccommodationOverviewFacilitiesListProps) {
    const { t } = useLocale();
    const customizations = useCustomizations();

    const accommodationFacilites = Object.entries(
        accommodation.accommodationAttributes?.facilities ?? {},
    ).reduce((acc, [key, value]) => {
        const facility = facilityAsReadableText(
            key as keyof AccommodationAttributes['facilities'],
            t,
        );
        if (value && facility) {
            acc.push(facility);
        }
        return acc;
    }, [] as string[]);

    return (
        <Grid container mx="auto">
            <Grid container direction="column">
                <Typography variant="h2" fontSize={22} fontWeight={400} mb={3}>
                    {t.facilities_included}
                </Typography>
                <Grid
                    container
                    wrap="wrap"
                    gap={2}
                    sx={(theme) => ({
                        border: `2px ${customizations.bookingWidgetColor} solid`,
                        padding: theme.spacing(3),
                    })}
                >
                    {accommodationFacilites.map((facility) => (
                        <Typography key={facility} flexGrow={1} flexBasis="40%" fontWeight={600}>
                            - {facility}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
