import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TZDate } from '@repo/tzdate';
import { useCallback, useMemo } from 'react';
import StaticDateRangePicker from 'src/components/common/date-range-picker/StaticDateRangePicker';
import { getLocaleNumberFormatNoDecimals, useLocale } from '@repo/i18n';
import { ProductInstance } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { groupBy } from 'lodash-es';
import { MultipleNumberInputValueType } from 'src/components/common/MultipleNumberInput/MultipleNumberInput';

type Props = {
    availability: ProductInstance[][];
    value: DateRange<TZDate>;
    onChange: (value: DateRange<TZDate>) => void;
    onChangeMonth: (value: TZDate) => void;
    isLoading?: boolean;
    guests: MultipleNumberInputValueType[][];
    expectPrice?: boolean;
};

export default function Calendar(props: Props) {
    const { guests, availability, value, onChange, onChangeMonth, isLoading, expectPrice } = props;
    const { locale, dayjsLocale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();

    const calendarAvailability = useCalendarAvailability(availability, guests);

    const { calendarSx, calendarDaySx } = useCalendarSx();

    const onChangeDateRange = useCallback(
        (dateRange: DateRange<TZDate>) => {
            const [date1, date2] = dateRange;

            if (date1 && date2 && date1.isSame(date2)) {
                onChange([date1, null] as DateRange<TZDate>);
                return;
            }
            const isInvalidRange =
                date1 &&
                date2 &&
                new Array(Math.abs(date1.diff(date2, 'days'))).fill(null).some((_, i) => {
                    const firstDate = date1!.isBefore(date2!) ? date1 : date2;
                    const date = firstDate!.add(i, 'days');

                    const d = !calendarAvailability.has(date.format('YYYY-MM-DD'));
                    return d;
                });
            if (isInvalidRange) {
                onChange([date1, null] as DateRange<TZDate>);

                return;
            }
            onChange(dateRange);
        },
        [calendarAvailability, onChange],
    );

    const renderCustomDay = useCallback(
        (date: TZDate) => {
            const availability = calendarAvailability.get(date.format('YYYY-MM-DD'));

            return (
                <Stack gap={1}>
                    <Box sx={{ lineHeight: 1, fontSize: 16 }}>{date.format('DD')}</Box>
                    <Box
                        sx={{
                            lineHeight: 1,
                            fontSize: 12,
                            [`@container (width < ${360 - customizations.baseUnit * 4}px)`]: {
                                display: 'none',
                            },
                        }}
                    >
                        {availability && getLowestPriceFromAvailabilities(availability, locale)}
                    </Box>
                </Stack>
            );
        },
        [calendarAvailability, customizations.baseUnit, locale],
    );

    return (
        <Stack direction="row" justifyContent="center" width="100%" position="relative">
            {isLoading && (
                <Stack
                    position="absolute"
                    zIndex={10}
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    width="100%"
                    height="100%"
                    bgcolor="#fff"
                    justifyContent="center"
                    alignItems="center"
                    border={`1px solid ${theme.palette.grey[200]}`}
                    borderRadius={customizations.borderRadius + 'px'}
                >
                    <CircularProgress />
                </Stack>
            )}
            <Stack
                borderRadius={customizations.borderRadius + 'px'}
                border={`1px solid ${theme.palette.grey[200]}`}
                bgcolor="white"
                sx={calendarSx}
            >
                <ThemeProvider
                    theme={{
                        ...theme,

                        typography: {
                            ...theme.typography,
                            body1: {
                                ...theme.typography.body1,
                                color: theme.palette.common.black,
                            },
                        },
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
                        <StaticDateRangePicker
                            dateRangeVariant="nights"
                            dateRange={value}
                            onChange={onChangeDateRange}
                            onMonthChange={(d) => {
                                if (!d) return;
                                onChangeMonth(d);
                            }}
                            minDate={TZDate.now().startOf('day')}
                            maxDate={TZDate.now().add(3, 'years').endOf('day')}
                            isDateUnavailable={(d) => !calendarAvailability.has(d)}
                            loading={false}
                            showDaysOutsideCurrentMonth={false}
                            showToolbar={false}
                            renderInput={() => <></>}
                            components={{
                                ActionBar: () => null,
                            }}
                            customDaySx={calendarDaySx}
                            renderCustomDay={expectPrice ? renderCustomDay : undefined}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </Stack>
        </Stack>
    );
}

function useCalendarAvailability(
    availability: ProductInstance[][],
    guests: MultipleNumberInputValueType[][],
) {
    return useMemo(() => {
        const roomGuests = guests
            .map((room) => room.reduce((acc, guest) => acc + guest.value, 0))
            .toSorted((a, b) => b - a);

        const availabilityByDate = groupBy(availability.flat(1), (product) =>
            product.start.format('YYYY-MM-DD'),
        );

        return Object.entries(availabilityByDate).reduce(
            (
                acc: Map<string, { products: ProductInstance[]; fromPrice: number }>,
                [key, products],
            ) => {
                const actualCapacityMap = new Map<string, number>();
                products.forEach((product) => {
                    actualCapacityMap.set(product.id, product.capacity);
                });
                const productsSortedByPersonsPerUnit = products.toSorted(
                    (a, b) => (b.personsPerUnit ?? -1) - (a.personsPerUnit ?? -1),
                );
                const doWeHaveEnoughRooms = roomGuests.every((guests) => {
                    const firstValidProduct = productsSortedByPersonsPerUnit.find(
                        (product) =>
                            (actualCapacityMap.get(product.id) === null ||
                                actualCapacityMap.get(product.id)! > 0) &&
                            (product.personsPerUnit === null || product.personsPerUnit >= guests),
                    );
                    if (firstValidProduct) {
                        actualCapacityMap.set(
                            firstValidProduct.id,
                            actualCapacityMap.get(firstValidProduct.id)! - 1,
                        );
                        return true;
                    }
                    return false;
                });

                if (!doWeHaveEnoughRooms) return acc;

                let lowestPrice = Math.min(
                    ...products.map(
                        (product) => product.product?.fromPrice ?? Number.MAX_SAFE_INTEGER,
                    ),
                );
                lowestPrice = lowestPrice === Number.MAX_SAFE_INTEGER ? 0 : lowestPrice;

                const type = products[0].product?.type;

                if (!acc.has(key)) {
                    acc.set(key, {
                        products: products,
                        fromPrice: lowestPrice,
                    });
                } else {
                    acc.set(key, {
                        products: [...(acc.get(key)?.products ?? []), ...products],
                        fromPrice:
                            type === 'accommodation'
                                ? 0
                                : Math.min(acc.get(key)?.fromPrice ?? 0, lowestPrice),
                    });
                }

                return acc;
            },
            new Map<string, { products: ProductInstance[]; fromPrice: number }>(),
        );
    }, [availability, guests]);
}

function getLowestPriceFromAvailabilities(
    availabilities: { products: ProductInstance[] },
    locale: string,
) {
    const prices = availabilities.products.map((product) => {
        return product.ticketOptions[0]?.price ?? 0;
    });
    const lowest = Math.min(...prices);
    if (lowest === 0) return '-';
    return getLocaleNumberFormatNoDecimals(locale, lowest);
}

function useCalendarSx() {
    const customizations = useCustomizations();
    return useMemo(
        () => ({
            calendarSx: {
                '& .MuiPickerStaticWrapper-content': {
                    borderRadius: customizations.borderRadius + 'px',
                },
                '& .MuiDayPicker-header': {
                    minWidth: 380,
                    padding: 0,
                    maxHeight: 'auto',
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        minWidth: 330,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        minWidth: 300,
                    },
                },
                '& .MuiDayPicker-weekDayLabel': {
                    minWidth: 48,
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        minWidth: 42,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        minWidth: 36,
                    },
                },
                '& .MuiDayPicker-slideTransition': {
                    minHeight: 350,
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        minHeight: 310,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        minHeight: 280,
                    },
                },
                '& .MuiCalendarPicker-monthContainer': {
                    minWidth: 380,
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        minWidth: 330,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        minWidth: 300,
                    },
                },
                '& .MuiCalendarPicker-root': {
                    minWidth: 380,
                    maxHeight: 'unset',
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        minWidth: 330,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        minWidth: 300,
                    },
                },
                '& .MuiCalendarPicker-viewTransitionContainer': {
                    maxHeight: 'unset',
                },
                '& .MuiPickersDay-root': {
                    width: 50,
                    height: 50,
                    [`@container accommodationsearchform (width < ${395}px)`]: {
                        width: 42,
                        height: 42,
                    },
                    [`@container accommodationsearchform (width < ${
                        360 - customizations.baseUnit * 4
                    }px)`]: {
                        width: 36,
                        height: 36,
                    },
                },
            },
            calendarDaySx: {
                width: 50,
                height: 50,
                [`@container accommodationsearchform (width < ${395}px)`]: {
                    width: 42,
                    height: 42,
                },
                [`@container (width < ${360 - customizations.baseUnit * 4}px)`]: {
                    width: 'unset',
                    height: 'unset',
                },
            },
        }),
        [customizations],
    );
}
