import { Box, Stack, SxProps, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getDifficultyText } from '@repo/widget-utils/mapToTranslations';
import { useAtom } from 'ximple/atoms';
import ProductCardIcon from './ProductCardIcon';
import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import ChildCareRounded from '@mui/icons-material/ChildCareRounded';
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded';
import { Product } from '@repo/types';
import { getProductDisplayTitle } from '@repo/widget-utils/display-helper';
import { formatMinutesToDaysHoursMinutes } from '@repo/widget-utils/DateHelpers';

export function Icons(props: {
    product?: Product | null;
    hidePriceIcon?: boolean;
    size?: string;
    containerJustify?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    iconJustify?: 'center' | 'flex-start';
}) {
    const { size = '1.5em', containerJustify = 'center', iconJustify } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    const iconStyles = {
        '& > .MuiSvgIcon-root': {
            color: customizations.productCardIconColor,
            width: size,
            height: size,
        },
        '& > .MuiTypography-root': {
            padding: theme.spacing(0, 0.5),
            color: customizations.productCardTextColor,
        },
    };

    if (props.product?.icons && props.product.icons.length === 0) return null;
    return (
        <Box sx={{ containerType: 'inline-size' }}>
            <Stack
                direction="row"
                flexWrap="wrap"
                gap={2}
                justifyContent={containerJustify}
                sx={{
                    '@container (min-width: 350px)': {
                        gap: 4,
                    },
                }}
            >
                {!props.product?.icons && (
                    <DefaultIcons
                        product={props.product}
                        hidePriceIcon={props.hidePriceIcon}
                        iconStyles={iconStyles}
                        justify={iconJustify}
                    />
                )}
                {props.product?.icons &&
                    props.product.icons
                        .filter((icon) => icon.show === undefined || icon.show)
                        .map((icon, index) => (
                            <ProductCardIcon
                                key={index}
                                label={icon.text}
                                Icon={icon.url}
                                sx={iconStyles}
                                justify={iconJustify}
                            />
                        ))}
            </Stack>
        </Box>
    );
}

function DefaultIcons(props: {
    product?: Product | null;
    hidePriceIcon?: boolean;
    iconStyles: SxProps;
    justify?: 'center' | 'flex-start';
}) {
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const productAttrs = getCommonAttributes(props.product);

    const minAge =
        productAttrs?.from_age ??
        productAttrs?.default_prices.reduce(
            (acc, price) => Math.min(acc, price?.fromAge ?? Number.MAX_VALUE),
            Number.MAX_VALUE,
        ) ??
        Number.MAX_VALUE;

    return (
        <Fragment>
            <ProductCardIcon
                label={formatMinutesToDaysHoursMinutes(t, productAttrs?.duration ?? 0)}
                Icon={AccessAlarmsRoundedIcon}
                sx={props.iconStyles}
                justify={props.justify}
            />
            <ProductCardIcon
                label={getDifficultyText(t, productAttrs)}
                Icon={TrendingUpRounded}
                sx={props.iconStyles}
                justify={props.justify}
            />
            <ProductCardIcon
                label={minAge < Number.MAX_VALUE ? `${minAge}+` : '-'}
                Icon={ChildCareRounded}
                sx={props.iconStyles}
                justify={props.justify}
            />
            {!props.hidePriceIcon && (
                <ProductCardIcon
                    label={`${
                        productAttrs?.from_price
                            ? formatCurrencyNoDecimalsIfPossible(
                                  locale,
                                  currency,
                                  productAttrs.from_price,
                              )
                            : '-'
                    }`}
                    Icon={LocalOfferRounded}
                    sx={props.iconStyles}
                    justify={props.justify}
                />
            )}
        </Fragment>
    );
}

function getCommonAttributes(product?: Product | null) {
    if (!product) return;
    return {
        title: getProductDisplayTitle(product),
        default_prices: product.ticketOptions,
        from_price: product.fromPrice,
        from_age: product.fromAge,
        description: product.shortDescription,
        difficulty: product.difficulty,
        duration: product.duration,
    };
}
