import { RefObject, useEffect } from 'react';
import { displayModalAtom } from 'src/state/ui/displayModal.atom';

export interface IModalProps {
    open: boolean;
    onClose: () => void;
    children: any;
    id: string;
    large?: boolean;
    refocusElementOnClose?: string | RefObject<HTMLElement>;
    shadowRoot?: ShadowRoot;
}

export default function Modal(props: IModalProps): JSX.Element | null {
    const { open, children, id, onClose, refocusElementOnClose, shadowRoot, large } = props;

    useEffect(() => {
        if (open) {
            displayModalAtom.update({
                type: 'SHOW_MODAL',
                data: {
                    id,
                    content: children,
                    onClose,
                    refocusElementOnClose,
                    shadowRoot,
                    large,
                },
            });
        } else if (!open) {
            displayModalAtom.update({
                type: 'HIDE_MODAL',
                data: id,
            });
        }
    });

    return null;
}
