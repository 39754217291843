import {
    Box,
    Button,
    capitalize,
    CircularProgress,
    darken,
    Grid,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';

import { useLocale, getLocaleNumberFormatTwoDecimals } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';
import { Translations } from '@repo/types';

interface IProps {
    goPayClicked?: () => void;
    goPayClickedInvoice?: () => void;
    isMakingReservation?: boolean;
    goToCheckout?: () => void;
    hideGoPay: boolean;
    nextButtonText?: string;
    totalPrice: number;
    invertedColorsClass?: Record<string, unknown>;
    onCloseModal?: () => void;
    isConfirmationPage?: boolean;
    enableGoPay: boolean;
}

export default function SummaryPayment(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const { totalPrice, enableGoPay, isMakingReservation, goPayClicked, goPayClickedInvoice } =
        props;
    const customizations = useCustomizations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const rebookReturnUrl = findUrlQueryParam('rebookReturnUrl');

    return (
        <Grid container direction="column" justifyContent="space-between" wrap="nowrap">
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                wrap="nowrap"
                mt={theme.spacing(1)}
            >
                <Grid container item xs={4} alignItems="center">
                    {props.isConfirmationPage && (
                        <Button
                            variant={customizations.primaryButtonStyle}
                            color="primary"
                            onClick={() => window.print()}
                            className="no-print"
                        >
                            {t.print}
                        </Button>
                    )}
                </Grid>
                <Grid container justifyContent="space-between" alignItems="flex-end" wrap="nowrap">
                    <Typography
                        variant="h6"
                        align="right"
                        color="textSecondary"
                        width="100%"
                        pr={theme.spacing(1.5)}
                        lineHeight={1}
                        sx={props.invertedColorsClass}
                    >
                        {t.total.toUpperCase()}&nbsp;{currency.currency}&nbsp;
                    </Typography>
                    <Typography
                        variant="h4"
                        align="right"
                        width="100%"
                        fontSize={28}
                        fontWeight={customizations.boldFontWeight}
                        mb={-0.5}
                        lineHeight={1}
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {getLocaleNumberFormatTwoDecimals(locale, totalPrice)}
                    </Typography>
                </Grid>
            </Grid>
            {props.onCloseModal ? (
                <Grid container justifyContent="space-between" mt={theme.spacing(4)}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: customizations.bookingWidgetPrimaryColor,
                            backgroundColor: customizations.bookingWidgetColor,
                            border: `1px solid ${customizations.bookingWidgetPrimaryColor}`,
                            marginBottom: theme.spacing(1),
                            '&:hover': {
                                backgroundColor:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? darken(theme.palette.primary.main, 0.2)
                                        : 'rgba(0, 0, 0, 30%) ',
                            },

                            '& .MuiButton-label': {
                                color: customizations.bookingWidgetPrimaryColor,
                            },
                        }}
                        onClick={props.onCloseModal}
                    >
                        {t.continue_shopping}
                    </Button>
                    <Button
                        color="button"
                        sx={{
                            backgroundColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? `${customizations.bookingWidgetPrimaryColor} !important`
                                    : 'transparent !important',
                            color:
                                customizations.primaryButtonStyle === 'contained'
                                    ? `${customizations.bookingWidgetColor} !important`
                                    : `${customizations.bookingWidgetPrimaryColor} !important`,
                            marginBottom: theme.spacing(1),

                            borderColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? 'transparent'
                                    : customizations.bookingWidgetPrimaryColor,
                            '&:hover': {
                                backgroundColor:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? `${darken(
                                              customizations.bookingWidgetPrimaryColor,
                                              0.2,
                                          )} !important`
                                        : 'rgba(0, 0, 0, 30%) !important',
                                color:
                                    customizations.primaryButtonStyle === 'contained'
                                        ? `${darken(
                                              customizations.bookingWidgetColor,
                                              0.2,
                                          )} !important`
                                        : undefined,
                            },
                            '&:focus': {
                                boxShadow: `0 0 0 2px ${customizations.bookingWidgetColor}, 0 0 0 3px ${customizations.bookingWidgetPrimaryColor} !important`,
                            },
                        }}
                        variant={customizations.primaryButtonStyle}
                        onClick={props.goToCheckout}
                    >
                        {capitalize(t.checkout)}
                    </Button>
                </Grid>
            ) : (
                !props.hideGoPay &&
                !props.isConfirmationPage && (
                    <Stack direction="row" justifyContent="flex-end" gap={2} mt={theme.spacing(4)}>
                        {goPayClickedInvoice && (
                            <Button
                                color="primary"
                                variant={customizations.primaryButtonStyle}
                                disabled={isMakingReservation || !enableGoPay}
                                onClick={goPayClickedInvoice}
                            >
                                {t.pay_with_invoice}
                            </Button>
                        )}
                        <Button
                            sx={{ minWidth: goPayClickedInvoice ? undefined : '50%' }}
                            color="primary"
                            variant={customizations.primaryButtonStyle}
                            disabled={isMakingReservation || !enableGoPay}
                            onClick={goPayClicked}
                        >
                            {buttonDisplay(t, props.nextButtonText, isMakingReservation)}
                        </Button>
                    </Stack>
                )
            )}
            {rebookReturnUrl && (
                <Grid item display="flex" justifyContent="end">
                    <Button
                        sx={{ mt: theme.spacing(2) }}
                        color="warning"
                        variant={customizations.primaryButtonStyle}
                        href={rebookReturnUrl}
                    >
                        {t.continueWithRebooking}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

function buttonDisplay(
    t: Translations,
    nextButtonText?: string,
    isMakingReservation?: boolean,
): JSX.Element {
    if (isMakingReservation) {
        return (
            <Box>
                <CircularProgress
                    color="primary"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                {t.next} - {nextButtonText ?? t.go_and_pay}
            </Box>
        );
    } else {
        return (
            <Box>
                {t.next} - {nextButtonText ?? t.go_and_pay}
            </Box>
        );
    }
}
