import { Grid } from '@mui/material';

interface IProps {
    size: string;
    children: any;
}

export function SmallerTextInline(props: IProps) {
    const { children } = props;
    return (
        <Grid component="span" fontSize={props.size}>
            {children}
        </Grid>
    );
}
