import { ErrorSeverity } from 'src/utils/widget/error-handling';
import { atom } from 'ximple';

type DisplaySnackbarState = {
    visible: boolean;
    message: string;
    severity?: ErrorSeverity;
};

export const defaultDisplaySnackbarAtomState: DisplaySnackbarState = {
    visible: false,
    message: '',
    severity: 'error',
};

export const displaySnackbarAtom = atom<DisplaySnackbarState>({
    initialValue: defaultDisplaySnackbarAtomState,
});
