import { Checkbox, FormControlLabel, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { ContactPerson, CheckoutQuestionnaire, CheckoutQuestionWithAnswer } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { findContactPersonValue } from '@repo/widget-utils/checkout-info-helper';
import BilberryQuestionInput from './BilberryQuestionInput';

type PropsBase = {
    title: string;
    index: number;
    showErrors: boolean;
    questionnaire: CheckoutQuestionnaire;
    onChange: (checkoutInfo: CheckoutQuestionnaire) => void;
};

type PropsForGuestList = PropsBase & {
    contactPerson: ContactPerson | null;
    copyFromContactPerson: boolean;
    onChangeCopyFromContactPerson: (value: boolean) => void;
};

type Props = PropsBase | PropsForGuestList;

export default function Questionnaire(props: Props): JSX.Element | null {
    const { t } = useLocale();
    const {
        onChange,
        questionnaire,
        title,
        index,
        contactPerson,
        copyFromContactPerson = false,
        onChangeCopyFromContactPerson,
    } = props as PropsForGuestList;
    const customizations = useCustomizations();
    const theme = useTheme();

    const showCopyFromContactPerson =
        index === 0 && contactPerson && !customizations.disableCopyFromContactPerson;

    const questions = Object.entries(questionnaire);
    if (questions.length === 0) return null;

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
                marginTop: theme.spacing(1),
            }}
            variant="outlined"
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography color="textSecondary" variant="h6" mt={0}>
                    {title}
                </Typography>
                {showCopyFromContactPerson && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={copyFromContactPerson}
                                onChange={(_event, value) => {
                                    onChangeCopyFromContactPerson(value);
                                    copyFromContactPersonOnChange(
                                        value,
                                        questionnaire,
                                        contactPerson,
                                        onChange,
                                    );
                                }}
                            />
                        }
                        labelPlacement="start"
                        label={
                            (
                                <Typography color="textSecondary" variant="body1">
                                    {capitalize(t.same_as_main_contact)}
                                </Typography>
                            ) as JSX.Element
                        }
                        color="secondary"
                    />
                )}
            </Grid>

            {questions.map(([key, question]) => {
                const contactPersonValue = findContactPersonValue(contactPerson, question.key);

                const disabledIfCopiedFromContactPerson =
                    index === 0 && copyFromContactPerson && contactPersonValue !== null;

                return (
                    <BilberryQuestionInput
                        key={key}
                        onChange={(question) =>
                            onChange({
                                ...questionnaire,
                                [question.id]: question,
                            })
                        }
                        showErrors={props.showErrors}
                        question={question}
                        disabled={disabledIfCopiedFromContactPerson}
                    />
                );
            })}
        </Paper>
    );
}

function copyFromContactPersonOnChange(
    copyFromContactPerson: boolean,
    questionnaire: CheckoutQuestionnaire,
    contactPerson: ContactPerson | null,
    onChange: (questionnaire: CheckoutQuestionnaire) => void,
) {
    const questions = Object.values(questionnaire);
    const updatedQuestionnaire = questions.reduce((final, question) => {
        const contactPersonValue = findContactPersonValue(contactPerson, question.key);
        const updatedQuestion: CheckoutQuestionWithAnswer = {
            ...question,
            answer:
                copyFromContactPerson && contactPersonValue !== null
                    ? contactPersonValue
                    : question.answer,
        };
        return {
            ...final,
            [question.id]: updatedQuestion,
        };
    }, {} as CheckoutQuestionnaire);
    onChange(updatedQuestionnaire);
}
