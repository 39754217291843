import { Box, Button, darken, Stack, Typography, useTheme } from '@mui/material';
import { useLocale, formatCurrencyNoDecimalsIfPossible } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { TicketOptionWithQuantity } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getPriceSummaryFromTicketOptions } from '@repo/widget-utils/price-helper';
import { useAtom } from 'ximple/atoms';

type InlineSummaryProps = {
    onBook: () => void;
    quantities: TicketOptionWithQuantity[];
    fromPrice: number | null;
    bookingDisabled?: boolean;
    hasCapacity?: boolean;
    totalPriceFontSize?: number;
    fromLabelFontSize?: number;
    additionalLabel?: JSX.Element;
};
export function InlineSummary({
    onBook,
    quantities,
    fromPrice,
    bookingDisabled,
    hasCapacity,
    totalPriceFontSize,
    fromLabelFontSize,
    additionalLabel,
}: InlineSummaryProps) {
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const { totalPrice, creditsUsed, numTicketsIncludedInMembership } =
        getPriceSummaryFromTicketOptions(quantities);

    const hasQuantities = quantities.some((q) => q.quantity > 0);
    return (
        <Box
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
        >
            <Stack
                width="100%"
                boxSizing="border-box"
                direction="row"
                gap={1}
                flexWrap="nowrap"
                alignItems="flex-end"
                justifyContent="space-between"
            >
                {hasCapacity ? (
                    <>
                        <Typography
                            sx={{
                                flexGrow: 0,
                                flexShrink: 1,
                                minWidth: 'fit-content',
                                color: customizations.productCardTextColor,
                                fontSize: totalPriceFontSize ?? theme.typography.fontSize * 1.5,
                                fontWeight: 'bold',
                                lineHeight: 1,
                            }}
                        >
                            <Box
                                component="span"
                                sx={{
                                    fontSize: fromLabelFontSize ?? '60%',
                                    pr: 1,
                                }}
                            >
                                {quantities.some(({ quantity }) => quantity > 0) || !fromPrice
                                    ? t.total
                                    : fromPrice !== null
                                      ? t.from
                                      : ''}
                            </Box>
                            {hasQuantities && (
                                <>
                                    {totalPrice > 0
                                        ? formatCurrencyNoDecimalsIfPossible(
                                              locale,
                                              currency,
                                              totalPrice,
                                          )
                                        : ''}
                                    {creditsUsed && creditsUsed > 0
                                        ? `${creditsUsed} ${t.credit.num(creditsUsed)}`
                                        : ''}
                                    {numTicketsIncludedInMembership &&
                                    numTicketsIncludedInMembership > 0
                                        ? t.inclInMembership
                                        : ''}
                                </>
                            )}
                            {!hasQuantities &&
                                fromPrice &&
                                formatCurrencyNoDecimalsIfPossible(locale, currency, fromPrice)}
                            {!hasQuantities &&
                                !fromPrice &&
                                formatCurrencyNoDecimalsIfPossible(locale, currency, 0)}
                        </Typography>
                    </>
                ) : (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            {t.no_available_capacity_for_this_tour}
                        </Typography>
                    </Box>
                )}
                {additionalLabel}
            </Stack>
            <Button
                variant="outlined"
                disabled={bookingDisabled}
                onClick={onBook}
                sx={(theme) => ({
                    width: '100%',
                    px: 3,
                    py: 1.5,
                    fontSize: 16,
                    borderWidth: 1,
                    mt: 1,
                    flexShrink: 0,
                    borderRadius: theme.shape,
                    color: customizations.productCardPrimaryColor,
                    backgroundColor: customizations.productCardPrimaryColorContrast,
                    borderColor: customizations.productCardPrimaryColor,
                    '&:disabled': {
                        color: customizations.productCardPrimaryColor,
                        backgroundColor: customizations.productCardPrimaryColorContrast,
                        borderColor: customizations.productCardPrimaryColor,
                        filter: 'grayscale(0.9)',
                        opacity: 0.75,
                    },
                    '&:hover': {
                        color: darken(customizations.productCardPrimaryColor, 0.1),
                        backgroundColor: darken(
                            customizations.productCardPrimaryColorContrast,
                            0.1,
                        ),
                        borderColor: darken(customizations.productCardPrimaryColor, 0.1),
                    },
                    '&:focus': {
                        boxShadow: `0 0 0 2px ${customizations.productCardPrimaryColorContrast}, \
                                    0 0 0 3px ${customizations.productCardPrimaryColor}`,
                    },
                })}
            >
                {t.book_now}
            </Button>
        </Box>
    );
}
