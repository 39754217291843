import { Product } from '@repo/types';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';

export default function useTicketTypesWithinProduct(product: Product | null) {
    return useMemo(
        () =>
            uniqBy(
                (product?.ticketOptions ?? []).flatMap((p) => p.ticketTypes),
                'id',
            ),
        [product],
    );
}
