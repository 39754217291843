import { Box, useTheme } from '@mui/material';
import EditActivity from 'src/components/domain/summary/edit-activity/EditActivity';
import { CartItem } from '@repo/types';
import { SummaryInfoFromQuantities } from '../SummaryInfoFromQuantities';

interface IProps {
    cartItem: CartItem;
    isCheckoutSummary: boolean;
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

export default function SummaryInformation(props: IProps): JSX.Element {
    const theme = useTheme();

    const isAccommodation = props.cartItem.products[0]?.product?.type === 'accommodation';
    const isUsingPaymentPlan = props.cartItem.ticketOptions.some((x) => x.membership);

    const ticketOptions = isAccommodation
        ? props.cartItem.ticketOptions.slice(0, 1)
        : props.cartItem.ticketOptions;

    return (
        <Box m={theme.spacing(0.5, 0, 2, 0)}>
            <SummaryInfoFromQuantities
                quantities={ticketOptions}
                ticketType={props.cartItem.ticketType}
                invertedColorsClass={props.invertedColorsClass}
                isUsingPaymentPlan={isUsingPaymentPlan}
            />
            {props.isCheckoutSummary &&
                !props.isConfirmationPage &&
                !props.cartItem.pkg &&
                !isUsingPaymentPlan && (
                    <EditActivity
                        cartItem={props.cartItem}
                        invertedColorsClass={props.invertedColorsClass}
                        isUsingPaymentPlan={isUsingPaymentPlan}
                    />
                )}
        </Box>
    );
}
