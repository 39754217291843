import { Button } from '@mui/material';
import { tzdate, TZDate } from '@repo/tzdate';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Modal from 'src/components/common/modal/Modal';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getCartItemDisplayTitle } from '@repo/widget-utils/display-helper';
import { getInitialQuantityData, updateQuantityData } from '@repo/widget-utils/price-helper';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import {
    CartItem,
    MembershipSite,
    MembershipUser,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
} from '@repo/types';
import { useAvailabilities, useProduct } from '@repo/widget-utils/api/api';
import BookingBase from '../../booking-base/BookingBase';
import { cartAtom } from 'src/state/cart/cartAtom';
import { removeFromCartEvent, createAddProductsToCartEvent } from 'src/state/cart/cart.reducer';
import { DateRange } from '@mui/x-date-pickers-pro';
import { getDateRangeVariant } from 'src/widgets/activities/activity-booking/booking/Booking';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { displayModalAtom } from 'src/state/ui/displayModal.atom';
import { formatTime } from '@repo/widget-utils/DateHelpers';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import useTicketTypesWithinProduct from 'src/hooks/domain/cart/useTicketTypesWithinProduct';
import usePriceQuantities from 'src/hooks/domain/cart/usePriceQuantities';
import useUpdateNewCartItem from 'src/hooks/domain/cart/useUpdateNewCartItem';
import { BookingContextProvider, useBookingContext } from 'src/widgets/BookingContext';

interface IProps {
    cartItem: CartItem;
    invertedColorsClass?: any;
    isUsingPaymentPlan: boolean;
}

export default function EditActivity(props: IProps): JSX.Element {
    const { t } = useLocale();
    const customizations = useCustomizations();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const initialSelectedTimeSlot = {
        label: formatTime(tzdate(props.cartItem.products[0].start)),
        sublabel: formatTime(tzdate(props.cartItem.products[0].end)),
        product: props.cartItem.products[0],
        products: props.cartItem.products[0]?.timeslots ?? [],
    };

    return (
        <>
            <Button
                sx={[
                    {
                        padding: 0,
                        color:
                            !props.invertedColorsClass ||
                            Object.keys(props.invertedColorsClass!).length === 0
                                ? { color: customizations.linkColor }
                                : { color: customizations.bookingWidgetPrimaryColor },
                        textDecoration: `${customizations.linkStyle} !important`,
                    },
                    !!props.invertedColorsClass &&
                        Object.keys(props.invertedColorsClass).length > 0 && {
                            backgroundColor: customizations.bookingWidgetColor,
                            color: customizations.bookingWidgetPrimaryColor,
                        },
                ]}
                variant="text"
                onClick={(e: React.MouseEvent) => {
                    if (e.currentTarget instanceof HTMLElement) setAnchorEl(e.currentTarget);
                }}
            >
                {capitalize(t.edit)}
            </Button>
            {Boolean(anchorEl) && (
                <BookingContextProvider
                    initialTicketOptions={props.cartItem.ticketOptions}
                    initialProducts={props.cartItem.products}
                    initialTimeSlot={initialSelectedTimeSlot}
                >
                    <EditActivityModal {...props} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                </BookingContextProvider>
            )}
        </>
    );
}

export function EditActivityModal(
    props: IProps & {
        anchorEl: HTMLElement | null;
        setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    },
): JSX.Element {
    const { cartItem, anchorEl, setAnchorEl } = props;
    const { t } = useLocale();
    const { currentSite, loggedInUser } = useMemberContext();
    const {
        quantities,
        setQuantities,
        selectedProducts,
        setSelectedProducts,
        selectedTimeSlot,
        setSelectedTimeslot,
        selectedTicketType,
        setSelectedTicketType,
    } = useBookingContext();

    const [dateRange, setDateRange] = useState<DateRange<TZDate>>([null, null]);
    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: TZDate | null;
        endDay: TZDate | null;
    }>({
        startDay: null,
        endDay: null,
    });

    const isTimeslots = cartItem.products[0].product?.type === 'timeslot';
    const { data: product } = useProduct(
        cartItem.products[0]?.product?.id,
        cartItem.products[0].product?.type === 'accommodation'
            ? 'accommodation'
            : isTimeslots
            ? 'timeslot'
            : undefined,
        [cartItem.products[0].start, cartItem.products[cartItem.products.length - 1].start],
    );

    const { data: availabilities } = useAvailabilities(
        product,
        availabilitySearchPeriod.startDay,
        availabilitySearchPeriod.endDay,
    );

    useUpdateNewCartItem(cartItem.disablePaymentPlans, cartItem.requiresPaymentPlans);

    const ticketTypes = useTicketTypesWithinProduct(product);
    const priceQuantities = usePriceQuantities(product, selectedProducts, quantities);

    useEffect(() => {
        setQuantities((quantities) =>
            updateQuantityData(selectedProducts ?? [], product?.ticketOptions ?? [], quantities),
        );
    }, [selectedProducts, product, setQuantities]);

    function noExtendSetDateRange(newRange: DateRange<TZDate>) {
        //Removes the ability to extend range in both directions in mui calendar
        if (dateRange[0]?.format('YYYY-MM-DD') !== newRange[0]?.format('YYYY-MM-DD')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    const cancel = () => {
        setAnchorEl(null);
        setSelectedTimeslot(undefined);
        setQuantities([]);
        displayModalAtom.update({
            type: 'HIDE_MODAL',
            data: 'edit-activity-modal',
        });
    };

    useEffect(() => {
        if (!cartItem || !anchorEl) return;

        const productStartDate = tzdate(cartItem.products[0].start);
        if (cartItem.products.length < 2) {
            setSelectedTimeslot({
                product: cartItem.products[0],
                label: formatTime(productStartDate),
                sublabel: formatTime(tzdate(cartItem.products[0].end)),
                products: cartItem.products[0]?.timeslots ?? [],
            });
        } else {
            const productEndDate = tzdate(cartItem.products[cartItem.products.length - 1].start);
            setDateRange([productStartDate, productEndDate]);
        }

        setSelectedProducts(cartItem.products);

        setAvailabilitySearchPeriod({
            startDay: productStartDate.subtract(2, 'month').startOf('month'),
            endDay: productStartDate.add(3, 'month').startOf('month'),
        });

        setQuantities(cartItem.ticketOptions);
    }, [cartItem, anchorEl]);

    useWidgetEventEffect(
        (cartItem) => ({
            eventType: 'viewItem',
            productType: cartItem.products[0].product?.type ?? 'timepoint',
            product: cartItem.products[0].product!,
        }),
        cartItem,
        anchorEl,
    );

    const numPrices = availabilities[0]?.ticketOptions.length;

    return (
        <Fragment>
            <Modal
                open={Boolean(anchorEl)}
                id="edit-activity-modal"
                refocusElementOnClose={{ current: anchorEl }}
                onClose={cancel}
            >
                <BookingBase
                    disableFixedPosition={true}
                    productTitle={getCartItemDisplayTitle(props.cartItem)}
                    setSelectedProducts={(e) => setSelectedProducts(e)}
                    numPrices={numPrices}
                    travelerQuantities={priceQuantities}
                    priceQuantities={priceQuantities}
                    defaultQuantities={getInitialQuantityData(
                        product?.ticketOptions ?? [],
                        product?.minEntrants,
                    )}
                    setQuantities={setQuantities}
                    selectedTimeSlot={selectedTimeSlot}
                    onSelectTimeSlot={setSelectedTimeslot}
                    availabilityData={availabilities}
                    leftButtonLabel={capitalize(t.cancel)}
                    rightButtonLabel={capitalize(t.update)}
                    onClickLeftButton={cancel}
                    onClickRightButton={useSave(
                        cartItem,
                        priceQuantities,
                        setQuantities,
                        setAnchorEl,
                        setSelectedTimeslot,
                        currentSite,
                        loggedInUser,
                        selectedProducts,
                        selectedTicketType,
                        selectedTimeSlot,
                    )}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    hasChosenDate={!!selectedProducts}
                    setHasChosenDate={() => null}
                    attemptedBooking={false}
                    productCapacity={(selectedProducts ?? cartItem.products).reduce(
                        (acc, cur) => Math.min(acc, cur.capacity),
                        Number.MAX_VALUE,
                    )}
                    title={t.edit}
                    visible={true}
                    onToggleVisible={() => null}
                    addMarginRightToHeader={true}
                    expandArrowInside={false}
                    positionOffscreen={false}
                    selectedDateRange={dateRange}
                    onSelectDateRange={noExtendSetDateRange}
                    selectedTicketType={selectedTicketType}
                    setSelectedTicketType={setSelectedTicketType}
                    ticketTypes={ticketTypes}
                    dateRangeVariant={getDateRangeVariant(
                        (selectedProducts ?? cartItem.products)[0].product,
                    )}
                    showCartCompatibilityWarning={false}
                    showMembershipBookingDisabledWarning={false}
                    isUsingPaymentPlan={props.isUsingPaymentPlan}
                />
            </Modal>
        </Fragment>
    );
}

function useSave(
    cartItem: CartItem,
    quantities: TicketOptionWithQuantity[],
    setQuantities: React.Dispatch<React.SetStateAction<TicketOptionWithQuantity[]>>,
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    setSelectedTimeslot: React.Dispatch<React.SetStateAction<TimeSlotType | undefined>>,
    currentSite: MembershipSite | null,
    loggedInUser: MembershipUser | null,
    selectedProducts?: ProductInstance[],
    selectedTicketType?: TicketType,
    selectedTimeslot?: TimeSlotType | undefined,
) {
    return useCallback(() => {
        const fn = async () => {
            const selected = [
                ...(selectedProducts?.map((p) => ({ ...p, timeslots: [] })) ?? cartItem.products),
            ];
            const [{ product }] = selected;
            if (!product) return;
            if (product?.type === 'accommodation' && !selectedTicketType) return;

            if (product?.type === 'timeslot') {
                if (!selectedTimeslot?.products) return;
                selected[0] = {
                    ...selected[0],
                    timeslots: selectedTimeslot.products as any,
                    start: selectedTimeslot.products[0].start,
                    end: selectedTimeslot.products[selectedTimeslot.products.length - 1].end,
                } as ProductInstance;
            }
            // remove existing product and exchange with the new one
            await cartAtom.update(removeFromCartEvent(cartItem, currentSite, loggedInUser));
            await cartAtom.update(
                createAddProductsToCartEvent(
                    selected,
                    quantities,
                    cartItem.disablePaymentPlans,
                    cartItem.requiresPaymentPlans,
                    currentSite,
                    loggedInUser,
                    cartItem.pkg,
                    selectedTicketType,
                ),
            );
            setAnchorEl(null);
            setSelectedTimeslot(undefined);
            setQuantities([]);
            await displayModalAtom.update({
                type: 'HIDE_MODAL',
                data: 'edit-activity-modal',
            });
        };
        fn();
    }, [
        cartItem,
        selectedProducts,
        quantities,
        setQuantities,
        setAnchorEl,
        setSelectedTimeslot,
        selectedTicketType,
        selectedTimeslot,
        currentSite,
        loggedInUser,
    ]);
}
