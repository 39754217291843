import { Box, Button, darken, Grid, Popover, useTheme } from '@mui/material';
import { TZDate } from '@repo/tzdate';
import { createRef, useCallback, useContext, useEffect, useMemo } from 'react';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from '@repo/i18n';
import { ProductInstance } from '@repo/types';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { zIndex } from 'src/utils/widget/zIndex';
import BilberryStaticCalendar from '../BilberryStaticCalendar';
import { isNil } from 'lodash-es';
import { findFirstAvailableProduct } from '@repo/widget-utils/DateHelpers';

interface IProps {
    minDate: TZDate;
    displayDate: TZDate | null;
    anchorEl: HTMLDivElement | HTMLInputElement | null;
    updateDisplayDate: (date: TZDate | null) => void;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | HTMLInputElement | null>>;
    id?: string;
    availabilityData: ProductInstance[];
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }) => void;
}

export default function PackageCalendarPopover(props: IProps) {
    const {
        minDate,
        displayDate,
        anchorEl,
        updateDisplayDate,
        setAnchorEl,
        availabilityData,
        setAvailabilitySearchPeriod,
        id = 'bilberry-calendar-input',
    } = props;
    const customizations = useCustomizations();
    const mountPoint = useContext(MountPointContext);
    const popoverRef = createRef<HTMLDivElement>();
    const theme = useTheme();
    const { t } = useLocale();
    const threeYearsAhead = minDate.add(5, 'year');
    const trap = useFocusTrap(popoverRef, !!anchorEl, `#${id}`, mountPoint.shadowRoot);

    const onSelectDate = useCallback(
        (date: TZDate | null) => {
            const [firstAvailableProductOfDay] = availabilityData
                .filter((avail) => avail.start.isSame(date, 'day'))
                .sort((a, b) => a.start.diff(b.start));
            if (firstAvailableProductOfDay) {
                updateDisplayDate(firstAvailableProductOfDay.start);
            }
        },
        [availabilityData, updateDisplayDate],
    );

    useEffect(() => {
        if (!isNil(displayDate)) return;
        const firstAvailableProduct = findFirstAvailableProduct(availabilityData);

        if (firstAvailableProduct) {
            onSelectDate(firstAvailableProduct.start);
        }
    }, [availabilityData, displayDate, onSelectDate]);

    const updateAvailabilitySearchPeriod = (date: TZDate | null) => {
        if (!date) return;

        setAvailabilitySearchPeriod({
            startDay: date.subtract(1, 'month').startOf('month'),
            endDay: date.add(1, 'month').startOf('month').add(1, 'day'),
        });
    };

    const onClose = () => {
        if (trap) trap.deactivate(200);
        setAnchorEl(null);
    };

    const availabilityDataDays = useMemo(
        () =>
            availabilityData.reduce((acc, cur) => {
                if (cur.capacity > 0) {
                    acc.set(cur.start.format('YYYY-MM-DD'), true);
                }
                return acc;
            }, new Map<string, boolean>()),
        [availabilityData],
    );

    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
                zIndex: `${zIndex.alwaysVisible} !important` as any,

                '& > .MuiPaper-root': {
                    maxWidth: 325,
                },
            }}
            container={mountPoint.popover}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={onClose}
            aria-modal={true}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            <BilberryStaticCalendar
                date={displayDate}
                onChange={onSelectDate}
                minDate={minDate}
                maxDate={threeYearsAhead}
                onMonthChange={updateAvailabilitySearchPeriod}
                onYearChange={updateAvailabilitySearchPeriod}
                loading={false}
                shouldDisableDate={(date) => isDateUnavailable(date, availabilityDataDays)}
            />

            <Box
                padding={theme.spacing(1, 0)}
                sx={{
                    '& button:hover': {
                        backgroundColor: () =>
                            customizations.primaryButtonStyle === 'contained'
                                ? darken(theme.palette.primary.main, 0.2)
                                : 'rgba(0, 0, 0, 30%)',
                    },
                }}
            >
                <Grid container justifyContent="center">
                    <Button
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        disabled={!displayDate}
                        onClick={() => onOkClicked(onClose)}
                        role={capitalize(t.select_date)}
                    >
                        {t.ok.toUpperCase()}
                    </Button>
                </Grid>
            </Box>
        </Popover>
    );
}

function onOkClicked(onClose: () => void) {
    onClose();
}

export function isDateUnavailable(
    date: string | null,
    availabilityRecord: Map<string, boolean>,
): boolean {
    if (!date) return false;
    return !availabilityRecord.has(date);
}
