import { BilberryPromoCodeStatus, DiscountSource } from '@repo/types';

/** Converts a giftcard into a discount source, that can be used to perform price calculations */
export const promocodeAsDiscountSource = (promocode?: BilberryPromoCodeStatus): DiscountSource => ({
    name: promocode?.name,
    amount: promocode?.coupon_value,
    type: promocode?.coupon_type === 'percent' ? 'percentage' : 'absolute',
});

/** Function that calculates the applicable products to apply to a promo code
 *  Possible states:
 *
 *   - products: [] , valuecard_product_ids: [] -> Only apply to all products
 *   - products: [1,2,3], valuecard_product_ids: [] -> Only apply to products 1,2,3
 *   - products: [], valuecard_product_ids: [1,2] -> Only apply to value card products 1,2
 *   - products: [1,2,3], valuecard_product_ids: [1,2] -> Applies to both products 1,2,3 and value card products 1,2
 *
 * We can only apply to value cards if there are elements in the value card list
 * If the list of products is empty, we can only apply to all products if the value card list is empty as well.
 * However, if both lists have elements, we want to be able to apply the value card to all elements.
 * @param products A list of value card products that are applicable
 * @param valuecardProductIds A list of products that are applicable
 * @param isValueCard Whether the funciton is called from the value card checkout
 */
export function getApplicableProductsForPromoCode(
    products: number[],
    valuecardProductIds: number[],
    isValueCard?: boolean,
) {
    if (isValueCard) {
        return { isUsingAllProducts: false, applicableProducts: valuecardProductIds };
    } else {
        const canBeAppliedToProducts = valuecardProductIds.length === 0;
        const canBeAppliedToAllProducts = products.length === 0 && valuecardProductIds.length === 0;

        if (canBeAppliedToAllProducts) return { isUsingAllProducts: true, applicableProducts: [] };
        else if (canBeAppliedToProducts)
            return { isUsingAllProducts: false, applicableProducts: products };
        else return { isUsingAllProducts: false, applicableProducts: products };
    }
}
export function isPromoCodeApplicableForProductPurchase(
    promoCode: BilberryPromoCodeStatus,
    productId: number,
    isValueCard?: boolean,
) {
    const valuecardProductIds = promoCode.valuecard_product_ids ?? [];
    const products = promoCode.products ?? [];

    const { isUsingAllProducts, applicableProducts } = getApplicableProductsForPromoCode(
        products,
        valuecardProductIds,
        isValueCard,
    );

    return !productId || applicableProducts.some((id) => id === productId) || isUsingAllProducts;
}
