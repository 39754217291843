/**
 * Exports a {Translations} object based on the user's browser language.
 * If no translation is found for this language, the translation defaults to English.
 *
 * @exports {Translations}
 */

import { RawTranslations, Translations, UserTextCustomizations } from '@repo/types';
import nb from './translations/nb';
import en from './translations/en';
import de from './translations/de';
import es from './translations/es';
import fr from './translations/fr';
import it from './translations/it';
import nl from './translations/nl';
import { customTranslations } from './custom-translations';
import merge from 'lodash-es/merge';
import { parseTranslationTemplate } from './template-parser';

function addParsingUtilsToTranslation(translations: RawTranslations) {
    return Object.fromEntries(
        Object.entries(translations).map(([key, item]: [string, any]) => {
            if (typeof item === 'object') {
                const itemKeys = Object.keys(item);
                itemKeys.forEach((x) => {
                    if (typeof item[x] === 'function') {
                        item[x] = item[x].bind(translations);
                    }
                    if (x === 'template') {
                        item.parsed = (...args: any[]) =>
                            parseTranslationTemplate(
                                (translations[key as keyof Translations] as Record<string, any>)
                                    .template,
                                ...args,
                            );
                    }
                });
                if (itemKeys.includes('singular') && itemKeys.includes('plural')) {
                    item.num = (num: number) =>
                        num === 1
                            ? (translations[key as keyof Translations] as Record<string, any>)
                                  .singular
                            : (translations[key as keyof Translations] as Record<string, any>)
                                  .plural;
                }
            } else if ((en as any)[key]?.template) {
                return [
                    key,
                    {
                        parsed: (...args: any[]) =>
                            parseTranslationTemplate(
                                translations[key as keyof Translations] as string,
                                ...args,
                            ),
                    },
                ];
            }
            return [key, item];
        }),
    ) as Translations;
}

const nb_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, nb, customTranslations['nb'] ?? {}, customTranslations['nb-NO'] ?? {}),
);
const nn_withCustomTranslations = addParsingUtilsToTranslation(
    merge(
        {},
        nb_withCustomTranslations,
        customTranslations['nn'] ?? {},
        customTranslations['nn-NO'] ?? {},
    ),
);
const en_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, en, customTranslations['en'] ?? {}, customTranslations['en-US'] ?? {}),
);
const de_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, de, customTranslations['de'] ?? {}, customTranslations['de-DE'] ?? {}),
);
const es_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, es, customTranslations['es'] ?? {}, customTranslations['es-ES'] ?? {}),
);
const fr_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, fr, customTranslations['fr'] ?? {}, customTranslations['fr-FR'] ?? {}),
);
const it_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, it, customTranslations['it'] ?? {}, customTranslations['it-IT'] ?? {}),
);
const nl_withCustomTranslations = addParsingUtilsToTranslation(
    merge({}, nl, customTranslations['nl'] ?? {}, customTranslations['nl-NL'] ?? {}),
);

export const allTranslations: { [locale: string]: Translations } = {
    'nb-NO': nb_withCustomTranslations,
    'nn-NO': nn_withCustomTranslations,
    'en-US': en_withCustomTranslations,
    no: nb_withCustomTranslations,
    nb: nb_withCustomTranslations,
    nn: nn_withCustomTranslations,
    en: en_withCustomTranslations,
    de: de_withCustomTranslations,
    'de-DE': de_withCustomTranslations,
    es: es_withCustomTranslations,
    'es-ES': es_withCustomTranslations,
    fr: fr_withCustomTranslations,
    'fr-FR': fr_withCustomTranslations,
    it: it_withCustomTranslations,
    'it-IT': it_withCustomTranslations,
    nl: nl_withCustomTranslations,
    'nl-NL': nl_withCustomTranslations,
};

export const defaultTranslation: Translations = en_withCustomTranslations;

const findSupportedLanguageKey = (
    locale: string,
    customTextLanguageKeys: UserTextCustomizations[keyof UserTextCustomizations],
): string | undefined => {
    if (customTextLanguageKeys === undefined) return undefined;

    const hasCustomTextForLocale = locale in customTextLanguageKeys;
    if (hasCustomTextForLocale) {
        return locale;
    }
    const [firstPart, lastPart] = locale.split('-');
    const hasCustomTextForFirstPart = firstPart in customTextLanguageKeys;
    const hasCustomTextForLastPart = lastPart in customTextLanguageKeys;
    if (hasCustomTextForFirstPart) {
        return firstPart;
    } else if (hasCustomTextForLastPart) {
        return lastPart;
    }
    return undefined;
};

export const getCustomOrDefaultText = (
    textCustomizations: UserTextCustomizations | undefined,
    textCustomizationKey: keyof UserTextCustomizations | undefined,
    locale: string,
    defaultText: string,
): string => {
    if (
        !(textCustomizations && textCustomizationKey && textCustomizationKey in textCustomizations)
    ) {
        return defaultText;
    }

    const customTextLanguageKeys = textCustomizations[textCustomizationKey];
    const translation = findSupportedLanguageKey(locale, customTextLanguageKeys);

    if (
        !translation ||
        !customTextLanguageKeys ||
        typeof customTextLanguageKeys[translation] !== 'string' // Only simple strings are supported for custom user texts
    ) {
        return defaultText;
    }

    return customTextLanguageKeys[translation] as string;
};
