import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useLocale, formatCurrency } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';

interface IProps {
    totalPrice: number;
    showPrintButton: boolean;
    showNextButton: boolean;
    goPayClicked?: () => void;
    isCreatingGiftcard: boolean;
}

export default function SummaryGiftcardPayment(props: IProps): JSX.Element {
    const { totalPrice, showPrintButton, showNextButton, goPayClicked, isCreatingGiftcard } = props;
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [currency] = useAtom(currencyAtom);

    return (
        <Grid container direction="column" justifyContent="space-between" wrap="nowrap">
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                wrap="nowrap"
                mt={theme.spacing(1)}
            >
                {showPrintButton && (
                    <Grid container item xs={4} alignItems="center">
                        <Button
                            variant={customizations.primaryButtonStyle}
                            color="primary"
                            onClick={() => window.print()}
                            sx={{ whiteSpace: 'nowrap', marginRight: theme.spacing(1) }}
                        >
                            {largeScreen ? capitalize(t.print_receipt) : capitalize(t.print)}
                        </Button>
                    </Grid>
                )}
                <Grid container justifyContent="space-between" alignItems="flex-end" wrap="nowrap">
                    <Typography
                        variant="h6"
                        align="right"
                        color="textSecondary"
                        width="100%"
                        pr={theme.spacing(1.5)}
                    >
                        {t.total.toUpperCase()}&nbsp;
                    </Typography>
                    <Typography
                        variant="h4"
                        align="right"
                        color="textSecondary"
                        fontSize={28}
                        fontWeight={customizations.boldFontWeight}
                        whiteSpace="nowrap"
                        mb="-1px"
                    >
                        {formatCurrency(locale, currency, totalPrice)}
                    </Typography>
                </Grid>
            </Grid>
            {showNextButton && (
                <Grid container item justifyContent="flex-end">
                    <Button
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        sx={{ whiteSpace: 'nowrap', marginTop: theme.spacing(4) }}
                        onClick={goPayClicked}
                        disabled={isCreatingGiftcard}
                    >
                        <ButtonDisplay isCreatingGiftcard={isCreatingGiftcard} />
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

function ButtonDisplay({ isCreatingGiftcard }: { isCreatingGiftcard: boolean }): JSX.Element {
    const { t } = useLocale();
    if (isCreatingGiftcard) {
        return (
            <Box>
                <CircularProgress color="primary" />
                {capitalize(t.next)} - {capitalize(t.payment)}
            </Box>
        );
    } else {
        return (
            <Box>
                {capitalize(t.next)} - {capitalize(t.payment)}
            </Box>
        );
    }
}
