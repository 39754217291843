import { decycle, retrocycle } from './cycle';
import { TZDate, tzdate } from '@repo/tzdate';

export function serialize(obj: any): any {
    const decycled = decycle(obj, dayjsReplacer);
    return JSON.stringify(decycled);
}

export function deserialize(obj: any): any {
    const retrocycled = retrocycle(JSON.parse(obj), dayjsReviver);
    return retrocycled;
}

export function dayjsReplacer(value: any) {
    if (value instanceof TZDate) {
        return { $dayjs: value.toStringForLocalStorage() };
    } else {
        return value;
    }
}

export function dayjsReviver(value: any) {
    if (typeof value === 'object' && value !== null) {
        if ('$dayjs' in value) {
            return tzdate(value.$dayjs);
        }
    }
    return value;
}
