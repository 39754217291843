import { Button, Link, SxProps, Typography, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { route } from 'src/components/common/router/Router';
import { useRef, useState } from 'react';
import { getCustomOrDefaultText, useLocale } from '@repo/i18n';
import { Product, UserTextCustomizations } from '@repo/types';
import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { largeButton } from 'src/components/utils/jss/ButtonStyleUtils';
import { bookingWidgetInputStyle } from 'src/components/utils/jss/TextFieldStyleUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { Icons } from './ProductCardIcons';
import { ProductCardReadMoreDialog } from './ProductCardReadMoreDialog';

export function ProductCardContainerLandscape(props: {
    children: React.ReactNode[] | React.ReactNode;
    border?: string;
    borderRadius?: number;
    p?: { xsContainer?: number; mdContainer?: number };
}) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const {
        children,
        border,
        borderRadius = parseInt(customizations.borderRadius.toString()) * 3,
    } = props;

    return (
        <Stack
            bgcolor={customizations.productCardColor}
            color={customizations.productCardTextColor}
            border={border}
            p={props.p?.xsContainer ?? 2}
            direction="column"
            sx={{
                '@container (width > 600px)': {
                    '&': {
                        flexDirection: 'row',
                        padding: theme.spacing(props.p?.mdContainer ?? 3),
                    },
                },
            }}
            justifyContent="space-between"
            gap={3}
            borderRadius={borderRadius}
            overflow="hidden"
            position="relative"
            boxShadow={customizations.imageShadow ? theme.shadows[3] : undefined}
            flexWrap="nowrap"
        >
            {children}
        </Stack>
    );
}

type ProductCardInfoContentLandscapeProps = {
    title?: string | null | JSX.Element;
    description?: string | null;
    fromPrice?: number | null;
    originalPrice?: number | null;
    url: string;
    imageUrl?: string;
    imageAlt?: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    customPriceLabel?: string | JSX.Element;
    hidePriceIcon?: boolean;
    hideImage?: boolean;
    readMoreProductId?: string;
    readMoreLinkVariant?: 'href' | 'popup' | 'none';
    icons?: React.ReactNode[] | React.ReactNode;
    onClick?: () => void;
    showFrom?: boolean;
    hideFromLabel?: boolean;
    sx?: SxProps;
    infoAndImageAsLandscape?: boolean;
    iconsPosition?: 'above_title' | 'below_title' | 'below_image';
    maxImageHeightDesktop?: number;
    extraInfoBelowTitle?: JSX.Element;
    maxWidth?: number | string;
    body?: JSX.Element;
    hideTitle?: boolean;
    hideDescription?: boolean;
};

export function ProductCardInfoLandscape(props: ProductCardInfoContentLandscapeProps) {
    const {
        url,
        imageUrl,
        imageAlt,
        sx,
        hideImage = false,
        infoAndImageAsLandscape = false,
        maxImageHeightDesktop = 700,
        iconsPosition,
    } = props;
    const customizations = useCustomizations();

    return (
        <Box width="100%" height="100%" sx={{ containerType: 'inline-size' }}>
            <Stack
                alignItems="flex-start"
                gap={2}
                height="100%"
                width="100%"
                position="relative"
                direction={infoAndImageAsLandscape ? 'row' : 'column'}
                sx={{
                    ...sx,
                    '@container(max-width: 500px)': {
                        flexDirection: 'column',
                    },
                }}
            >
                {imageUrl && !hideImage && (
                    <Stack gap={2} width="100%">
                        <Link
                            href={url}
                            display="block"
                            position="relative"
                            width="auto"
                            overflow="hidden"
                            sx={{
                                aspectRatio: '16 / 9',
                                '@container (width > 500px)': {
                                    maxHeight: `${maxImageHeightDesktop}px`,
                                },
                            }}
                            borderRadius={`${customizations.borderRadius * 2}px`}
                        >
                            <Box
                                component="img"
                                src={imageUrl}
                                alt={imageAlt}
                                width="100%"
                                height="100%"
                                sx={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                }}
                            />
                        </Link>
                        {infoAndImageAsLandscape && iconsPosition === 'below_image' && props.icons}
                    </Stack>
                )}
                <ProductCardInfoContentLandscape {...props} />
            </Stack>
        </Box>
    );
}

export function ProductCardInfoContentLandscape(props: ProductCardInfoContentLandscapeProps) {
    const {
        iconsPosition = 'above_title',
        extraInfoBelowTitle,
        maxWidth = 500,
        body,
        hideTitle,
        hideDescription,
    } = props;
    const { t } = useLocale();

    const customizations = useCustomizations();
    const theme = useTheme();

    const [isDisplayingMoreInfo, setIsDisplayingMoreInfo] = useState(false);

    const focusRef = useRef<HTMLAnchorElement>(null);

    return (
        <Stack
            maxWidth={maxWidth}
            gap={props.description ? 2 : 1}
            overflow="hidden"
            width="100%"
            position="relative"
        >
            {iconsPosition === 'above_title' && props.icons}
            <Box>
                {!hideTitle &&
                    (typeof props.title === 'string' ? (
                        <Typography
                            variant="h3"
                            color={customizations.productCardTextColor}
                            fontSize={theme.typography.fontSize * 1.5}
                            lineHeight={1.1}
                            fontWeight="bold"
                        >
                            {props.title ?? ''}
                        </Typography>
                    ) : (
                        props.title
                    ))}
                {extraInfoBelowTitle}
            </Box>
            {iconsPosition === 'below_title' && props.icons}
            {((!hideDescription && props.description) || props.readMoreLinkVariant) && (
                <Box
                    height="100%"
                    sx={{
                        '& .MuiTypography-root': {
                            color: customizations.productCardTextColor,
                        },
                    }}
                >
                    {!hideDescription && props.description && parseHtmlToMui(props.description)}
                    {props.readMoreLinkVariant === 'popup' && (
                        <Link
                            onClick={() => {
                                setIsDisplayingMoreInfo(true);
                            }}
                            sx={{
                                display: 'block',
                                cursor: 'pointer',
                                mt: 1,
                                color: `${customizations.linkColor} !important`,
                                fontFamily: theme.typography.body1.fontFamily ?? '',
                            }}
                        >
                            {t.read_more}
                        </Link>
                    )}
                    {props.readMoreLinkVariant === 'href' && (
                        <Link
                            href={props.url}
                            sx={{
                                display: 'block',
                                cursor: 'pointer',
                                mt: 1,
                                color: `${customizations.productCardLinkColor} !important`,
                                fontFamily: theme.typography.body1.fontFamily ?? '',
                            }}
                        >
                            {t.read_more}
                        </Link>
                    )}
                    {body && <Box mt={2}>{body}</Box>}
                </Box>
            )}
            {props.readMoreLinkVariant === 'popup' && props.readMoreProductId && (
                <ProductCardReadMoreDialog
                    focusRef={focusRef}
                    display={isDisplayingMoreInfo}
                    setDisplay={setIsDisplayingMoreInfo}
                    productId={props.readMoreProductId}
                />
            )}
        </Stack>
    );
}

export function ProductCardButton(props: {
    onClick?: () => void;
    url?: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
}) {
    const { t, locale } = useLocale();

    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const theme = useTheme();

    // Support linking directly to gift-card-checkout ( HL requirement )
    let { onClick } = props;
    let alwaysUseOnClick = false;
    if (new RegExp('.+#/gift-card-checkout').test(props.url ?? '')) {
        onClick = () => {
            route('/gift-card-checkout');
        };
        alwaysUseOnClick = true;
    }

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onClick?.();
        }
    };

    const colors = bookingWidgetInputStyle(
        customizations,
        customizations.productCardColor,
        customizations.productCardTextColor,
    );

    return (
        <Button
            variant="outlined"
            color="primary"
            sx={{
                ...largeButton(theme),
                color: colors.color,
                backgroundColor: colors.backgroundColor,
                borderColor: colors.color,
            }}
            onClick={onClick}
            onKeyDown={handleEnter}
            href={alwaysUseOnClick ? '' : props.url}
        >
            {getCustomOrDefaultText(
                configurations.textCustomizations,
                props.buttonCustomizationKey,
                locale,
                t.read_more_and_book,
            )}
        </Button>
    );
}

export type ProductCard2Props = {
    product: Product;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    disableMaxWidth?: boolean;
    body?: JSX.Element;
    hidePriceIcon?: boolean;
    readMoreProductId?: string;
    readMoreLinkVariant?: 'href' | 'popup' | 'none';
    hideImage?: boolean;
    hideIcons?: boolean;
    url?: string;
    border?: string;
    borderRadius?: number;
    infoAndImageAsLandscape?: boolean;
    iconsPosition?: 'above_title' | 'below_title' | 'below_image';
    iconsRowJustify?: 'space-evenly' | 'flex-start';
    maxImageHeightDesktop?: number;
    hideTitle?: boolean;
    hideDescription?: boolean;
};

export function ProductCard2Landscape(props: ProductCard2Props): JSX.Element {
    const {
        product,
        body,
        hidePriceIcon,
        hideIcons,
        hideImage,
        readMoreProductId,
        readMoreLinkVariant,
        url = product.url,
        border,
        borderRadius,
        infoAndImageAsLandscape,
        iconsPosition,
        maxImageHeightDesktop,
        hideTitle,
        hideDescription,
    } = props;

    return (
        <Box sx={{ containerType: 'inline-size' }}>
            <ProductCardContainerLandscape border={border} borderRadius={borderRadius}>
                <ProductCardInfoLandscape
                    url={url}
                    hideImage={hideImage}
                    hideTitle={hideTitle}
                    hideDescription={hideDescription}
                    readMoreProductId={readMoreProductId}
                    readMoreLinkVariant={readMoreLinkVariant}
                    imageUrl={product.coverImage.src}
                    imageAlt={product.coverImage.alt}
                    title={product.title}
                    description={product.shortDescription}
                    maxImageHeightDesktop={maxImageHeightDesktop}
                    icons={
                        !hideIcons ? (
                            <Icons
                                product={product}
                                hidePriceIcon={hidePriceIcon}
                                size="1.25em"
                                containerJustify="flex-start"
                                iconJustify="flex-start"
                            />
                        ) : undefined
                    }
                    sx={
                        body && !infoAndImageAsLandscape
                            ? {
                                  '@container (width > 600px)': {
                                      maxWidth: '50%',
                                  },
                              }
                            : undefined
                    }
                    iconsPosition={iconsPosition}
                    infoAndImageAsLandscape={infoAndImageAsLandscape}
                    body={infoAndImageAsLandscape ? body : undefined}
                />
                {!infoAndImageAsLandscape && body}
            </ProductCardContainerLandscape>
        </Box>
    );
}
