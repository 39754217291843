import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import { Button, Link as MuiLink } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useLocale } from '@repo/i18n';

type BackButtonProps = {
    onClick?: () => void | false;
    label?: string;
};

export default function BackButton({ onClick, label }: BackButtonProps) {
    const { t } = useLocale();
    const customizations = useCustomizations();

    return (
        <MuiLink
            aria-roledescription="link"
            component={Button}
            onClick={() => {
                if (onClick && onClick() === false) return;

                window.location.href = window.location.pathname + window.location.search;
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                    if (onClick && onClick() === false) return;

                    window.location.href = window.location.pathname + window.location.search;
                }
            }}
            underline="hover"
            sx={{
                color: customizations.linkColor,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1.75,
                width: 'fit-content',
                textTransform: 'none',
                padding: '0px',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: customizations.linkColor,
                },
            }}
            variant="body1"
        >
            <ChevronLeftRoundedIcon sx={{ color: customizations.linkColor, marginRight: 0.5 }} />
            {label ?? t.backToWebsite}
        </MuiLink>
    );
}
