import { BilberryPromoCodeStatus, CartItem } from '@repo/types';
import { createIntentMultiRequest } from '@repo/widget-utils/booking/createIntent';
import { shouldUsePaymentPlansForCart } from '@repo/widget-utils/cart/cartUtils';
import { useMemo } from 'react';
import { useDebounce } from 'src/hooks/common/useDebounce';
import { paymentIntentAtom } from 'src/state/paymentIntentAtom.atom';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useAtom } from 'ximple';

export default function useIntentMultiRequest(
    isCompanyContact: boolean,
    cartItems: CartItem[],
    appliedPromoCode: BilberryPromoCodeStatus | null,
) {
    const { currentSite } = useMemberContext();

    const [{ valueCardIdsToIgnore, valueCardProductIdsToIgnore, valueCardProductTypeIdsToIgnore }] =
        useAtom(paymentIntentAtom);

    const isUsingPaymentPlan = useMemo(() => {
        return shouldUsePaymentPlansForCart(cartItems, currentSite, isCompanyContact);
    }, [cartItems, currentSite, isCompanyContact]);

    const request = useMemo(() => {
        return isUsingPaymentPlan
            ? createIntentMultiRequest(
                  cartItems,
                  valueCardIdsToIgnore,
                  valueCardProductIdsToIgnore,
                  valueCardProductTypeIdsToIgnore,
                  appliedPromoCode?.coupon_code,
              )
            : null;
    }, [
        isUsingPaymentPlan,
        cartItems,
        appliedPromoCode,
        valueCardIdsToIgnore,
        valueCardProductIdsToIgnore,
        valueCardProductTypeIdsToIgnore,
    ]);

    const debouncedRequest = useDebounce(request, 500);

    return {
        isUsingPaymentPlan,
        request: debouncedRequest,
    };
}
