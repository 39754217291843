import {
    MembershipConsumer,
    MembershipIntentMultiResponse,
    MembershipPaymentPlan,
    MembershipTicket,
    CartItem,
    TicketOptionWithQuantity,
} from '@repo/types';
import { MembershipIntentMultiRequest } from '../api/membership-api';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { getMembershipActivityReservationsFromCart, getTimeslotsFromCart } from '../ProductMapper';
import { createInitialCompanyInfo } from '../checkout-info-helper';

export function createIntentMultiRequest(
    cartItems: CartItem[],
    valueCardIdsToIgnore?: number[],
    ticketsToIgnore?: number[],
    ticketTypesToIgnore?: number[],
    promoCodeReference?: string,
) {
    const timeslotCartItems = cartItems.filter(
        (item) => item.products[0]?.product?.type === 'timeslot',
    );
    const timeslotsReservation = getTimeslotsFromCart(
        timeslotCartItems,
        valueCardIdsToIgnore ?? [],
        ticketsToIgnore ?? [],
        ticketTypesToIgnore ?? [],
    );
    if (timeslotsReservation) {
        timeslotsReservation.reservations = timeslotsReservation?.reservations.map((r, i) => ({
            ...r,
            tickets: ticketOptionsToIntent(
                timeslotCartItems[i].ticketOptions,
                valueCardIdsToIgnore,
                ticketsToIgnore,
                ticketTypesToIgnore,
            ),
        }));
    }

    const consumer: MembershipConsumer = {
        city: '',
        email: 'info@bilberry.no',
        phoneNumber: '',
        phonePrefix: '',
        addressLine1: '',
        addressLine2: '',
        country: 'no',
        lastName: '',
        postalCode: '',
        firstName: '',
        receiveNewsletter: false,
    };

    const tours = getMembershipActivityReservationsFromCart(
        cartItems,
        {
            contactPerson: {
                city: '',
                email: '',
                phone: { number: '', dialCode: '' },
                address: '',
                country: 'no',
                lastName: '',
                postCode: '',
                firstName: '',
                hasAcceptedTerms: true,
                hasAcceptedPrivacy: true,
                receiveNewsletter: false,
            },
            orderQuestionnaire: cartItems.reduce((acc, item) => {
                acc[getCartItemId(item)] = {};
                return acc;
            }, {} as any),
            guestListQuestionnaire: cartItems.reduce((acc, item) => {
                acc[getCartItemId(item)] = {};
                return acc;
            }, {} as any),
            copyFromContactPerson: false,
            companyInfo: createInitialCompanyInfo(null, null),
            contactType: 'person',
        },
        valueCardIdsToIgnore ?? [],
        ticketsToIgnore ?? [],
        ticketTypesToIgnore ?? [],
    );

    const request: MembershipIntentMultiRequest = {
        consumer,
        timeslotReservations: timeslotsReservation?.reservations ?? [],
        activityReservations: tours,
        checkoutUrl: window.location.origin + window.location.pathname,
        coupon_code: promoCodeReference,
    };

    return request;
}

export function ticketOptionsToIntent(
    ticketOptions: TicketOptionWithQuantity[],
    valueCardIdsToIgnore?: number[],
    ticketsToIgnore?: number[],
    ticketTypesToIgnore?: number[],
): MembershipTicket[] {
    const allParticipants = ticketOptions.flatMap((x) => {
        return Array(x.quantity).fill({
            defaultTicketOptionId: x.defaultId,
            ignoredValueCardIds: valueCardIdsToIgnore || [],
            ignoredValueCardProductIds: ticketsToIgnore || [],
            ignoredValueCardProductTypeIds: ticketTypesToIgnore || [],
        });
    });
    return allParticipants as MembershipTicket[];
}

export type TicketOptionWithPaymentPlans = {
    ticketOption: TicketOptionWithQuantity;
    paymentPlans: MembershipPaymentPlan[];
};

export function getTicketOptionsWithPaymentPlans(
    item: CartItem,
    newPaymentPlans: MembershipIntentMultiResponse,
) {
    if (!newPaymentPlans) return [];

    const activityReservation = newPaymentPlans.activityReservations.find((reservation) => {
        return Object.keys(reservation.paymentPlanByTourId).some((key) =>
            item.products.some((p) => p.id === key || p.collectedIds.includes(key)),
        );
    });

    const timeslotsReservation = newPaymentPlans.timeslotReservations.find((reservation) =>
        reservation.reservation.timeslotIds.every((id) =>
            item.products[0].timeslots.some((t) => t.id === id.toString()),
        ),
    );

    let paymentPlans: Array<{ tickets: MembershipPaymentPlan[] }> | undefined;
    if (activityReservation) {
        paymentPlans = Object.values(activityReservation.paymentPlanByTourId);
    } else if (timeslotsReservation) {
        paymentPlans = [timeslotsReservation.paymentPlan];
    }

    if (item.products.length < 2) {
        return item.ticketOptions.map((t) => {
            const paymentPlansForTicketOption =
                paymentPlans
                    ?.flatMap((plan) => plan.tickets)
                    .filter(
                        (p) =>
                            p.ticket.defaultTicketOptionId.toString() === t.defaultId ||
                            p.ticket.defaultTicketOptionId.toString() === t.id,
                    ) ?? [];

            return {
                ticketOption: t,
                paymentPlans: paymentPlansForTicketOption,
            } as TicketOptionWithPaymentPlans;
        });
    }

    return item.ticketOptions.map((t) => {
        const paymentPlansForTicketOption =
            paymentPlans
                ?.flatMap((plan) => plan.tickets)
                .filter(
                    (p) =>
                        item.products
                            .flatMap((p) => p.ticketOptions)
                            .find((to) => to.id === p.ticket.defaultTicketOptionId.toString())
                            ?.defaultId === t.defaultId,
                ) ?? [];

        return {
            ticketOption: t,
            paymentPlans: paymentPlansForTicketOption,
        } as TicketOptionWithPaymentPlans;
    });
}
