import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { tzdate, TZDate } from '@repo/tzdate';
import { localeAtom, useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';

interface IProps {
    day: TZDate;
    onDayChange: (param: TZDate) => void;
    days: TZDate[];
    useMonths: boolean;
}

export default function UpcomingDaySelector(props: IProps) {
    const { day, onDayChange, days, useMonths } = props;
    const { t } = useLocale();

    const handleChange = (event: any, newDay: TZDate) => {
        if (newDay) {
            onDayChange(newDay);
        }
    };
    const theme = useTheme();

    const shouldUseDates =
        (days.length > 7 || days[0]?.isAfter(TZDate.now().add(6, 'day'))) ?? false;

    const weekdays: string[] = [
        capitalize(t.sunday),
        capitalize(t.monday),
        capitalize(t.tuesday),
        capitalize(t.wednesday),
        capitalize(t.thursday),
        capitalize(t.friday),
        capitalize(t.saturday),
    ];
    const months: string[] = [
        capitalize(t.january),
        capitalize(t.february),
        capitalize(t.march),
        capitalize(t.april),
        capitalize(t.may),
        capitalize(t.june),
        capitalize(t.july),
        capitalize(t.august),
        capitalize(t.september),
        capitalize(t.october),
        capitalize(t.november),
        capitalize(t.december),
    ];

    return (
        <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
            <ToggleButtonGroup
                sx={{ display: 'flex', maxWidth: '100%' }}
                value={day}
                exclusive
                onChange={handleChange}
            >
                {days.map((currDay) => (
                    <ToggleButton
                        sx={{
                            whiteSpace: 'nowrap',
                            margin: theme.spacing(1),
                            '&:first-of-type': {
                                marginLeft: 0,
                            },
                            '&:last-of-type': {
                                marginRight: 0,
                            },
                        }}
                        key={currDay.unix()}
                        value={currDay}
                    >
                        {useMonths
                            ? months[currDay.month()]
                            : weekdayToString(currDay, weekdays, shouldUseDates)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}

function weekdayToString(day: TZDate, weekdays: string[], shouldUseDates: boolean): string {
    const { t } = localeAtom.subject.value;
    const now = TZDate.now();
    if (shouldUseDates) return day.formatInCalendarTimezone('D. MMM YYYY');
    if (day.isSame(now, 'day')) return t.today;
    if (day.isSame(now.add(1, 'day'), 'day')) return t.tomorrow;
    return weekdays[day.day()];
}
