import { Product, ProductInstance } from '@repo/types';

/**
 * The total number of entrants for a tour (or selected tour if multi-day product, is_accommodation or is_rental), combining all ticket options.
 * If the product is a product instance collection, the max entrants is calculated based on the combined capacity of all product instances.
 * Otherwise, the max entrants is the capacity of the product, or for multi-day products, the minimum capacity of all selected products
 * (ie. the day with the lowest capacity).
 *
 * @param selectedProducts {ProductInstance[]} The products for which to calculate the max entrants
 * @returns
 */
export function getProductMaxEntrants(selectedProducts: ProductInstance[] | undefined) {
    if (!selectedProducts) return undefined;

    const isProductInstanceCollection = selectedProducts.some(
        (p) => p.productInstanceCollectionId !== null,
    );

    if (selectedProducts.length === 1) {
        if (isProductInstanceCollection) {
            // In this case the actual product instances are registered in the ticketOptions
            const allProducts = selectedProducts.flatMap((p) =>
                p.ticketOptions.flatMap((t) => t.productInstances),
            );
            return allProducts.reduce((acc, cur) => acc + cur.capacity, 0);
        } else {
            return selectedProducts[0].capacity;
        }
    } else {
        if (isProductInstanceCollection) {
            // In this case the max entrants of the product is effectively useless, so we set it to the maximum safe integer
            return Number.MAX_SAFE_INTEGER;
        }
        const capacities = selectedProducts.map((p) => p.capacity);
        return Math.min(...capacities.slice(0, selectedProducts.length - 1));
    }
}

export function getProductMinEntrants(
    selectedProducts: ProductInstance[] | undefined,
    product: Product | null,
) {
    const defaultValue = product?.minEntrants ?? 1;
    if (!selectedProducts) return defaultValue;
    else if (selectedProducts.length === 1) return selectedProducts[0].minEntrants ?? defaultValue;
    else {
        const multipleDayEntrants =
            Math.max(
                ...selectedProducts
                    .slice(0, selectedProducts.length - 1)
                    .map((p) => p.minEntrants ?? 0),
            ) ?? 0;
        if (multipleDayEntrants !== 0) return multipleDayEntrants;
        else return defaultValue;
    }
}
