import { instantiateShadowRoot } from 'src/utils/widget/shadowRootHelper';
import { BilberryCustomElementBase } from './BilberryCustomElementBase';

export default class BilberryCustomElement extends BilberryCustomElementBase {
    protected _shadowRoot: ShadowRoot;
    protected _mountPoint: HTMLDivElement;
    protected _popoverMountPoint: HTMLDivElement;

    constructor() {
        super();
        const { mountPoint, shadowRoot, popoverMountPoint } = instantiateShadowRoot(this);
        this._shadowRoot = shadowRoot;
        this._mountPoint = mountPoint;
        this._popoverMountPoint = popoverMountPoint;
    }

    protected connectedCallback(): void {
        super.connectedCallback();
    }
}
