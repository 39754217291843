import { Box, useTheme } from '@mui/material';

import { parseHtmlToMui } from 'src/components/utils/html-parser/parseBilberryHtmlToMui';
import { useProduct } from '@repo/widget-utils/api/api';

const Container = (props: { children: any }) => {
    const theme = useTheme();
    return (
        <Box
            p={theme.spacing(0, 2, 4, 0)}
            sx={{
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(0, 0, 4, 0),
                },
            }}
        >
            {props.children}
        </Box>
    );
};

export default function ProductDetailsWidgetRoot(props: { productCatalogId: string }) {
    const { data: product } = useProduct(props.productCatalogId);

    return (
        <>
            <Container>{parseHtmlToMui(product?.longDescription ?? '')}</Container>
            {product?.additionalInfo && (
                <Container>{parseHtmlToMui(product.additionalInfo)}</Container>
            )}
        </>
    );
}
