import Close from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';

import { Product } from '@repo/types';
import ProductGallery2, {
    ProductGalleryAlign,
} from 'src/widgets/activities/product/product-gallery-2/gallery/ProductGallery2';

type AccommodationOverviewHeroProps = {
    accommodation: Product;
    onClickClose?: () => void;
};

export default function AccommodationOverviewHero({
    accommodation,
    onClickClose,
}: AccommodationOverviewHeroProps) {
    const images = [accommodation.coverImage, ...accommodation.images];

    return (
        <Grid
            container
            direction="column"
            mb={3}
            wrap="nowrap"
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                overflow: 'hidden',
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                wrap="nowrap"
            >
                <Typography variant="h1" color="textPrimary" mb={3}>
                    {accommodation.title}
                </Typography>
                <IconButton
                    onClick={onClickClose}
                    sx={(theme) => ({
                        padding: theme.spacing(2),
                        margin: theme.spacing(-2, -2, 0, 0),
                    })}
                >
                    <Close fontSize="large" />
                </IconButton>
            </Grid>
            {images.length > 0 && (
                <ProductGallery2
                    imageList={images}
                    numVisibleImages={{
                        numXs: 1,
                        numSm: 1,
                        numMd: 1,
                        numLg: 1,
                    }}
                    align={ProductGalleryAlign.CENTER}
                />
            )}
        </Grid>
    );
}
