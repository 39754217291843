import { BilberryWidgetEventsContentful } from './events.types';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { getPriceSummaryFromCartItems } from '@repo/widget-utils/price-helper';
import { BilberryCreateGiftcardRequest, BilberryPromoCodeStatus } from '@repo/types';
import { ValueCardPurchase } from 'src/state/valueCard';
import { companyAtom } from 'src/state/company';
import { MembershipValueCardProduct } from '@repo/types';
import { CartItem, Package, Product } from '@repo/types';
import { sumBy } from 'lodash-es';

export function mapCartItemToWidgetEvent(
    cartItem: CartItem,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const priceSummary = getPriceSummaryFromCartItems([cartItem], promoCode);
    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoCodeReductionForProduct =
        !!promoCode &&
        (promoCode.products.find((pId) => cartItem.products.find((p) => p.id === pId.toString())) ||
            promoCode.products?.length === 0)
            ? promoCode.coupon_type === 'percent'
                ? (promoCode.coupon_value / 100) * priceSummary.totalPrice
                : promoCode.coupon_value
            : 0;
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                : undefined,
        products: cartItem.products.map((p) => ({
            currency: currencyAtom.subject.value.currency,
            priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
            product: {
                productTitle: p.product?.title ?? '',
                productId: p.product?.id ?? '',
                productType: p.product?.type as any,
            },
            prices: cartItem.ticketOptions
                .filter((to) => to.quantity > 0)
                .map((to) => ({
                    priceType: to.ticketCategoryId,
                    numberOfTravelers: to.quantity,
                    totalIncVat: to.price * to.quantity - promoCodeReductionForProduct,
                    totalExVat:
                        to.price * to.quantity -
                        to.vatAmount * to.quantity -
                        (to.vatAmount / to.price) * promoCodeReductionForProduct,
                })),
            promoCode:
                promoCodeIsProductSpecific && promoCodeReductionForProduct > 0 && promoCode
                    ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                    : undefined,
        })),
    };
}

export function mapMembershipBookingToWidgetEvent(
    purchase: ValueCardPurchase,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const currentId =
        purchase.category === 'membership' ? purchase.membership.id : purchase.credits.id;
    const currentValueCard = companyAtom.subject.value.company?.valueCardProducts?.find(
        ({ id }) => id === currentId,
    );

    const totalPrice = (currentValueCard?.price ?? 0) / 100;
    const totalNetPrice = (currentValueCard?.netPrice ?? 0) / 100;

    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoCodeReductionForProduct =
        !!promoCode && (promoCode.products.includes(currentId) || promoCode.products?.length === 0)
            ? promoCode.coupon_type === 'percent'
                ? (promoCode.coupon_value / 100) * totalPrice
                : promoCode.coupon_value
            : 0;

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: totalPrice,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                : undefined,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'ValueCard',
                    productId: currentId?.toString() ?? '',
                    productTitle: `${currentValueCard?.name}${
                        currentValueCard?.valueCardProductCategory
                            ? ' - ' + currentValueCard.valueCardProductCategory.name
                            : ''
                    }`,
                },

                priceIncVat: totalPrice,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: totalPrice,
                        totalExVat: totalNetPrice,
                        numberOfTravelers: 1,
                    },
                ],
                promoCode:
                    promoCodeIsProductSpecific && promoCodeReductionForProduct > 0 && promoCode
                        ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                        : undefined,
            },
        ],
    };
}

export function mapProductToWidgetEvent(
    product: Product,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: product.fromPrice ?? 0,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: product.fromPrice ?? 0,
                prices: [],
                product: {
                    productTitle: product.title,
                    productId: product.id,
                    productType: product.type,
                },
            },
        ],
    };
}

export function mapPackageToWidgetEvent(
    product: Package,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: product.fromPrice ?? 0,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: product.fromPrice ?? 0,
                prices: [],
                product: {
                    productTitle: product.title,
                    productId: product.id,
                    productType: 'Package',
                },
            },
        ],
    };
}

export function mapMembershipValueCardProductToWidgetEvent(
    card: MembershipValueCardProduct,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: card.price / 100,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'ValueCard',
                    productId: card.id.toString(),
                    productTitle: `${card.name}${
                        card?.valueCardProductCategory
                            ? ' - ' + card.valueCardProductCategory.name
                            : ''
                    }`,
                },
                priceIncVat: card.price / 100,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: card.price / 100,
                        totalExVat: card.netPrice / 100,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}

export function mapBilberryGiftcardToWidgetEvent(
    giftCard: BilberryCreateGiftcardRequest,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: giftCard.amount,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Giftcard',
                    productId: 'Giftcard',
                    productTitle: 'Giftcard',
                },
                priceIncVat: giftCard.amount,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: giftCard.amount,
                        totalExVat: giftCard.amount,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}

export function mapBilberryGiftcardUsageToWidgetEvent(
    appliedGiftCards: {
        priceReduction: number;
        giftcardReference: string;
    }[],
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const totalPriceReduction = sumBy(appliedGiftCards, (x) => x.priceReduction);

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: -totalPriceReduction,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Applied Giftcard',
                    productId: 'Applied Giftcard',
                    productTitle: 'Applied Giftcard',
                },
                priceIncVat: -totalPriceReduction,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: -totalPriceReduction,
                        totalExVat: -totalPriceReduction,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}
