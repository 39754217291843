import { createRoot } from 'react-dom/client';
import { useEffect, useState, createRef } from 'react';
import {
    getStringAttribute,
    getYesAttribute,
} from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import Booking from '../activity-booking/booking/Booking';
import { BookingContextProvider } from 'src/widgets/BookingContext';

export function BookingInline(props: {
    productCatalogId: string;
    isTimeslots: boolean;
    isAccommodation: boolean;
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
}): JSX.Element {
    const ref = createRef<HTMLDivElement>();
    const [popoverMountPoint, setPopoverMountPoint] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current) setPopoverMountPoint(ref.current);
    }, []);

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <BookingContextProvider initialTicketOptions={[]}>
                <Booking
                    productCatalogId={props.productCatalogId}
                    isInlineBooking={true}
                    isTimeslots={props.isTimeslots}
                    isAccommodation={props.isAccommodation}
                />
            </BookingContextProvider>
        </WidgetWrapper>
    );
}
class BilberryBookingInline extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const productCatalogId = getStringAttribute(this, 'product-catalog-id', false) ?? '';
        const isTimeslots = getYesAttribute(this, 'timeslots');
        const isAccommodation = getYesAttribute(this, 'accommodation');

        createRoot(this._mountPoint).render(
            <BookingInline
                productCatalogId={productCatalogId}
                isTimeslots={isTimeslots}
                isAccommodation={isAccommodation}
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            />,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-inline', BilberryBookingInline);
}
