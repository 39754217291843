import { DateRange } from '@mui/x-date-pickers-pro';
import { tzdate, TZDate } from '@repo/tzdate';
import { ProductInstance, TicketOptionWithQuantity, Translations } from '@repo/types';
import { TimeSlotType } from './TimeSlotType';

export function getAvailableTimeSlots(
    availabilityData: ProductInstance[] | undefined,
): TimeSlotType[] {
    return (
        availabilityData?.map((productInstance) => {
            return {
                label: formatTime(productInstance.start),
                sublabel: formatTime(productInstance.end),
                product: productInstance,
            };
        }) ?? []
    );
}

export function findFirstAvailableProduct(
    availabilityData: ProductInstance[],
): ProductInstance | null {
    if (availabilityData.length === 0) return null;

    const availableAccommodations = availabilityData.filter(
        (accommodation) => accommodation.capacity > 0,
    );

    if (availableAccommodations.length > 0) return availableAccommodations[0];

    return availabilityData[0];
}

export const findAvailabilityData = (
    date: TZDate | null | undefined,
    availabilityData: ProductInstance[],
    quantities: TicketOptionWithQuantity[],
) => {
    if (!date) return [];

    const nonZeroQuantities = quantities.filter((x) => x.quantity > 0);

    // Every availability must have a price for the user-selected price categories,
    // unless no price categories have been selected
    const result = availabilityData.filter(
        (avail) =>
            avail.start.format('YYYY-MM-DD') === date.format('YYYY-MM-DD') &&
            (nonZeroQuantities.length === 0 ||
                avail.ticketOptions.some((y) => {
                    return nonZeroQuantities.some((z) => y.ticketCategoryId === z.ticketCategoryId);
                })),
    );

    return result;
};

export const tryFindFirstTimeslot = (
    date: TZDate | null | undefined,
    availabilityData: ProductInstance[],
    quantities: TicketOptionWithQuantity[],
) => {
    const availabilityDataForDate = findAvailabilityData(date, availabilityData, quantities);
    const availableTimeSlots = getAvailableTimeSlots(availabilityDataForDate);
    return availableTimeSlots.length > 0 ? availableTimeSlots[0] : undefined;
};

export function getAllDatesInDateRange(dateRange: DateRange<TZDate> | undefined) {
    const dates: TZDate[] = [];
    if (!dateRange?.[0]) return dates;

    dates.push(dateRange[0]);

    if (dateRange[1]) {
        let currentDate = dateRange[0].add(1, 'day');

        while (currentDate <= dateRange[1]) {
            dates.push(currentDate);
            currentDate = currentDate.add(1, 'day');
        }
    }
    return dates;
}

export function formatTime(date: TZDate) {
    return date.formatInCalendarTimezone('HH:mm');
}

/**
 * Formats a provided number of minutes into a duration using days, hours and minutes.
 *
 * @param t - Translations
 * @param minutes - Number of minutes
 */
export function formatMinutesToDaysHoursMinutes(t: Translations, minutes: number) {
    const hours = Math.floor(minutes / 60);
    const minutesRest = minutes % 60;

    const days = Math.floor(hours / 24);
    const hoursRest = hours % 24;

    const dayString = days > 0 ? `${days}${t.dayShort}` : ``;
    const hourString = hoursRest > 0 ? `${hoursRest}${t.hourShort}` : ``;
    const minuteString = minutesRest > 0 ? `${minutesRest}${t.minuteShort}` : ``;

    const parts = [dayString, hourString, minuteString].filter((x) => x.length > 0);
    return parts.join(' ');
}

export function getValueCardDateRange(validFrom: string, validTo: string) {
    const validFromDayJs = tzdate(validFrom, false);
    const validToDayJs = tzdate(validTo, false);

    return {
        startDate: validFromDayJs.format('ll'),
        endDate: validToDayJs.format('ll'),
    };
}
