import { registerWidgetUsage } from 'src/metrics/bilberryWidgetMetrics';
import { debugLog } from '@repo/common-utils/Logger';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';

export class BilberryCustomElementBase extends HTMLElement {
    constructor() {
        super();
    }

    protected connectedCallback(): void {
        debugLog('BilberryCustomElement connectedCallback for ', this.tagName);
        if (!configurationAtom.subject.value.disableBilberryWidgetMetrics) {
            debugLog('Bilberry widget metrics are enabled. Registering...');
            registerWidgetUsage(this);
        } else {
            debugLog('Bilberry widget metrics are disabled');
        }
    }
}
