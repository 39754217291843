import { useEffect } from 'react';
import useNewCartItem from './useNewCartItem';
import { useBookingContext } from 'src/widgets/BookingContext';

export default function useUpdateNewCartItem(
    newItemDisablePaymentPlans: boolean,
    newItemRequiresPaymentPlans: boolean,
) {
    const { setNewCartItem, selectedProducts, quantities, selectedTimeSlot } = useBookingContext();
    const selectedNewCartItem = useNewCartItem(
        selectedProducts,
        quantities,
        selectedTimeSlot?.products,
        newItemDisablePaymentPlans,
        newItemRequiresPaymentPlans,
    );
    useEffect(() => {
        if (selectedNewCartItem) {
            setNewCartItem(selectedNewCartItem);
        } else {
            setNewCartItem(null);
        }
    }, [selectedNewCartItem, setNewCartItem]);
}
