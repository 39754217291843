import { getDialcodes, getUserCountry, norway } from '@repo/common-utils/countries';
import {
    validateEmail,
    validatePhone,
    validateTextField,
} from '@repo/common-utils/validation-helper';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { PaymentRedirectContext } from './payment-redirect-helper';
import { uniqBy } from 'lodash-es';
import {
    CartItem,
    CheckoutInfo,
    CheckoutQuestionnaire,
    CheckoutQuestionWithAnswer,
    CompanyInfo,
    Iso2Code,
    ContactPerson,
    CompletedGiftcardPaymentInfo,
    CompletedPaymentInfo,
    Purchase,
    MembershipUser,
} from '@repo/types';
import { configurationAtom } from './widgetsConfiguration';
import { localeAtom } from '@repo/i18n';

export function createInitialCheckoutInfoData(
    cachedCheckoutInfo: CheckoutInfo | null,
    cartItems: CartItem[],
    user: MembershipUser | null,
): CheckoutInfo | null {
    const initialContactPerson = createInitialContactPerson(cachedCheckoutInfo, user);
    const initialCompanyInfo = createInitialCompanyInfo(cachedCheckoutInfo, user);

    const initialCheckoutInfo: CheckoutInfo = {
        contactType: configurationAtom.subject.value.enableInvoicePayment
            ? cachedCheckoutInfo?.contactType ?? 'person'
            : 'person',
        copyFromContactPerson: false,
        guestListQuestionnaire: {},
        contactPerson: initialContactPerson,
        companyInfo: initialCompanyInfo,
        orderQuestionnaire: {},
    };

    initialCheckoutInfo.orderQuestionnaire = cartItems.reduce((acc, cur) => {
        const id = getCartItemId(cur);
        const product = cur.products[0]; // TODO: handle packages correctly. As it is now, we only get the order questionnaire from the first productinstance, but we need to get all "connected" instances.
        if (product) {
            acc[id] = product.orderQuestionnaire.reduce((questionnaireAcc, question) => {
                const cachedQuestion = cachedCheckoutInfo?.orderQuestionnaire[id]?.[question.id];
                return {
                    ...questionnaireAcc,
                    [question.id]: {
                        ...question,
                        answer: cachedQuestion
                            ? cachedQuestion.answer
                            : question.type === 'checkbox'
                            ? false
                            : '',
                    },
                };
            }, {} as CheckoutQuestionnaire);
        }
        return acc;
    }, {} as CheckoutInfo['orderQuestionnaire']);

    initialCheckoutInfo.guestListQuestionnaire = cartItems.reduce((acc, cur) => {
        const id = getCartItemId(cur);
        const guestListQuestionnaires = uniqBy(
            cur.products.flatMap((product) => product.guestListQuestionnaire),
            (glq) => glq.id,
        );

        acc[id] = cur.ticketOptions
            .filter((opt) => opt.quantity > 0)
            .reduce((guestAcc, opt) => {
                // Add one guest list entity per quantity * pax
                for (let i = 0; i < opt.quantity * opt.guests; i++) {
                    guestAcc[opt.id + i] = guestListQuestionnaires.reduce((glAcc, question) => {
                        const cachedQuestion =
                            cachedCheckoutInfo?.guestListQuestionnaire[id]?.[opt.id + i]?.[
                                question.id
                            ];
                        glAcc[question.id] = {
                            ...question,
                            answer: cachedQuestion
                                ? cachedQuestion.answer
                                : question.type === 'checkbox'
                                ? false
                                : '',
                        };
                        return glAcc;
                    }, {} as CheckoutQuestionnaire);
                }
                return guestAcc;
            }, {} as CheckoutInfo['guestListQuestionnaire']['']);
        return acc;
    }, {} as CheckoutInfo['guestListQuestionnaire']);

    return initialCheckoutInfo;
}

export function validateBilberryQuestion(question: CheckoutQuestionWithAnswer) {
    let isValid = true;
    if (
        question.mandatory &&
        typeof question.answer === 'string' &&
        !validateTextField(question.answer)
    ) {
        isValid = false;
    } else if (
        question.mandatory &&
        typeof question.answer === 'boolean' &&
        question.answer === false
    ) {
        isValid = false;
    }
    return isValid;
}

export function validateCheckoutInfoData(
    checkoutInfoData: CheckoutInfo,
    showAddressFields: boolean,
) {
    const allQuestions = [
        ...Object.values(checkoutInfoData.orderQuestionnaire).flatMap((x) => Object.values(x)),
        ...Object.values(checkoutInfoData.guestListQuestionnaire).flatMap((x) =>
            Object.values(x).flatMap((y) => Object.values(y)),
        ),
    ];

    const anyInvalidQuestions = allQuestions.some((question) => {
        return !validateBilberryQuestion(question);
    });

    let isContactOrCompanyInfoValid = true;
    if (checkoutInfoData.contactType === 'company') {
        const { isValid } = validateCompanyInfo(checkoutInfoData.companyInfo);
        isContactOrCompanyInfoValid = isValid;
    } else if (checkoutInfoData.contactType === 'person') {
        const { isValid } = validateContactPerson(
            checkoutInfoData.contactPerson,
            showAddressFields,
        );
        isContactOrCompanyInfoValid = isValid;
    }

    return isContactOrCompanyInfoValid && !anyInvalidQuestions;
}

export function validateCompanyInfo(companyInfo: CompanyInfo) {
    const isCompanyNameValid = validateTextField(companyInfo.companyName);
    const isOrgNumberValid = companyInfo.orgNumber.match(/^[0-9]{9}$/) !== null;
    const isContactPersonValid = validateTextField(companyInfo.contactPerson);
    const isPhoneValid = validatePhone(companyInfo.phone);
    const isEmailValid = validateEmail(companyInfo.email);
    const isAddressValid = validateTextField(companyInfo.address);
    const isPostCodeValid = validateTextField(companyInfo.postCode);
    const isCityValid = validateTextField(companyInfo.city);

    const isValid =
        isOrgNumberValid &&
        isPhoneValid &&
        isEmailValid &&
        isAddressValid &&
        isPostCodeValid &&
        isCityValid &&
        companyInfo.hasAcceptedPrivacy &&
        companyInfo.hasAcceptedTerms;

    return {
        isCompanyNameValid,
        isContactPersonValid,
        isOrgNumberValid,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isPostCodeValid,
        isCityValid,
        isValid,
    };
}

export function validateContactPerson(contactPerson: ContactPerson, showAddressFields: boolean) {
    const isFirstNameValid = validateTextField(contactPerson.firstName);
    const isLastNameValid = validateTextField(contactPerson.lastName);
    const isPhoneValid = validatePhone(contactPerson.phone);
    const isEmailValid = validateEmail(contactPerson.email);
    const isAddressValid = !showAddressFields || validateTextField(contactPerson.address);
    const isPostCodeValid = !showAddressFields || validateTextField(contactPerson.postCode);
    const isCityValid = !showAddressFields || validateTextField(contactPerson.city);

    const isValid =
        isFirstNameValid &&
        isLastNameValid &&
        isPhoneValid &&
        isEmailValid &&
        isLastNameValid &&
        isAddressValid &&
        isPostCodeValid &&
        isCityValid &&
        contactPerson.hasAcceptedPrivacy &&
        contactPerson.hasAcceptedTerms;

    return {
        isFirstNameValid,
        isLastNameValid,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isPostCodeValid,
        isCityValid,
        isValid,
    };
}

export function createInitialContactPerson(
    cachedCheckoutInfo: CheckoutInfo | null,
    user: MembershipUser | null,
): ContactPerson {
    const storedContactPerson = cachedCheckoutInfo?.contactPerson;
    const userCountry = getUserCountry(localeAtom.subject.value.locale, user?.country ?? '');

    const country =
        (storedContactPerson?.country || userCountry) ??
        (configurationAtom.subject.value.defaultCountry as Iso2Code | undefined) ??
        norway;

    return {
        firstName: (storedContactPerson?.firstName || user?.firstName) ?? '',
        lastName: (storedContactPerson?.lastName || user?.lastName) ?? '',
        country: country,
        phone: {
            dialCode:
                (storedContactPerson?.phone.dialCode || user?.phonePrefix) ??
                getDialcodes()[country],
            number: (storedContactPerson?.phone.number || user?.phoneNumber) ?? '',
        },
        email: (storedContactPerson?.email || user?.email) ?? '',
        address: (storedContactPerson?.address || user?.addressLine1) ?? '',
        postCode: (storedContactPerson?.postCode || user?.postalCode) ?? '',
        city: (storedContactPerson?.city || user?.city) ?? '',
        hasAcceptedPrivacy: storedContactPerson?.hasAcceptedPrivacy ?? false,
        hasAcceptedTerms: storedContactPerson?.hasAcceptedTerms ?? false,
        receiveNewsletter: storedContactPerson?.receiveNewsletter ?? false,
    };
}

export function createInitialCompanyInfo(
    cachedCheckoutInfo: CheckoutInfo | null,
    user: MembershipUser | null,
): CompanyInfo {
    const storedCompanyInfo = cachedCheckoutInfo?.companyInfo;
    const userCountry = getUserCountry(localeAtom.subject.value.locale, user?.country ?? '');
    const country =
        (storedCompanyInfo?.country || userCountry) ??
        (configurationAtom.subject.value.defaultCountry as Iso2Code | undefined) ??
        norway;

    return {
        companyName: storedCompanyInfo?.companyName ?? '',
        orgNumber: storedCompanyInfo?.orgNumber ?? '',
        contactPerson: storedCompanyInfo?.contactPerson ?? '',
        country,
        phone: {
            dialCode:
                (storedCompanyInfo?.phone.dialCode || user?.phonePrefix) ?? getDialcodes()[country],
            number: (storedCompanyInfo?.phone.number || user?.phoneNumber) ?? '',
        },
        email: (storedCompanyInfo?.email || user?.email) ?? '',
        address: (storedCompanyInfo?.address || user?.addressLine1) ?? '',
        postCode: (storedCompanyInfo?.postCode || user?.postalCode) ?? '',
        city: (storedCompanyInfo?.city || user?.city) ?? '',
        hasAcceptedPrivacy: storedCompanyInfo?.hasAcceptedPrivacy ?? false,
        hasAcceptedTerms: storedCompanyInfo?.hasAcceptedTerms ?? false,
        receiveNewsletter: storedCompanyInfo?.receiveNewsletter ?? false,
    };
}

export function findContactPersonValue(
    contactPerson: ContactPerson | null,
    key: string,
): string | null {
    if (!contactPerson) return null;

    switch (key.toLowerCase()) {
        case 'fornavn':
        case 'firstname':
        case 'first_name':
            return contactPerson.firstName;
        case 'etternavn':
        case 'lastname':
        case 'last_name':
            return contactPerson.lastName;
        default:
            return null;
    }
}

export function validateGiftcardInfo(value: number, contactPerson: ContactPerson) {
    const isValueValid = value > 0;
    const { isValid: isContactPersonValid } = validateContactPerson(contactPerson, false);
    return isValueValid && isContactPersonValid;
}

export function createInitialCompletedPaymentInfo(
    storedPurchase: Purchase | null,
    paymentRedirectContext: PaymentRedirectContext | null,
) {
    if (!paymentRedirectContext) return null;

    const initialCompletedPaymentInfo: CompletedGiftcardPaymentInfo & CompletedPaymentInfo = {
        paymentId: paymentRedirectContext.paymentId,
        referenceId:
            storedPurchase && storedPurchase.paymentId === paymentRedirectContext.paymentId
                ? storedPurchase.referenceId
                : '',
    };
    return initialCompletedPaymentInfo;
}
