import { RefObject } from 'react';
import { atom } from 'ximple';

type DisplayModalState = {
    content: JSX.Element;
    id: string;
    onClose: () => void;
    refocusElementOnClose?: string | RefObject<HTMLElement>;
    shadowRoot?: ShadowRoot;
    large?: boolean;
};

interface IDisplayModalEvent {
    type: 'SHOW_MODAL' | 'HIDE_MODAL';
}

interface IShowModalEvent extends IDisplayModalEvent {
    type: 'SHOW_MODAL';
    data: DisplayModalState;
}

interface IHideModalEvent extends IDisplayModalEvent {
    type: 'HIDE_MODAL';
    data: string;
}

const reduceDisplayModalState = (
    state: DisplayModalState[],
    action: IShowModalEvent | IHideModalEvent,
) => {
    let index = -1;
    switch (action.type) {
        case 'SHOW_MODAL':
            index = state.findIndex((x) => x.id === action.data.id);
            return [...state.slice(0, index), action.data, ...state.slice(index + 1)];

        case 'HIDE_MODAL':
            index = state.findIndex((x) => x.id === action.data);
            if (index === -1) return state;
            return [...state.slice(0, index), ...state.slice(index + 1)];

        default:
            return state;
    }
};

export const displayModalAtom = atom<DisplayModalState[], IShowModalEvent | IHideModalEvent>({
    initialValue: [],
    update: reduceDisplayModalState,
});
