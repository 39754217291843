import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';
import {
    Box,
    Collapse,
    Fade,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { DateRangePicker } from 'src/components/common/date-range-picker/DateRangePicker';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import BookingWidgetCard from 'src/components/domain/booking-widget-card/BookingWidgetCard';
import useLeadsState from 'src/hooks/domain/useLeadsState';
import { getCustomOrDefaultText, useLocale } from '@repo/i18n';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import TextFieldStyleUtils, {
    bookingWidgetInputStyle,
} from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { getPrimaryColors } from 'src/components/utils/theme/getPrimaryColors';
import { TZDate } from '@repo/tzdate';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';
import { UserTextCustomizations } from '@repo/types';

export default function RequestTour(props: {
    titleTextCustomizationKey?: keyof UserTextCustomizations;
    submitButtonTextCustomizationKey?: keyof UserTextCustomizations;
    thankYouTextCustomizationKey?: keyof UserTextCustomizations;
    expandArrowInside: boolean;
    hideRequestDate: boolean;
    positionOffscreen?: boolean;
}): JSX.Element {
    const {
        titleTextCustomizationKey,
        submitButtonTextCustomizationKey,
        thankYouTextCustomizationKey,
        expandArrowInside,
        hideRequestDate,
        positionOffscreen = false,
    } = props;
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const configurations = useConfigurations();

    const [{ visible }] = useAtom(toggleBookingAtom);

    const onToggleVisible = useCallback((visible: boolean, interaction: 'keyboard' | 'mouse') => {
        toggleBookingAtom.update({ visible, interaction });
    }, []);

    const {
        quantities,
        onClickGotIt,
        onClickBook,
        showConfirm,
        showSubmitError,
        emailValid,
        nameValid,
        email,
        setEmail,
        setName,
        name,
        descriptionValid,
        description,
        setDescription,
        onChangeQuantities,
        setPhone,
        phone,
        setDateRange,
        dateRange,
        dateRangeValid,
        defaultQuantities,
    } = useLeadsState(hideRequestDate);

    const colors = bookingWidgetInputStyle(
        customizations,
        customizations.bookingWidgetColor,
        customizations.bookingWidgetColorContrast,
    );

    const textfieldStyles = {
        '& .MuiInputBase-root': {
            '&:hover,&:focus,&:active,&.Mui-focused': {
                boxShadow: `0px 0px 2px 2px ${
                    getPrimaryColors(
                        customizations.bookingWidgetColor,
                        customizations.bookingWidgetPrimaryColor,
                        customizations.bookingWidgetColor,
                        true,
                    ).backgroundColor
                }`,
            },
        },
        '& .MuiFilledInput-input': {
            padding: theme.spacing(1.5),
            borderRadius: customizations.borderRadius,
            color: colors.color,
        },
        '& .MuiFilledInput-root': TextFieldStyleUtils.bookingWidgetInputStyle(
            customizations,
            colors.backgroundColor,
            colors.color,
        ),
        '& textarea': {
            marginBottom: theme.spacing(1.5),
        },
    };

    const inputlabelStyles = {
        ...TextFieldStyleUtils.positionInputLabelAboveField,
        whiteSpace: 'nowrap',
        color: customizations.bookingWidgetColorContrast,
    };

    return (
        <BookingWidgetCard
            visible={visible}
            onToggleVisible={onToggleVisible}
            title={capitalize(
                getCustomOrDefaultText(
                    configurations.textCustomizations,
                    titleTextCustomizationKey,
                    locale,
                    t.request_tour,
                ),
            )}
            justifyTitle="center"
            hideLeftButton={true}
            rightButtonLabel={capitalize(
                getCustomOrDefaultText(
                    configurations.textCustomizations,
                    submitButtonTextCustomizationKey,
                    locale,
                    t.submit_request,
                ),
            )}
            onClickRightButton={showConfirm ? onClickGotIt : onClickBook}
            expandArrowInside={expandArrowInside}
            toggleButton={
                expandArrowInside ? (
                    <Box
                        position="absolute"
                        mt={0}
                        right="50%"
                        left="50%"
                        p={0}
                        sx={[
                            visible
                                ? { top: '-1.5em', transform: 'rotate(90deg)' }
                                : { top: '1.5em', transform: 'rotate(-90deg)' },
                            {
                                backgroundColor: customizations.bookingWidgetColor,
                                color: customizations.bookingWidgetPrimaryColor,

                                '&:hover, &:active': {
                                    backgroundColor: customizations.bookingWidgetColor,
                                    color: customizations.bookingWidgetPrimaryColor,
                                },
                                '& .MuiSvgIcon-root': {
                                    color: customizations.bookingWidgetPrimaryColor,
                                    transform: 'scale(2)',
                                },
                            },
                        ]}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => onToggleVisible(!visible, 'mouse')}
                            sx={{ transform: 'scale(0.6)' }}
                            aria-label={
                                visible
                                    ? t.aria_close_request_tour_widget
                                    : t.aria_open_request_tour_widget
                            }
                        >
                            {visible ? <ArrowForwardIos /> : <ArrowForwardIos />}
                        </IconButton>
                    </Box>
                ) : (
                    <IconButton
                        sx={{
                            backgroundColor: customizations.bookingWidgetColor,
                            color: customizations.bookingWidgetPrimaryColor,
                            position: 'absolute',
                            top: '-1em',
                            marginTop: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            '&:hover, &:active': {
                                backgroundColor: customizations.bookingWidgetColor,
                                color: customizations.bookingWidgetPrimaryColor,
                            },
                            '& .MuiSvgIcon-root': {
                                color: customizations.bookingWidgetPrimaryColor,
                            },
                        }}
                        color="primary"
                        onClick={() => onToggleVisible(!visible, 'mouse')}
                        aria-label={
                            visible
                                ? t.aria_close_request_tour_widget
                                : t.aria_open_request_tour_widget
                        }
                    >
                        {visible ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                    </IconButton>
                )
            }
            positionOffscreen={positionOffscreen}
        >
            <Collapse in={showConfirm} sx={{ marginTop: '0 !important' }}>
                <Fade in={showConfirm}>
                    <div>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            position="relative"
                            pt={theme.spacing(4)}
                            width="100%"
                            sx={{
                                backgroundColor: customizations.bookingWidgetColor,
                            }}
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                color={{ color: customizations.bookingWidgetColorContrast }}
                            >
                                {showSubmitError
                                    ? t.an_error_occurred_when_submitting_your_request
                                    : capitalize(
                                          getCustomOrDefaultText(
                                              configurations.textCustomizations,
                                              thankYouTextCustomizationKey,
                                              locale,
                                              t.thank_you_for_your_request,
                                          ),
                                      )}
                            </Typography>
                        </Grid>
                    </div>
                </Fade>
            </Collapse>
            <Collapse in={!showConfirm}>
                <Fade in={!showConfirm}>
                    <Grid container direction="column">
                        <FormControl fullWidth={true}>
                            <MultipleNumberInput
                                values={quantities}
                                defaultValues={defaultQuantities}
                                onChange={onChangeQuantities}
                                labelColor={customizations.bookingWidgetColorContrast}
                            />
                        </FormControl>
                        {!hideRequestDate && (
                            <Box flex="1 0 auto">
                                <DateRangePicker
                                    dateRange={dateRange}
                                    onSelectDateRange={setDateRange}
                                    minDate={TZDate.now()}
                                    required={true}
                                    label={t.when_are_you_going}
                                    showError={!dateRangeValid}
                                    labelColor={customizations.bookingWidgetColorContrast}
                                    backgroundColor={colors.backgroundColor}
                                    color={colors.color}
                                />
                            </Box>
                        )}

                        <FormControl fullWidth error={!descriptionValid}>
                            <InputLabel
                                id="comment-input-label"
                                required={true}
                                sx={inputlabelStyles}
                            >
                                {t.comment}
                            </InputLabel>
                            <TextField
                                sx={textfieldStyles}
                                multiline
                                required={true}
                                minRows={1}
                                maxRows={8}
                                value={description}
                                onChange={(e) => setDescription(e.currentTarget.value)}
                                helperText={!descriptionValid ? t.please_provide_a_description : ''}
                                error={!descriptionValid}
                                variant="filled"
                                margin="dense"
                                size="small"
                                type="text"
                                color="secondary"
                                name="comment"
                                placeholder={t.request_tour_comment}
                                inputProps={{
                                    'aria-labelledby': 'comment-input-label',
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!nameValid}>
                            <InputLabel id="name-input-label" required={true} sx={inputlabelStyles}>
                                {t.name}
                            </InputLabel>
                            <TextField
                                sx={textfieldStyles}
                                required={true}
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                                error={!nameValid}
                                helperText={!nameValid ? t.please_provide_your_name : ''}
                                variant="filled"
                                type="text"
                                color="secondary"
                                margin="dense"
                                size="small"
                                name="name"
                                inputProps={{
                                    'aria-labelledby': 'name-input-label',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!emailValid}>
                            <InputLabel
                                id="email-input-label"
                                required={true}
                                sx={inputlabelStyles}
                            >
                                {t.email}
                            </InputLabel>
                            <TextField
                                required={true}
                                error={!emailValid}
                                value={email}
                                sx={textfieldStyles}
                                onChange={(e) => setEmail(e.currentTarget.value)}
                                helperText={!emailValid ? t.please_provide_your_email : ''}
                                variant="filled"
                                type="text"
                                color="secondary"
                                margin="dense"
                                size="small"
                                name="email"
                                inputProps={{
                                    'aria-labelledby': 'email-input-label',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="mobile-input-label" sx={inputlabelStyles}>
                                {t.phone_number}
                            </InputLabel>
                            <TextField
                                sx={textfieldStyles}
                                value={phone}
                                onChange={(e) => setPhone(e.currentTarget.value)}
                                variant="filled"
                                type="tel"
                                color="secondary"
                                margin="dense"
                                size="small"
                                name="mobile"
                                inputProps={{
                                    'aria-labelledby': 'mobile-input-label',
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Fade>
            </Collapse>
        </BookingWidgetCard>
    );
}
