import { CartItem, MembershipIntentMultiResponse } from '@repo/types';
import {
    addMembershipToCartItems,
    removeMembershipFromCartItems,
} from '@repo/widget-utils/cart/cartUtils';
import { useMemo } from 'react';

export default function useCartItemsWithMembershipAddedOrRemoved(
    cartItems: CartItem[],
    isUsingPaymentPlan: boolean,
    paymentPlanResponse: MembershipIntentMultiResponse | null,
) {
    return useMemo(() => {
        return paymentPlanResponse && isUsingPaymentPlan
            ? addMembershipToCartItems(cartItems, paymentPlanResponse)
            : removeMembershipFromCartItems(cartItems);
    }, [cartItems, paymentPlanResponse, isUsingPaymentPlan]);
}
