import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import ChildCareRounded from '@mui/icons-material/ChildCareRounded';
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded';
import { Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { getCustomOrDefaultText, useLocale } from '@repo/i18n';
import { Product, UserTextCustomizations } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { formatMinutesToDaysHoursMinutes } from '@repo/widget-utils/DateHelpers';
import { getDifficultyText } from '@repo/widget-utils/mapToTranslations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

type IProps = {
    product: Product;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
};

function OverviewIcon(props: { Icon: typeof SvgIcon | string; label?: string; value?: string }) {
    const { Icon, value, label } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    const isSvgUrl = typeof Icon === 'string' && Icon.endsWith('.svg');

    return (
        <Box
            display="inline-flex"
            flexDirection="row"
            boxSizing="border-box"
            p={theme.spacing(0, 2, 2, 0)}
            sx={{
                boxSizing: 'border-box',
                display: 'inline-flex',
                flexDirection: 'row',
                padding: theme.spacing(0, 2, 2, 0),
                '&:last-of-type': {
                    paddingRight: 0,
                },
                [theme.breakpoints.down('sm')]: {
                    flex: '1',
                    minWidth: '50%',
                    '&:last-of-type': {
                        paddingRight: theme.spacing(2),
                    },
                },
            }}
        >
            <Box flex="1" display="flex" alignItems="flex-start">
                {typeof Icon !== 'string' ? (
                    <Icon sx={{ fontSize: '2.5em' }} />
                ) : isSvgUrl ? (
                    <Box
                        aria-hidden
                        height="2.5em"
                        width="2.5em"
                        component="object"
                        type="image/svg+xml"
                        sx={{
                            maskImage: `url(${Icon})`,
                            maskSize: 'contain',
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center',
                            background: theme.palette.primary.main,
                        }}
                    />
                ) : (
                    <Box component="img" src={Icon} aria-hidden height="2.5em" width="auto" />
                )}
            </Box>
            <Box
                pl={theme.spacing(1)}
                display="inline-flex"
                flexDirection="column"
                flex="2"
                sx={{
                    '& p': {
                        whiteSpace: 'break-spaces',
                    },
                }}
            >
                {label && <Typography fontSize="0.85rem">{label}</Typography>}
                {value && (
                    <Typography
                        variant="h5"
                        component="p"
                        fontWeight={customizations.boldFontWeight}
                    >
                        {value}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default function OverviewIconRow(props: IProps) {
    const { t, locale } = useLocale();
    const configurations = useConfigurations();
    const {
        product,
        durationTextCustomizationKey,
        difficultyTextCustomizationKey,
        ageTextCustomizationKey,
        pricesFromTextCustomizationKey,
    } = props;

    const minAge =
        product?.fromAge ??
        product?.ticketOptions.reduce(
            (acc, price) => Math.min(acc, price?.fromAge ?? Number.MAX_VALUE),
            Number.MAX_VALUE,
        ) ??
        Number.MAX_VALUE;

    if (product.icons) {
        if (product.icons.length === 0) return null;
        return (
            <Box
                position="relative"
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                {product.icons.map((icon, i) => (
                    <OverviewIcon key={i} Icon={icon.url} label={icon.label} value={icon.text} />
                ))}
            </Box>
        );
    }

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
        >
            {product.duration !== -1 && (
                <OverviewIcon
                    Icon={AccessAlarmsRoundedIcon}
                    label={getCustomOrDefaultText(
                        configurations.textCustomizations,
                        durationTextCustomizationKey,
                        locale,
                        t.duration,
                    )}
                    value={formatMinutesToDaysHoursMinutes(t, product.duration)}
                />
            )}
            <OverviewIcon
                Icon={TrendingUpRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    difficultyTextCustomizationKey,
                    locale,
                    t.difficulty,
                )}
                value={getDifficultyText(t, product)}
            />
            <OverviewIcon
                Icon={ChildCareRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    ageTextCustomizationKey,
                    locale,
                    t.age,
                )}
                value={minAge < Number.MAX_VALUE ? `${minAge}+` : '-'}
            />
            <OverviewIcon
                Icon={LocalOfferRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    pricesFromTextCustomizationKey,
                    locale,
                    t.prices_from,
                )}
                value={`${product.fromPrice}`}
            />
        </Box>
    );
}
