import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { DateRangePicker } from '../../common/date-range-picker/DateRangePicker';

type IProps = {
    dateRange: DateRange<TZDate>;
    onDateRangeChanged: (newRange: DateRange<TZDate>) => void;
    minDate?: TZDate;
};

export default function HotelCalendar(props: IProps): JSX.Element {
    const customizations = useCustomizations();
    const { t } = useLocale();
    return (
        <DateRangePicker
            dateRange={props.dateRange}
            onSelectDateRange={props.onDateRangeChanged}
            label={capitalize(t.check_in_check_out)}
            labelColor={customizations.accommodationSearchFormColorContrast}
            minDate={props.minDate}
            required
            dateRangeVariant="nights"
        />
    );
}
